import React, {useState} from 'react';
import styled from 'styled-components';


const TableWrapper = styled.div`
   .tbl {
    height: 37rem;
    overflow-y:scroll;
   }

   .tbl-lng {
    height: 50rem;
    overflow-y:scroll;
   }

   .tbl-med {
    height: 65rem;
    overflow-y:scroll;
   }


`;


const Table = (props) => {

    const data_table = () => {
        try {
           
            if (Array.isArray(props.data)) {
              return props.data.map((item, index)=>{
               
                  return (
                       
                    <li className={props.row_class} key={index} id={item.idx} onClick={props.select}>
                        {props.meta.map((value, index) => {
                        return<div key={index} className={`col ${props.meta[index].col_width}`} data-label={`${props.meta[index].data_label}`}>{item[props.meta[index].key]}</div> 
                        })}
                    </li>
                    
                   );
              });
          } else {
            return (
               
                    <li className={props.row_class}>
                            <div>The Data List is Empty.</div>
                    </li>
               
            );
          }
          } catch (err) {
            return;
          }
        
    }

   

    return(
        <TableWrapper>
            <div className={`${props.table_height}`}>
                <ul className="responsive-table">
                    {props.include_header ? 
                    <li className="table-header">
                        {props.meta.map((value, index) => {
                        return <div key={index} className={`col ${props.meta[index].col_width}`}>{`${props.meta[index].data_label}`}</div>
                        })}
                    </li>
                    : ''}
                    {data_table()}
                </ul>
            </div>
        </TableWrapper>
    );
}

export default Table;
