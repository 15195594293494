import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';

const AddNoteWrapper = styled.div`
   
    width: 40rem;
    height: 30rem;
    margin: auto;
    background-color: var(--color-grey-dark-2);
    color: #fff;
    
    border: 2rem solid  rgba(15, 215, 239, 1);
    border-radius: 5px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    font-size: 2rem;
    white-space: nowrap;
    box-shadow: 0 1rem 3rem rgba(#fff, .2);
    
   
    #scan__title {
        font-family: 'BenchNine' sans-serif;
        font-size: 2.4rem;
        font-weight: 700;
        text-transform: uppercase;
        align-self: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
       
    }

   

    .note__input {
       
        
        color: #000;
        width:20rem;
        border:none;
        background-color: var(--color-grey-light-2);
        outline: none;
        height: 10rem;
        padding-left: 1rem;
        
    }
    

    #note__input {

    font-family: inherit;
    
    font-size: 1.75rem;
    font-weight: 400;   
    border-radius: 2px;
    background-color: var(--color-grey-dark-2);
    border: none;
    width: 20rem;
    height: 10rem;
   

    }

    .header {
        width: 100%;
        margin-top: -4rem;
     }

   .submit_note {
         margin-top: 2.75rem;
         width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

   }




`;




const  AddNote = (props)  => {
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  
   
    useEffect(() => {
      
    }, []);
    
  const submitNote = () => {
       
        props.submit(value);
        props.close();
    }
  
   


    return (
        <AddNoteWrapper>

         
           <div className="header"><FormHeader onClose={props.close} title="Add Dispense Comment" addClose={true} class_style="form_header--light"></FormHeader></div>
             <div id='note__input'>
             <textarea autoFocus rows="3" cols="20"  className="note__input" onChange={(e) => setValue(e.target.value)} maxLength="210"></textarea>
              {/* <input onChange={(e) => setValue(e.target.value)} autoFocus type="text" className="note__input" maxLength="210"  /> */}
            </div>
            <div className="submit_note">{ disabled ? <div></div> : <ButtonExpand title="SUBMIT" click={submitNote} />}</div>


        </AddNoteWrapper>
    );
}

export default AddNote;
