import React, { useState } from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';
import SessionTimer from './session-timer';

const IdleModalWrapper = styled.div`

    z-index:9998;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
   
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .modal_container { 
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
       
        height: 25vh;
        width: 35vw;
        background-color: var(--color-grey-dark-2);
        opacity: 1;
        transition: all .3s;
        min-height: 36.3rem;
        border: 2rem solid  rgba(15, 215, 239, 1);
        border-radius: 5px;

        @media screen and (max-width: 1200px) {
            height: 35vh;
            width: 50vw;
        }

      }

      .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        margin-top: 3rem;
      }

      .modal-body {
        font-size: 2rem;
        color: navy;
        display: flex;
        flex-direction: column; 
        align-items: center;
        justify-content: center;
        margin-top: var(--line);
        margin-bottom: var(--line);
      }
     

      .modal-nav-timer {
        margin-bottom: 3rem;
        background-color:navy;
      }

 
   

`;


const IdleModal = (props) => {

    const [stopped, setStopped] = useState(false);

    localStorage.setItem('session_seconds', '100');

    const submit = () => {
        console.log('submit stay');
       localStorage.setItem('session_seconds', '10002');
        props.stay();
    };

    const logout = () => {
        localStorage.setItem('session_seconds', '10002');
        console.log('submit logout');
        props.logout();
        
    };

    const session_timeout = () => {
        console.log(`session timeout`);
        localStorage.setItem('session_seconds', '10002');
        props.onTimeOut();
        
        

    }


    return(
        <IdleModalWrapper>
          <div className="modal_container">
           <FormHeader onClose={props.close} title="SESSION EXPIRATION" addClose={true} class_style="form_header--light"></FormHeader>
           
            <div className="modal-nav-timer">
                   <SessionTimer on_timeout={session_timeout}  /> 
            </div>   

            <div className="modal-body">
                <p>
                    Your session is about to expire. You'll be automatically signed out.
                </p>
                <p>
                    Do you want to stay signed in?
                </p>
            </div>
            <div className="modal-footer">
                <ButtonExpand title="Sign Out Now"  click={logout} />
                    
                
                <ButtonExpand title="Stay Signed In" click={submit} />
                    
            </div>
            </div>
        </IdleModalWrapper>
    );
}

export default IdleModal;