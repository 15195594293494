import { useEffect } from 'react';
import { latest_dsp_base, URL } from '../constants';
import { getAPI} from '../utility';

const DSPUpload = () => {

    useEffect(() => {
        loadDSPData(); 
    }, [])

    const loadDSPData = () => {
        for(var i in latest_dsp_base)
        {
           
            
            let obj = {};

            obj.PatientClinic_PK = latest_dsp_base[i].PatientClinic_PK;
            obj.Patient_FK = latest_dsp_base[i].Patient_FK;
            obj.DrugClinic_FK = latest_dsp_base[i].DrugClinic_FK; 
            obj.pvd_base_fk = latest_dsp_base[i].pvd_base_fk;
            obj.Written_Date = latest_dsp_base[i].WrittenDate;
            obj.DaysSupply = latest_dsp_base[i].DaysSupply;
            obj.RxNumber = latest_dsp_base[i].RxNumber;
            obj.Directions = latest_dsp_base[i].Directions;
            obj.EIE = latest_dsp_base[i].EIE;
            obj.EIEate = latest_dsp_base[i].EIEDate;
            obj.DispenseQty = latest_dsp_base[i].DispenseQty;    
           

            let json = JSON.stringify(obj);
      
         //POST DATA
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'x-functions-key': getAPI() },
          body: json
          };
          fetch(`${URL}uploadDispense`, requestOptions)
                .then(() => {
                  console.log("success" );
              })            
              .catch(err => {
                  console.log("error");
              });

    }

        
    }

    
 

}

export default DSPUpload;



