import React, { useState, useEffect } from 'react';
import Toast from './toaster';
import FormHeader from './form-header';
import { URL } from '../constants';
import InputMask from 'react-input-mask';
import {formatDate, getSession, getAPI } from '../utility';
import LoadingButton from '../theme/loading-button';
import styled from 'styled-components';
import moment from 'moment';
import { saveAs } from 'file-saver';

const ClnAdminCspmpWrapper = styled.div`
     font-size: 1.4rem;
    z-index: 9999;
    color:#fff;
    height: 70%;
    width: 100%;
    opacity: 1;
    transition: all .3s;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);
    }

    .rprt_message {
        font-size: 1.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    

`;


function DateInput(props) {
    return (
      <InputMask 
        className="form_date--light" 
        mask='99/99/9999'  
        defaultValue={props.value}
        onChange={props.onChange}>
       
      </InputMask>
    );
  }


const ClnAdminCspmp = (props) => {

    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');

   
    const handleInput = ({ target: { value } }) => setStart(value);
    const handleInputEnd = ({ target: { value } }) => setEnd(value);
   
    const [valid_err, setValErr] = useState([]);
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");
   
    const [disable, setDisable] = useState(false);
  
   
    const runReport = () => {

        setDisable(true);

        let current = new Date();
        let fileName = formatDate(current);
    
        
        const errors = [];
        let ul = '';


        let str = moment(start, "MM/DD/YYYY", true).isValid() ? start : errors.push("The Start Date Is Not Valid. Please Enter a New Start Date and try again.");  //*
        let ed = moment(end, "MM/DD/YYYY", true).isValid() ? end : errors.push("The End Date Is Not Valid. Please Enter a New End Date and try again.");  //*

        if (errors.length > 0) {
            setValErr(errors);
            setDisable(false);
           
        } else {

             setValErr([]);

            ul = `${URL}cspmpDateRange?cln=${getSession("cln")}&start=${str}&end=${ed}`;

            fetch(ul, {
                method: 'GET',
                    headers: {
                        'x-functions-key': getAPI(),	
                    }
                })  //
                .then(response => response.ok ? response :  (function(){throw new Error('Network response was not ok.')}()))
                .then( response => response.json())
                .then(rslt => {
                
              
                const file = new Blob([rslt], { type: 'text/plain;charset=utf-8' });
                saveAs(file, fileName + '.dat');
       
                setToastIcon("success");
                setToastMsg("CSPMP Report Generated Successfully");
                setToastState(true);
                setToastBackground("#5cb85c");
                setToastTitle("Success");
                setDisable(false);
 
             
             })
             .catch(err => {
                setToastIcon("error");
                setToastTitle("Error");
                setToastBackground("#d9534f");
                setToastMsg("There was a Problem Generating the CSPMP Report. Please Try Again Later.");
                setToastState(true);
                setDisable(false);
             });

           

        }
    };

 



    const clearForm = () => {

        setDisable(false);
        setValErr([]);
        setStart("");
        setEnd("");
     
    }
    
    
    

    const err = () => {
        try {
            if (Array.isArray(valid_err)) {
              return valid_err.map((item, index)=>{
                  return (
                        <ul>
                              <li className="err_list">{item}</li>
                             
                        </ul>
                   );
              });
          } else {
            return (
              <tr><td colSpan='8'><div>The Error List is Empty.</div></td></tr>
            );
          }
          } catch (err) {
            return;
          }
    }

        


    return(
        <ClnAdminCspmpWrapper>
        
                <div className="form__container">
                    
                    <div  className="form_content ">
                    <FormHeader title="MANUAL CSPMP REPORTING" addClose={false} class_style="form_header--light"></FormHeader>
                    <div className="rprt_message"><span>Enter a Start and End Date for the Report Date Range you wish to Run. </span><span>The Report will be Generated and automatically Downloaded to you Default Download Directory.</span> </div>
                    <div className="form_row2-col2">
                    <div className='start'>
                            <DateInput 
                                value={props.start}
                                onChange={handleInput}>
                                    
                            </DateInput>
                            <span className="form_label--light">Start Date</span>
                            
                            </div>
                            <div className='end'>

                            <DateInput props
                                value={props.end}
                                onChange={handleInputEnd}>
                            </DateInput>
                            <span className="form_label--light">End Date</span>
                            
                            </div>
                    </div>

                    <div id="error_list" >{err()}</div>
                    
                    <div className="form_btn_bar">
                           
                            <LoadingButton title="CLEAR FORM" id="check2" select={clearForm} />
                            { disable ? null : <LoadingButton id="check3" title="RUN REPORT" select={runReport} />}
                          
                    </div>
                       
                    </div>
                </div>
                <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>
        </ClnAdminCspmpWrapper>
    );
}
export default ClnAdminCspmp;