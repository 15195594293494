import React from 'react';
import styled from 'styled-components';
import { isAdmin, isGlobal } from '../utility';


const MenuDetailWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-grey-dark-2);
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 1.75rem;
    margin-top: 19rem;
    width: 30rem;
    padding: 5rem;
    border: 1px solid var(--color-grey-light-2);
    z-index:10;
   
    ul {
        width: 100%;
    }
    li {
        list-style-type: none;
        text-decoration: none;
        list-style: none;
        width: 100%;
        border-bottom: 1px solid var(--color-grey-light-2)

    }

    li:hover {
        background-color: var(--color-grey-light-2);
        cursor: pointer;
    }

`; 

const MenuDetail = (props) => {
    return (
        <MenuDetailWrapper>
            <ul>
                <li onClick={props.click1}>{props.item1}</li>
                <li onClick={props.click2}>{props.item2}</li>
                <li onClick={props.click3}>{props.item3}</li>
                <li onClick={props.click4}>{props.item4}</li>
                <li onClick={props.click5}>{isAdmin() ? props.item5 : "            "}</li>
                <li onClick={props.click6}>{isGlobal() ? props.item6 : "           "}</li>
            </ul>
        </MenuDetailWrapper>
        //  
    )
}

export default MenuDetail;