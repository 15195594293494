import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import Table from './table';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import ReactLoading from 'react-loading';

const LastEncounterWrapper = styled.div`

    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 998;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;



        @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
            -webkit-backdrop-filter: grayscale(80%) blur(5px);
            backdrop-filter: grayscale(70%) blur(5px);
            background-color: rgba(#000, .4);

        }





.last_encounter_content {
        height: 70vh;
        width: 80vw;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 999;
        opacity: 1;
        transition: all .3s;
        background-color:  rgba(0,0,0, 0.6); 
        backdrop-filter: blur(19px);
        -webkit-backdrop-filter: blur(19px);
        border-radius: 10px;
        border: 1px solid rgba(255,255,255,0.6);
        font-size: 2rem;

}


`;

const LastEncounter = (props) => {

        const [url, setUrl] = useState("");
        const { status, data, error } = useFetch(url);

        const [table_meta, setTableMeta] = useState([]);
    
        useEffect(() => {
            setTableMeta([
                {"col_width": "col-40", "data_label": "Drug","key": "LN"},
                {"col_width": "col-15", "data_label": "Written Date","key": "WrittenDate"},
                {"col_width": "col-25", "data_label": "Provider","key": "Provider"},
                {"col_width": "col-25", "data_label": "RxNumber","key": "RxNumber"},
                {"col_width": "col-25", "data_label": "Dispense Qty","key": "DispenseQty"}
            ]);

            setUrl(`${URL}patientLastEncounter?idx=${props.idx}`);
            
        }, []);

    

        return(
            <LastEncounterWrapper>
                <div  className="last_encounter_content">
               
                <FormHeader onClose={props.onClose} title="PATIENT LAST ENCOUNTER" addClose={true} class_style="form_header--dark" ></FormHeader>
                  
                 
                    <div>
                        {status === 'error' && <div>{error}</div>}
                        {status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
                        {status === 'fetched' && (
                            <Table data={data} meta={table_meta} row_class="table-row" include_header="true" table_height="tbl_med" ></Table>
                        )}
                    </div>
                </div>
               

            </LastEncounterWrapper>
        );

   
}

export default LastEncounter;