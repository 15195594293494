import React, {useState, useEffect} from 'react';
import AutoCompleteDropdown  from '../theme/autocomplete-dropdown';
import styled from 'styled-components';
import { dsp_list, pvd_list, URL } from '../constants';
import ScanEntry from './scan-entry';
import useFetch from '../hooks/useFetch';
import Toast from './toaster';
import { getSession, parseGS1Barcode } from '../utility';
import listIcon from '../assets/list.svg'; 
import LotSearch from './lot-search';
import { Tooltip } from 'react-tooltip';

import Overlay from '../theme/overlay';
import arrow_down_right from '../assets/arrow_down_right.png';
import arrow_up from '../assets/arrow_up.png';



const RxSearchWrapper = styled.div`

    background-color: var(--color-grey-light-4);
    color: var(--color-grey-dark-1);
    font-size: 1.6rem;
    font-family:'Raleway', sans-serif;;
    grid-column: 1 / -1;
    grid-row: 3 / 4;
    max-height:7.5rem;
    margin-top: 1rem;
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--line);

 

.slider {
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    
	transition-property: all;
	transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    
       
    .slider.closed {
        display:flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--color-grey-light-2);
        font-size: 1.6rem;
        color: inherit;
        line-height: 1.3;
        padding: .6em 1.4em .5em .8em;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        border-radius: 10rem;
        overflow-y: hidden;
        max-height: 7rem;
        padding: .7rem 2rem;
        border-radius: 10rem;
        min-width: 20rem;
        box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
        border: 1px solid #aaa;
	
    }
}

.search-ln {
    color: navy;
    font-weight: bold;
    width: 40rem;
    height: 3rem;
    font-size: 1.6rem;
    transition: all .5s;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #aaa;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: var(--color-grey-light-2);
    margin-left: 1rem;
    margin-right: 1rem;

    @media only screen and (max-width: 1200px) {
        font-size: 1.5rem;
        width: 40rem;
    }

    @media only screen and (max-width: 1170px) {
        font-size: 1.2rem;
        width:50rem;
    }

    @media only screen and (max-width: 1160px) {
        font-size: 1.2rem;
        width: 170rem
    }
}

.search-lot {
    color: navy;
    font-weight: bold;
    width: 20rem;
    height: 3rem;
    font-size: 1.6rem;
    transition: all .5s;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #aaa;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: var(--color-grey-light-2);
    margin-left: 1rem;
    margin-right: 1rem;

    @media only screen and (max-width: 1029px) {
        font-size: 1.4rem;
    }

}

.slider--2 {
    
    display: none;
	max-height: 0; /* approximate max height */
	transition-property: all;
	transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
       
    .slider--2.closed--2 {
        display:flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--color-grey-light-2);
        font-size: 1.6rem;
        color: inherit;
        line-height: 1.3;
        padding: .6em 1.4em .5em .8em;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        border-radius: 10rem;
        overflow-y: hidden;
        max-height: 7rem;
        padding: .7rem 2rem;
        border-radius: 10rem;
        min-width: 20rem;
        box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
        border: 1px solid #aaa;
	
    }
}

.ln_close__button {
    fill:  navy;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    margin-left: 0 auto;
    border: none;
    background-color: transparent;
    margin-left: 10rem;
    
}

.lot_close__button {
    fill:  navy;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    margin-left: 0 auto;
    border: none;
    background-color: transparent;
    margin-left: 12rem;
    
}


.btn__invisible {
        
    position: relative;;
    top: -5rem;
    transition: all .6s;

    .btn__invisible__visible {
        display: inline-block;
        padding: 2rem 7.5rem;
        position: relative;;
        top: 1.5rem;
        
    }
    
}

.select-css {
	display: block;
	font-size: 1.6rem;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
    margin: 0;
    margin-top: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    -moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    transition: all .5s;
    
    
}
.select-css::-ms-expand {
	display: none;
}
.select-css:hover {
	border-color: #888;
}
.select-css:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
    outline: none;
}
.select-css option {
	font-weight:normal;
}

.scan_ent {
    margin-right: 5rem;
    fill: navy;
    cursor: pointer;
}

.list-icon {
    /* transform: scale(.15); */
    cursor: pointer;
    margin-left: -10rem;

}

.list_container {
    transform: scale(.15);
   
}



`;

const RxSearch = (props) => {

  const [ln, setLN] = useState('');
  const [lot, setLOT] = useState('');
  const [drg_idx, setDRG_IDX] = useState('');
  const [lot_data, setLotData] = useState('');
  const [cln_idx, setCLN_IDX] = useState(getSession('cln'));
  const [show_ln, setShowLN] = useState(true);
  const [show_lot, setShowLOT] = useState(true);
  const [scan, setScan] = useState(false);
  const [type,  setType] = useState('LN');

  const[autoLot, setAutoLot] = useState(false);
  const[autoLN, setAutoLN] = useState(false);

  const [active, setActive] = useState('true');

  const [ndc_scan, setNDCScan] = useState('');
  const [lot_scan, setLOTScan] = useState('');
  const [url, setUrl] = useState('');

  const { status, data, error } = useFetch(url);

  
  const [showLots, setShowLots] = useState(false);
  const [overlay, setOverlay] = useState(false);

  const [toast, setToastState] = useState(false);
  const [toast_msg, setToastMsg] = useState("");
  const [toast_icon, setToastIcon] = useState("");
  const [toast_title, setToastTitle] = useState("Success");
  const [toast_background, setToastBackground] = useState("#5cb85c");

    useEffect(() => {
        localStorage.setItem('dsp_list', JSON.stringify(dsp_list));
        localStorage.setItem('pvd_list', JSON.stringify(pvd_list));

        setAutoLN(true);

  }, []);
 
useEffect(() => {

    if(ln === '' || lot === '') return;
    let obj = {};
    obj.LN = ln.LN;
    obj.FRM_IDX = ln.FRM_IDX;
    obj.MEDID = ln.MEDID;
    obj.DaysSupply = ln.DaysSupply;
    obj.DefaultDosage = ln.DefaultDirections;
    obj.QTY = ln.QTY;
    obj.CustomDirections = ln.CUSTOM;
    obj.LOT = lot.LOT;
    obj.DRG_IDX = lot.DRG_IDX;
    obj.NDC = lot.NDC;
    obj.MNDC = lot.MNDC;
    obj.ExpDate = lot.ExpDate;
    // obj.INGREDS = lot.INGREDS;
    obj.INV = lot.INV;
    obj.IsControl = lot.IsControl;
    obj.SNI = lot.SNI;
    obj.Schedule = lot.Schedule;

    setLN('');
    setShowLN(true);
    setAutoLN(true);
    setLOT('');
    
    setShowLOT(true);
    setAutoLot(true);
   
   props.select(obj);
 
}, [ln,lot]);

useEffect(() => {

    if(status === 'fetched') {
        
        if (data[0] !== undefined) { 
        let obj = {};
        obj.LN = data[0].LN;
        obj.DaysSupply = data[0].DaysSupply;
        obj.DefaultDosage = data[0].DefaultDirections;
        obj.QTY = data[0].QTY;
        obj.CustomDirections = data[0].CUSTOM;
        obj.LOT = data[0].LOT;
        obj.DRG_IDX = data[0].DRG_IDX;
        obj.NDC = data[0].NDC;
        obj.MNDC = data[0].MNDC;
        obj.ExpDate = data[0].ExpDate;
        // obj.INGREDS = data[0].INGREDS;
        obj.INV = data[0].INV;
        obj.IsControl = data[0].IsControl;
        obj.SNI = data[0].SNI;
        obj.Schedule = data[0].Schedule;
        obj.FRM_IDX = data[0].FRM_IDX;
        obj.MEDID = data[0].MEDID;
        obj.MNDC = data[0].MNDC;

        setLN('');
        setShowLN(true);
        setAutoLN(true);
        setLOT('');
        setShowLOT(true);
        setAutoLot(true);

        props.select(obj);
        
    } else {

        setToastIcon("error");
        setToastTitle("Error");
        setToastBackground("#d9534f");
        setToastMsg("Scanned Drug Not Found.");
        setToastState(true);
    }


    }
 
}, [data]);

const selectLN = (val) => {
    setLN(val);
    setLotData(val.LOTS);
    

    if(getSession("tablet")) {
        setAutoLot(false);
        setAutoLN(false);
    } else {
        setAutoLot(true);
        setAutoLN(false);
    }
  
    setShowLN(false);
    
};

const selectLOT= (val) => {
    setLOT(val);
   
    
    setShowLots(false);

    if(getSession("tablet")) {
        
        setShowLOT(true);
        setAutoLot(false);
    } else {
        setShowLOT(false);
        setAutoLot(false);
    }

};




  function closeLN() {
    setLN('');
    setShowLN(true);
    setAutoLN(true);
    // setLOT('');
    // setShowLOT(true);
  }

  function closeLOT(evt) {
    setLOT('');
    setShowLOT(true);
    setAutoLot(true);
  }

  const scanResult = (val) => {

   

    let ndc = "";
    let lot = "";
    
    if(val.length > 0) {

        if(val.length > 30) {
            const scan = parseGS1Barcode(val);
            ndc = scan.ndc
            lot = scan.lot;
        } else {    
            let bc = val.split("*");
            ndc = bc[0];
            lot = bc[1];

            let ndc_scan = ndc.substring(0, 5) + "-" + ndc.substring(5, 9) + "-" + ndc.substring(9, 11);
            let parts = ndc_scan.split("-");

            if (parts[0] === "93522") {
                ndc = "12634" + parts[1] + parts[2];
            }
        }
      
        setNDCScan(ndc);
        setLOTScan(lot);
        setUrl(`${URL}inventoryScanLabelSelect?ndc=${ndc}&lot=${lot}&cln=${cln_idx}`);
    } else {
        return;
    }

  }

const show_lots = () => {
    setShowLots(true);
}

const closeOverlay = () => {
    setOverlay(false);
}



  
    return (
      <RxSearchWrapper>
        {!show_ln ? (
         <div  className="search-ln">
            <div className="slider">
                <span>{ln !== '' ? `${ln.LN} ` : null}</span>
                <button className="ln_close__button" onClick={closeLN}>
                    <svg width="16" height="16"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path></svg>
                </button>
            </div>
         </div>
        ) :  autoLN ?  <AutoCompleteDropdown focus={true}  Label="LN" url={`${URL}inventoryAutofill?cln=${cln_idx}&type=${type}`}  data=""  select={(val) => selectLN(val)}/> : null }
        
         {!show_lot ? (
         <div  className="search-lot">
            <div className="slider">
                <span>{lot !== '' ? `${lot.LOT}` : null}</span>
                <button className="lot_close__button" onClick={closeLOT}>
                    <svg width="16" height="16"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path></svg>
                </button>
            </div>
           
         </div>
        ) : autoLot ? <AutoCompleteDropdown focus={autoLot} Label="LOT" url=""  data={lot_data} select={v => selectLOT(v)}/>   : null} 
        
        <Tooltip id="rxs-tooltip" place="top" variant="info"  style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }}/>

       { getSession("help") ?  <svg style={{marginRight: "3rem", cursor:"pointer"}} onClick={() => setOverlay(true)} width="40px" height="40px" viewBox="0 0 12 12" enable-background="new 0 0 12 12"  version="1.1"  ><path d="M6,0C2.6862793,0,0,2.6862793,0,6s2.6862793,6,6,6s6-2.6862793,6-6S9.3137207,0,6,0z M6.5,9.5h-1v-1h1V9.5z   M7.2651367,6.1738281C6.7329102,6.5068359,6.5,6.6845703,6.5,7v0.5h-1V7c0-0.9023438,0.7138672-1.3486328,1.2348633-1.6738281  C7.2670898,4.9931641,7.5,4.8154297,7.5,4.5c0-0.5517578-0.4487305-1-1-1h-1c-0.5512695,0-1,0.4482422-1,1V5h-1V4.5  c0-1.1025391,0.8969727-2,2-2h1c1.1030273,0,2,0.8974609,2,2C8.5,5.4023438,7.7861328,5.8486328,7.2651367,6.1738281z" fill="#000000"></path></svg> : null}
               

      
       { getSession("tablet") ? <div className="list_container"> <img  onClick={show_lots} className="list-icon" src={listIcon} alt="" /></div> :  <a data-tooltip-id="rxs-tooltip"  data-tooltip-content="Scan Drug Label Barcode"><div className="scan_ent" onClick={() => setScan(true)}>
                     <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 -980 960 960" ><path d="M40-120v-182h60v122h122v60H40Zm697 0v-60h122v-122h60v182H737ZM153-231v-499h80v499h-80Zm121 0v-499h42v499h-42Zm122 0v-499h83v499h-83Zm125 0v-499h121v499H521Zm163 0v-499h42v499h-42Zm83 0v-499h38v499h-38ZM40-658v-182h182v60H100v122H40Zm819 0v-122H737v-60h182v182h-60Z"/></svg>
       </div></a> } 
      
             {scan ? <ScanEntry title="SCAN BOTTLE BARCODE" submit={(val) => scanResult(val)} close={() => setScan(false)} /> : null}
         
       {showLots ? <LotSearch data={lot_data} close={() => setShowLots(false)} select={(val) => selectLOT(val)} /> : null}

       {overlay ? <Overlay close={() => setOverlay(false)} >
       <>
                        
                        <div  className="item-end" style={{gridRow: "4/5",gridColumn: "1/2", marginLeft: "3rem", marginTop: "30px"}}>
                            <span>Load the Drug Here.</span>
                            <img src={arrow_down_right} alt="arrow" className="arrow" />
                        </div>
                        
                        <div style={{gridColumn: "4/6",gridRow: "5/6",alignSelf:"start", marginTop:"-40px"}}>
                            <img src={arrow_up} alt="arrow" className="arrow" />
                            <span >The Lot Autofill will contain all the lots for the selected Drug</span>
                        </div>
        
                                               
                            <div style={{gridRow:"5/6", gridColumn:"6/-1", alignSelf:"start", marginLeft: "30px"}}>
                                <img src={arrow_up} alt="arrow" className="arrowLeft" />
                                <span >You can also Load the Drug By Scanning its Label By Clicking Here</span>
                            </div>
                            
                       
                        
                        <span className="close_overlay" onClick={closeOverlay} style={{gridColumn: "3/5", gridRow:"6/7",  fontFamily: "BenchNine", fontSize:"3rem",cursor:"pointer"}}>Click Here to Close</span>
                    </> 
           </Overlay> : null}


           <div className="toasty">
                    {toast ?  <Toast 
                        id={74}
                        position={'bottom-center'}
                        autoDelete={true}
                        autoDeleteTime={3000}
                        title={toast_title}
                        description={toast_msg}
                        backgroundColor={toast_background}
                        icon={toast_icon}
                        close={() => setToastState(false)}

                    /> : null }
                 </div>
       
        </RxSearchWrapper>
    );
  }
  
  export default RxSearch;