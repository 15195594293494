import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ReactLoading from 'react-loading';

const ScanEntryWrapper = styled.div`
   
    width: 40rem;
    height: 20rem;
    margin: auto;
    background-color: var(--color-grey-dark-2);
    color: #fff;
    
    border: 2rem solid  rgba(15, 215, 239, 1);
    border-radius: 5px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    font-size: 2rem;
    white-space: nowrap;
    box-shadow: 0 1rem 3rem rgba(#fff, .2);
    
   
    #scan__title {
        font-family: 'BenchNine' sans-serif;
        font-size: 2.4rem;
        font-weight: 700;
        text-transform: uppercase;
        align-self: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
       
    }

   

    .scan__input {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 2.5rem;
        color: var(--color-grey-dark-2);
        width:.3rem;
        border:none;
        background-color: var(--color-grey-dark-2);
        outline: none;
        height: .3rem;

    }
    
    .scan__btn-bar {

        display: flex;
        align-items: space-between;
        justify-content: space-between;
        border-top: var(--line);
        margin-right: 1rem;
        margin-left: 1rem;
        margin-bottom: 2rem;
        width: 100%
    }

    #scan__input {

    font-family: inherit;
    
    font-size: 1.75rem;
    padding: 1rem 2rem;
    border-radius: 2px;
    background-color: var(--color-grey-dark-2);
    border: none;
    width: 25rem;
    display: block;
    transition: all .3s;
    text-align: center;


    }

    .header {
        width: 100%;
        margin-top: -1rem;
     }

   

@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes expand {
    from {min-width: 50px} 
    to {min-width: 350px}
}

@keyframes expand {
    from {min-width: 50px}
    to {min-width: 350px}
}
@-webkit-keyframes stay {
    from {min-width: 350px} 
    to {min-width: 350px}
}

@keyframes stay {
    from {min-width: 350px}
    to {min-width: 350px}
}
@-webkit-keyframes shrink {
    from {min-width: 350px;} 
    to {min-width: 50px;}
}

@keyframes shrink {
    from {min-width: 350px;} 
    to {min-width: 50px;}
}

@-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;} 
    to {bottom: 60px; opacity: 0;}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 60px; opacity: 0;}
}

`;




const  ScanEntry = (props)  => {
    const [value, setValue] = useState('');
    
   const [loading, setLoading] = useState(false);
   
    useEffect(() => {
      
    }, []);
    
  const submitScan = (val) => {
        setLoading(false);
        props.submit(val);
        props.close();
    }
  const suppressCrtl = (event) => {
            setLoading(true);
       
            if( event.keyCode == 13 || event.keyCode == 16 ||  event.keyCode == 17 ) {
                event.preventDefault();
                    return;
                }

                if(event.ctrlKey) {
                    event.preventDefault();
                    return;
                }
        
  };
   


    return (
        <ScanEntryWrapper>

          {loading && <ReactLoading type="spin" color="#fff" height={40} width={40} />}
           <div className="header"><FormHeader onClose={props.close} title={props.title} addClose={true} class_style="form_header--light"></FormHeader></div>
             <div id='scan__input'>
              <input autoFocus type="text" className="scan__input" onKeyDown={e => suppressCrtl(e)} onBlur={e => submitScan(e.target.value)} />
            </div>


        </ScanEntryWrapper>
    );
}

export default ScanEntry;
