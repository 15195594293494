import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonExpand from '../theme/btn_expand';
import FormHeader from './form-header';
import Toast from './toaster';
import ReactLoading from 'react-loading';
import { URL } from '../constants';
import useFetch from '../hooks/useFetch';

const GroupWrapper = styled.div`

        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 10vh;
        width: 40vw;
        background-color: var(--color-grey-dark-2);
        opacity: 1;
        transition: all .3s;
        min-height: 27.3rem;
        border: 2rem solid  rgba(15, 215, 239, 1);
        border-radius: 5px;

        .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        margin-top: 3rem;
      }

`;

const Group = (props) => {

    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(undefined);
    const [url, setURL] = useState(undefined);
	
    const { status, data, error } = useFetch(url);

    
    useEffect(() => {
    
        setURL(`${URL}admReturnGroups`);


    }, []);

    useEffect(() => {
        if(selected !== undefined) {
        if (selected.idx === '-1') {
            props.edit(selected);
        }
    }
    }, [selected]);

    const submitAdd = () => {
        console.log('submit add group');
        props.edit(selected);

    }

    const useGroup = () => {    

        console.log('use group');
        props.select(selected);

    }

    const changeGroup = (val) => {
        console.log('change group');
        console.log(val);

        

        if(val === '-1') {
            console.log('add new group');
            setShow(false);
            setSelected({idx: '-1', name: 'Add New Group'});
            
        } else if (val === '0') {
                console.log("no group selected");
                setShow(false);

        } else {
            console.log('change group load group');
            let opt = data.find(item => item.idx.toString() === val);
            setShow(true);
            console.log(opt);
            setSelected(opt);
        }
    }

    const loadGroups = () => {
        if(data.length !== 0) {
        return data.map((item, index) => {
            return (<option key={index} value={item.idx}>{item.name}</option>)
            
        })

        } else {
            return null;
        }
    }
    

   
    return (
        <GroupWrapper>
            <div className="modal_container">
           <FormHeader title="GROUP MANAGEMENT" addClose={false} class_style="form_header--light"></FormHeader>
        
            <div className="modal-body">
                    <div id="grp-select_container">
                                            {status === 'error' && <div>{error}</div>}
                                            {status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
                                            {status === 'fetched' && (
                                        <select id="gender" onChange={e => changeGroup(e.target.value)}   className="form_input req">
                                            <option value="0">SELECT GROUP</option>
                                            <option value="-1">**ADD NEW**</option>
                                           
                                            
                                                {loadGroups()}
                                             
                                        </select>

                                            )}
                                    
                    </div>
            </div>
                    <div className="modal-footer">
                       { show ? 
                       <>              
                        <ButtonExpand title="USE THIS GROUP" click={useGroup} />
                        <ButtonExpand title="EDIT GROUP" click={submitAdd} />
                        </>
                         : null   }
                    </div>
            </div>
        </GroupWrapper>
    );
}

export default Group;