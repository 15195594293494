import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Checkbox from '../theme/checkbox';
import ButtonExpand from '../theme/btn_expand';
import TwoFactor from './two-factor';
import { URL } from '../constants';
import { getAPI } from '../utility';
import Toast from './toaster';
import { UserAgent } from './login-usr-agent';


const Login2AuthWrqpper = styled.div`
    
    z-index:9999;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
   
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .login-usr_container { 
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: white;
        height: 25vh;
        width: 40vw;
        background-color: var(--color-grey-dark-2);
        opacity: 1;
        transition: all .3s;
        min-height: 36.3rem;
        border: 2rem solid  rgba(15, 215, 239, 1);
        border-radius: 5px;
        margin-right:3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      .auth-header {
        font-size: 2rem;
        margin-left: 2rem;
        margin-right: 2rem;
        text-align: center;
      }

      .auth-header--2 {
        font-size: 2rem;
        margin-left: 3rem;
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      .login-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 2.5rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
      }
    .send-code {
        margin-top: 3rem;
        margin-left: 3rem;
        font-size: 1.5rem;
        padding:1rem;
        text-align: center;
      }

      .resend {
        margin-top: 1rem;
        margin-bottom: 2rem;
        text-align: center;
        border: 1px solid white;
        width: 20rem;
        font-size: 2rem;
        border-radius: 15px;
        padding: .5rem;
        cursor: pointer;
      }
    

`;

const Login2Auth = (props) => {

    const [auth, setAuth] = useState(false);
    const [usr_phone, setUsrPhone] = useState('');
    const [usr_email, setUsrEmail] = useState('');
    const [snd, setSnd] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [msg, setMessage] = useState('1');

    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");
    

    useEffect(() => {
        
        let json = localStorage.getItem('session');
        let session = JSON.parse(json);

        setUsrPhone(session.CellPhone);
        setUsrEmail(session.Email);

    }, []);

    const submit = () => {  
        // setup code to send code to user
        setDisabled(true);
      
        let obj = {};
        obj.ip = props.ip;
        obj.msg = msg;
        obj.usr = props.usr;
        obj.cln = props.cln;

        console.log(obj);
        let json = JSON.stringify(obj);
        console.log(json);
        

        //POST DATA
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
            body: json
            };
            fetch(`${URL}authSetupCode`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    let rslt = "";
                    try {
                      rslt = (JSON.parse(data));
                      
                      setAuth(true);
                      setSnd(false);
                      setDisabled(false);

                    } catch {
                      rslt = data;
                      console.log("error", rslt);
                      setAuth(true);
                      setSnd(false);
                      setDisabled(false);
                    }
                   
                })            
                .catch(err => {
                    console.log(err);
                });
        
       
        
    }

    const getCode = (code) => {
        setAuth(false);
        console.log(code);

        let obj = {};
        obj.ip = props.ip;
        obj.cde = code;
        obj.usr = props.usr;
        obj.cln = props.cln;

        console.log(obj);
        let json = JSON.stringify(obj);

        //POST DATA
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
            body: json
            };
            fetch(`${URL}authConfirmCode`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    let rslt = "";
                    //if code fails reload with error
                    try {
                      rslt = ("code confirm result",JSON.parse(data));
                      if(rslt.ERROR_MSG) {
                          console.log("DEVICE NOT AUTHORIZED");
                          setAuth(true);
                          setToastIcon("error");
                          setToastTitle("Error");
                          setToastBackground("#d9534f");
                          setToastMsg("The Device Code could not be Authorized. Please Try Again.");
                          setToastState(true);
                          
                      } else {
                          console.log(rslt);
                          localStorage.setItem('session', JSON.stringify(rslt));
                          let d = rslt.device;
                          
                          localStorage.setItem('device', d);

                          UserAgent();

                          props.close();
                      }

                    } catch {
                      rslt = data;
                     
                    }
                   
                })            
                .catch(err => {
                    console.log(err);
                });
        


       
    }



    return (
        <Login2AuthWrqpper>
            <div className="login-usr_container">
               { snd ?
                <div className="auth-header">
                    <div>We either do not recognize this device or your IP address has changed</div>
                    <div>CRx needs to validate this device. Please select below how you would like to receive the Validation Code.</div>
                </div>
                :
                <div className="auth-header--2">
                    <div>Enter the Validation Code sent to your Phone</div>
                </div>
                 }

                { snd ? <div className="send-code">
                    <Checkbox  label={`SMS Message to: ${usr_phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}`}  checked={true} />
                    {/* <Checkbox  label={`Email to: ${usr_email}`} /> */}
                </div> : null}
                { auth ? <TwoFactor  select={(code) => getCode(code)}></TwoFactor> : null}

                {auth ? <div className="resend" onClick={submit}>Resend Code</div>: null}

                { snd ?
                <div className="login-footer">
                  { disabled ? <div></div> : <ButtonExpand title="SUBMIT" click={submit} />}
                </div>
                : null}
               
               
                

                
            </div>

            
            <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>

           
        </Login2AuthWrqpper>
    );
}

export default Login2Auth;







