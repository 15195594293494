import React, { useState, useEffect } from 'react';
import Toast from './toaster';
import FormHeader from './form-header';
import SideList from './side-list';
import {suffix, URL} from '../constants';
import { isValidNumber, isValidString, getSession, getAPI } from '../utility';
import LoadingButton from '../theme/loading-button';
import ButtonSlide  from '../theme/btn-slide';
import styled from 'styled-components';
import useFetch from '../hooks/useFetch';
import Overlay from '../theme/overlay';
import arrow_down_right from '../assets/arrow_down_right.png';
import arrow_up from '../assets/arrow_up.png';
import arrow_down from '../assets/arrow_down.png';


const ClnAdminPvdWrapper = styled.div`

    font-size: 1.4rem;
    z-index: 999;
    color:#fff;
    height: 70%;
    width: 100%;
    opacity: 1;
    transition: all .3s;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }

    .dea_entry_form {
        border: 1px solid var(--color-primary);
        border-radius: 3rem;
        margin-top: 2rem;
        width: 70rem;
        margin-left: 2rem;
        display: none; 
    }
    #existing_dea {
        max-width: 15rem;
        margin-left: 2rem;
        margin-top: 1rem;
    }

    .country_enter {
        max-width: 20rem;
    }

    .dea_exist {

        margin-left: 2rem;
    }

    .viewDEA {
        display:block;
    }

    .hideDEA {
        display:none;
    }

    .dea_title {
        margin-left: 2rem;
        margin-top: 1rem;
    }
   
`;


const ClnAdminPvd = (props) => {
    const [table_meta, setTableMeta] = useState([]);

    const [valid_err, setValErr] = useState([]);
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");

    const [suffixes, setSuffixes] = useState(suffix);
    const [pvd_idx, setPVDIDX] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState("");
    const [ncpdp, setNCPDP] = useState('');
    const [npi, setNPI] = useState('');
    const [title, setTitle] = useState('');
    
    const [idx, setIDX] = useState("");
    const [grp_idx, setGRP_IDX] = useState(getSession("grp"))
    const [type, setType] = useState('');
    const [pvd_list, setPVDList] = useState([]);
    const [act_list, setACTList] = useState([]);
    const [inact_list, setINACTList] = useState([]);
    const [dea_list, setDEAList] = useState([]);
    const [universal, setUniversal] = useState('true');

    
    const [pvd_loaded, setPVDLoaded] = useState(false);
    const [btn_text, setBtnText] = useState("DEACTIVATE PROVIDER");

    const [dea_class, setDEAClass] = useState("hideDEA");
    const [dea_idx, setDEAIDX] = useState("");
    const [dea, setDEA] = useState("");
    const [dea_loaded, setDEALoaded] = useState(false);
    const [dea_alias, setDEA_Alias] = useState("");
    const [dea_exp, setDEAEXP] = useState("");

    const [url, setURL] = useState('');
    const [query, setQuery] = useState('');
    const { status, data, error } = useFetch(url);


    const [disable, setDisable] = useState(false);
    const [disable_dea, setDisableDEA] = useState(false);

    const [overlay, setOverlay] = useState(false);


  
    useEffect(() => {
        setTableMeta([
            {"col_width": "col-100", "data_label": "Provider","key": "FullName"},
           
        ]);

        setType('pvd_act');
        setURL(`${URL}providerGetActiveProviders?idx=${getSession("grp")}`);

    }, []);

    useEffect(() => {
      
        setURL(`${URL}providerGetActiveProviders?idx=${getSession("grp")}`);
        setBtnText("DEACTIVATE PROVIDER");
    }, [query]);

    useEffect(() => {
      
        console.log(data);
    }, [data]);



   
      const ReturnInActiveProviderList = async() => {
       
        try {
            const response = await fetch(URL +`providerGetInActiveProviders?idx=${getSession("grp")}`, {
                method: 'GET',
                    headers: {
                        'x-functions-key': getAPI(),	
                    }
                });
            const inact = await response.json();
            
            setINACTList(JSON.parse(inact));
            setPVDList(JSON.parse(inact));
        } catch (err) {
            console.log(err);
            
            setINACTList([]);
            setPVDList([]);
            setBtnText("DEACTIVATE PROVIDER"); 
        }
       
      } 

      useEffect(() => {
        if(status === "fetched") {
           
            setACTList(data);
            setPVDList(data);
        }
        }, [status]);

      const resetProviderScreen = () => {

       console.log("reset screen");

        setValErr([]);
      
        setDEALoaded(false);
        let d = document.getElementById("existing_dea");
        d.value = "0";
        
        setPVDIDX("");
        setFirstName("");
        setLastName("");
        setMiddleName("");
        setNCPDP("");
        setNPI("");
        setTitle("");
        setValErr([]);
        setToastMsg("");
        setSuffixes("0");
        setIDX("");
        setType("");
        

        setDEAClass("hideDEA");

        setPVDLoaded(false);
      
      }
      


    const deactivateProvider = () => {
        const errors = [];
        let ul = '';
        
        let it = isValidNumber(idx) ? idx : errors.push("Provider Index Is Not Valid. Please Reload Provider and try again.");  //*
        if (errors.length > 0) {
            setValErr(errors);
           
        } else {

             setValErr([]);

             if (btn_text === "DEACTIVATE PROVIDER") {

                ul = `${URL}providerDeActivate?idx=${it}`;

          
             } else {

                ul = `${URL}providerReActivate?idx=${it}`;

             }

             fetch(ul, {
                method: 'GET',
                    headers: {
                        'x-functions-key': getAPI(),	
                    }
                })  //
            .then((rslt) => {
               
                setToastIcon("success");
                setToastMsg("Provider Activation Change was Successful");
                setToastState(true);
                setToastBackground("#5cb85c");
                setToastTitle("Success");

                setURL('');
                setQuery(Math.floor(Math.random() * 100));

            
            })
            .catch(err => {
                setToastIcon("error");
                setToastTitle("Error");
                setToastBackground("#d9534f");
                setToastMsg("There was a Problem Changing the Activation of the Provider. Please Try Again Later.");
                setToastState(true);
            });

        }

        
    }
    
    
    const selectRow = (id) => {
         
        resetProviderScreen();
        let obj = pvd_list.find(obj => obj.idx == id);

        console.log(obj);
      
        setIDX(obj.idx);
        setFirstName(obj.firstName);
        setLastName(obj.lastName);
        setMiddleName(obj.middleName);
        setTitle(obj.title === undefined ? "" : obj.title);
        setNPI(obj.npi);
        setNCPDP(obj.ncpdp);

        setValErr([]);
      



        setPVDLoaded(true);
        setDEAIDX("");
        setDEA("");
        setDEALoaded(false);
        setDEA_Alias("");
        setDEAEXP("");
        setDEAClass("viewDEA");

        loadDEAList(obj.idx);
       
    }

    const saveProvider = () => {

        setDisable(true);

        const errors = [];
         let obj = {};
   
         obj.PVD_IDX = idx
         obj.GRP_IDX = grp_idx;
         obj.FirstName = isValidString(firstName) ? firstName.toUpperCase() : errors.push("Provider First Name Is Required."); //*
         obj.LastName = isValidString(lastName) ? lastName.toUpperCase() : errors.push("Provider Last Name Is Required."); //*
         obj.MiddleName = middleName === undefined ? "" : middleName.toUpperCase();
         obj.Suffix = title === '----' ? '' :  title;
         obj.NewCropType = "";
         obj.NCPDP = ncpdp === undefined ? "" : ncpdp;
         obj.NPI = npi === undefined ? "" : npi;
         obj.eprescribe = "false";

      
   
        let json = JSON.stringify(obj);
        
 
        if (errors.length > 0) {
            setValErr(errors);
            setDisable(false);
            
        } else {

            // setPVD(json);

            //set fetch url based on pvd loaded
            let ul = pvd_loaded ? URL + "providerPostUpdate" : URL + "providerPostInsert";

             //POST DATA
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key': getAPI() },
            body: json
            };
            fetch(ul, requestOptions)
            .then((response) => {
                 
                if (response.ok === false) {  throw new Error('Network response was not ok.') };

                    setValErr([]);
                    setToastIcon("success");
                    setToastTitle("Success");
                    setToastMsg("Provider Saved Successfully");
                    setToastBackground("#5cb85c");
                    setToastState(true);

                    setDisable(false);

                    setURL('');
                    setQuery(Math.floor(Math.random() * 100));

                })            
                .catch(err => {
                    setToastIcon("error");
                    setToastTitle("Error");
                    setToastBackground("#d9534f");
                    setToastMsg("There was a Problem Saving the Provider. Please Try Again.");
                    setToastState(true);
                    
                    setDisable(false);
                });
           
             
        }
     }
 
     
 

     const updateActiveTab = (e) => {
        
        let index = e.target.id;
        switch(index) {
            case "1": setPVDList(act_list); setBtnText("DEACTIVATE PROVIDER"); 
             break;
            case "2":    ReturnInActiveProviderList();  setBtnText("REACTIVATE PROVIDER"); 
             break;
            default:  setPVDList(act_list); setBtnText("DEACTIVATE PROVIDER");
        }

       
    }
 
   
   
     const err = () => {
         try {
             if (Array.isArray(valid_err)) {
               return valid_err.map((item, index)=>{
                   return (
                         <ul>
                               <li className="err_list">{item}</li>
                              
                         </ul>
                    );
               });
           } else {
             return (
               <tr><td colSpan='8'><div>The Patient Allergen Profile is Empty.</div></td></tr>
             );
           }
           } catch (err) {
             return;
           }
     }

     
        
      
    

    let suffixs = suffix.map((item, index) => {
        return (<option key={index} value={item}>{item}</option>);
    });

    const saveDEA = () => {

        setDisableDEA(true);
        //set url based on the state of the pvd_loaded
        let ur = dea_loaded ? URL + "providerDEAUpdate" : URL + "providerDEAInsert";
        const errors = [];

       
        let obj = {};
        obj.dea = dea;
        obj.exp = dea_exp;
        obj.dea_alias = dea_alias;
        obj.idx = dea_idx;
        obj.cln = getSession("cln");
        obj.universal = universal;
        obj.pvd = idx;

        let json = JSON.stringify(obj);
       
  
       
        if (errors.length > 0) {
            setValErr(errors);
            
        } else {
                   //POST DATA
                const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
                  body: json
                  };
                  fetch(ur, requestOptions)
                      .then(response => response.json())
                      .then((data )=> {
                          setValErr([]);

                          setToastIcon("success");
                          setToastTitle("Error");
                          setToastMsg("DEA Saved Successfully");
                          setToastBackground("#5cb85c");
                          setToastState(true);
                          setDisableDEA(false);
                         
                          
                      })            
                      .catch(err => {
                          setToastIcon("error");
                          setToastTitle("Error");
                          setToastBackground("#d9534f");
                          setToastMsg("There was a Problem Processing the DEA. Please Try Again.");
                          setToastState(true);

                          setDisableDEA(false);
                          
                      });
        
            } 
        
          
    }

    const setDEAValue = (e) => {
       
     
        let obj = dea_list.find(obj => obj.dea == e.target.value);
       
        if (e.target.value.length > 0) {
            setDEALoaded(true);
            setDEAIDX(obj.idx);
            setDEA(obj.dea);
            setDEAEXP(obj.dea_expire);
            setDEA_Alias(obj.dea_alias);

        } else {    
            setDEALoaded(false);
        }
    }

    const loadDEAList = async (idx) => {
       
                const ul = URL +`providerReturnDEA?idx=${idx}`;
                let dta = [];

				try {
					const response = await fetch(ul, {
                        method: 'GET',
                            headers: {
                                'x-functions-key': getAPI(),	
                            }
                        });
					const rslt = await response.json();
					
					try {
						dta = rslt;
                      
                        setDEAList(dta);
					} catch {
						console.log(rslt);
					}
					
					
					
				} catch (error) {
					 console.log(error.message);
				}
			
		};
     
        const load_dea = () => {
           
            if (dea_list.length > 0) {
            let dea = dea_list.map((item, index) => {
                return (<option key={index} value={item.dea}>{item.dea}</option>);
            });

            return dea;
            } else {    
                return (<option value="0">-----------</option>);
            }
        }

    
        const closeOverlay = () => {
            setOverlay(false);
        }


    return(
        <ClnAdminPvdWrapper>
            <div className="form__container">
                    <div  className="form_content--side">
                        <SideList data={pvd_list} meta={table_meta} title="" select={(id) => selectRow(id)} tab_change={updateActiveTab}></SideList>
                    </div>
                  
                    <div  className="form_content ">
                        <FormHeader title="MANAGE PROVIDERS" addClose={false} class_style="form_header--light"></FormHeader>
                        { getSession("help") ? <svg style={{marginRight: "3rem", cursor:"pointer", position:"absolute", top:"20", left: "500"}} onClick={() => setOverlay(true)} width="40px" height="40px" viewBox="0 0 12 12" enableBackground="new 0 0 12 12"  version="1.1"  ><path d="M6,0C2.6862793,0,0,2.6862793,0,6s2.6862793,6,6,6s6-2.6862793,6-6S9.3137207,0,6,0z M6.5,9.5h-1v-1h1V9.5z   M7.2651367,6.1738281C6.7329102,6.5068359,6.5,6.6845703,6.5,7v0.5h-1V7c0-0.9023438,0.7138672-1.3486328,1.2348633-1.6738281  C7.2670898,4.9931641,7.5,4.8154297,7.5,4.5c0-0.5517578-0.4487305-1-1-1h-1c-0.5512695,0-1,0.4482422-1,1V5h-1V4.5  c0-1.1025391,0.8969727-2,2-2h1c1.1030273,0,2,0.8974609,2,2C8.5,5.4023438,7.7861328,5.8486328,7.2651367,6.1738281z" fill="#ffffff"></path></svg> : null}
                        <div id="error_list" >{err()}</div>
                        <div className="form_row1-col3">
                            <div className="form_col3_fld1">
                                <input onChange={e => setFirstName(e.target.value)} defaultValue={firstName}  type="text" className="form_input--light req" placeholder="First Name" id="firstname" maxLength="35" required autoComplete="off"  />
                                <label htmlFor="firstname" className="form_label--light">First Name*</label>
                            </div>
                            <div className="form_col3_fld2">
                                <input onChange={e => setLastName(e.target.value)} defaultValue={lastName} type="text" className="form_input--light req" placeholder="Last Name"  id="lastname" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="lastname" className="form_label--light">Last Name*</label>
                            </div>
                            <div className="form_col3_fld3">
                                <select id="title" onChange={e => setTitle(e.target.value)} value={title}  className="form_input--light">
                                    {suffixs}
                                </select>
                                <label htmlFor="title" className="form_label--light">Title</label>
                              
                            </div>
                        </div>
                        <div className="form_row2-col3">
                            <div className="form_col3_fld1">
                            <select id="country" className="form_input--light req country_enter" >
                                <option value="US">US</option>
                                <option value="CN">CN</option>
                                <option value="MX">MX</option>
                            </select>
                                <label htmlFor="country" className="form_label--light">Country</label>
                            </div>
                            <div className="form_col3_fld2">
                            <input type="text"  onChange={e => setNCPDP(e.target.value)} value={ncpdp}  className="form_input--light req" placeholder="NCPDP"   id="ncpdpe" autoComplete="off"  /> 
                                <label htmlFor="ncpdp" className="form_label--light">NCPDP</label>
                            </div>
                            
                            <div className="form_col3_fld3">
                                                              
                                <input onChange={e => setNPI(e.target.value)}  value={npi} type="text" className="form_input--light req" mask="99/99/9999" placeholder="NPI"  id="dea_expire" autoComplete="off" required /> 
                                <label htmlFor="npi" className="form_label--light">NPI</label>
                                
                            </div>

                           
                    </div>
                    { pvd_loaded ? <div className="dea_title">DEA MANAGEMENT</div> : null}
                    <div id="dea_form" className={`${dea_class} dea_entry_form`} >

                            
                        <select id="existing_dea" className="form_input--light req " onChange={setDEAValue} >
                                <option value="0">-----------</option>
                               {load_dea()}
                            
                            
                        </select>
                        <label htmlFor="existing_dea" className="form_label--light dea_exist">Existing DEA</label>


                    <div className="form_row1-col3 dea_enter">
                       
                        <div className="form_col3_fld1">
                            <input onChange={e => setDEA(e.target.value)}  type="text" value={dea} className="form_input--light req" placeholder="DEA"  id="dea" autoComplete="off" required /> 
                            <label htmlFor="dea" className="form_label--light">DEA</label>
                        </div>
                        <div className="form_col3_fld2 ">
                                                    
                            <input onChange={e => setDEAEXP(e.target.value)} value={dea_exp}  type="text" className="form_input--light req" mask="99/99/9999" placeholder="DEA Expiration"  id="dea_expire" autoComplete="off" required /> 
                            <label htmlFor="dea_expire" className="form_label--light">DEA Expires</label>
                            
                        </div>   
                        <div className="form_col3_fld3 ">
                                                    
                            <input onChange={e => setDEA_Alias(e.target.value)} value={dea_alias} type="text" className="form_input--light req"  placeholder="DEA Alias"  id="dea_alias" autoComplete="off" required /> 
                            <label htmlFor="dea_alias" className="form_label--light">DEA Alias</label>
                            
                        </div>  
                        <div>
                            { disable_dea ? <div></div> : <ButtonSlide width={'157%'} click={saveDEA} title="SAVE DEA" />}
                        </div>


                    </div>
    


                </div> 
                    <div className="form_btn_bar">
                        <LoadingButton title={btn_text} id="check" select={deactivateProvider} />
                        <LoadingButton title="RESET SCREEN" id="check2" select={resetProviderScreen} />
                        {disable ? null :<LoadingButton id="check3" title="SAVE PROVIDER" select={saveProvider} /> }
                    </div>
                      
            </div>
                    
        </div>

        <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>

            {overlay ? <Overlay close={() => setOverlay(false)} >
              <>
                        
                        <div  className="item-end" style={{gridRow: "6/7",gridColumn: "1/2", marginLeft: "20px", alignSelf: "end", justifySelf:"end"}}>
                            <span>Reset Screen Here.</span>
                            <img src={arrow_down_right} alt="arrow" className="arrow" />
                        </div>
                        
                        <div style={{gridColumn: "3/5",gridRow: "6/7",alignSelf:"end", justifySelf: "start", marginLeft:"90px"}}>
                            
                            <span >Click Here to Scan Patient's Drivers License.</span>
                        </div>

                                                                     
                            <div style={{gridRow:"6/7", gridColumn:"5/-1", alignSelf:"end", marginLeft: "30px"}}>
                              
                                <span >Save the Patient Demographics Here.</span>
                            </div>

                            <div style={{gridRow:"8/-1", gridColumn:"2/6", alignSelf:"start", }}>
                              
                                <span >Be Sure to Validate Info Transfered from License </span>
                            </div>
                            
                            
                       
                        
                        <span className="close_overlay" onClick={closeOverlay} style={{gridColumn: "3/5", gridRow:"2/3",  fontFamily: "BenchNine", fontSize:"3rem",cursor:"pointer"}}>Click Here to Close</span>
        </> 
           </Overlay> : null}

        </ClnAdminPvdWrapper>
    );
}
export default ClnAdminPvd;