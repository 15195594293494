import React, { useState, useEffect } from 'react';
import Tabs from './tabs';
import FormHeader from './form-header';
import EditInventory from './glb-admin-inv';
import GlobalOnBoarding from './glb-admin-group';
import GlobalAdminImport from './glb-admin-download'
import GlobalAdminLog from './glb-admin-errors'
import CRxOrder from './glb-admin-orders'
import styled from 'styled-components';

const AdminGlobalWrapper = styled.div`
    font-size: 1.4rem;
    z-index: 9999;
    color:#fff;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    transition: all .3s;



    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }

`;

const AdminGlobal = (props) => {

    const [tabs, setTabs] = useState(['CRx DOWNLOAD', 'ORDER PROCESS', 'ON BOARDING', 'INVENTORY ADJUSTMENTS', 'APPLICATION ERRORS']);
    const [tab1, setTab1] = useState(true);
    const [tab2, setTab2] = useState(false);
    const [tab3, setTab3] = useState(false);
    const [tab4, setTab4] = useState(false);
    const [tab5, setTab5] = useState(false);

    const updateActiveTab = (e) => {
        let index = e.target.id;
        switch(index) {
             case "1": setTab1(true); setTab2(false); setTab3(false); setTab4(false); setTab5(false);
             break;
             case "2": setTab1(false); setTab2(true); setTab3(false); setTab4(false); setTab5(false);
             break;
             case "3": setTab1(false); setTab2(false); setTab3(true); setTab4(false); setTab5(false);
             break;
             case "4": setTab1(false); setTab2(false); setTab3(false); setTab4(true); setTab5(false);
             break;
             case "5": setTab1(false); setTab2(false); setTab3(false); setTab4(false); setTab5(true);
             break;
             default:  setTab1(true); setTab2(false); setTab3(false); setTab4(false); setTab5(false);
        }
    }

    return(
        <AdminGlobalWrapper>
             <FormHeader onClose={(props.onClose)} title="GLOBAL ADMIN" addClose={true} class_style="form_header--light"></FormHeader>
             <Tabs tabs={tabs} active={updateActiveTab}></Tabs>
             {tab3 && <GlobalOnBoarding onClose={() => setTab3(false)} />}
             {tab2 && <CRxOrder onClose={() => setTab2(false)} />}
             {tab4 && <EditInventory></EditInventory>}
             {tab1 &&<GlobalAdminImport  onClose={() => setTab1(false)}></GlobalAdminImport>}
             {tab5 &&<GlobalAdminLog onClose={() => setTab5(false)}></GlobalAdminLog>}
        </AdminGlobalWrapper>

    );
   
}

export default AdminGlobal;