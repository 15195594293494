import React, { useState, useEffect } from 'react';
import Toast from './toaster';
import FormHeader from './form-header';
import SideList from './side-list';
import { URL } from '../constants';
import {isValidNumber, isValidString, getSession, getAPI } from '../utility';
import {suffix, dsp_list, us_states} from '../constants';
import useFetch from '../hooks/useFetch';
import LoadingButton from '../theme/loading-button';
import styled from 'styled-components';

const ClnAdminDspWrapper = styled.div`
     font-size: 1.4rem;
    z-index: 9999;
    color:#fff;
    height: 70%;
    width: 100%;
    opacity: 1;
    transition: all .3s;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);
    }

    

`;


const ClnAdminDsp = (props) => {
    const [states, setStates] = useState(us_states);
    const [dsp_idx, setDSP_IDX] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [title, setTitle] = useState('');
    const [country, setCountry] = useState('');
    const [license, setLicense] = useState('');
    const [state, setState] = useState('');
    const [alias, setAlias] = useState('');
    const [shipto, setShipTo] = useState('');
    const [dsp_data, setDSPData] = useState([]);
    const [act_data, setACTData] = useState([]);
    const [inact_data, setINACTData] = useState([]);
    const [table_meta, setTableMeta] = useState([]);
    const [btn_text, setBtnText] = useState("DEACTIVATE DISPENSER");
   
   
    const [valid_err, setValErr] = useState([]);
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");
   
    const [idx, setIDX] = useState(getSession("grp"));
    

    const [disable, setDisable] = useState(false);
    const [query, setQuery] = useState('');
    const [url, setURL] = useState('');
   
       
    const [dsr_loaded, setDSRLoaded] = useState(false);

    const { status, data, error } = useFetch(url);
    
  
    useEffect(() => {
        setTableMeta([
            {"col_width": "col-100", "data_label": "Dispenser","key": "name"},
           
        ]);
        
        setQuery(Math.floor(Math.random() * 100));

    }, []);

    useEffect(() => {
         setURL(`${URL}dispenserGetActiveDispensers?idx=${idx}`);
    }, [query]);

    useEffect(() => {

        if (status === "fetched") {
           
            setACTData(data);
            setDSPData(data);
        }
        
    }, [status]);

    const ReturnInActiveDispenserList = async() => {
          
       
         try {
            const response = await fetch(URL +`dispenserGetInActiveDispensers?idx=${idx}`, {
                method: 'GET',
                    headers: {
                        'x-functions-key': getAPI(),	
                    }
                });
            const inact = await response.json();
            setINACTData(JSON.parse(inact));
            setDSPData(JSON.parse(inact));
         } catch (error) {
            console.log(error);
            setINACTData([]);
            setDSPData([]);
            setBtnText("DEACTIVATE DISPENSER"); 

        }
    }

 

    const deactivateDispenser = () => {
        
        const errors = [];
        let ul = '';
        
        let it = isValidNumber(dsp_idx) ? dsp_idx : errors.push("Dispenser Index Is Not Valid. Please Reload Dispenser and try again.");  //*
        if (errors.length > 0) {
            setValErr(errors);
           
        } else {

             setValErr([]);

             if (btn_text === "DEACTIVATE DISPENSER") {

                ul = `${URL}dispenserDeActivate?idx=${it}`;

          
             } else {

                ul = `${URL}dispenserReActivate?idx=${it}`;

             }

             fetch(ul, {
                method: 'GET',
                    headers: {
                        'x-functions-key': getAPI(),	
                    }
                })  //
             .then((rslt) => {
                
                 setToastIcon("success");
                setToastMsg("Dispenser Activation Change was Successful");
                setToastState(true);
                setToastBackground("#5cb85c");
                setToastTitle("Success");

                setURL('');
                setQuery(Math.floor(Math.random() * 100));
 
             
             })
             .catch(err => {
                setToastIcon("error");
                setToastTitle("Error");
                setToastBackground("#d9534f");
                setToastMsg("There was a Problem Changing the Activation of the Dispenser. Please Try Again Later.");
                setToastState(true);
             });

           

        }
    };

 

    const selectRow = (id) => {
      
        setDSRLoaded(true);
        setValErr([]);
      
        let obj = dsp_data.find(obj => obj.idx == id);
      

        setDSP_IDX(obj.idx);
        setFirstName(obj.firstName);
        setLastName(obj.lastName);
        setMiddleName(obj.middleName);
        setTitle(obj.title);
        setCountry(obj.country);
        setAlias(obj.alias);
        setShipTo(obj.shipto);
        setLicense(obj.license);
        setState(obj.license_state);
    }

    const clearForm = () => {

        setDSRLoaded(false);
        setDisable(false);
        setValErr([]);
      
        
        setDSP_IDX("");
        setFirstName("");
        setLastName("");
        setMiddleName("");
        setTitle("");
        setCountry("");
        setAlias("");
        setShipTo("");
        setLicense("");
        setState("");
    }
    
    
    const saveDispenser = () => {

        setDisable(true);
      
       const errors = [];
     
        let obj = {};
        obj.dsr = dsp_idx !== undefined ? dsp_idx.toString() : "0";
       
        
        
        obj.idx = dsp_idx;
        obj.grp = idx;
        obj.firstName = isValidString(firstName) ? firstName.toUpperCase() : errors.push("Dispenser First Name Is Required."); //*
        obj.lastName = isValidString(lastName) ? lastName.toUpperCase() : errors.push("Dispenser Last Name Is Required."); //*
        obj.middleName = isValidString(middleName) ?  middleName.toUpperCase() : "";
        obj.title = title;
        obj.country = country;
        obj.license = license;
        obj.license_state = state;
        obj.alias = alias;
        obj.shipto = shipto;
        obj.active = "true";

  
        let json = JSON.stringify(obj);
        
      

       if (errors.length > 0) {
           setValErr(errors);
           setDisable(false);
          
       } else {

           

             let ul = dsr_loaded ? URL + "dispenserPostUpdate" : URL + "dispenserPostInsert";

                 //POST DATA
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
            body: json
            };
            fetch(ul, requestOptions)
            .then((response) => {
                 
                if (response.ok === false) {  throw new Error('Network response was not ok.') };

                    setValErr([]);
                    setToastIcon("success");
                    setToastMsg("Dispenser Saved Successfully");
                    setToastState(true);
                    setToastBackground("#5cb85c");
                    setToastTitle("Success");

                    setDisable(false);
                    setURL('');
                    setQuery(Math.floor(Math.random() * 100));
                })            
                .catch(err => {
                    setToastIcon("error");
                    setToastTitle("Error");
                    setToastBackground("#d9534f");
                    setToastMsg("There was a Problem Saving Dispenser. Please Try Again Later.");
                    setToastState(true);

                    setDisable(false);
                });
            }
    
       
    }

   

   const updateActiveTab = (e) => {
       
        let index = e.target.id;
        switch(index) {
            case "1": setDSPData(act_data); setBtnText("DEACTIVATE DISPENSER");
             break;
            case "2":  ReturnInActiveDispenserList();  setBtnText("REACTIVATE DISPENSER");
             break;
            default:  setDSPData(act_data); setBtnText("DEACTIVATE DISPENSER");
        }

    }

      const mapStates = (e) => {
        setCountry(e.target.value);

       switch(e.target.value) {
           case "US" : setStates(us_states);
           break;
           case "MX": setStates(mx_states);
           break;
           case "CN" : setStates(cn_states);
           break;
           default: setStates(us_states);
       }
    }

    const err = () => {
        try {
            if (Array.isArray(valid_err)) {
              return valid_err.map((item, index)=>{
                  return (
                        <ul>
                              <li className="err_list">{item}</li>
                             
                        </ul>
                   );
              });
          } else {
            return (
              <tr><td colSpan='8'><div>The Error List is Empty.</div></td></tr>
            );
          }
          } catch (err) {
            return;
          }
    }

    let suffixs = suffix.map((item, index) => {
        return (<option key={index} value={item}>{item}</option>);
    });

    let _states = states.map((item, index) => {
        return (<option key={index}  value={item}>{item}</option>);
        });

        


    return(
        <ClnAdminDspWrapper>
        
                <div className="form__container">
                    <div  className="form_content--side ">
                        <SideList data={dsp_data} meta={table_meta} title="" select={(id) => selectRow(id)} tab_change={updateActiveTab}></SideList>
                    </div>
                    <div  className="form_content ">
                    <FormHeader title="MANAGE DISPENSERS" addClose={false} class_style="form_header--light"></FormHeader>
                    <div id="error_list" >{err()}</div>
                    <div className="form_row1-col3">
                            <div className="form_col3_fld1">
                                <input onChange={e => setFirstName(e.target.value)} value={firstName}  type="text" className="form_input--light req" placeholder="First Name" id="firstname" maxLength="35" required autoComplete="off"  />
                                <label htmlFor="firstname" className="form_label--light">First Name*</label>
                            </div>
                            <div className="form_col3_fld2">
                                <input onChange={e => setLastName(e.target.value)} value={lastName} type="text" className="form_input--light req" placeholder="Last Name"  id="lastname" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="lastname" className="form_label--light">Last Name*</label>
                            </div>
                            <div className="form_col3_fld3">
                                <input onChange={e => setMiddleName(e.target.value)} value={middleName} type="text" className="form_input--light" placeholder="Middle Name" id="name" autoComplete="off" />  
                                <label htmlFor="name" className="form_label--light">Middle Name</label>
                            </div>
                    </div>
                    <div className="form_row2-col3">
                            <div className="form_col3_fld1">
                            <select id="title" onChange={e => setTitle(e.target.value)} value={title}  className="form_input--light">
                                    {suffixs}
                                </select>
                                <label htmlFor="title" className="form_label--light">Title</label>
                            </div>
                            <div className="form_col3_fld2">
                                <input onChange={e => setAlias(e.target.value)} value={alias} type="text" className="form_input--light" placeholder="Alias"  id="alias" maxLength="35" autoComplete="off"  />  
                                <label htmlFor="alias" className="form_label--light">Alias</label>
                            </div>
                            <div className="form_col3_fld3">
                                <input onChange={e => setShipTo(e.target.value)} value={shipto} type="text" className="form_input--light" placeholder="Ship To" maxLength="4" id="shipto" autoComplete="off" />  
                                <label htmlFor="shipto" className="form_label--light">Ship To</label>
                            </div>
                    </div>
                    <div className="form_row3-col3">
                            <div className="form_col3_fld1">
                                <input onChange={e => setLicense(e.target.value)} value={license}  type="text" className="form_input--light" placeholder="License" id="license" maxLength="15"  autoComplete="off"  />
                                <label htmlFor="license" className="form_label--light">License</label>
                            </div>
                            <div className="form_col3_fld2">
                            <select id="country" onChange={mapStates} className="form_input--light" value={country} >
                                    <option value="US">US</option>
                                    <option value="CN">CN</option>
                                    <option value="MX">MX</option>
                                    
                                </select>
                                <label htmlFor="country" className="form_label--light">Country</label>
                            </div>
                            <div className="form_col3_fld3">
                                <select id="state" onChange={e => setState(e.target.value)} value={state}  className="form_input--light">
                                    {_states}
                                </select>
                                <label htmlFor="state" className="form_label--light">State</label>
                            </div>
                    </div>
                    <div className="form_btn_bar">
                            <LoadingButton title={btn_text} id="check" select={deactivateDispenser} />
                            <LoadingButton title="CLEAR FORM" id="check2" select={clearForm} />
                            { disable ? null : <LoadingButton id="check3" title="SAVE DISPENSER" select={saveDispenser} />}
                          
                    </div>
                       
                    </div>
                </div>
                <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>
        </ClnAdminDspWrapper>
    );
}
export default ClnAdminDsp;