import React, { useState, useEffect } from "react";
import checkGreen from '../assets/checkGreen.svg';
import validateUser from '../assets/next-square.svg';
import errorUsr from '../assets/error-login.svg';
import { URL } from '../constants';
import useFetch from '../hooks/useFetch';
import styled from 'styled-components';

const UserNameWrapper = styled.div`

position: relative;
display: flex;
align-items: flex-start;
justify-content: center;
width: 50rem;
margin: 0 auto;

.search-form__input {

    font-family: inherit;
    color: #fff;
    font-size: 2.2rem;
    padding: .7rem 2rem;
    border-radius: 2px;
    border: none;
    border-bottom: 3px solid var(--color-primary);
    transition: all .5s;
    outline: none;
    background-color: var(--color-grey-dark-2);

    &::placeholder {
        font-weight: 300;
        color: var(--color-primary);
    }
  
   

}

.left-inner-addon {
    position: relative;
    width: 250px;
    margin-top: 1rem;
    margin-left: 5rem;
}
.left-inner-addon input {
    padding-right: 10px;
    width: 270px; /* 200px - 30px */
}
.left-inner-addon img {
    position: absolute;
    right: 0px;
    cursor: pointer;
    margin-top: -3.7rem;
    margin-right: .3rem;
    transform: scale(.7);

    
}

.next-step {
    transform: scale(1.2);
    margin-top: .7rem;
    margin-right: -5rem;
    cursor: pointer;
}

.check-show {
    display: block;
}

.check-hide {
    display: none;
}
`;



const userName = (props) => {

    const [usr, setUsr] = useState("");
    const [url, setUrl] = useState("");
    const [usr_valid, setUsrValid] = useState(false);
    const [isError, setIsError] = useState(false);
    const { status, data, error } = useFetch(url);

    const checkUsr = () => {
        console.log('checkUsr', usr);
            if (usr.length > 0) {
                setUrl(`${URL}authGetConfirmUser?param=${usr}`);
            }
            else {  
            return false;
            }
        }  
        
        


      
        useEffect(() => {
           
            if(status === 'fetched') {
                
                if (data) {

                    setUsrValid(true);
                    props.select(data);
                    setIsError(false);
                }
                else {
                    setIsError(true);
                    setUsrValid(true);
                    props.select('0');
                }
            }
       
        }, [data]);

        const enterUsr = (e) => {

      
            if (e.key === "Enter") {
                console.log("Enter key was pressed. Run your function.");
                e.preventDefault();
                checkUsr();
              } else {
                return false;
              }
            
           
        }

    return (
        <UserNameWrapper>
            <div className="left-inner-addon">
                <input  autoFocus  type="text" className="search-form__input" onKeyDown={(e) => enterUsr(e)} onChange={(e) => setUsr(e.target.value)} value={usr}  placeholder="CRx User Name" id="username" maxLength="35" autoComplete="off"  />
                <img className={usr_valid ? 'check-show' : 'check-hide'} src={isError ? errorUsr : checkGreen} alt="" />
            </div>
            <div onClick={checkUsr}><img className="next-step" src={validateUser} alt="" /></div>
        </UserNameWrapper>
    );
}

export default userName;