import React, {useState, useEffect} from 'react';
import AutoCompleteDropdown  from '../theme/autocomplete-dropdown';
import styled from 'styled-components';
import Overlay from '../theme/overlay';
import arrow_down_right from '../assets/arrow_down_right.png';
import arrow_up from '../assets/arrow_up.png';
import { getSession, getAPI } from '../utility';



const ProviderDetailWrapper = styled.div`

    background-color: var(--color-grey-light-4);
    color: var(--color-grey-dark-1);
    font-size: 1.6rem;
    font-family:'Raleway', sans-serif;;
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    max-height:7.5rem;
    margin-top: 1rem;
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--line);

  

.slider {
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    
	transition-property: all;
	transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    
       
    .slider.closed {
        display:flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--color-grey-light-2);
        font-size: 1.6rem;
        color: inherit;
        line-height: 1.3;
        padding: .6em 1.4em .5em .8em;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        border-radius: 10rem;
        overflow-y: hidden;
        max-height: 7rem;
        padding: .7rem 2rem;
        border-radius: 10rem;
        min-width: 20rem;
        box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
        border: 1px solid #aaa;
	
    }
}

.search-dsp {
    color: navy;
    font-weight: bold;
    width: 25rem;
    height: 3rem;
    font-size: 1.6rem;
    transition: all .5s;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #aaa;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: var(--color-grey-light-2);
    margin-left: 1rem;
    margin-right: 1rem;
}

.slider--2 {
    
    display: none;
	max-height: 0; /* approximate max height */
	transition-property: all;
	transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
       
    .slider--2.closed--2 {
        display:flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--color-grey-light-2);
        font-size: 1.6rem;
        color: inherit;
        line-height: 1.3;
        padding: .6em 1.4em .5em .8em;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        border-radius: 10rem;
        overflow-y: hidden;
        max-height: 7rem;
        padding: .7rem 2rem;
        border-radius: 10rem;
        min-width: 20rem;
        box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
        border: 1px solid #aaa;
	
    }
}

.pvd_close__button {
    fill:  navy;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    margin-left: 0 auto;
    border: none;
    background-color: transparent;
    margin-left: 5rem;
    
}



.btn__invisible {
        
    position: relative;;
    top: -5rem;
    transition: all .6s;

    .btn__invisible__visible {
        display: inline-block;
        padding: 2rem 7.5rem;
        position: relative;;
        top: 1.5rem;
        
    }
    
}

.select-css {
	display: block;
	font-size: 1.6rem;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
    margin: 0;
    margin-top: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    -moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    transition: all .5s;
    
    
}
.select-css::-ms-expand {
	display: none;
}
.select-css:hover {
	border-color: #888;
}
.select-css:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
    outline: none;
}
.select-css option {
	font-weight:normal;
}




`;

const ProviderDetail = (props) => {

  const [dsp, setDSP] = useState('');
  const [dsp_idx, setDSP_IDX] = useState('');
  const [pvd, setPVD] = useState('');
  const [pvd_idx, setPVD_IDX] = useState('');
  const [dsp_list, setDSP_LIST] = useState([]);
  const [pvd_list, setPVD_LIST] = useState([]);

  const [autoPVD, setAutoPVD] = useState(false);


  const [show_dsp, setShowDSP] = useState(true);
  const [show_pvd, setShowPVD] = useState(true);
  const [overlay, setOverlay] = useState(false);

    useEffect(() => {
       let json = JSON.parse(localStorage.getItem('session'));
  

       setDSP_LIST(json.dispensers);
       setPVD_LIST(json.providers);
        
    }, []);
 



const selectDSR = (val) => {
    setDSP(val);
    
    setShowDSP(false);
    props.selectedDSR(val.DSR_IDX);
    setAutoPVD(true);
};

const selectPVD = (val) => {
    setPVD(val);
    
    setShowPVD(false);
    props.selectedPVD(val.PVD_IDX);
    setAutoPVD(false);
   

};




  function closeDispenser(evt) {
    setDSP('');
    setShowDSP(true);
  }

  function closeProvider(evt) {
    setPVD('');
    setShowPVD(true);
  }


  const closeOverlay = () => {
    setOverlay(false);
}


  
    return (
      <ProviderDetailWrapper>
        {!show_dsp ? (
         <div  className="search-dsp">
            <div className="slider">
                <span>{dsp !== '' ? `${dsp.Dispenser}` : null}</span>
                <button className="pvd_close__button" onClick={closeDispenser}>
                    <svg width="16" height="16"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path></svg>
                </button>
            </div>
         </div>
        ) : <AutoCompleteDropdown Label="Dispenser" focus={true} url="" data={dsp_list}  select={(val) => selectDSR(val)}/>}
         {!show_pvd ? (
         <div  className="search-dsp">
            <div className="slider">
                <span>{pvd !== '' ? `${pvd.Provider}` : null}</span>
                <button className="pvd_close__button" onClick={closeProvider}>
                    <svg width="16" height="16"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path></svg>
                </button>
            </div>
         </div>
        ) : autoPVD ? <AutoCompleteDropdown focus={true} Label="Provider" url=""  data={pvd_list} select={v => selectPVD(v)}/> : null }
        
       { getSession("help") ? <svg style={{marginRight: "3rem", cursor:"pointer"}} onClick={() => setOverlay(true)} width="40px" height="40px" viewBox="0 0 12 12" enable-background="new 0 0 12 12"  version="1.1"  ><path d="M6,0C2.6862793,0,0,2.6862793,0,6s2.6862793,6,6,6s6-2.6862793,6-6S9.3137207,0,6,0z M6.5,9.5h-1v-1h1V9.5z   M7.2651367,6.1738281C6.7329102,6.5068359,6.5,6.6845703,6.5,7v0.5h-1V7c0-0.9023438,0.7138672-1.3486328,1.2348633-1.6738281  C7.2670898,4.9931641,7.5,4.8154297,7.5,4.5c0-0.5517578-0.4487305-1-1-1h-1c-0.5512695,0-1,0.4482422-1,1V5h-1V4.5  c0-1.1025391,0.8969727-2,2-2h1c1.1030273,0,2,0.8974609,2,2C8.5,5.4023438,7.7861328,5.8486328,7.2651367,6.1738281z" fill="#00000"></path></svg> : null}
      
        {overlay ? <Overlay close={() => setOverlay(false)} >
        <>
                        
                       
                      
                        <div style={{gridColumn: "4/6",gridRow: "4/5", marginTop:"-40px"}}>
                            <img src={arrow_up} alt="arrow" className="arrow" />
                            <span >Load the Provider for the Dispense Here</span>
                        </div>
        
                        <div style={{gridColumn: "2/4",gridRow: "4/5", justifySelf:"start", marginLeft:"90px"}}>
                            <img src={arrow_up} alt="arrow" className="arrow" />
                            <span >Unload an Active Dispenser by clicking the "X" </span>
                        </div>
                        
                        
                           
                        
                        <span className="close_overlay" onClick={closeOverlay} style={{gridColumn: "3/5", gridRow:"6/7",  fontFamily: "BenchNine", fontSize:"3rem",cursor:"pointer"}}>Click Here to Close</span>
                    </> 
           </Overlay> : null}
   
        </ProviderDetailWrapper>
    );
  }
  
  export default ProviderDetail;