import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SignaturePad from 'react-signature-canvas';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';
import useFetch from '../hooks/useFetch';
import Toast from './toaster';
import { URL } from '../constants';
import { getAPI } from '../utility';

const PatientSignatureWrapper = styled.div`

    width: 75rem;
    height: 70rem;
    margin: auto;
    background-color: var(--color-grey-dark-2);
    color: #fff;
    
    border: 2rem solid  rgba(15, 215, 239, 1);
    border-radius: 5px;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    font-size: 2rem;
    white-space: nowrap;
    box-shadow: 0 1rem 3rem rgba(#fff, .2);

.sigContainer {
  width: 80%;
  height: 65%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 150px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
  display: block;
  margin: 0 auto;
}

.sig-btn-bar {
       display: flex;
       justify-content: space-around;
       margin-right:2rem;
       margin-top:3rem;
     }

`;

const PatientSignature = (props) => {

  const [toast, setToastState] = useState(false);
  const [toast_msg, setToastMsg] = useState("");
  const [toast_icon, setToastIcon] = useState("");
  const [toast_title, setToastTitle] = useState("Success");
  const [toast_background, setToastBackground] = useState("#5cb85c");

    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const [disable, setDisable] = useState(false);
    const [url, setURL] = useState('');
    const { status, data, error } = useFetch(url);

    useEffect(() => {
        if(props.idx === '') {
          setToastIcon("error");
          setToastTitle("Error");
          setToastBackground("#d9534f");
          setToastMsg("Please Select a Dispense First and Try Again.");
          setToastState(true);
          props.close();
        } else {
          setURL(`${URL}dispenseReturnSignature?idx=${props.idx}`);
        }
        
    }, []);

    useEffect(() => {
      if(status === 'fetched') {
        console.log(data);
        setTrimmedDataURL(data[0].dsp_signature);
      }
    }, [status]);


    let sigPad = {};

    const clear = () => {
        sigPad.clear();
    }
    const trim = () => {

        setDisable(true);

        setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png'));
        let signature = sigPad.getTrimmedCanvas().toDataURL('image/png');

        let obj = {};
        obj.idx = props.idx;
        obj.sig = signature;

        let json = JSON.stringify(obj);

        console.log(json);

        if (signature.length === 0) {
          setToastIcon("error");
          setToastTitle("Error");
          setToastBackground("#d9534f");
          setToastMsg("Please Enter a Signature and Try Again.");
          setToastState(true);

          setDisable(false);
          
      } else {
                 //POST DATA
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'x-functions-key' :  getAPI() },
                body: json
                };
                fetch(`${URL}dispenseUpdateSignature`, requestOptions)
                     .then(() => {
                        
                        setToastIcon("success");
                        setToastTitle("Success");
                        setToastMsg("Patient Signature Saved Successfully");
                        setToastBackground("#5cb85c");
                        setToastState(true);
                        setDisable(false);
                       
                        
                    })            
                    .catch(err => {
                        console.log(err);
                        setToastIcon("error");
                        setToastTitle("Error");
                        setToastBackground("#d9534f");
                        setToastMsg("There was a Problem Saving the Patient Signature. Please Try Again.");
                        setToastState(true);

                        setDisable(false);
                        
                    });
      
          } 

        
    }


    return(
        <PatientSignatureWrapper>
           <div className="header"><FormHeader onClose={props.close} title="PATIENT CONSULTATION" addClose={true} class_style="form_header--light"></FormHeader></div>
           {trimmedDataURL ? <img className="sigImage" src={trimmedDataURL} /> : null}
           <div className="sigContainer">
                <SignaturePad canvasProps={{className: "sigPad"}}
                    ref={(ref) => { sigPad = ref }} />
           </div>

           <div className="sig-btn-bar">
                <ButtonExpand title="CLEAR" click={clear} />
                { disable ? <div></div> : <ButtonExpand title="SUBMIT" click={trim} />}
                
           </div>

          

           <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>


        </PatientSignatureWrapper>
    );
}

export default PatientSignature;