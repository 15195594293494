import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';
import Toast from './toaster';
import RxHistoryAdd from './rxhistory_add';
import PatientSignature from './patient-signature';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import ReactLoading from 'react-loading';
import { getAPI } from '../utility';
import Table from './table';
import Overlay from '../theme/overlay';
import arrow_down_right from '../assets/arrow_down_right.png';
import arrow_up from '../assets/arrow_up.png';
import arrow_down from '../assets/arrow_down.png';
import arro_up_right from '../assets/arro_up_rights.png';
import { getSession } from '../utility';

const PatientRxHistoryWrapper = styled.div`

    
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 998;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;



        @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
            -webkit-backdrop-filter: grayscale(80%) blur(5px);
            backdrop-filter: grayscale(70%) blur(5px);
            background-color: rgba(#000, .4);

        }



 .rxhistory__header {
    grid-row: 1/2;
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--line);
    padding: 2rem;
}

.rxhistory__title {
    font-size: 2rem;
    color: var(--color-primary);
    margin-left: 2rem;
    

}

.rxhistory_close {
       
        text-decoration: none;
        transition: all .3s;
        line-height: 1;
        margin-right: 2rem;
        cursor: pointer;
        fill: var(--color-primary);
        transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

        .rxhistory_close:hover {
            fill: red;
        }

    

  
}

.rxhistory_content {
        height: 70vh;
        width: 80vw;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 99;
        opacity: 1;
        transition: all .3s;
        background-color:  rgba(0,0,0, 0.6); 
        backdrop-filter: blur(19px);
        -webkit-backdrop-filter: blur(19px);
        border-radius: 10px;
        border: 1px solid rgba(255,255,255,0.6);
        font-size: 2rem;
  }

    
 

.rxhistory__add-header {
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--line);
    padding: 2rem;
}

.rxhistory__btn-bar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1rem;
}

.btn__table {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  
  
}

#btn__add {
    position: absolute;
    top:-100%;
    width: 100%;
    height: 25rem;
    background-color: var(--color-primary);
    transition: all .7s cubic-bezier(0.36, 0, 0.66, -0.56);
    

    #btn__add-close {
        text-decoration: none;
        transition: all .3s;
        line-height: 1;
        margin-right: 2rem;
        cursor: pointer;
        fill: #fff;
        transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

        &:hover {
            fill: red;
        }

    
    }

    #btn__add-title {
        color: #fff;
        font-size: 2rem;
    }

}

.btn_disabled {
    text-transform: uppercase;
    text-decoration: none;
    padding: .75rem 3rem; 
    display: inline-block;
    border-radius: 10rem;
    transition: all .2s;
    position: relative;
    border:none;
    background-color: #ccc;
}





`;

const PatientRxHistory = (props) => {

    const [rx_idx, setIDX] = useState('');
    const [rxNumber, setRxNumber] = useState('');
    const [ln, setLN] = useState('');
    const [onsetDate, setOnsetDate] = useState('');
    const [source, setSource] = useState('');
    const [table_meta, setTableMeta] = useState([]);
    const [add, setAdd] = useState(false);
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");
    const [query, setQuery] = useState('');
    const [sig, setSig] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [url, setUrl] = useState("");
    const { status, data, error } = useFetch(url);

    const [overlay, setOverlay] = useState(false);

    useEffect(() => {
        setTableMeta([
            {"col_width": "col-15", "data_label": "Rx Number","key": "RxNumber"},
            {"col_width": "col-40", "data_label": "Label Name","key": "DrugDescription"},
            {"col_width": "col-25", "data_label": "Written Date","key": "WrittenDateString"},
            {"col_width": "col-25", "data_label": "Source","key": "drg_source"}
        ]);
       
        setUrl(`${URL}patientRxHistory?idx=${props.idx}`);

    }, []);
    
    useEffect(() => {
        setUrl(`${URL}patientRxHistory?idx=${props.idx}`);
    }, [query]);

    

     const selectRow = (e) => {
        setDisabled(false);
       
        setIDX(e.target.id);

        const sel = document.getElementById(e.target.id);
        let els = document.getElementsByClassName('table-row__selected');
        
        while (els[0]) {
            els[0].classList.remove('table-row__selected')
        }

        sel.classList.add("table-row__selected");
    }
 
    function open_add() {
        
            // evt.preventDefault();
            setAdd(true);
    }

    const replace = async() => {
        console.log('replace');
      
        let arr =  data.filter((item) => item.idx.toString() === rx_idx);
        let src = arr[0].drg_source;
       

        const sel = document.getElementsByClassName('table-row__selected');
        
        if (sel.length > 0) {
           

            let ul = "";
            if (src === "Supplemental") {
                ul = `${URL}dispenseSupplementalDelete?idx=${rx_idx}`
            }  else {
                ul = `${URL}dispenseUpdateReverse?idx=${rx_idx}`
            }


            try {
              const response = await fetch(ul, {
                method: 'GET',
                    headers: {
                        'x-functions-key': getAPI(),	
                    }
                });
              const rslt = await response.json();
              let data = "";
              try {
                  
                  setToastIcon("success");
                  setToastTitle("Success");
                  setToastBackground("#5cb85c");
                  setToastMsg("Dispense Reversed / Replaced Successfully.");
                  setToastState(true);

                  setDisabled(true);

                  setUrl('');
                  setQuery(Math.floor(Math.random() * 100));
                
              } catch {
                  data = rslt;
              }
              
              
             
          } catch (error) {
            console.log(error);
                setToastIcon("error");
                setToastTitle("Error");
                setToastBackground("#d9534f");
                setToastMsg("There was a Problem Reversing this Dispense, Please Try Again.");
                setToastState(true);
                
          }
        }
    }

    const destroy = async () => {
      
        let arr =  data.filter((item) => item.idx.toString() === rx_idx);
        let src = arr[0].drg_source;


        const sel = document.getElementsByClassName('table-row__selected');


        if (sel.length > 0) {
         
            
           let ul = "";
           if (src === "Supplemental") {
               ul = `${URL}dispenseSupplementalDelete?idx=${rx_idx}`
           }  else {
               ul = `${URL}dispenseUpdateReverse?idx=${rx_idx}`
           }


            try {
              const response = await fetch(ul, {
                method: 'GET',
                headers: {
                    'x-functions-key': getAPI(),
                 }	
                });
              const rslt = await response.json();
              let data = "";
              try {
                  
                  setToastIcon("success");
                  setToastTitle("Success");
                  setToastBackground("#5cb85c");
                  setToastMsg("Dispense Reversed / Destroyed Successfully.");
                  setToastState(true);

                  setDisabled(true);
                  
                  setUrl('');
                  setQuery(Math.floor(Math.random() * 100));
              } catch {
                  data = rslt;
              }
              
              
             
          } catch (error) {
                setToastIcon("error");
                setToastTitle("Error");
                setToastBackground("#d9534f");
                setToastMsg("There was a Problem Reversing this Dispense, Please Try Again.");
                setToastState(true);
                
          }
        }
        
    }

    const reloadRx = () => {
        setAdd(false);
        setUrl('');
        setQuery(Math.floor(Math.random() * 100));
    }

        
  const closeOverlay = () => {
    setOverlay(false);
}

    return(
            <PatientRxHistoryWrapper>
                <div id="rx_content" className="rxhistory_content">
                   
                    <FormHeader onClose={props.onClose} title="PATIENT RX HISTORY" addClose={true} class_style="form_header--dark" ></FormHeader>
                    { getSession("help") ? <svg style={{marginRight: "3rem", cursor:"pointer", position:"absolute", top:"20", left: "600"}} onClick={() => setOverlay(true)} width="40px" height="40px" viewBox="0 0 12 12"   version="1.1"  ><path d="M6,0C2.6862793,0,0,2.6862793,0,6s2.6862793,6,6,6s6-2.6862793,6-6S9.3137207,0,6,0z M6.5,9.5h-1v-1h1V9.5z   M7.2651367,6.1738281C6.7329102,6.5068359,6.5,6.6845703,6.5,7v0.5h-1V7c0-0.9023438,0.7138672-1.3486328,1.2348633-1.6738281  C7.2670898,4.9931641,7.5,4.8154297,7.5,4.5c0-0.5517578-0.4487305-1-1-1h-1c-0.5512695,0-1,0.4482422-1,1V5h-1V4.5  c0-1.1025391,0.8969727-2,2-2h1c1.1030273,0,2,0.8974609,2,2C8.5,5.4023438,7.7861328,5.8486328,7.2651367,6.1738281z" fill="#ffffff"></path></svg> : null }
                           
                    <div className="rxhistory__btn-bar">
                            <ButtonExpand title="ADD NEW" click={open_add} />
                            { disabled ? <div className="btn_disabled">REVERSE-REPLACE</div> : <ButtonExpand title="REVERSE-REPLACE" click={replace} />}
                            { disabled ? <div className="btn_disabled">REVERSE-DESTROY</div> : <ButtonExpand title="REVERSE-DESTROY" click={destroy} />}
                            { disabled ? <div className="btn_disabled">PATIENT SIGNATURE</div> : <ButtonExpand title="PATIENT SIGNATURE" click={() => setSig(true)}/>}
                           
                    </div>
                    <div>
                    {status === 'error' && <div>{error}</div>}
                    {status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
                    {status === 'fetched' && (
                        <Table data={data} meta={table_meta} row_class="table-row" include_header="true" select={selectRow} table_height="tbl-med"></Table>
                    )}
                    </div>
                </div>

                <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
              </div>

                { add ? <RxHistoryAdd ptt={props.idx} close={() => setAdd(false)} select={reloadRx}></RxHistoryAdd> : null }
                { sig ? <PatientSignature idx={rx_idx} close={() => setSig(false)} ></PatientSignature> : null }
                

                {overlay ? <Overlay close={() => setOverlay(false)} >
              <>
                        
                        <div  className="item-end" style={{gridRow: "2/3",gridColumn: "1/3", marginLeft: "20px", alignSelf: "end", justifySelf:"start"}}>
                            <span>Add New Supplemental Med Here.</span>
                            <img src={arrow_down_right} alt="arrow" className="arrow" />
                        </div>
                        
                        <div  className="item-end" style={{gridRow: "2/3",gridColumn: "3/5", marginLeft: "-20px", alignSelf: "end", justifySelf:"start"}}>
                            <span>With Dispense Selected,Reverse Destroy Med here.</span>
                            <img src={arrow_down_right} alt="arrow" className="arrow" />
                        </div>
                        <div  className="item-end" style={{gridRow: "3/4",gridColumn: "2/4", marginLeft: "-20px", alignSelf: "end", justifySelf:"start"}}>
                            <span>With Dispense Selected,Reverse Replace Med here.</span>
                            <img src={arro_up_right} alt="arrow" className="arrow" />
                        </div>

                        <div  className="item-end" style={{gridRow: "3/4",gridColumn: "4/7", marginLeft: "-20px", alignSelf: "end", justifySelf:"start"}}>
                            <span>Add a Patient Signature to Selected Dispense here.</span>
                            <img src={arro_up_right} alt="arrow" className="arrow" />
                        </div>

                        <div style={{gridColumn: "3/7",gridRow: "4/5",alignSelf:"end", justifySelf: "start", marginLeft:"90px"}}>
                            
                            <span >Click on Dispense Row to Select. It will remain highlighted Orange.</span>
                        </div>

                                               
                        <span className="close_overlay" onClick={closeOverlay} style={{gridColumn: "3/5", gridRow:"1/3",  fontFamily: "BenchNine", fontSize:"3rem",cursor:"pointer"}}>Click Here to Close</span>
             </> 
           </Overlay> : null}
               
            </PatientRxHistoryWrapper>
           
        );
}

export default PatientRxHistory;

