import React from 'react';
import logo from './PatientDispenseLog.jpg';
import {
    Document,
    Page,
    View,
    Text,
    StyleSheet,
    PDFViewer,
    Image
    
  } from "@react-pdf/renderer";
  

const styles = StyleSheet.create({
    body: {
      padding: 10,
     
    },
    viewer: {
      width: window.innerWidth / 1.78,
      height: window.innerHeight / 1.60,
    }
    });


const ManualPatientLog = () => {
    
        return (
          
                <PDFViewer style={styles.viewer}>         
                    <Document >
                        <Page style={styles.body}>
                            <View>
                                <Image  src={logo} cache={false} />
                               
                                
                            </View>
                        </Page>
                    </Document>
                </PDFViewer> 
           
        )
    }

    export default ManualPatientLog;