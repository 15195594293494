import React from 'react';
import styled from 'styled-components';
import ButtonExpand from '../theme/btn_expand';

const DisclaimerWrapper = styled.div`


    z-index:9999;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
   
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
    .disc_container { 
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
       
        height: 40rem;
        width: 50vw;
        background-color: var(--color-grey-dark-2);
        opacity: 1;
        transition: all .3s;
        min-height: 36.3rem;
        border: 2rem solid  var(--color-primary);
        border-radius: 5px;

      }
      .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        margin-top: 3rem;
      }

      .modal-body {
        font-size: 2rem;
        color: navy;
        display: flex;
        flex-direction: column; 
        align-items: center;
        justify-content: center;
        margin-top: var(--line);
        margin-bottom: var(--line);
        padding: 2rem;
      }
     

`;

const Disclaimer = (props) => {

    const cancel = () => {


        props.cancel(props.opt_temp);
    }

    const select = () => {
        props.select(props.opt_temp); 
    }
  
    return (
      <DisclaimerWrapper>
        <div className="disc_container">
            <div className="modal-body">
                <h2>Disclaimer</h2>
                
                    <p>{props.disc}</p>
                
            </div>
            <div className="modal-footer">
                <ButtonExpand title="ACCEPT"  click={select} />
                    
                
                <ButtonExpand title="CANCEL" click={cancel} />
                    
            </div>
        </div>
      </DisclaimerWrapper>
     
    );
  
}

export default Disclaimer;
