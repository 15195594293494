import React, {useEffect, useState} from 'react';
import Toast from './toaster';
import InputMask from 'react-input-mask';
import ButtonExpand from '../theme/btn_expand';
import styled from 'styled-components';
import FormHeader from './form-header';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import {us_states, mx_states, cn_states} from '../constants';
import CSPMPSetup from './cspmp-setup';
import {isValidNumber, isValidString, isDate, isSSN, isPhone, isZip5, getSession, getAPI } from '../utility';


const ClinicEditWrapper = styled.div`

    width: 80rem;
    height: 75rem;
    margin: auto;
    background-color: var(--color-grey-dark-2);
    color: #fff;
    
    border: 2rem solid  rgba(15, 215, 239, 1);
    border-radius: 5px;
    
   
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    font-size: 2rem;
    white-space: nowrap;
    box-shadow: 0 1rem 3rem rgba(#fff, .2);

    .form-container {
       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: 1fr ;
       grid-gap: 2rem;
       padding-top: 1rem;
       padding-bottom: 1rem;
       font-size: 1.75rem;
       height: auto;
    }

    .form-title {
        margin-top: 1rem;
        margin-left: 1rem;
        text-transform: uppercase;
    }

    .cln-btn-bar {
        
        display: flex;
        align-items: space-between;
        justify-content: space-around;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        width: 100%;
     
    }

    
    .adm-lbl {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .adm-txt {
        font-size: 2.1rem;
        font-weight: bold;
    }

   

`;


const ClinicEdit = (props) => {

    const [name, setName] = useState('');
    const [cspmp, setCSPMP] = useState(false);
    const [idx, setIdx] = useState('-1');
    const[pmp, setPMP] = useState({});
    const [cln, setCln] = useState(getSession(props.clinic.idx));
    const [grp, setGRP]  = useState("");
    const [clinicNumber , setClinicNumber ] = useState("");
    const [clinicShipto , setChinicShipto ] = useState("");
    const [clinicName , setClinicName ] = useState("");
    const [clinicAlias , setClinicAlias ] = useState("");
    const [clinicPhone , setClinicPhone ] = useState("");
    const [clinicFax , setFax ] = useState("");
    const [clinicType , setType ] = useState("");
    const [address1 , setAddress1 ] = useState("");
    const [address2 , setAddress2 ] = useState("");
    const [city , setCity ] = useState("");
    const [state , setState ] = useState("");
    const [zip , setZip ] = useState("");
    const [country , setCountry ] = useState("US");
    const [isRefill , setRefill ] = useState("");
    const [primaryName , setPrimary ] = useState("");
    const [dea , setDEA ] = useState("");
    const [region , setRegion ] = useState("");
    const [usr , setUsr ] = useState("");
    const [pwd , setPwd ] = useState("");
    const [key , setKey ] = useState("");

    const [clinic_details , setClinicDetails ] = useState({});

    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");

    const [valid_err, setValErr] = useState([]);

    const [showUse, setShowUse] = useState(false);


    const [states, setStates] = useState(us_states);

    const [url, setUrl] = useState("");
    const { status, data, error } = useFetch(url);

    useEffect(() => {
        console.log('group object passed to edit',props.grp.idx);
        setCln(props.clinic.idx);
        setIdx(props.clinic.idx);
        setGRP(props.grp.idx);

    }, []);


   

    useEffect(() => {

        console.log('idx', idx);

        if(idx !== '-1') {

           setUrl(`${URL}admReturnClinicDetail?idx=${idx}`); 

        }
    }, [idx]);

        useEffect(() => {

            console.log("pmp",pmp);
            
            if (Object.keys(pmp).length !== 0) {
                setCSPMP(true);
            }
            
        }, [pmp]);

        useEffect(() => {
            if(Object.keys(clinic_details).length !== 0) {
                setCSPMP(true); 
            }
        }, [clinic_details]);

    useEffect(() => {
        if(status === "fetched") {

            console.log('fetched',data);
            
            setGRP(data.GRP_IDX);
            setClinicNumber(data.ClinicNumber);
            setChinicShipto(data.ClinicShipTo);
            setClinicName(data.ClinicName);
            setClinicAlias(data.ClinicAlias);
            setClinicPhone(data.ClinicPhone);
            setFax(data.ClinicFax);
            setType(data.ClinicType);
            setAddress1(data.Address1);
            setAddress2(data.Address2);
            setCity(data.City);
            setState(data.State);
            setZip(data.ZipCode);
            setCountry(data.Country);
            setRefill(data.IsRefill);
            setPrimary(data.PrimaryName);
            setDEA(data.DEA);
            setRegion(data.Region);
            setUsr(data.Usr);
            setPwd(data.Pwd);
            setKey(data.RegionKey);



        }
    }, [status]);

    const submit = () => {
        console.log('submit');

        setValErr([]);
        let errors = [];

       let obj = {};
       obj.cln = idx === '-1' ? null : idx;
       obj.grp = grp; 
       obj.clinicNumber = isValidString(clinicNumber) ? clinicNumber : errors.push("Clinic Number Is Required.");
       obj.clinicShipto = isValidString(clinicShipto) ? clinicShipto : errors.push("Clinic ShipTo Is Required.");
       obj.clinicName = isValidString(clinicName) ? clinicName.toUpperCase() : errors.push("Clinic Name Is Required.");
       obj.clinicAlias = clinicAlias.toUpperCase();
       obj.clinicPhone = isPhone(clinicPhone) ? clinicPhone : errors.push("Clinic Phone Is Required.");
       obj.clinicFax =  clinicFax  === undefined ? "" : clinicFax;
       obj.clinicType = "Regular";
       obj.address1 = isValidString(address1) ? address1.toUpperCase() : errors.push("Address1 Is Required.");
       obj.address2 = address2;
       obj.city = isValidString(city) ? city.toUpperCase() : errors.push("City Is Required.");
       obj.state = state;
       obj.zip = isZip5(zip) ? zip : errors.push("Zip Code Is Required.");
       obj.country = country === "" ? "US" : country;
       obj.isRefill = "false";
       obj.primaryName = primaryName;
       obj.dea  = dea;
       obj.region = region;
       obj.usr  = usr;
       obj.pwd  = pwd;
   
         console.log(obj);
         let json = JSON.stringify(obj);
         console.log(json);

         console.log('errors',errors.length);
           
        if (errors.length > 0) {
            setValErr(errors);
            
        } else {


             let ur = idx !== '-1' ? URL + "admUpdateClinic" : URL + "adInsertClinic";

               //POST DATA
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
          body: json
          };
          fetch(`${ur}`, requestOptions)
              .then (response => {
                if (response.ok === false) {  throw new Error('Network response was not ok.') };
                response.json()
              })
              .then(data => {
              
                  console.log(data);
                  setIdx(data); 
                  setValErr([]);
                  setToastIcon("success");
                  setToastMsg("Clinic Saved Successfully");
                  setToastState(true);
                  setShowUse(true);
                
              })            
              .catch(err => {
                  console.log(err);
                  setToastIcon("error");
                  setToastTitle("Error");
                  setToastBackground("#d9534f");
                  setToastMsg("There was a Problem Saving the Clinic. Please Try Again.");
                  setToastState(true);
                  setShowUse(false);
              });
        }
    }

    const useClinic = () => {    

        console.log('use clinic', clinicName);
        props.select({ idx:idx, name: clinicName });

    }

    const mapStates = (e) => {

        setCountry(e.target.value);

       switch(e.target.value) {
           case "US" : setStates(us_states);
           break;
           case "MX": setStates(mx_states);
           break;
           case "CN" : setStates(cn_states);
           break;
           default: setStates(us_states);
       }
        
       
    }


    let _states = states.map((item, index) => {
        return (<option value={item}>{item}</option>);
        });

        const launchCSPMP = () => { 
           
            let obj = {};
            obj.grp =grp;
            obj.clinicAlias = clinicAlias;
            obj.clinicPhone = clinicPhone;
            obj.clinicPhone = clinicPhone;
            obj.clinicAddress1 = address1;
            obj.clinicAddress2 = address2;
            obj.clinicCity = city;
            obj.clinicState = state;
            obj.clinicZip = zip;

            console.log(obj);

            setClinicDetails(obj);

            
            
        }

        const loadCSPMP = (cs) => {
           console.log(cs);
        }

        const err = () => {
            try {
                if (Array.isArray(valid_err)) {
                  return valid_err.map((item, index)=>{
                      return (
                            <ul>
                                  <li className="err_list">{item}</li>
                                 
                            </ul>
                       );
                  });
              } else {
                return (
                  <tr><td colSpan='8'><div>No Errors to Report.</div></td></tr>
                );
              }
              } catch (err) {
                return;
              }
        }

        const switchToMask = (e) => {

            console.log('switch to mask', e.target.id);
    
            if (e.target.id === "mobile_set") {
                const val_phn = document.getElementById("mobile_set");
                const enter_phn = document.getElementById("mobile");
               
                val_phn.style.display = "none";
                enter_phn.style.display = "block";
    
            } else if (e.target.id === "fax_set") {
                const val_phn = document.getElementById("fax_set");
                const enter_phn = document.getElementById("fax");
               
                val_phn.style.display = "none";
                enter_phn.style.display = "block";
            }
    
                  
          
        }
    
        const switchToInput = (e) => {
    
            console.log('switch to input',e.target.id);
    
            if (e.target.id === "mobile") {
                const val_phn = document.getElementById("mobile_set");
                const enter_phn = document.getElementById("mobile");
               
                
    
                val_phn.style.display = "block";
                enter_phn.style.display = "none";
    
            } else if (e.target.id === "fax") {
                const val_phn = document.getElementById("fax_set");
                const enter_phn = document.getElementById("fax");
               
                
    
                val_phn.style.display = "block";
                enter_phn.style.display = "none";
            }

        }


    return (
        <ClinicEditWrapper>
             <FormHeader onClose={props.close} title="CLINIC EDIT"addClose={true} class_style="form_header--light" ></FormHeader>
         
             <div id="error_list" >{err()}</div>


             <div className="form_row1-col2">
                <div className="form_col2_fld1">
                    <input onChange={e => setClinicName(e.target.value)} value={clinicName}  type="text" className="form_input--light adm-txt req" placeholder="Clinic Name" id="cln_name" maxLength="35" autoComplete="new-user-first-name"  />
                    <label htmlFor="cln_name" className="form_label--light adm-lbl">Clinic Name</label>
                </div>
                <div className="form_col2_fld2">
                    <input onChange={e => setClinicAlias(e.target.value)} value={clinicAlias} type="text" className="form_input--light adm-txt req" placeholder="Clinic Alias"  id="cln_alias" maxLength="35" autoComplete="new-user-last-name"  />  
                    <label htmlFor="cln_alias" className="form_label--light adm-lbl">Clinic Alias</label>
                </div>
                
            </div>
            <div className="form_row1-col2">
                <div className="form_col2_fld1">
                    <input onChange={e => setClinicNumber(e.target.value)} value={clinicNumber}  type="text" className="form_input--light adm-txt req" placeholder="Clinic Number" id="cln_number" maxLength="35" autoComplete="new-user-first-name"  />
                    <label htmlFor="cln_number" className="form_label--light adm-lbl">Clinic Number</label>
                </div>
                <div className="form_col2_fld2">
                    <input onChange={e => setChinicShipto(e.target.value)} value={clinicShipto} type="text" className="form_input--light adm-txt req" placeholder="Clinic ShipTo"  id="cln_shipto" maxLength="35" autoComplete="new-user-last-name"  />  
                    <label htmlFor="cln_shipto" className="form_label--light adm-lbl">Clinic ShipTo</label>
                </div>
                
            </div>
            <div className="form_row1-col2">
                <div className="form_col2_fld1">

                        <input onFocus={switchToMask}   value={clinicPhone} type="text" className="form_input--light"  id="mobile_set"/>  
                        <InputMask onBlur={switchToInput} style={{display:"none"}}  onChange={e => setClinicPhone(e.target.value)} value={clinicPhone}  type="text" className="form_input--light" mask="(999) 999-9999" placeholder="Phone"   id="mobile" autocomplete="false" /> 
                        <label htmlFor="mobile" className="form_label--light adm-lbl">Clinic Phone</label>
                </div>
                <div className="form_col2_fld2">

                        <input onFocus={switchToMask}   value={clinicFax} type="text" className="form_input--light"  id="fax_set"/>  
                        <InputMask onBlur={switchToInput} style={{display:"none"}}  onChange={e => setFax(e.target.value)} value={clinicFax}  type="text" className="form_input--light" mask="(999) 999-9999" placeholder="Fax"   id="fax" autocomplete="false" /> 
                        <label htmlFor="cln_fax" className="form_label--light adm-lbl">Clinic Fax</label>
                </div>
                
            </div>

            <div className="form_row1-col3">
                <div id="country_container" className="form_col3_fld1">
                    <select id="country" value={country} className="form_input req" onChange={mapStates}>
                        <option value="US">US</option>
                        <option value="CN">CN</option>
                        <option value="MX">MX</option>
                        
                    </select>
                    <label for="country" className="form_label--light adm-lbl">Country</label>
                </div>
                <div className="form_col3_fld2">
                    <input onChange={e => setAddress1(e.target.value)} value={address1} type="text" className="form_input--light adm-txt req" placeholder="Address1"  id="cln_address1" maxLength="35" autoComplete="new-user-last-name"  />  
                    <label htmlFor="cln_address1" className="form_label--light adm-lbl">Address1</label>
                </div>
                <div className="form_col3_fld3">
                    <input onChange={e => setAddress2(e.target.value)} value={address2} type="text" className="form_input--light adm-txt req" placeholder="Address2"  id="cln_address2" maxLength="35" autoComplete="new-user-last-name"  />  
                    <label htmlFor="cln_address2" className="form_label--light adm-lbl">Address2</label>
                </div>
                
            </div>
            <div className="form_row1-col3">
                <div className="form_col3_fld1">
                    <input onChange={e => setCity(e.target.value)} value={city} type="text" className="form_input--light adm-txt req" placeholder="City"  id="cln_city" maxLength="35" autoComplete="new-user-last-name"  />  
                    <label htmlFor="cln_city" className="form_label--light adm-lbl">City</label>
                </div>
                <div className="form_col3_fld2">
                        <select id="state" className="form_input req"  value={state}  onChange={e => setState(e.target.value)}>
                            {_states}
                        </select>
                        <label for="state" className="form_label--light adm-lbl">State</label>
                </div>

                <div className="form_col3_fld3">
                    <input onChange={e => setZip(e.target.value)} value={zip} type="text" className="form_input--light adm-txt req" placeholder="Zip Code"  id="cln_zip" maxLength="35" autoComplete="new-user-last-name"  />  
                    <label htmlFor="cln_zip" className="form_label--light adm-lbl">Zip Code</label>
                </div>
            </div>


             <div className="cln-btn-bar">
                   { showUse ? <ButtonExpand title="USE THIS CLINIC" click={useClinic} /> : <div></div>}
                    <ButtonExpand title="CSPMP" click={launchCSPMP} />
                    <ButtonExpand className="cln-btn-right" title="SUBMIT" click={submit}  /> 

                </div>
                
                <div className="toasty">
                    {toast ?  <Toast 
                        id={74}
                        position={'bottom-center'}
                        autoDelete={true}
                        autoDeleteTime={3000}
                        title={toast_title}
                        description={toast_msg}
                        backgroundColor={toast_background}
                        icon={toast_icon}
                        close={() => setToastState(false)}

                    /> : null }
                </div>

                {cspmp ? <CSPMPSetup cln={idx} clinic={clinic_details} close={() => setCSPMP(false)} select={(cs) => loadCSPMP(cs)}/> : null}
          
        </ClinicEditWrapper>
    );
}

export default ClinicEdit;
