import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';
import {reactions} from '../constants';
import InputMask from 'react-input-mask';
import AutoCompleteForm from '../theme/autocomplete-form';
import { URL } from '../constants';
import Toast from './toaster';
import { getAPI } from '../utility';

const AllergenAddWrapper = styled.div`
    width: 60rem;
    height: 40rem;
    margin: auto;
    background-color: var(--color-grey-dark-2);
    color: #fff;
    
    border: 2rem solid  rgba(15, 215, 239, 1);
    border-radius: 5px;
    
   
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    font-size: 2rem;
    white-space: nowrap;
    box-shadow: 0 1rem 3rem rgba(#fff, .2);

    .alg-mini-form {
       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: 1fr 1fr;
       grid-gap: 2rem;
       padding-top: 1rem;
       padding-bottom: 1rem;
       font-size: 2rem;
       margin-left: 2rem;
       margin-right: 2rem;
    }

     .alg-btn-bar {
       display: flex;
       justify-content: flex-end;
       margin-right:2rem;
       margin-top:3rem;
     }

     .header {
        width: 100%;
        margin-top: -1rem;
     }

     #alg-type_container {

        grid-column: 1 / 2;
       
        
     }

    #allergen-container {
        grid-column: 2 / 3;
        width:200px;
    }

    #reaction-container {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        width:200px;
    }

    #reaction-type-container {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        width:200px;
    }

    #severity-container {
        grid-row: 3 / 4;
        grid-column: 1 / 2;
        width:200px;
    }

`;

const AllergenAdd = (props) => {

   
    const [onsetDate, setOnsetDate] = useState('');
    const [type, setType] = useState('');
    const [reaction_type, setReactionType] = useState('');
    const [severity, setSeverity] = useState('');
    const [allergen, setAllergen] = useState('');
    const [reaction, setReaction] = useState('');
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");

    const [valErr, setValErr] = useState([]);
    const [disabled, setDisabled] = useState(false);

    let _reaction = reactions.map((item, index) => {
        return (<option value={item}>{item}</option>);
    });

    const submit = () => {

        setDisabled(true);

        let data = {
            pat: props.ptt,
            rxcui: "",
            source: "FDB",
            dam_desc: allergen.DAM_CONCEPT_ID_DESC,
            dam_id: allergen.DAM_CONCEPT_ID,
            dam_type: type,
            onset: onsetDate,
            reaction_type: reaction_type,
            severity: severity,
            reaction: reaction
           
        }
        console.log(data);
        let json = JSON.stringify(data);
        

        //POST DATA
        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-functions-key': getAPI(), },
        body: json
        };
        fetch(`${URL}allergenPostInsert`, requestOptions)
        .then (response => {
            if (response.ok === false) {  throw new Error('Network response was not ok.') };
                             
                setValErr([]);
                setToastIcon("success");
                setToastMsg("Patient Allergen Added Successfully");
                setToastState(true);
                setToastBackground("#5cb85c");
                setToastTitle("Success");
                setDisabled(false);

                props.select();
            })            
            .catch(err => {
                
                setToastIcon("error");
                setToastTitle("Error");
                setToastBackground("#d9534f");
                setToastMsg("There was a Problem Adding the Patient Allergen, Please Try Again");
                setToastState(true);
                setDisabled(false);
            });
    }

    const selectAlg = (alg) => {

        console.log(alg);
        setAllergen(alg);
    }



    return (
        <AllergenAddWrapper>
              <div className="header"> <FormHeader onClose={props.close}  title="ADD PATIENT ALLERGEN" addClose={true} class_style="form_header--light"></FormHeader></div>
                <div className="alg-mini-form">
                                <div id="alg-type-container">
                                        <select id="alg-type" className="form_input--light req" value={type} onChange={(e) => setType(e.target.value)} >
                                            <option value="U">Allergen Type...</option>
                                            <option value="1">Ingredient Concepts</option>
                                            <option value="2">Brand Concepts</option>
                                            <option value="6">Group Concepts</option>
                                        </select>
                                </div>
                                <div id="allergen-container">
                                <AutoCompleteForm Label="ALLERGEN" id="algn" maxLength="35" required autoComplete="off"  select={(alg) => selectAlg(alg)} url={`${URL}autocompleteAllergen?idx=${type}`}/>
                                    
                                   
                                </div>
                                <div id="reaction-container">
                                        <select id="reaction" className="form_input--light req" onChange={(e) => setReaction(e.target.value)} >
                                            {_reaction}
                                           
                                        </select>
                                    
                                </div>
                                <div id="reaction-type-container">
                                        <select id="reaction-type" className="form_input--light req" value={reaction_type} onChange={(e) => setReactionType(e.target.value)} >
                                            <option value="U">Reaction Type...</option>
                                            <option value="Intolerence Concepts">Intolerence Concepts</option>
                                            <option value="True Allergy">True Allergy</option>
                                            <option value="Hypersensitivity">Hypersensitivity</option>
                                           
                                        </select>
                                </div>
                                <div id="onset-date-container">
                                        <InputMask type="tel" className="form_input--light req" mask="99/99/9999" placeholder="Onset Date"  onChange={(e) => setOnsetDate(e.target.value)}  id="onsetDate" autoComplete="off" required /> 
                                        {/* <label for="onsetDate" className="form_label">Onset Date</label> */}
                                </div>
                                <div id="severity-container">
                                        <select id="severity" className="form_input--light req" value={severity} onChange={(e) => setSeverity(e.target.value)} >
                                            <option value="U">Severity...</option>
                                            <option value="Mild">Mild</option>
                                            <option value="Moderate">Moderate</option>
                                            <option value="Severe">Severe</option>
                                        </select>
                               </div>
                        </div>
                        <div className="alg-btn-bar">{ disabled ? <div></div> : <ButtonExpand title="SUBMIT" click={submit} />}</div>

                        <div className="toasty">
                            {toast ?  <Toast 
                                id={74}
                                position={'bottom-center'}
                                autoDelete={true}
                                autoDeleteTime={3000}
                                title={toast_title}
                                description={toast_msg}
                                backgroundColor={toast_background}
                                icon={toast_icon}
                                close={() => setToastState(false)}

                            /> : null }
                        </div>
                    
                    </AllergenAddWrapper>
    );
};

    export default AllergenAdd;