import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import crx_logo from '../assets/custom-rx_logo.png';
import useIdle from '../hooks/useIdleTimeout';
import IdleModal from './idle-modal';
import PinLogin from './pin_login';
import { getSession, getAPI } from '../utility';
import { URL } from '../constants';
import Overlay from '../theme/overlay';
import arrow_down_right from '../assets/arrow_down_right.png';
import arrow_up from '../assets/arrow_up.png';
import { Tooltip } from 'react-tooltip';




const HeaderWrapper = styled.div`
       
        
        font-size: 1.4rem;
        background-color: #000;
        border-bottom: var(--line-wht);
        position: relative;
        display: flex;
        

        .header__logo-container {
            margin-left: 1rem;
            display: flex;
            align-items: flex-start;
            justify-items: center;
            transform: scale(1);
        }


        .quickstart {
                font-size: 1.4rem;
                border: 1px solid #FFF;
                border-radius: 5px;
                width: auto;
                height: auto;
                padding: 1rem;
                margin: 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #FFF;
                cursor: pointer;
        }


        .user-nav {

        align-self: stretch;

        display: flex;
        align-items: center;
        margin: 0 auto;
        margin-right: 1rem;
        font-family: 'BenchNine';


        & > * {
            padding: 0 2rem;
            cursor: pointer;
            /* height: 100%; */
            display: flex;
            align-items: center;
            padding: .5rem;
        }

        & > *:hover {
            background-color: var(--color-grey-light-2);
            
        }

        &__counter {
            border: 1px solid transparent;
            border-radius: 3px;
            background-color: #fff;
            padding: .5rem;
            display:flex;
            align-items: center;
            justify-content: center;

        }



        &__icon-box {
            position: relative;
            cursor: pointer;
            height: 100%;
            padding:1rem;
            
        
        
            
        }

        &__icon   {
            height: 3.25rem;
            width: 3.25rem;
            fill: #fff;
            margin-top: 1rem;

          
        }
        
            
        
            
        

        &__usr {
            display: flex;
            flex-direction: column;
            font-size: 2rem;
            font-weight: 400;
            color: #fff;

        }



    }

    .user-nav__usr:hover {   
        background-color: #000;
        cursor: none;
    }

    .header__cln__name {
        font-size: 1.2rem;

    }

    .info_nav:hover {
        background-color: #000;
    }

`;

// $bp-largest: 75em; //1200px
// $bp-large: 68.75em; //1100px
// $bp-medium: 56.35em; //900px
// $bp-smaller: 50em; //800px
// $bp-small: 37.5em; //600px
// $bp-smallest: 31.25em; //500px



const Header = () => {



    const [openModal, setOpenModal] = useState(false);
    const [openPIN, setOpenPIN] = useState(false);
    const [currentUser, setCurrentUser] = useState(getSession("UserFullName"));
    const [clinic, setClinic] = useState(getSession("cln_display"));
    const [hshPIN, setHshPIN] = useState(getSession("PIN"));
    const [overlay, setOverlay] = useState(false);

    useEffect(() => {

        const session = localStorage.getItem("session");
        
        if (session !== null) {
            
            let json = JSON.parse(session);
            let user = json.UserFullName;
            setCurrentUser(user);
        }
    }, []);


    const handleIdle = () => {
      setOpenModal(true);
     
    }
  
    const {idleTimer} = useIdle({ onIdle: handleIdle, idleTime: 720});

    const stay = () => {
        console.log('idleTime before reset',idleTimer.getRemainingTime());
          setOpenModal(false);
          idleTimer.reset();
          console.log('idleTimer after reset',idleTimer.getRemainingTime());
       
    }

    const session_warning_timeout = () => {
       
        setOpenModal(false);
        setOpenPIN(true);

    }

    const session_expired = () => {
        idleTimer.pause();
        setOpenPIN(false);
        logoutApp();
    }
    
    const validatePIN = async(pin) => {

     
      setOpenPIN(false);
      let ul = (`${URL}userReturnPINEncode?pin=${pin.join('')}`);
   
      const response = await fetch(ul, {
        method: 'GET',
            headers: {
                'x-functions-key': getAPI(),	
            }
        });
      const rslt = await response.json();

     

      if (rslt === hshPIN) {
        idleTimer.reset();
      } else {
        setOpenPIN(true);
      }
    }

  

    const logoutApp = () => {
        let sess = localStorage.getItem("session");
        idleTimer.pause();
        setOpenPIN(false);
      
        if(sess !== null) {
            localStorage.removeItem("session");
            window.location.reload();
        }
    
      }  

     const closeOverlay = () => {
        setOverlay(false);
    }

    const reloadScreen = async () => {
       // window.location.reload();
       const response = await fetch(URL +`cspmpAutoLoad`, {
        method: 'GET',
            headers: {
                'x-functions-key': getAPI(),	
            }
        });


    const inact = await response.json();

    console.log('report', inact);


    }


    return (

        <HeaderWrapper>
           <div className="header__logo-container">
                {/* Header */}
                <Tooltip id="hdr-tooltip" place="top" variant="info"  style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }}/>
                <img src={crx_logo} alt="CustomRx Logo" />
           </div>         
           <nav className="user-nav">
           <a className="info_nav" data-tooltip-id="hdr-tooltip"  data-tooltip-content="Refresh CRx">
                    <svg  className="info_nav" onClick={reloadScreen} height="45px" viewBox="0 0 24 24" width="45px" ><path d="m12 2a10 10 0 1 0 10 10 10 10 0 0 0 -10-10m6 9h-5l1.81-1.81a3.94 3.94 0 0 0 -2.81-1.19 4 4 0 1 0 3.86 5h2.05a6 6 0 1 1 -5.91-7 5.91 5.91 0 0 1 4.22 1.78l1.78-1.78z" fill="#ffffff"/></svg>
            </a>
                
            { getSession("help") ? <svg className="info_nav" style={{marginRight: "3rem", cursor:"pointer"}} onClick={() => setOverlay(true)} width="40px" height="40px" viewBox="0 0 12 12"   version="1.1"  ><path d="M6,0C2.6862793,0,0,2.6862793,0,6s2.6862793,6,6,6s6-2.6862793,6-6S9.3137207,0,6,0z M6.5,9.5h-1v-1h1V9.5z   M7.2651367,6.1738281C6.7329102,6.5068359,6.5,6.6845703,6.5,7v0.5h-1V7c0-0.9023438,0.7138672-1.3486328,1.2348633-1.6738281  C7.2670898,4.9931641,7.5,4.8154297,7.5,4.5c0-0.5517578-0.4487305-1-1-1h-1c-0.5512695,0-1,0.4482422-1,1V5h-1V4.5  c0-1.1025391,0.8969727-2,2-2h1c1.1030273,0,2,0.8974609,2,2C8.5,5.4023438,7.7861328,5.8486328,7.2651367,6.1738281z" fill="#ffffff"></path></svg> : null}
               
              
                <div  className="user-nav__usr">
                    <span  className="header__usr__name">{currentUser}</span>
                    <span className="header__cln__name">{clinic}</span>
                </div>
             
                <div className="user-nav__icon-box" onClick={logoutApp}>
                <svg className="user-nav__icon">
                    <path d="M24 20v-4h-10v-4h10v-4l6 6zM22 18v8h-10v6l-12-6v-26h22v10h-2v-8h-16l8 4v18h8v-6z"></path>
                </svg>

                </div>
            </nav>
           
          
           { openModal ? <IdleModal stay={stay} logout={logoutApp} close={() => setOpenModal(false)} onTimeOut={session_warning_timeout} /> : null }

           { openPIN ? <PinLogin current_user={currentUser}  onTimeOut={session_expired} logout={logoutApp} validate_pin={validatePIN}/> : null }

           {overlay ? <Overlay close={() => setOverlay(false)} >
                <>
                        
                        <div  className="item-end" style={{gridRow: "1/2",gridColumn: "1/2", marginLeft: "3rem", marginTop: "30px"}}>
                            <span>Perform all Administrative Tasks here</span>
                            <img src={arrow_down_right} alt="arrow" className="arrow" />
                        </div>
                        <div className="item-start item-justify-top" style={{gridRow: "2/3", gridColumn: "2/3"}}>
                            <span >Generate One Click Daily CSPMP Reports</span>
                        </div>
                        <div style={{gridColumn: "3/4",gridRow: "2/3", marginTop:"40px"}}>
                            <span >Search for existing Patients. Begin by typing at least the first three letters of Last Name</span>
                        </div>
        
                        <div style={{gridColumn: "4/5",gridRow: "2/3", marginLeft:"40px"}}>
                            <img src={arrow_up} alt="arrow" className="arrow" />
                            <span >Add New Patients</span>
                        </div>
                        
                        
                            
                            <div style={{gridRow:"1/2", gridColumn:"4/5", marginTop: "20px", marginLeft: "50px"}}>
                                {/* <img src={arrow_up_left} alt="arrow" className="arrowLeft" /> */}
                                <span >Print Queued Dispenses</span>
                            </div>
                            
                        <div style={{gridColumn:"5/6", gridRow:"2/3", marginLeft:"40px"}}>
                            <span >Switch Between Zebra and Sheet Label Printing</span>
                            
                        </div>
                        
                        <span className="close_overlay" onClick={closeOverlay} style={{gridColumn: "3/5", gridRow:"4/6",  fontFamily: "BenchNine", fontSize:"3rem",cursor:"pointer"}}>Click Here to Close</span>
                    </> 
           </Overlay> : null}
            
        </HeaderWrapper>
    );
}

export default Header;