import React from 'react';
import styled from "styled-components";
import FormHeader from './form-header';

const LabelPositionWrapper = styled.div`
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    z-index: 998;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);
    }

    .frm_content { 
       position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 60vh;
        width: 50%;
        
       
        /* background-color: rgba(0,0,0,.6); */
        background-color: var(--color-grey-dark-2);
        z-index: 1999;
        opacity: 1;
        transition: all .3s;
   
     min-height: 43.3rem;
     border: 2rem solid  var(--color-primary);
     border-radius: 5px;

      }

      .label_template {
        display: flex;
        width:50%;
        height: 80%;
        border: 1px solid red;
        margin: 0 auto;
        padding-top: 3%;
        background-color: #fff;
      }

      .lbl_position {
        width: 100%;
        height:19%;
        margin-left: 4%;
        font-size: 2.5rem;
        color: #000;
        text-align: center;
        background-color: #fff;
        cursor: pointer;
        border: 1px solid #000;
        border-radius: 3px;
      }

      .lbl_position:hover {
        background-color: yellow;
    }

      .label-left {
        width: 46%;
        margin-right: 3%;
      }

      .label-right {
        width: 46%;
      
      }
    

`;

const LabelPosition = (props) => {

const selectLabel = (position) => {
    console.log(position);

    props.select(position);
}

    return(
        <LabelPositionWrapper>
            <div  className="frm_content ">
                <FormHeader title="SELECT LABEL PRINT POSITION"  addClose={false}  class_style="form_header--light"></FormHeader>

                <div className="label_template">
                    <div className="label-left">
                        <div className="lbl_position" onClick={() => selectLabel(1)}>1</div>
                        <div className="lbl_position" onClick={() => selectLabel(3)}>3</div>
                        <div className="lbl_position" onClick={() => selectLabel(5)}>5</div>
                        <div className="lbl_position" onClick={() => selectLabel(7)}>7</div>
                        <div className="lbl_position" onClick={() => selectLabel(9)}>9</div>
                    </div>

                    <div className="label-right">
                        <div className="lbl_position" onClick={() => selectLabel(2)}>2</div>
                        <div className="lbl_position" onClick={() => selectLabel(4)}>4</div>
                        <div className="lbl_position" onClick={() => selectLabel(6)}>6</div>
                        <div className="lbl_position" onClick={() => selectLabel(8)}>8</div>
                        <div className="lbl_position" onClick={() => selectLabel(10)}>10</div>
                    </div>
                
                </div>

            </div>
                  
        </LabelPositionWrapper>
    );
};

export default LabelPosition;