import React from 'react';
import styled from 'styled-components';

const ButtonSlideWrapper = styled.div`
  
   .btn-hover-slide { 
        border: none;
        display: block;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        outline: none;
        overflow: hidden;
        position: relative;
        color: #fff;
        font-weight: 700;
        font-size: 1.1rem;
        background-color: #222;
        padding: 10px 40px;
        margin: 0 auto;
        box-shadow: 0 5px 15px rgba(0,0,0,0.20);

   }


   .btn-hover-slide span {
    position: relative; 
    z-index: 1;
  }

  .btn-hover-slide:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 490%;
    width: ${props => props.slideWidth || "150%"};
    background: var(--color-tertiary);
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
    transform: translateX(-98%) translateY(-25%) rotate(45deg);
   
  }
  
  .btn-hover-slide:hover:after {
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
  }

`;

const ButtonSlide = (props) => {

const buttonClick = () => {
        
        props.click();
}

    return (
        <ButtonSlideWrapper slideWidth={props.width}>
            <button onClick={buttonClick} className="btn-hover-slide">
                <span>{props.title}</span>
            </button>
        </ButtonSlideWrapper>
    )
}

export default ButtonSlide;