import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Toast from './toaster';
import SideList from './side-list';
import AutoCompleteForm from '../theme/autocomplete-form';
import LoadingButton from '../theme/loading-button';
import { URL,  lots, reason_codes, inv} from '../constants';
import { isValidNumber, isValidString, getSession, getAPI } from '../utility';


const ClnAdminInvWrapper = styled.div`
    color: #fff;
    width: 100%;
    max-height: 90%;
    border: .25rem solid rgba(#fff, .5);
    border-radius: 5px;
    border-top: var(--line);
    

    .edit_inventory__container {
        display: flex;
        justify-content: space-around;
         margin-top: 1.5rem;
         font-size: 2rem;
    }

    .edit_inventory_content {
        
        width: 65%;
        height: 32rem;
        box-shadow: 0 .5rem 2rem rgba(#fff, .2);
        border-radius: 5px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, 1fr);
        overflow: hidden;
        position: relative;
        opacity: 1;
        border: .5rem solid rgba(#fff, .5);
        transition: all 1s;
        background-color: rgba(var(--color-primary-rgb), .75);
        background-size: cover;
        background-position: top;

      

        .edit_inventory--side {
            overflow: scroll;
            position: relative;
            width: 25%;
            height: 32rem;
            border-radius: 5px;
            border: .5rem solid rgba(#fff, .5);
        }

        

    }

    
    .rcv_dsp {
        max-width: 25rem;
        padding: 1rem 2rem;
        margin-left: 7rem;
        
    }

    .edit_inventory__search {
           
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:2rem;

        .edit_inventory__search__input {
            font-family: 'BenchNine', sans-serif;
            font-size: initial;
            background-color: var(--color-grey-light-2);
            border: none;
            color: var(--color-primary);
            padding: .7rem 2rem;
            border-radius: 1rem;
            width: 50%;
            transform: all .3s;
            transition: all .5s;
            z-index: 10;
            margin-top: 1rem;
            margin-left: 2.5rem;

            &:focus {
                outline: none;
                width: 70%;
                background-color: var(--color-grey-light-3);
            }

            &::input-placeholder {
                font-weight: 100;
                color: var(--color-grey-light-4);
            }
        }
        .edit_inventory__search__input:focus  {
                background-color: var(--color-grey-light-3);
            }

    
    }

    .edit_inventory__top-hdr {
        font-family: 'BenchNine', sans-serif;
        font-size: 2.5rem;
    }

    .edit_inventory__btn-bar {
           
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: -1rem;
    }

    .edit_inventory__metadata {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1rem;
    }

    .edit_inventory__adj {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1rem;
    }

   

 

    input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    .toggle {
        transform: scale(.6);
    }

.switch {
  cursor: pointer;
  text-indent: -9999px;
  width: 15rem;
  height: 6rem;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 5rem;
  height: 5rem;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

#switch:checked + label {
  background: var(--color-tertiary);

}

#switch:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 10rem;
}

#ndc {
    font-size: 2rem;
}

#lot {
    font-size: 2rem;
}

.hideReason {
    display: none;
}

.showReason {
    display: block;
}

.lrg_type {     
    font-size: 2rem;
    font-weight: 700;
}


`;


const ClnAdminInv = (props) => {

const [toast, setToastState] = useState(false);
const [toast_msg, setToastMsg] = useState("");
const [toast_icon, setToastIcon] = useState("");
const [toast_title, setToastTitle] = useState("Success");
const [toast_background, setToastBackground] = useState("#5cb85c");
    
  const [active_inv, setActiveInv] = useState('');
  const [idx, setIDX] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [updateReason, setUpdateReason] = useState('');
  const [invAmt, setInvAmt] = useState('');
  const [reasonCode, setReasonCode] = useState('');
 
  const [ndc, setNDC] = useState("");
  const [lot, setLot] = useState("");
  const [active_lots, setActiveLots] = useState([]);
  const [_active, set_Active] = useState([]);
  const [inactive_lots, setInActiveLots] = useState([]);
  const [INVLoaded, setINVLoaded] = useState(false);
  const [active, setActive] = useState("true");
  const [reasonClass, setReasonClass] = useState("hideReason");

  const [disabled, setDisabled] = useState(false);  
  const [valid_err, setValErr] = useState([]);

  useEffect(() => {
   
  }, []);

    

 
  const clearForm = () => {
        setUpdateReason("");
        setUnitPrice("");
        setInvAmt("");
        setActiveInv("");
        setReasonCode("0");
        setIDX("");
        setNDC("");
        setLot("");
        

        setINVLoaded(false);
        setReasonClass("hideReason");


  }

  const submitForm = () => {

        setDisabled(true);

         const errors = [];
         let obj = {};

       
         obj.cln =  getSession("cln")
         obj.drg = idx;
         obj.updated = isValidNumber(invAmt) ? invAmt : errors.push("New Inventory Amount is Required"); //*
         obj.original = active_inv;
         obj.price = isValidString(unitPrice) ? unitPrice : errors.push("Unit Price Is Required."); //*
         obj.reason = isValidString(reasonCode) ? reasonCode : errors.push("Reason Code Is Required."); //*
        
         obj.MCH_IDX = getSession("DeviceToken");
        
        let json = JSON.stringify(obj);
        console.log(json);
        console.log(errors.length);
        if (errors.length > 0) {
            setDisabled(false);
            setValErr(errors);
            
        } else {

            // setPVD(json);

            //set fetch url based on pvd loaded
           

             //POST DATA
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
            body: json
            };
            fetch(`${URL}inventory_UserInvUpdate`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setValErr([]);
                    setToastIcon("success");
                    setToastMsg("Inventory Saved Successfully");
                    setToastState(true);

                    setDisabled(false);
                })            
                .catch(err => {
                    setToastIcon("error");
                    setToastTitle("Error");
                    setToastBackground("#d9534f");
                    setToastMsg("There was a Problem Saving the Inventory. Please Try Again.");
                    setToastState(true);

                    setDisabled(false);
                });
             
        }
     
  }

 
const selectLN = (ln) => {
    console.log(ln.INV);
    let act = ln.INV.filter(obj => obj.IsActive == true);
    let inact = ln.INV.filter(obj => obj.IsActive == false);

    console.log(act);
    setActiveLots(act);
    setInActiveLots(inact);

    set_Active(act);

}

  
  
  const selectRow = (id) => {
       
      let obj = _active.find(obj => obj.idx == id);
      setINVLoaded(true);
       console.log(obj);

       setIDX(obj.idx);
       setActiveInv(obj.INV);
       setUnitPrice(obj.UnitPrice);
       setInvAmt("");
       setNDC(obj.NDC);
       setLot(obj.LOT);
   
     
  }

  const updateActiveTab = (e) => {
      console.log(e.target.id);
      let index = e.target.id;
      switch(index) {
          case "1": set_Active(active_lots);
           break;
          case "2": set_Active(inactive_lots);
           break;
          default:  set_Active(active_lots);
      }

  };

  const updateReasonCode = (val) => {
        console.log(val);

        if(val == "Other")  {
            setReasonClass("showReason");
        } else {
            setReasonCode(val);
            setReasonClass("hideReason");
        }   
    }         


  const table_meta = [
      {'col_width': 'col-100', 'data_label': 'LOT','key': 'LOT', 'input': 'string'},
     
   ];


  
  let _reason_codes = reason_codes.map((item, index) => {
      return (<option key={index} value={item}>{item}</option>);
  });

  
  const err = () => {
   
    try {
        if (Array.isArray(valid_err)) {
          return valid_err.map((item, index)=>{
              return (
                    <ul>
                          <li className="err_list">{item}</li>
                         
                    </ul>
               );
          });
      } else {
        return (
          <tr><td colSpan='8'><div>No Errors to Report.</div></td></tr>
        );
      }
      } catch (err) {
        return;
      }
}


    return(
        <ClnAdminInvWrapper>
             
        <div className="form__container">
                   
                  
                    <div  className="form_content--side ">
                        <SideList data={_active} meta={table_meta} title="" select={selectRow} tab_change={updateActiveTab}></SideList>
                    </div>
                    
                    <div  className="form_content ">
                    <span className="edit_inventory__top-hdr"></span>
                    <form>
                   
                    <div className="form_row1-col1">

                        <div className="form_col1_fld1">
                                    
                                    <AutoCompleteForm Label="SEARCH INVENTORY" id="inv_ln" maxLength="35"  autoComplete="off" select={(ln) => selectLN(ln)}   url={`${URL}autocompleteINV?cln=${getSession("cln")}&active=${active}`}/>
                                    
                        </div>
                        
                    </div>
                    <div className="form_row2-col3">
                            <div className="form_col3_fld1">
                              <div className="form_text--light req"  id="ndc" maxLength="35" autoComplete="off" > {ndc} </div> 
                              <label htmlFor="ndc" className="form_label--light">NDC</label>
                                
                           </div>
                           <div className="form_col3_fld2">
                           
                               
                           </div>
                           <div className="form_col3_fld3">
                             <div className="form_text--light req"  id="lot" maxLength="35" autoComplete="off" > {lot} </div> 
                             <label htmlFor="lot" className="form_label--light">LOT</label>
                                
                           </div>
                    </div>
                    
                    <div className="form_row3-col3">
                          <div className="form_col3_fld1">
                                <div className="lrg_type">{active_inv}</div>
                                
                                <label htmlFor="active_inv" className="form_label--light">Current Inventory</label>
                           </div>
                           <div className="form_col3_fld2">
                                <input onChange={e => setInvAmt(e.target.value)} defaultValue={invAmt} type="text" value={invAmt} className="form_input--light req" placeholder="--"  id="new_inv" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="new_inv" className="form_label--light">New Inventory</label>
                           </div>
                           <div className="form_col3_fld3">
                                <input onChange={e => setUnitPrice(e.target.value)} defaultValue={unitPrice} value={unitPrice} type="text" className="form_input--light req" placeholder="Unit Price"  id="unit_price" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="unit_price" className="form_label--light">Unit Price</label>
                           </div>
                        
                    </div>

                    <div className="form_row4-col2">

                        <div className="form_col2_fld1">
                            <select id="reason-code" onChange={e => updateReasonCode(e.target.value)} value={reasonCode}  className="form_input--light">
                                    {_reason_codes}
                            </select>
                                <label htmlFor="reason-code" className="form_label--light">Reason Code</label>
                        </div>

                    </div>
                    <div className={`form_row5-col1 ${reasonClass}`}>

                        <div className="form_col1_fld1">
                            <textarea onChange={e => setReasonCode(e.target.value)} className="form_input--light"  id="reason" placeholder="Update Reason">{reasonCode}</textarea>  
                            <label htmlFor="reason" className="form_label--light">Update Reason</label>
                        </div>

                    </div>
                      
                        
                    </form>
                  
                  


                    <div className="edit_inventory__btn-bar">
                        <LoadingButton title="CLEAR FORM" select={clearForm}  ></LoadingButton>
                       
                        { disabled ? null : <LoadingButton title="SUBMIT" select={submitForm}  ></LoadingButton>}
                    </div>
                    <div id="error_list" >{err()}</div>
                    
                    </div>
                    
        </div>
       
            <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>
        </ClnAdminInvWrapper>
    );
}
export default ClnAdminInv;