import React, { useState, useEffect } from 'react';
import Tabs from './tabs';
import FormHeader from './form-header';
import styled from 'styled-components';
// import ClnAdminSite from './cln-admin-site';
import ClnAdminPvd from './cln-admin-pvd';
import ClnAdminInv from './cln_admin-inv';
import ClnAdminDsp from './cln-admin-dsp';
import ClnAdminUsr from './cln-admin-usr';
import ClnAdminCRx from './cln-admin-usage';
import ClnAdminCspmp from './cln-admin-cspmp';


const AdminClinicWrapper = styled.div`
    font-size: 1.4rem;
    z-index: 9999;
    color:#fff;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    transition: all .3s;



    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }

`;

const AdminClinic = (props) => {

    const [tabs, setTabs] = useState(['CRx USAGE',  'PROVIDER MANAGEMENT', 'INVENTORY ADJUSTMENTS', 'USER MANAGEMENT', 'DISPENSER MANAGEMENT', 'CSPMP DATE RANGE REPORT']);
    const [tab1, setTab1] = useState(true);
    const [tab2, setTab2] = useState(false);
    const [tab3, setTab3] = useState(false);
    const [tab4, setTab4] = useState(false);
    const [tab5, setTab5] = useState(false);
    const [tab6, setTab6] = useState(false);
   
    const [selected, setSelected] = useState(true);
    const [cln, setCln] = useState(1);
    const [grp, setGrp] = useState(1);

    const updateActiveTab = (e) => {
        let index = e.target.id;
        switch(index) {
             case "1": setTab1(true); setTab2(false); setTab3(false); setTab4(false); setTab5(false); setTab6(false);
             break;
             case "2": setTab1(false); setTab2(true); setTab3(false); setTab4(false); setTab5(false);   setTab6(false);
             break;
             case "3": setTab1(false); setTab2(false); setTab3(true); setTab4(false); setTab5(false);  setTab6(false);
             break;
             case "4": setTab1(false); setTab2(false); setTab3(false); setTab4(true); setTab5(false);  setTab6(false);
             break;
             case "5": setTab1(false); setTab2(false); setTab3(false); setTab4(false); setTab5(true); setTab6(false);
             break;
             case "6": setTab1(false); setTab2(false); setTab3(false); setTab4(false); setTab5(false); setTab6(true);
             break;
             
             default:  setTab1(true); setTab2(false); setTab3(false); setTab4(false); setTab5(false); setTab6(false);
        }
    }

    return(
        <AdminClinicWrapper>
             <FormHeader onClose={(props.onClose)} title="CLINICAL ADMIN" addClose={true} class_style="form_header--light"></FormHeader>
             <Tabs tabs={tabs} active={updateActiveTab} isSelected={selected}></Tabs>
                {tab1 && <ClnAdminCRx></ClnAdminCRx>}
                {tab2 && <ClnAdminPvd></ClnAdminPvd>}
                {tab3 && <ClnAdminInv></ClnAdminInv>}
                {tab4 && <ClnAdminUsr></ClnAdminUsr>}
                {tab5 && <ClnAdminDsp></ClnAdminDsp>}
                {tab6 && <ClnAdminCspmp></ClnAdminCspmp>}
        </AdminClinicWrapper>

    );
   
}

export default AdminClinic;