import React from 'react';
import styled from 'styled-components';
import arrow_up_left from '../assets/arrow_up_left.png';
import arrow_up_right from '../assets/arrow_up_right.png';
import arrow_down_left from '../assets/arrow_down_left.png';
import arrow_down_right from '../assets/arrow_down_right.png';
import arrow_down from '../assets/arrow_down.png';
import arrow_left from '../assets/arrow_left.png';
import arrow_right from '../assets/arrow_right.png';
import arrow_up from '../assets/arrow_up.png';
import arro_up_left from '../assets/arro_up_left.png';
import arro_up_right from '../assets/arro_up_rights.png';


const OverlayWrapper = styled.div`

    font-family: 'Vujahday Script', cursive;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.4);
    opacity: 1;
    z-index: 9999;
    color: #fff;
    font-size: 2rem;
    border : 1px solid red;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-gap: 1rem;
    align-items: center;
    justify-items: center;

    .overlay__container {
       
        
        border : 1px solid red;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-gap: 1rem;
        align-items: center;
        justify-items: center;
           
    }
   
    .arrowRight {
        transform: scale(.8);
    }

    .arrow {
        transform: scale(1.8);
    }

    .item-top {
        align-self: start;
    }

    .item-middle {
        align-self: center;
    }

    .item-bottom {
        align-self: end;
    }

    .item-justify-top {
        justify-self: start;
    }

    .close_overlay:hover {
        color: var(--color-primary);
    }

`;

const Overlay = ({children,close}) => {

    const closeOverlay = () => {
        close();
    }

    return(
    <OverlayWrapper >
        
         {children}    
           
    </OverlayWrapper>

    );
}

export default Overlay;
