import React, { useState } from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';
import SessionTimer from './session-timer';
import PIN from './pin';

const PinLoginWrapper = styled.div`

    z-index:9998;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
   
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .pin_container { 
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 27vh;
        width: 35vw;
        background-color: var(--color-grey-dark-2);
        opacity: 1;
        transition: all .3s;
        min-height: 39.3rem;
        border: 2rem solid  rgba(234, 22, 37, .5);
        border-radius: 5px;

        @media screen and (max-width: 1200px) {
            height: 35vh;
            width: 50vw;
        }

      }

      .pin-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        margin-top: 3rem;
      }

      .pin-body {
        font-size: 2rem;
        color: navy;
        display: flex;
        flex-direction: column; 
        align-items: center;
        justify-content: center;
        margin-top: var(--line);
        margin-bottom: var(--line);
      }
     

      .pin-nav-timer {
        margin-bottom: 3rem;
        background-color:navy;
      }

 
   

`;


const PinLogin = (props) => {

    const [pin, setPIN] = useState([]);

    
    localStorage.setItem('session_seconds', '300');

    const submit = () => {
        localStorage.setItem('session_seconds', '10002');
        console.log('submit pin');
        props.validate_pin(pin);
    };

    const logout = () => {
        localStorage.setItem('session_seconds', '10002');
        console.log('submit logout');
        props.logout();
    };

    const session_timeout = () => {
        localStorage.setItem('session_seconds', '10002');
        console.log(`session timeout`);
        props.onTimeOut();
        

    }

    const inputPIN = (which) => {
        
        setPIN(which);
    }



    return(
        <PinLoginWrapper>
          <div className="pin_container">
           <FormHeader onClose={props.close} title="LOGIN WITH PIN" addClose={false} class_style="form_header--light"></FormHeader>
           
            <div className="pin-nav-timer">
                    <SessionTimer on_timeout={session_timeout} /> 
            </div>   

            <div className="pin-body">
                <p>
                    Your session is about to expire. You'll be automatically signed out.
                </p>
                <p>
                   { `Please enter the PIN tfor User: ${props.current_user} to remain signed in.` }
                </p>
                <div>
                   <PIN onSavePin={inputPIN} pin={ pin.toString().replace(/,/g, '') }/>
                </div>
            </div>
            <div className="pin-footer">
                <ButtonExpand title="Sign Out Now"  click={logout} />
                    
                
                <ButtonExpand title="SUBMIT PIN" click={submit} />
                    
            </div>
            </div>
        </PinLoginWrapper>
    );
}

export default PinLogin;