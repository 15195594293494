
import Html from 'react-pdf-html';
import ReactDOMServer from 'react-dom/server';
import {
    Document,
    Page,
    Font,
    Text,
    View,
    StyleSheet,
    PDFViewer,
  } from "@react-pdf/renderer";
import FormHeader from "../components/form-header";
import styled from "styled-components";
import {URL} from '../constants';
import useFetch from '../hooks/useFetch';
import { getSession } from '../utility';




Font.register({
    family: 'Ubuntu',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      },
      {
        src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
        fontWeight: 'bold',
      },
      {
        src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
        fontWeight: 'normal',
        fontStyle: 'italic',
      },
    ],
  });


const PDFViewerWrapper = styled.div`
    height: 70vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.8);
    opacity: 1;
    z-index: 998;



`;

// Create styles
const styles = StyleSheet.create({
 
  viewer: {
    width: window.innerWidth / 1.5,
    height: window.innerHeight / 1.5,
  },
});

const CurrentAdherenceReport = (props) => {


    const url = query && `${URL}reportGetReport?idx=${getSession("cln")}&rpt=currentAdherence&param=cln`;
    const { status, data, error } = useFetch(url);

    const data_table = () => {
        try {
            console.log(data);
            if (Array.isArray(data)) {
              return data.map((item, index)=>{
               
                  return (
                    <tr>
                    <td class='col-45'>
                      
                        {item.LN}                                  
                      
                    </td>
                    <td class='col-15'>
                     
                       {item.DF}
                     
                    </td>
                    <td class='col-20'>
                      
                       {item.NDC}
                     
                    </td>
                    <td class='col-15'>
                    
                          {item.UnitPrice}
                     
                    </td>
  
                  </tr>
                    
                );
              });
          } else {
            return (
               <li class="table-row">
                    <div>No data to report</div>
               </li>
            );
          }
          } catch (err) {
            return;
          }
        
    }


    const stylesheet = {
        // clear margins for all <p> tags
        p: {
          margin: 0,
        },
        td: {
                    border: '1px solid #000000',
                    borderWidth: '1px 1px 1px 1px',
                    textAlign: 'left',
                    padding: '7px',
                    fontSize: '15px',   
                    color: '#000000',
        },
        backgroundColor: '#cdcdcd',
        
        ['.row_title']: {
            fontSize: '14px',
            fontWeight: 'bold',
        },
      };

    const element = ( 

<html>


<style>
            {`
                    @page {
                    margin-top: 30px;
                    margin-bottom: 20px;
                    margin-left: 20px;
                    margin-right: 20px;
                    }

                    .col-45 {
                        width: 45%;
                       
                      }

                      .col-15 {
                        width: 15%;
                       
                      }

                      .col-20 {
                        width: 20%;
                       
                      }

                      
                    table {
                        width: 100%;
                    }
        
                    .row_divide {
        
                    font-size:15px;
                    font-family: 'Ubuntu';
                    font-weight:bold;
                    color:#000000;
                    background-color: #cdcdcd;
        
                    }
        
                    .row_columns
                    {
                    font-size:12px;
                    font-weight:bold;
                    }
        
        
                    .row_title
                    {
                    font-size:14px;
                    
                    font-weight:bold;
                    }
        
        
                    .CSSTableGenerator {
        
                    padding:0px;
                    width:100%;
                    border:1px solid #000000;
        
        
                    }
                    .CSSTableGenerator table{
                    
                   
                    width:100%;
                    height:auto;
                    margin:0px;padding:0px;
                    }
        
                    .CSSTableGenerator tr:last-child td:last-child {
                    
                   
                    border-bottom-right-radius:6px;
                    }
        
        
        
                    .CSSTableGenerator tr {
                    max-height: 35px;
                    }
                    .CSSTableGenerator thead tr {
                    max-height: 40px;
                    }
        
                    .CSSTableGenerator tr:nth-child(odd){ background-color:#E0F9FF; }
                    .CSSTableGenerator tr:nth-child(even)    { background-color:#ffffff; }
        
                    .CSSTableGenerator td{
                   
                    border:1px solid #000000;
                    border-width:1px 1px 1px 1px;
                    text-align:left;
                    padding:7px;
                    font-size:15px;
                    
                    font-weight:normal;
                    color:#000000;
        
                    }
            `}

          </style>
  
        <body>
          
          
        </body>
      </html>


    );


const html = ReactDOMServer.renderToStaticMarkup(element);

return (
    <PDFViewerWrapper>
        <FormHeader onClose={props.close}  title={props.title} addClose={true} class_style="form_header--dark"></FormHeader>
                {status === 'error' && <div>{error}</div>}
				{status === 'fetching' && <div className="loading"></div>}
				{status === 'fetched' && (
            <PDFViewer  style={styles.viewer}>
                <Document>
                    <Page>
                        <Html stylesheet={stylesheet}>{html}</Html>
                    </Page>
                </Document>
            </PDFViewer>
        )}
  </PDFViewerWrapper>
);

}

export default CurrentAdherenceReport;