import React, { useState } from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';


const LotSearchWrapper = styled.div`

    z-index:9998;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
   
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .modal_container { 
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
       
        height: 50rem;
        width: 60vw;
        background-color: var(--color-grey-dark-2);
        opacity: 1;
        transition: all .3s;
        min-height: 36.3rem;
        border: 2rem solid  var(--color-primary);
        border-radius: 5px;

      }

      .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        margin-top: 3rem;
      }

      .modal-body {
        font-size: 2rem;
        color: navy;
        display: flex;
        flex-direction: column; 
        align-items: center;
        justify-content: center;
        margin-top: var(--line);
        margin-bottom: var(--line);
      }
     

      .modal-nav-timer {
        margin-bottom: 3rem;
        background-color:navy;
      }

      .order_responsive-table {
      max-height: 25rem;
      width:57vw;
      overflow-y: scroll;
      }
  
    li {
      border-radius: 3px;
      padding: .5rem .6rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: .75rem;
    }
    .order_table-header {
      background-color: var(--color-primary);
      font-size: 1.1rem;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      color: #fff;
    }
    .order_table-row {
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
      color: var(--color-primary);
      transition: all .9s;
      cursor: pointer;
      text-transform: uppercase;
      font-size:1.75rem;
      font-weight: 700;

      &:hover {
          background-color: rgb(243, 184, 162);
          color: var(--color-primary);
      }

      &__selected {
        background-color: rgb(247, 104, 9);
        color:#fff;
      }
    }

    .order-row--light__selected {
        background-color: rgb(247, 104, 9);
        color:#fff;
   }
    .col-5 {
        flex-basis: 5%;
        pointer-events: none;
      }
      .col-10 {
        flex-basis: 10%;
        pointer-events: none;
      }
      .col-15 {
        flex-basis: 15%;
        pointer-events: none;
      }
      .col-input {
        flex-basis: 10%;
        // pointer-events: none;
      }
      .col-20 {
        flex-basis: 20%;
        pointer-events: none;
      }
    .col-25 {
      flex-basis: 25%;
      pointer-events: none;
    }
    .col-30 {
      flex-basis: 30%;
      pointer-events: none;
    }
    .col-35 {
      flex-basis: 35%;
      pointer-events: none;
    }
    .col-40 {
      flex-basis: 40%;
      pointer-events: none;
    }
    .col-50 {
        flex-basis: 50%;
        pointer-events: none;
      }
      .col-60 {
        flex-basis: 60%;
        pointer-events: none;
      }
      .col-75 {
        flex-basis: 75%;
        pointer-events: none;
      }
      .col-100 {
        flex-basis: 100%;
        pointer-events: none;
        text-align: center;
      }

 .recent__table {
  width: 100%;
 }
   

`;


const LotSearch = (props) => {

    const loadLot = (lot) => {
        console.log(lot);

        props.select(lot);
    } 

    const data_table = () => {
        try {
           
            
            console.log('data_table',props.data);
            if (props.data.length > 0) {
              return props.data.map((item, index)=>{
               console.log(item.ln);
                  return (
                   
                    <li key={index} className="order_table-row" id={index} onClick={() => loadLot(item)}>
                   
                        <div className="col col-25"  data-label="LOT">{item.LOT}</div>
                        <div className="col col-15" data-label="CURRENT INV">{item.INV}</div>
                        <div className="col col-15" data-label="EXP. DATE">{item.ExpDate}</div>
             
                    </li>
                   );
              });
          } else {
            return (
               <li className="table-row">
                    <div>The Order Que is Empty.</div>
               </li>
            );
          }
          } catch (err) {
            return;
          }
        
    }      

    return(
        <LotSearchWrapper>
          <div className="modal_container">
           <FormHeader onClose={props.close} title={props.title} addClose={true} class_style="form_header--light"></FormHeader>
           

            <div className="modal-body">
                <p>
                   {props.header}
                </p>
               
                <div className="recent__table ">
                        
                        <ul className="order_responsive-table ">
                        <li className="order_table-header">
                                <div className="col col-25">LOT</div>
                                <div className="col col-15">CURRENT INV</div>
                                <div className="col col-15">EXP DATE</div>
                               
                        </li>
                           
                           {data_table()}
                        </ul>
                </div>
               
            </div>
          
            </div>
        </LotSearchWrapper>
    );
}

export default LotSearch;