
import {
    Document,
    Page,
    Font,
    Text,
    View,
    StyleSheet,
    PDFViewer,
  } from "@react-pdf/renderer";
import {URL} from '../constants';
import useFetch from '../hooks/useFetch';
import BenchNine from './fonts/BenchNine/BenchNine-Regular.ttf';
import ReactLoading from 'react-loading';
import { getSession } from '../utility';

Font.register({
  family: "BenchNine",
  format: "truetype",
  src: BenchNine,
})

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 40
const COLN_WIDTH = (100 - COL1_WIDTH) / 3


const styles = StyleSheet.create({
  body: {
    padding: 10,
    fontFamily: "BenchNine",
  },
  viewer: {
    width: window.innerWidth / 1.78,
    height: window.innerHeight / 1.60,
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 

  tableRow: { 
    margin: "auto", 
    flexDirection: "row",
    backgroundColor: "#E0F9FF"
  
  }, 

  tableHeaderRow: { 
    margin: "auto", 
    flexDirection: "row",
    backgroundColor: "#FFF3D4"
  
  }, 
  tableTitleRow: {
    marginLeft: "12%",
    flexDirection: "row",
    backgroundColor: "#FFFFFF"
  },
  tableCol1Title: { 
    width: '33%', 
    
  }, 

  tableCol2Title: { 
    width: "33%" 
    
  }, 

  tableCol3Title: { 
    width: "33%"
    
  }, 


  'tableRow:nth-child(odd)': {
    backgroundColor: "#E0F9FF"
  },

  'tableRow:nth-child(even)': {
    backgroundColor: "#CDCDCD"
  },

  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },     
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },   
  tableNoReportHeader: {
    width: '100%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
    
},
  tableColClinic: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },   
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },   
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: 5, 
    fontSize: 12,
    fontWeight: 500
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  }
});

const DailyDispenseReport = (props) => {

  const query = getSession("cln");


    const url = query && `${URL}reportGetReport?idx=${getSession("grp")}&rpt=dailyDispenseMonth&param=grp`;
    const { status, data, error } = useFetch(url);

    const data_table = () => {
      try {
          console.log(error);
          if (Array.isArray(data)) {
            return data.map((item, index)=>{
             
                return (
                  <View style={styles.tableRow}> 
                  <View style={styles.tableCol1Header}> 
                    <Text style={styles.tableCellHeader}>{item.Day}</Text> 
                  </View> 
                  <View style={styles.tableColHeader}> 
                    <Text style={styles.tableCellHeader}>{item.Provider}</Text> 
                  </View> 
                  <View style={styles.tableColHeader}> 
                    <Text style={styles.tableCellHeader}>{item.DispenseTotal}</Text> 
                  </View> 
                  <View style={styles.tableColHeader}> 
                    <Text style={styles.tableCellHeader}>{item.LocationAlias}</Text> 
                  </View> 
                 
                </View>
                  
              );
            });
        } else {
          return (
            <View style={styles.tableNoReportHeader}> 
                    <Text style={styles.tableCellHeader}>No Data to Report</Text> 
            </View> 
          );
        }
        } catch (err) {
          return;
        }
      
  }



return (
  <>
   {status === 'error' && <div>{error}</div>}
   {status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
   {status === 'fetched' && (
  <PDFViewer style={styles.viewer}>
    <Document>
      <Page style={styles.body}>
         <View style={styles.table}> 
                  <View style={styles.tableTitleRow}> 
                    <View style={styles.tableCol1Header}> 
                      <Text style={styles.tableCellHeader}>Daily Dispense Report</Text> 
                    </View> 
                    <View style={styles.tableColClinic}> 
                      <Text style={styles.tableCellHeader}> {`${getSession("cln_display")}`}</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}> {`Print Date: ${props.print_date}`}</Text> 
                    </View> 
                    
                  </View>
                 <View style={styles.tableHeaderRow}> 
                    <View style={styles.tableCol1Header}> 
                      <Text style={styles.tableCellHeader}>Day</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>Provider</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>Dispense Total</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>Location</Text> 
                    </View> 
                    
                  </View>
                {data_table()}
        </View>
       
     
       
      </Page>
     </Document>
    </PDFViewer>
    )}
            
  </> 
);

}

export default DailyDispenseReport;