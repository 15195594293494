import React, { useState, useEffect } from "react";
import showPwd from '../assets/show-password.svg';
import hidePwd from '../assets/hide-password.svg';
import { URL } from '../constants';
import useFetch from '../hooks/useFetch';
import styled from 'styled-components';

const PassWordWrapper = styled.div`

position: relative;
display: flex;
align-items: flex-start;
justify-content: center;
width: 40rem;
margin: 0 auto;

.search-form__input {

    font-family: inherit;
    color: #fff;
    font-size: 2.2rem;
    padding: .7rem 2rem;
    border-radius: 2px;
    border: none;
    border-bottom: 3px solid var(--color-primary);
    transition: all .5s;
    outline: none;
    background-color: var(--color-grey-dark-2);

    &::placeholder {
        font-weight: 100;
        color: var(--color-primary);
    }
  
   

}

.left-inner-addon {
    position: relative;
    width: 255px;
    margin-top: 1rem;
    margin-left: 5rem;
}
.left-inner-addon input {
    padding-right: 10px;
    width: 270px; /* 200px - 30px */
}
.left-inner-addon img {
    position: absolute;
    right: 0px;
    cursor: pointer;
    margin-top: -3.7rem;
    margin-right: .3rem;
    transform: scale(.7);

    
}

.check-show {
    display: block;
}

.check-hide {
    display: none;
}
`;



const passWord = (props) => {

    const [pwd, setPwd] = useState(props.passcode);
    const [view_value, setViewValue] = useState(false);

  
    const toggleView = () => {
        setViewValue(!view_value);

    }    

    const getPwd = (val) => {
        setPwd(val);
        props.pwd(val);
    }
    

    return (
        <PassWordWrapper>
            <div className="left-inner-addon">
                <input  autoFocus  type={view_value ? 'text' : 'password'} className="search-form__input" onChange={e => getPwd(e.target.value)}  value={pwd}  placeholder="CRx Password" id="password" maxLength="35" autoComplete="off"  />
                <img onClick={toggleView}  src={view_value ? showPwd : hidePwd} alt="" />
            </div>
        </PassWordWrapper>
    );
}

export default passWord;