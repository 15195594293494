import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Group from './grp';
import Clinic from './cln';
import User from './usr';
import GroupEdit from './grp-edit';
import ClinicEdit from './cln-edit';
import UserEdit from './usr-edit';

const GlobalOnBoardingWrapper = styled.div`

.setter {
    border: 1px solid var(--color-primary);
    color: #fff;
    font-size: 1.8rem;
    width: auto;
    height: 3rem;
    border-radius: 12px;
    margin-top: 2rem;
    margin-left: 2rem;
    padding-left: 1rem;
    background-color: var(--color-secondary);
}

.close__button {
    border: none;
    background-color: transparent;
    color: #fff;
    float: right;
    margin-right:.5rem;
    margin-top: .5rem;
    margin-left: 1rem;
    cursor: pointer;
}

.result_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rslt {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    font-size: 1.8rem;
    /* border: 1px solid var(--color-primary);
    width: 60rem;
    height: 4rem;
    border-radius: 12px; */
    text-align: center;
}


`;

const GlobalOnBoarding = (props) => {
    
    const [edit_group, setEditGroup] = useState({});
    const [define_group, setDefineGroup] = useState({});
    const [showGroup, setShowGroup] = useState(false);
    const [edit_clinic, setEditClinic] = useState({});
    const [define_clinic, setDefineClinic] = useState({});
    const [showClinic, setShowClinic] = useState(false);
    const [edit_user, setEditUser] = useState({});
    const [define_user, setDefineUser] = useState({});
    
    const [showUser, setShowUser] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [grp, setGRP] = useState(true);
    const [cln, setCLN] = useState(false);
    const [usr, setUSR] = useState(false);

    const [grp_edit, setGRP_EDIT] = useState(false);
    const [cln_edit, setCLN_EDIT] = useState(false);
    const [usr_edit, setUSR_EDIT] = useState(false);
   
    useEffect(() => {
        console.log('use effect stage');
        

    },[]);

    useEffect(() => {   
        console.log('use effect group');
       if(edit_group.idx !== undefined ) {
        setGRP_EDIT(true);
       }
    },[edit_group]);

    useEffect(() => {   
        console.log('use effect clinic');
        
        if(edit_clinic.idx !== undefined) {
            setCLN_EDIT(true);
            
        }
        
    },[edit_clinic]);

    useEffect(() => {   
        console.log('use effect user');
        
        if(edit_user.idx !== undefined) {
            setUSR_EDIT(true);
            
        }
        
    },[edit_user]);

    const editGroup = (which) => {
        console.log(which);
        console.log('edit group');
        setEditGroup(which);
        
    }

    const defineGroup = (which) => {    
        console.log(which);
      
        setDefineGroup(which);
        setShowGroup(true);
        setGRP(false);
        setCLN(true);
        setGRP_EDIT(false);
    }

    const defineClinic = (which) => {    
        console.log('edit clinic', which);
        
        setDefineClinic(which);
        setShowClinic(true);
        setGRP(false);
        setCLN(false);
        setUSR(true);
        setCLN_EDIT(false);
    }
    const editClinic = (which) => {
        console.log(which);
        console.log('edit clinic', which);
        setEditClinic(which);
        
    }

    const defineUser = (which) => {    
        console.log(which);
        setDefineUser(which);
        setShowUser(true);
        setGRP(false);
        setCLN(false);
        setUSR(false);
        setShowResult(true);
        setUSR_EDIT(false);
    }

    const editUser = (which) => {
        console.log(which);
        console.log('edit user');
        setEditUser(which);
        
    }


    const closeGroup = () => {

        setGRP(true);
        setCLN(false);
        setUSR(false);

        setShowGroup(false);
        setShowClinic(false);
        setShowUser(false);
        setShowResult(false);

    }

    const closeClinic = () => {

        setGRP(false);
        setCLN(true);
        setUSR(false);

        setShowGroup(true);
        setShowClinic(false);
        setShowUser(false);
        setShowResult(false);

    }

    const closeUser = () => {
            
        setUSR(true);

        setShowGroup(true);
        setShowClinic(true);
        setShowUser(false);
        setShowResult(false);

    }
        
        



    return (
        <GlobalOnBoardingWrapper>
           
            { grp ? <Group select={(which) => defineGroup(which)} edit={(which) => editGroup(which)}/> : null}
            { cln ? <Clinic grp={define_group.idx} select={(which) => defineClinic(which)} edit={(which) => editClinic(which)}/>  : null}
            { usr ? <User grp={define_group.idx} select={(which) => defineUser(which)} edit={(which) => editUser(which)}/>  : null}
    <div className="result_container">
        {showGroup ? <div className="setter" onClick={closeGroup}>
                            <span>{define_group.name}</span>
                        <button className="close__button" >
                            <svg width="16" height="16"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path></svg>
                        </button></div>
          : null}
        {showClinic ? <div className="setter" onClick={closeClinic}>
                            <span>{define_clinic.name}</span>
                        <button className="close__button" >
                            <svg width="16" height="16"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path></svg>
                        </button></div>
          : null}
         {showUser ? <div className="setter" onClick={closeUser}>
                            <span>{define_user.name}</span>
                        <button className="close__button" >
                            <svg width="16" height="16"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path></svg>
                        </button></div>
          : null}

          
     </div>
     {showResult ? <div className="rslt">{`User ${define_user.name} is now ready to use CRx under  ${define_clinic.name}  Group ${define_group.name}`}</div> : null}

     {grp_edit ? <GroupEdit select={(which) => defineGroup(which)}  group={edit_group} close={() => setGRP_EDIT(false)} /> : null}
     {cln_edit ? <ClinicEdit select={(which) => defineClinic(which)} grp={define_group} clinic={edit_clinic} close={() => setCLN_EDIT(false)} /> : null}
     {usr_edit ? <UserEdit select={(which) => defineUser(which)}  grp={define_group} user={edit_user} close={() => setUSR_EDIT(false)} /> : null}
    </GlobalOnBoardingWrapper>
    );
}
export default GlobalOnBoarding;