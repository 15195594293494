import React, {useEffect, useState} from 'react';
import InputMask from 'react-input-mask';
import Toast from './toaster';
import ButtonExpand from '../theme/btn_expand';
import styled from 'styled-components';
import FormHeader from './form-header';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import PIN from './pin';
import { isValidNumber, isValidString, isPhone,  getSession, getAPI } from '../utility';


const UserEditWrapper = styled.div`

    width: 70rem;
    height: 70rem;
    margin: auto;
    background-color: var(--color-grey-dark-2);
    color: #fff;
    
    border: 2rem solid  rgba(15, 215, 239, 1);
    border-radius: 5px;
    
   
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    font-size: 2rem;
    white-space: nowrap;
    box-shadow: 0 1rem 3rem rgba(#fff, .2);

    .form-container {
       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: 1fr ;
       grid-gap: 2rem;
       padding-top: 1rem;
       padding-bottom: 1rem;
       font-size: 1.75rem;
       height: auto;
    }

    .form-title {
        margin-top: 1rem;
        margin-left: 1rem;
        text-transform: uppercase;
    }

    .usr-btn-bar {
        
        display: flex;
        align-items: space-between;
        justify-content: space-around;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        margin-top: 1rem;
        width: 100%;
     
    }

    .adm-lbl {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .adm-txt {
        font-size: 2.1rem;
        font-weight: bold;
    }

    
   

`;


const UserEdit = (props) => {

    const [name, setName] = useState('');
    const [idx, setIdx] = useState('0');

    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");

    const [grp, setGRP] = useState('');
 
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [middlename, setMiddleName] = useState('');
    const [pin, setPIN] = useState('111');
    const [role, setRole] = useState('');
    const [usr_name, setUsername] = useState('');
    const [pwd, setPwd] = useState('');

    const [pin_origin, setPinOrigin] = useState('');
    const [isActive, setIsActive] = useState('1');

    const [showUse, setShowUse] = useState(false);

    const [newcrop_role, setNewCropRole] = useState('');
    const [newcrop_type, setNewCropType] = useState('');
    const [newcrop_user, setNewCropUser] = useState('');

    const [valid_err, setValErr] = useState([]);

    const [disabled, setDisabled] = useState(false);

    const [userNameUnique, setUserNameUnique] = useState(true);


    const [url, setUrl] = useState("");
    const { status, data, error } = useFetch(url)

    useEffect(() => {
        console.log('group object passed to edit',props.grp.idx);
        
        setIdx(props.user.idx);
        setGRP(props.grp.idx);

    }, []);

    useEffect(() => {

        console.log('usr idx', idx);

        if(idx !== '0' && idx !== '-1' && idx !== ''   ) {

           setUrl(`${URL}admReturnUser?idx=${idx}`); 

        }
    }, [idx]);

    useEffect(() => {
        if(status === "fetched") {
            console.log('return usr',data);
            if(data.idx !== '0') {
                
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setMiddleName(data.middleName);
                setEmail(data.email);
                setPhone(data.mobilePhone);
                setUsername(data.userName);
                setPwd(data.password);
                setPIN(data.pin);
                setPinOrigin(data.pin);
                setRole(data.role);
            }
        


        }
    }, [status]);

    const submit = () => {
        setDisabled(true);
        setValErr([]);
        let errors = [];

        let obj = {};

        userNameUnique ? '' : errors.push("User Name is already in system. Please choose a different User Name"); //*;
              
         obj.usr = idx;
         obj.grp = grp;
         obj.firstname = isValidString(firstname) ? firstname : errors.push("User First Name Is Required."); //*
         obj.lastname = isValidString(lastname) ? lastname : errors.push("User Last Name Is Required."); //*
         obj.middlename = middlename === null ? "" : middlename;
         obj.usr_name = isValidString(usr_name) ? usr_name : errors.push("User Name Is Required."); //*;
         obj.pwd = isValidString(pwd) ? pwd : errors.push("Password Is Required."); //*;
         obj.pin = pin.length === 3 ? pin.toString().replace(/,/g, '') : errors.push("Three Color PIN Is Required."); 
         obj.email = email;
         obj.phone = phone;
         obj.role = isValidNumber(role) ? role : errors.push("User Role Is Required.");
         obj.isActive = isActive;
         obj.newcrop_role = "0";
         obj.newcrop_type = "0";
         obj.newcrop_user = "0";

           
         
         let json = JSON.stringify(obj);
         console.log(json);

         console.log('errors',errors.length);
           
        if (errors.length > 0) {
            setValErr(errors);
            setDisabled(false);
            
        } else {

            //  setUSR(json);

             let ur = idx !== '0' && idx !== '-1' ? URL + "admUpdateUser" : URL + "adInsertUser";

               //POST DATA
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
          body: json
          };
          fetch(`${ur}`, requestOptions)
              .then (response => {
                    if (response.ok === false) {  throw new Error('Network response was not ok.') };
                         response.json()
                    })
              .then(() => {
                  setValErr([]);
                  setToastIcon("success");
                  setToastMsg("User Saved Successfully");
                  setToastState(true);
                  setShowUse(true);
                  
                  setDisabled(false);
              })            
              .catch(err => {
                  setToastIcon("error");
                  setToastTitle("Error");
                  setToastBackground("#d9534f");
                  setToastMsg("There was a Problem Saving the User. Please Try Again.");
                  setToastState(true);
                  setShowUse(false);
                  setDisabled(false); 
              });
        }

     }
    

    const useUser = () => {    

        console.log('use group');
        props.select({idx:idx, name: firstname + ' ' + lastname});

    }

    const switchToMask = (e) => {

        console.log('switch to mask', e.target.id);

        if (e.target.id === "mobile_set") {
            const val_phn = document.getElementById("mobile_set");
            const enter_phn = document.getElementById("mobile");
           
            val_phn.style.display = "none";
            enter_phn.style.display = "block";

        }

              
      
    }

    const switchToInput = (e) => {

        console.log('switch to input',e.target.id);

        if (e.target.id === "mobile") {
            const val_phn = document.getElementById("mobile_set");
            const enter_phn = document.getElementById("mobile");
           
            var d = phone; 
            if(!isPhone(d)) {
                setPhone(ptt.phone);
            } 

            val_phn.style.display = "block";
            enter_phn.style.display = "none";

        }

                
      
    }

    const inputPIN = (which) => {
        
        setPIN(which);
    }


    const err = () => {
        try {
            if (Array.isArray(valid_err)) {
              return valid_err.map((item, index)=>{
                  return (
                        <ul>
                              <li className="err_list">{item}</li>
                             
                        </ul>
                   );
              });
          } else {
            return (
              <tr><td colSpan='8'><div>No Errors to Report.</div></td></tr>
            );
          }
          } catch (err) {
            return;
          }
    }

    const checkUSR = async() => {
       
      
         const response = await fetch(`${URL}usernameDuplicateCheck?usr=${usr_name}`, {
          method: 'GET',
              headers: {
                  'x-functions-key': getAPI(),	
              }
          });
      
        const duplicates = await response.json();
      
        console.log(duplicates);
       if (duplicates.length > 0) {
            setUserNameUnique(false);
            setToastIcon("error");
            setToastTitle("Error");
            setToastBackground("#d9534f");
            setToastMsg("This User Name already exists. User Names must be unique. ");
            setToastState(true);
       
       } else {
          setUserNameUnique(true);
       }
      
      }
      

    return (
        <UserEditWrapper>
             <FormHeader onClose={props.close} title="USER EDIT"addClose={true} class_style="form_header--light" ></FormHeader>
        

            <div id="error_list" >{err()}</div>

<div className="form_row1-col3">
    <div className="form_col3_fld1">
        <input onChange={e => setFirstName(e.target.value)} value={firstname}  type="text" className="form_input--light adm-txt req" placeholder="First Name" id="firstname" maxLength="35" autoComplete="new-user-first-name"  />
        <label htmlFor="firstname" className="form_label--light adm-lbl">First Name</label>
    </div>
    <div className="form_col3_fld2">
        <input onChange={e => setLastName(e.target.value)} value={lastname} type="text" className="form_input--light adm-txt req" placeholder="Last Name"  id="lastname" maxLength="35" autoComplete="new-user-last-name"  />  
        <label htmlFor="lastname" className="form_label--light adm-lbl">Last Name</label>
    </div>
    <div className="form_col3_fld3">
        <input onChange={e => setMiddleName(e.target.value)} value={middlename} type="text" className="form_input--light adm-txt" placeholder="Middle Name" id="name" autoComplete="new-user-middle-name"  />  
        <label htmlFor="name" className="form_label--light adm-lbl">Middle Name</label>
    </div>
</div>
<div className=" form_row2-col1">
    
   
</div>
<div className="form_row3-col3">
    <div className="form_col3_fld1">
        <input onChange={e => setUsername(e.target.value)}  onBlur={checkUSR} value={usr_name} type="text" className="form_input--light adm-txt" placeholder="User Name"  id="username" maxLength="35" aautoComplete="new-user-name" />  
        <label htmlFor="username" className="form_label--light adm-lbl">User Name</label>
    </div>
    <div className="form_col3_fld2">
        <input onChange={e => setPwd(e.target.value)} value={pwd}  type="text" className="form_input--light adm-txt req" placeholder="Password" id="password" maxLength="35" autoComplete="new-user-password-name"   />
        <label htmlFor="password" className="form_label--light adm-lbl">Password</label>
    </div>
    <div className="form_col3_fld3">
        <select id="role"  onChange={e => setRole(e.target.value)} className="form_input--light adm-txt" value={role} >
            <option value="0">---------</option>
            <option value="1">USER</option>
            <option value="2">LOCAL ADMIN</option>
            <option value="3">GLOBAL ADMIN</option>    
            <option value="4">USER NO DISPENSE</option>
        </select>
        <label htmlFor="role" className="form_label--light adm-lbl">Role</label>
    </div>
</div>
<div className=" form_row4-col1">
   <PIN  onSavePin={inputPIN}  ></PIN>
   <div>PIN: {pin}</div>
 
</div>
<div className=" form_row5-col2">
    <div className="form_col2_fld1">
    <input onFocus={switchToMask}   value={phone} type="text" className="form_input--light"  id="mobile_set"/>  
    <InputMask onBlur={switchToInput} style={{display:"none"}}  onChange={e => setPhone(e.target.value)}  type="text" className="form_input--light" mask="(999) 999-9999" placeholder="Phone"   id="mobile" autocomplete="false" /> 
    <label for="mobile" className="form_label--light">Authentication Phone</label>
    {/* {idx !== '0' ?
        <> 
        <input onFocus={switchToMask}  value={phone} type="text" className="form_input--light adm-txt"  id="mobile_set"  />  
        <InputMask onBlur={switchToInput} style={{display:"none"}} onChange={e => setPhone(e.target.value)}  type="text" className="form_input--light adm-txt req" mask="99/99/9999" placeholder="Mobile Phone"  id="mobile" autoComplete="off" required /> 
        <label htmlFor="mobile" className="form_label--light adm-lbl">Authentication Phone</label>
        </>
        :
        <>
        <InputMask type="text" onChange={e => setPhone(e.target.value)}  value={phone} className="form_input--light adm-txt  req" mask="(999) 999-9999" placeholder="Mobile Phone"   id="mobile" autoComplete="off" required /> 
        <label htmlFor="mobile" className="form_label--light adm-lbl">Authentication Phone</label>
        </>
        } */}
        
    </div>
    <div className="form_col2_fld2">
        <input onChange={e => setEmail(e.target.value)} value={email}  type="email" className="form_input--light adm-txt" placeholder="Email" id="email" maxLength="100" required autoComplete="off"  />
        <label htmlFor="email" className="form_label--light adm-lbl">Authentication Email</label>
    </div>
 
</div>
             <div className="usr-btn-bar">
                    {showUse ? <ButtonExpand title="USE THIS USER" click={useUser} /> : null}
                    { disabled ? <div></div> : <ButtonExpand className="cln-btn-right" title="SUBMIT" click={submit}  /> } 

                </div>
                
                <div className="toasty">
                    {toast ?  <Toast 
                        id={74}
                        position={'bottom-center'}
                        autoDelete={true}
                        autoDeleteTime={3000}
                        title={toast_title}
                        description={toast_msg}
                        backgroundColor={toast_background}
                        icon={toast_icon}
                        close={() => setToastState(false)}

                    /> : null }
                </div>
          
        </UserEditWrapper>
    );
}

export default UserEdit;
