import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Toast from './toaster';
import LoadingButton from '../theme/loading-button';
import FormHeader from './form-header';
import { URL, shape_codes, color_codes } from '../constants';
import AutoCompleteDropdown from '../theme/autocomplete-dropdown';
import AutoCompleteForm from '../theme/autocomplete-form';
import { getAPI } from '../utility';



const MNDCEditWrapper = styled.div`
    
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    z-index: 998;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);
    }
    

    .frm__container {
        display: flex;
        justify-content: space-around;
        margin-top: 5rem;
    }

    .frm_content {
        
        width: 75%;
        height: 70rem;
        box-shadow: 0 .5rem 2rem rgba(#fff, .2);
        border-radius: 5px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, 1fr);
        overflow: hidden;
        position: relative;
        opacity: 1;
        border: .5rem solid var(--color-primary);
        transition: all 1s;
        background-color: transparent;
        background-size: cover;
        background-position: top;

      

        .edit_inventory--side {
            overflow: scroll;
            position: relative;
            width: 25%;
            height: 32rem;
            border-radius: 5px;
            border: .5rem solid rgba(#fff, .5);
        }

        

    }

    
    .rcv_dsp {
        max-width: 25rem;
        padding: 1rem 2rem;
        margin-left: 7rem;
        
    }

    .edit_inventory__search {
           
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:2rem;

        .edit_inventory__search__input {
            font-family: 'BenchNine', sans-serif;
            font-size: initial;
            background-color: var(--color-grey-light-2);
            border: none;
            color: var(--color-primary);
            padding: .7rem 2rem;
            border-radius: 1rem;
            width: 50%;
            transform: all .3s;
            transition: all .5s;
            z-index: 10;
            margin-top: 1rem;
            margin-left: 2.5rem;

            &:focus {
                outline: none;
                width: 70%;
                background-color: var(--color-grey-light-3);
            }

            &::input-placeholder {
                font-weight: 100;
                color: var(--color-grey-light-4);
            }
        }
        .edit_inventory__search__input:focus  {
                background-color: var(--color-grey-light-3);
       }

    
    }

    .edit_inventory__top-hdr {
        font-family: 'BenchNine', sans-serif;
        font-size: 2.5rem;
    }

    .edit_inventory__btn-bar {
           
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: -1rem;
    }

    .edit_inventory__metadata {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1rem;
    }

    .edit_inventory__adj {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1rem;
    }

   

 

    input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    .toggle {
        transform: scale(.6);
    }

.switch {
  cursor: pointer;
  text-indent: -9999px;
  width: 15rem;
  height: 6rem;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 5rem;
  height: 5rem;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

#switch:checked + label {
  background: var(--color-tertiary);

}

#switch:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 10rem;
}

.search_hdr {
    font-family: 'BenchNine', sans-serif;
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}


`;


const MNDCEdit = (props) => {

const [toast, setToastState] = useState(false);
const [toast_msg, setToastMsg] = useState("");
const [toast_icon, setToastIcon] = useState("");
const [toast_title, setToastTitle] = useState("Success");
const [toast_background, setToastBackground] = useState("#5cb85c");
    
 
  const [idx, setIDX] = useState(undefined);
  const [ln, setLN] = useState("");
  const [imprint, setImprint] = useState("");
  const [ps, setPS] = useState("");
  const [color, setColor] = useState("0");
  const [mndc, setMNDC] = useState("");
  const [shape, setShape] = useState("0");
  const [mfg, setMFG] = useState("");

  const [disabled, setDisabled] = useState(false);

  const clearForm = () => {
       
        setIDX("");     
        setLN("");      
        setImprint("");   
        setPS("");   
        setColor("0");
        setMNDC("");     
        setShape("0");
        setMFG("");
        
  }

  const submitForm = () => {
    setDisabled(true);

         const errors = [];
         let obj = {};
   
       
            obj.frm = idx;
            obj.mndc = mndc;    
            obj.color = color;
            obj.shape = shape;
            obj.imprint = imprint;
            obj.mfg = mfg;
            obj.ps = ps;

        
        let json = JSON.stringify(obj);
       
        if (errors.length > 0) {
            setValErr(errors);
            setDisabled(false);
            
        } else {

           

             //POST DATA
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
            body: json
            };
            fetch(`${URL}formularyMNDCInsert`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    
                    setToastIcon("success");
                    setToastMsg("Inventory Saved Successfully");
                    setToastState(true);
                    setToastBackground("#5cb85c");
                    setToastTitle("Success");
                    setDisabled(false);
                })            
                .catch(err => {
                    setToastIcon("error");
                    setToastTitle("Error");
                    setToastBackground("#d9534f");
                    setToastMsg("There was a Problem Saving the Inventory. Please Try Again.");
                    setToastState(true);
                    setDisabled(false);
                });
             
        }
     
  }



    const selectFRM = (val) => {
        console.log(val);
        clearForm();
        setLN(val.LN);
        setIDX(val.IDX);
       
    }

    let _shapes = shape_codes.map((item, index) => {
        return (<option key={index} value={item.value}>{item.text}</option>);
        });

     let _colors = color_codes.map((item, index) => {
        return (<option key={index} value={item.value}>{item.text}</option>);
        });
        
    
 
    return(
        <MNDCEditWrapper>
             
        <div className="frm__container">
                  
              
                    
                    <div  className="frm_content ">
                      <FormHeader title="MANAGE MNDC" onClose={props.close} addClose={true}  class_style="form_header--light"></FormHeader>
                    <form>

                
                    <div className="form_row1-col1">

                    <div className="form_col1_fld1">
                                <input  type="text" onChange={(e) => setMNDC(e.target.value)} value={mndc}   className="form_input--light req" placeholder="ENTER NEW MNDC"  id="mndc" maxLength="11" autoComplete="off" required />  
                                <label htmlFor="mndc" className="form_label--light">NEW MNDC</label>
                           </div>
                       

                    </div>

                    <div className="form_row2-col2">
                        <div className="form_col2_fld1">
                                    <AutoCompleteForm Label="Formulary LN" id="frm" maxLength="60"  autoComplete="off" select={(frm) => selectFRM(frm)}   url={`${URL}formularyAutofill?idx=1`}/>
                                    <label htmlFor="frm" className="form_label--light">Formulary LN</label>
                        </div>
                           <div className="form_col2_fld2">
                                <input  type="text" onChange={(e) => setPS(e.target.value)} value={ps}  className="form_input--light req" placeholder="Package Size"  id="ps" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="ps" className="form_label--light">Package Size</label>
                           </div>

                    </div>
                   
                    <div className="form_row2-col2">
                            <div className="form_col2_fld1">
                            <select id="shape" onChange={e => setShape(e.target.value)}  value={shape} className="form_input req">
                                    <option value="0">----------</option>
                                    {_shapes}
                                </select>
                                <label htmlFor="shape" className="form_label">Shape</label>
                           </div>
                           <div className="form_col2_fld2">
                           <select id="color" onChange={e => setColor(e.target.value)}  value={color} className="form_input req">
                                    <option value="0">----------</option>
                                    {_colors}
                                </select>
                                <label htmlFor="color" className="form_label">Color</label>
                           </div>

                    </div>
                    <div className="form_row2-col2">
                    <div className="form_col2_fld1">
                                <input  type="text" onChange={(e) => setImprint(e.target.value)} value={imprint}  className="form_input--light req" placeholder="Imprint"  id="imprint" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="imprint" className="form_label--light">Imprint</label>
                        </div>
                        <div className="form_col2_fld2">
                                <input  type="text" onChange={(e) => setMFG(e.target.value)} value={mfg}  className="form_input--light req" placeholder="MFG"  id="mfg" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="mfg" className="form_label--light">MFG</label>
                        </div>

                    </div>
                   
                </form>
                  
                  


                    <div className="edit_inventory__btn-bar">
                        <LoadingButton title="CLEAR FORM" select={clearForm}  ></LoadingButton>
                       
                        { disabled ? <div></div> : <LoadingButton title="SUBMIT" select={submitForm}  ></LoadingButton>}
                    </div>
                    
                    </div>
        </div>

            <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>
        </MNDCEditWrapper>
    );
}
export default MNDCEdit;