import React, {useState, useEffect} from 'react';
import InputMask from 'react-input-mask';
import FormHeader from './form-header';
import LoadingButton from '../theme/loading-button';
import ReactLoading from 'react-loading';
import Toast from './toaster';
import {isValidNumber, isValidString, isDate, isSSN, isPhone, isZip5, getAPI} from '../utility';
import styled from 'styled-components';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';

const ImportEditWrapper = styled.div`
    
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 999;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
   

    
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }

    .patient_edit__header {
        grid-row: 1/-1;
        display: flex;
        align-items: center;
    }
  
    .patient_edit_content {
        height: 60vh;
        width: 50vw;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 999;
        opacity: 1;
        transition: all .3s;
        background-color:  rgba(0,0,0, 0.6); 
        backdrop-filter: blur(19px);
        -webkit-backdrop-filter: blur(19px);
        border-radius: 10px;
        border: 1px solid rgba(255,255,255,0.6);
        font-size: 2rem;

       

    }


    .patient_edit_close {
           
            text-decoration: none;
            transition: all .3s;
            line-height: 1;
            margin-right: 2rem;
            cursor: pointer;
            fill: var(--color-primary);
            transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

            .patient_edit_close:hover {
                fill: red;
            }

   
    }
    .import_edit_date {
        grid-row: 3/4;
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        padding: .5rem 2rem;
        align-items: center;
        justify-content: center;
    }

    #exp_container {
        grid-column: 1/2;
    }

    #shp_container {
        grid-column: 4/-1;
    }

 

    .import_edit_header {
        grid-row: 1/2;
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        padding: .5rem 2rem;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
    }

    #alias_container {
        grid-column: 1/2;
        font-size: 2.5rem;
        color: #fff;
        border-bottom: 2px solid var(--color-primary);
        text-align: center;
        
    }

    #ln_container {
        grid-column: 4/-1;
        font-size: 2.5rem;
        color: #fff;
        border-bottom: 2px solid var(--color-primary);
        text-align: center;
    
    }

    #gender_container {
        margin-top: 0;
        grid-column: 4/-1;
    }

    .patient_edit_name {
        grid-row: 2/3;
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 2rem;
        align-items: center;
        justify-content: center;
        padding:.5rem 2rem;
       
    }

    #ndc_container {
        grid-column: 1/2;
    }

    #lot_container {
        grid-column: 2/3;
    }

    #mndc_container {
        grid-column: 3/4;
    }

    #prefix_container {
       max-width: 10rem;
    }

    #firstname_container {
        grid-column: 1/2;
    }

    #lastname_container {
        grid-column: 2/3;
    }

    #middlename_container {
        grid-column: 3/4;
    }

    #suffix_container {
        grid-column: 4/-1;
    }


    .patient_edit_ssn {
        grid-row: 4/5;
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        align-items: center;
        justify-content: center;
        padding: .5rem 2rem;
    }

    #phone_container {
        grid-column: 1/2;
    }

    #dl_container {
        grid-column: 2/3;
    }
    #dl_state_container {
        grid-column: 3/4;
    }

    #dl {
        margin-top: 0;
        margin-left:1.5rem;
        max-width:25rem;
    }

    #dl_state {
        margin-top: 0;
        margin-left:1.5rem;
        max-width:20rem;
    }


    #ssn_container {
        grid-column: 4/-1;
    }

    .patient_edit_use_default {
        grid-row: 5/6;
        grid-column: 1/-1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: .5rem 2rem;
    }

    .patient_edit_address {
        grid-row: 6/7;
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        align-items: center;
        justify-content: center;
        padding: .5rem 2rem;
    }

    #address1_container {
        grid-column: 1/2;
    }

    #address2_container {
        grid-column: 2/3;
        font-size: 2.5rem;
        color: #fff;
        border-bottom: 2px solid var(--color-primary);
        margin-left: 10rem;
        margin-top: -2rem;
        text-align: center;
    }

    #address3_container {
        grid-column: 4/-1;
    }

   
    .patient_edit_country {
        grid-row: 7/8;
        grid-column: 1/-1;
        display: grid;
        grid-gap: 1.5rem;
        grid-template-columns: repeat(4,1fr);
        align-items: center;
        justify-content: center;
        padding: .5rem 2rem;
    }

    #country_container {
        grid-column: 1/2;
        margin-top: 0;
    }

    #state_container {
        grid-column: 2/3;
        margin-top: 0;
    }

    #city_container {
        grid-column: 3/4;
    }

    #zip_container {
        grid-column: 4/-1;
    }

    .patient_edit__btn-bar {
        grid-row: 8/9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 1rem;
    }

    .patient_edit__header {
        grid-row: 1/2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: var(--line);
        padding: 2rem;
    }
    .patient_edit__title {
        font-size: 2rem;
        color: var(--color-primary);
        margin-left: 2rem;
        

    }

    .patient_edit_left {
        width: 33.33333%;
        display: table-cell;
    }

    .patient_edit_right {
        width: 66.6666667%;
        display: table-cell;
        vertical-align: middle;
        padding: 3rem 5rem;
    }

    .patient_edit_img {
        display: block;
        width: 100%;

    }

    .patient_edit_text {
        font-size: 1.4rem;
        margin-bottom: 4rem;

        column-count: 2;
        column-gap: 4rem;
        column-rule: 1px solid var(--color-grey-dark-2);

        -moz-hyphens: auto;
        -ms-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }

    .err_list {
    color: red;
    font-size: 1.8rem;
    list-style: none;
}


`;


const LoadingButtonWrapper = styled.div`
    .ctn {
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
  }
  #check {
    display: none;
  }

  #check2 {
    display: none;
  }
  .btn-label {
   
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5rem;
    font-family: 'BenchNine', serif;
    font-size: 2rem;
    color: #fffeee;
    margin-top: 20%;
    height: 50px;
    width: 200px;
  }
  .btn-text-wait {
    font-family: 'BenchNine', serif;
    font-size: 1.6rem;
    color: #fffeee;
  }
  .load {
    display: none;
    width: 20px;
    height: 20px;
    border: 5px solid #fff;
    border-radius: 100%;
  }
  .open {
    border-top: 5px solid transparent;
    -webkit-animation: load-animate infinite linear 1s;
            animation: load-animate infinite linear 1s;
  }
 
  #check:checked ~ .btn-label > .load {
    display: inline-block;
  }
  @-webkit-keyframes load-animate {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      opacity: 0.35;
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes load-animate {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      opacity: 0.35;
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.btn-label--normal_light {
  background-color: #fff;
  color: var(--color-primary);
}


.btn-label--normal {
  background-color: var(--color-primary);
  transition: all .8s;

  &:hover {
    background-color:var(--color-grey-dark-1);
  }
}

.btn-label--success {
  background-color:rgb(12, 243, 31);
}

.btn-label--error {
  background-color:rgb(243, 12, 23);
}


.lds-facebook {
  display: inline-block;
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  z-index:99;
  margin-left: -3rem;
  margin-top: -5rem;
  
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  
}
.lds-facebook div:nth-child(1) {
  left: 0;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 2.4rem;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 4.8rem;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

`;

const ImportEdit = (props)  => {
  
    const [idx, setIDX] = useState('');
    const [cln, setCLN] = useState('');
    const [frm,setFRM] = useState('');
    const [prd, setPRD] = useState('');
    const [alias, setClinicAlias] = useState('');
    const [ln, setLN] = useState('');
    const [ndc, setNDC] = useState('');
    const [mndc, setMNDC] = useState('');
    const [lot, setLOT] = useState('');
    const [prd_code,setProductCode] = useState('');
    const [units, setUnits] = useState('');
    const [qty, setUnitQty] = useState('');
    const [shp, setShipDate] = useState('');
    const [exp, setExpDate] = useState('');
    const [order_number, setOrderNumber] = useState('');
   
    const [valid_err, setValErr] = useState([]);


    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");

    const [disable, setDisable] = useState(false);


    const [url, setUrl] = useState("");

    const { status, data, error } = useFetch(url);

    useEffect(() => {
        if (status === "fetched") {
          
          setToastIcon("success");
          setToastMsg("Import Updated Successfully");
          setToastState(true);
          setToastTitle("Success");
          setToastBackground("#5cb85c");
          props.updated();

        } else if (status === "error") {
          setToastIcon("error");
          setToastTitle("Error");
          setToastBackground("#d9534f");
          setToastMsg("There was a Problem Updating the Import, Please Try Again.");
          setToastState(true);
        }
      }, [status]);

   
    const deleteImport = () => {
        const errors = [];
              
        let it = isValidNumber(idx) ? idx : errors.push("Import Index Is Not Valid. Please Reload the Import and try again.");  //*
        if (errors.length > 0) {
            setValErr(errors);
            
        } else {
             setValErr([]);

             setUrl(`${URL}inventoryImportDelete?idx=${it}`);
 
        }

        
    }

  
    const saveImport = () => {

      setDisable(true);
       const errors = [];
       

        let obj = {};
        obj.idx = isValidNumber(idx) ? idx : errors.push("Import Index Is Not Valid. Please Reload Import and try again.");  //*
        obj.cln = isValidNumber(cln) ? cln : errors.push("Clinic Index Is Not Valid. Please Update Clinic on Invoice and Import again.");
        obj.frm = isValidNumber(frm) ? frm : errors.push("Formulary Index Is Not Valid. Please Reload Import and try again.");
        obj.prd = isValidNumber(prd) ? prd : errors.push("Product Code Is Not Valid. Please Update on Invoice and Import again."); //*
        obj.lot = isValidString(lot) ? lot : errors.push("LOT is Not Valid");
        obj.ndc = isValidString(ndc) ? ndc : errors.push("NDC is not valid.");
        obj.mndc = isValidString(mndc) ? mndc : errors.push("MNDC is not valid.");
        obj.units = isValidNumber(units) ? units : errors.push("Units Ordered is not valid.");  //*
        obj.qty = isValidNumber(qty) ? qty : errors.push("Unit Qty is not valid.");  //*
        obj.exp = isDate(new Date(exp)) ? exp : errors.push("Valid Exp Date Is Required."); //*
        obj.shp = isDate(new Date(shp)) ? shp : errors.push("Valid Ship Date Is Required."); //*
        obj.order = isValidString(order_number) ? order_number : errors.push("Order Number is not valid.");
       
        
       let json = JSON.stringify(obj);
       
      

       if (errors.length > 0) {
           setValErr(errors);
        
       } else {


        //POST DATA
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
            body: json
        };
        fetch(`${URL}inventoryImportUpdate`, requestOptions)
            .then(response => response.json())
            .then( () => {
                setValErr([]);
                setToastIcon("success");
                setToastMsg("Import Updated Successfully");
                setToastState(true);
                setToastTitle("Success");
                setToastBackground("#5cb85c");
                setDisable(false);
            })            
            .catch(err => {
                setToastIcon("error");
                setToastTitle("Error");
                setToastBackground("#d9534f");
                setToastMsg("There was a Problem Updating the Import, Please Try Again.");
                setToastState(true);
                setDisable(false);
            });
    
            props.updated();
        }

    }

   


    useEffect(() => {

            
        setIDX(props.data.IDX);
        setCLN(props.data.CLN_IDX);
        setClinicAlias(props.data.ClinicAlias);
        setExpDate(props.data.ExpDate);
        setFRM(props.data.FRM_IDX);
        setLN(props.data.LN);
        setNDC(props.data.NDC);
        setMNDC(props.data.MNDC);
        setLOT(props.data.LOT);
        setOrderNumber(props.data.OrderNumber);
        setPRD(props.data.PRD_IDX);
        setProductCode(props.data.ProductCode);
        setUnitQty(props.data.UnitQty);
        setUnits(props.data.UnitsOrdered);
        setShipDate(props.data.ShipDate);

        

        if (props.data.ErrorMsg ) {
          let err = props.data.ErrorMsg.split(".");
          setValErr(err);

        }
        
        
        let start = document.getElementById("ndc");
        start.focus();


    }, []);



     


        const err = () => {
            try {
                if (Array.isArray(valid_err)) {
                  return valid_err.map((item, index)=>{
                      return (
                            <ul>
                                  <li className="err_list">{item}</li>
                                 
                            </ul>
                       );
                  });
              } else {
                return (
                  <tr><td colSpan='8'><div>The Patient Allergen Profile is Empty.</div></td></tr>
                );
              }
              } catch (err) {
                return;
              }
        }

       
        const switchToMask = (e) => {

            if (e.target.id === "exp_set") {

                const val_dob = document.getElementById("exp_set");
                const enter_dob = document.getElementById("exp");
               
                val_dob.style.display = "none";
                enter_dob.style.display = "block";

            } else if (e.target.id === "shp_set") {
                const val_ssn = document.getElementById("shp_set");
                const enter_ssn = document.getElementById("shp");
               
                val_ssn.style.display = "none";
                enter_ssn.style.display = "block";

            } 
            
          
        }

        const switchToInput = (e) => {

            if (e.target.id === "exp") {

                const val_dob = document.getElementById("exp_set");
                const enter_dob = document.getElementById("exp");
              
    
                val_dob.style.display = "block";
                enter_dob.style.display = "none";

            } else if (e.target.id === "shp") {
                const val_ssn = document.getElementById("shp_set");
                const enter_ssn = document.getElementById("shp");
               
             
    
                val_ssn.style.display = "block";
                enter_ssn.style.display = "none";

            } 
            
          
        }

       
       
    return (
        <ImportEditWrapper>
                <div id="content" className="patient_edit_content ">
                <FormHeader onClose={props.onClose}  title="EDIT IMPORT" addClose={true} class_style="form_header--light"></FormHeader>
            
                <form>
                    <div className="import_edit_header">
                        <div id="alias_container">
                                    <span>{alias}</span>
                        </div>
                        <div id="ln_container">
                                    <span>{ln}</span>
                        </div>
                    </div>
               
                    <div className="patient_edit_name">
                            

                            
                            <div id="ndc_container">
                                <input role="presentation" onChange={e => setNDC(e.target.value)} value={ndc}  type="text" className="form_input req" placeholder="NDC" id="ndc" maxlength="35" required autoComplete="none"  />
                                <label for="ndc" className="form_label">NDC</label>
                            </div>
                            <div id="lot_container">
                                <input onChange={e => setLOT(e.target.value)} value={lot} type="text" className="form_input req" placeholder="LOT"  id="lot" maxlength="35" autocomplete="false" required />  
                                <label for="lot" className="form_label">LOT</label>
                            </div>
                            <div id="mndc_container">
                                <input onChange={e => setMNDC(e.target.value)} value={mndc} type="text" className="form_input" placeholder="MNDC" id="name" autocomplete="false" />  
                                <label for="mndc" className="form_label">MNDC</label>
                            </div>
                         
                    </div>

                    <div className="import_edit_date">
                            <div id="exp_container">
                                <input onFocus={switchToMask}  value={exp}  type="text" className="form_input"  id="exp_set"  />  
                                <InputMask onBlur={switchToInput} style={{display:"none"}}  onChange={e => setExpDate(e.target.value)}  type="text" className="form_input req" mask="99/99/9999" placeholder="EXP DATE"  id="exp" autocomplete="false" required /> 
                                <label for="dob" className="form_label">Exp Date</label>
                            </div>

                            <div id="shp_container">
                                <input onFocus={switchToMask}  value={shp}  type="text" className="form_input"  id="shp_set"  />  
                                <InputMask onBlur={switchToInput} style={{display:"none"}}  onChange={e => setShipDate(e.target.value)}  type="text" className="form_input req" mask="99/99/9999" placeholder="SHIP DATE"  id="shp" autocomplete="false" required /> 
                                <label for="shp" className="form_label">Ship Date</label>
                            </div>
                            
                            
                    </div>
                    

                    
                    <div className="patient_edit_address">
                        <div id="address1_container">
                                <input defaultValue={units}   onChange={e => setUnits(e.target.value)} type="text" className="form_input req" placeholder="UNITS ORDERED" id="address1" maxlength="35" autocomplete="false"  />
                                <label htmlFor="address1" className="form_label">Units Ordered</label>
                        </div>
                        <div id="address2_container">
                                    <span>{`PRODUCT:   ${prd_code}`}</span>
                        </div>`
                        <div id="address3_container">
                                <input defaultValue={qty}  onChange={e => setUnitQty(e.target.value)} type="text" className="form_input req" placeholder="UNIT QTY" id="address2" maxlength="35" autocomplete="false"/>  
                                <label htmlFor="address2" className="form_label">Unit Qty</label>
                        </div>
                    </div>

                  
                    <div className="patient_edit__btn-bar">
                        <LoadingButton id="check" title="DELETE IMPORT" select={deleteImport} />
                           
                        <div id="error_list" >{err()}</div>

                         
                       { disable ? <div></div> : <LoadingButtonWrapper>
                            <div className="ctn" onClick={saveImport}>
                                <input type="checkbox" id="check2" />
                                <label for="check2" id="save" className="btn-label btn-label--normal">
                                    <div id="btn2_animate" className=""><div></div><div></div><div></div></div>
                                        <p className="btn-text-wait">UPDATE IMPORT</p> 
                                </label>
                            </div>
                        </LoadingButtonWrapper>}

                           
                    </div>

                  

                    </form>
                    	
                </div>
                <div className="toasty">
                    {toast ?  <Toast 
                        id={74}
                        position={'bottom-center'}
                        autoDelete={true}
                        autoDeleteTime={3000}
                        title={toast_title}
                        description={toast_msg}
                        backgroundColor={toast_background}
                        icon={toast_icon}
                        close={() => setToastState(false)}

                    /> : null }
                 </div>
        </ImportEditWrapper>
        
    );

}
export default ImportEdit;