import React, { useState, useEffect } from 'react';
import PatientEdit from './patient-edit';
import PatientRxHistory from './patient-rx-history';
import PatientProblemList from './patient-problem-list';
import PatientAllergenProfile from './patient-allergen-profile';
import LastEncounter from "./last-encounter";
import styled from 'styled-components';
import ButtonSlide  from '../theme/btn-slide';
import Overlay from '../theme/overlay';
import arrow_down_right from '../assets/arrow_down_right.png';
import arrow_up from '../assets/arrow_up.png';
import { getSession } from '../utility';





const PatientDetailWrapper = styled.div`
    font-size: 1.5rem;
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    min-height: 16rem;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(20rem, 1fr));
    grid-template-rows: repeat(3, minmax(min-content, 1fr));
    overflow: hidden;
    
    

    .patient__btn-bar {
        grid-column: 1 / -1;
        background-color: #222;
        height: 100%;
        
        
    }

    .patient__btns {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        list-style: none;
        text-decoration: none;
        align-items: center;
        text-transform: uppercase;
        margin-top: 1rem;
    }

    

    .patient__name {
        grid-column: 2 / -1;
        grid-row: 2 / 3;
        background-color: #fff;
        color: var(--color-grey-dark-1);
        font-size: 2.5rem;
        font-family:'Raleway', sans-serif;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        border-bottom: var(--line);

        .patient__name--text {
            margin-right: auto;
            margin-left: auto;
        } 

        .patient__name--close {
            margin-right: 2rem;
            cursor: pointer;
            fill: var(--color-primary);
            transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

            &:hover {
                fill: red;
            }
        }

      }

   

      .patient__mrn {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        background-color: #fff;
        color: var(--color-grey-dark-1);
        font-size: 1.75rem;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400;
        border-bottom: var(--line);
        
    }

    .patient__dob {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        background-color: #fff;
        color: var(--color-grey-dark-1);
        font-size: 1.75rem;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400;
        border-bottom: var(--line);
       
    }

    .patient__gender {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        background-color: #fff;
        color: var(--color-grey-dark-1);
        font-size: 1.75rem;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400;
        border-bottom: var(--line);
        
    }

    .card_lbl {
        margin-right: 1rem;
        font-size: 2rem;
        padding: .5rem;
        color: navy;
        
    }

    .card_txt {
        padding: .5rem;
        color: Navy;
        font-size: 2rem;
        font-weight: 700;
        
    }



    .patient__known-allergen {

        grid-column: 2 / -1;
        grid-row: 3 / 4;
        background-color: var(--color-grey-dark-2);
        color: yellow;
        font-size: 1.75rem;
        font-family:'Raleway', sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400;
        border-bottom: var(--line);
       
        .patient__known-allergen--lbl {
            margin-left: 2rem;
        }

       .patient__known-allergen--txt {
            margin-left: 1.0rem;
            color: var(--color-primary);
        }

    }


.card {
   
    position: relative;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    /* border-right: var(--line); */
   
    }

    .card_cta {

        padding:1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

`;


const PatientDetail = (props) => {

    const [ptt_open, setPTT_OPEN] = useState(false);
    const [rx_open, setRX_OPEN] = useState(false);
    const [alg_open, setALG_OPEN] = useState(false);
    const [prb_open, setPRB_OPEN] = useState(false);
    const [last_open, setLAST_OPEN] = useState(false);
    const [overlay, setOverlay] = useState(false);


    const [alg_profile, setALG_PROFILE] = useState([]);

    useEffect(() => {
        setALG_PROFILE(props.pat.AllergenList);
    }, [props.pat.AllergenList]);

    function patient_edit_launch() {
       
        setPTT_OPEN(!ptt_open);
    }

    function patient_rxhistory_launch() {
       
        setRX_OPEN(!rx_open);
    }

    function patient_allergen_launch(data) {

        setALG_PROFILE(data);
        setALG_OPEN(!alg_open);
    }

    function last_encounter_launch() {
       
        setLAST_OPEN(!last_open);
    }

    function patient_problem_launch() {
       
        setPRB_OPEN(!prb_open);
    }

    const closeOverlay = () => {
        setOverlay(false);
    }
    
    return (
      <PatientDetailWrapper>

            {ptt_open ? <PatientEdit onClose={patient_edit_launch} idx={props.pat.IDX} usr={'1'}></PatientEdit> : null}
            {alg_open ? <PatientAllergenProfile onClose={data => patient_allergen_launch(data)} idx={props.pat.IDX}></PatientAllergenProfile> : null}
            {rx_open  ? <PatientRxHistory onClose={patient_rxhistory_launch} idx={props.pat.IDX}></PatientRxHistory> : null}
            {prb_open ? <PatientProblemList onClose={patient_problem_launch} idx={props.pat.IDX}></PatientProblemList> : null}
            {last_open ? <LastEncounter onClose={() => setLAST_OPEN(false)} idx={props.pat.IDX}></LastEncounter> : null}
     


          <div className="patient__btn-bar">
              <ul className="patient__btns">
                  <li><ButtonSlide width={'142%'} click={patient_edit_launch} title="Edit Patient" /></li>
                  <li><ButtonSlide width={'150%'} click={patient_rxhistory_launch} title="Rx History" /></li>
                  <li><ButtonSlide width={'156%'} click={patient_allergen_launch} title="Allergen Profile" /></li>
                  <li><ButtonSlide width={'162%'} click={patient_problem_launch} title="Problem List" /></li>
              </ul>
          </div>
          
          <div className="card">
                
                    <div className="card_cta">
                        <div className="mrn_container"><span className="card_lbl">MRN:</span><span className="card_txt">{props.pat.MRN}</span></div>
                        <div className="dob_conatainer"><span className="card_lbl">DOB:</span><span className="card_txt">{props.pat.DOB}</span></div>
                        <div className="last_container"><ButtonSlide width={'142%'} click={last_encounter_launch} title="Last Encounter" /></div> 
                    </div>
             
            </div>
          
           <div className="patient__name">
                <span className="patient__name--text">{`${props.pat.FirstName} ${props.pat.LastName}` }</span>
                
               { getSession("help") ? <svg style={{marginRight: "3rem", cursor:"pointer"}} onClick={() => setOverlay(true)} width="40px" height="40px" viewBox="0 0 12 12" enableBackground="new 0 0 12 12"  version="1.1"  ><path d="M6,0C2.6862793,0,0,2.6862793,0,6s2.6862793,6,6,6s6-2.6862793,6-6S9.3137207,0,6,0z M6.5,9.5h-1v-1h1V9.5z   M7.2651367,6.1738281C6.7329102,6.5068359,6.5,6.6845703,6.5,7v0.5h-1V7c0-0.9023438,0.7138672-1.3486328,1.2348633-1.6738281  C7.2670898,4.9931641,7.5,4.8154297,7.5,4.5c0-0.5517578-0.4487305-1-1-1h-1c-0.5512695,0-1,0.4482422-1,1V5h-1V4.5  c0-1.1025391,0.8969727-2,2-2h1c1.1030273,0,2,0.8974609,2,2C8.5,5.4023438,7.7861328,5.8486328,7.2651367,6.1738281z" fill="#00000"></path></svg> : null}
                <svg className="patient__name--close" onClick={props.onClose} width="32" height="32"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g><path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path></g></svg>
           </div> 
      
           <div className="patient__known-allergen">
                <span className="patient__known-allergen--lbl" >KNOWN ALLERGENS:</span><span className="patient__known-allergen--txt"> {alg_profile} </span>
                
           </div> 

           {overlay ? <Overlay close={() => setOverlay(false)} >
           <>
                        
                        <div  className="item-end" style={{gridRow: "2/3",gridColumn: "1/2", marginLeft: "3rem", marginTop: "30px"}}>
                            <span>Load the Patient's Last Encounter Here.</span>
                            <img src={arrow_down_right} alt="arrow" className="arrow" />
                        </div>
                        <div className="item-start item-justify-top" style={{gridRow: "2/3", gridColumn: "2/4"}}>
                            <span >Edit the Patient's Dmographic and Rx History using the Button Bar</span>
                        </div>
                        <div style={{gridColumn: "3/5",gridRow: "3/4", marginTop:"-40px"}}>
                            <span >The Patient's Known Allergens will Display Here</span>
                        </div>
        
                        <div style={{gridColumn: "2/5",gridRow: "4/5", justifySelf:"start"}}>
                            <img src={arrow_up} alt="arrow" className="arrow" />
                            <span >Load a Dispenser by Typing the at least the First Three Letters of their Last Name. </span>
                        </div>
                        
                        
                            
                            <div style={{gridRow:"3/4", gridColumn:"6/-1", marginTop: "20px", marginLeft: "50px"}}>
                                <img src={arrow_up} alt="arrow" className="arrowLeft" />
                                <span >Unload the Patient Here</span>
                            </div>
                            
                        <div style={{gridColumn:"4/6", gridRow:"2/3", marginLeft:"40px"}}>
                            <span >Maintain the Patient's Allergen & Problem Profile Here</span>
                            
                        </div>
                        
                        <span className="close_overlay" onClick={closeOverlay} style={{gridColumn: "3/5", gridRow:"4/6",  fontFamily: "BenchNine", fontSize:"3rem",cursor:"pointer"}}>Click Here to Close</span>
                    </> 
           </Overlay> : null}
        </PatientDetailWrapper>
    );
  }
  
  export default PatientDetail;