import { useEffect, useReducer } from 'react';

export const useDLParse = (dl_data) => {
	
	const initialState = {
		parse_status: 'idle',
		parse_error: null,
		parse: null,
	};

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'PARSING':
				return { ...initialState, parse_status: 'parsing' };
			case 'PARSED':
				return { ...initialState, parse_status: 'parsed', parse: action.payload };
			case 'PARSE_ERROR':
				return { ...initialState, parse_status: 'parse_error', error: action.payload };
			default:
				return state;
		}
	}, initialState);

    const first_attempt = () => {
        let json = "";
        try {
            let obj = {};
           
            let json = dl_data.replace('@ANSI','{"ANSI":"');
            json = json.replace('DAQ', '","DAQ": "');
            json = json.replace('DCS', '","DCS": "');
            json = json.replace('DDE', '","DDE": "');
            json = json.replace('DAC', '","DAC": "');
            json = json.replace('DDF', '","DDF": "');
            json = json.replace('DAD', '","DAD": "');
            json = json.replace('DDG', '","DDG": "');
            json = json.replace('DCA', '","DCA": "');
            json = json.replace('DCB', '","DCB": "');
            json = json.replace('DBD', '","DBD": "');
            json = json.replace('DBB', '","DBB": "');
            json = json.replace('DBA', '","DBA": "');
            json = json.replace('DBC', '","DBC": "');
            json = json.replace('DAU', '","DAU": "');
            json = json.replace('DAY', '","DAY": "');
            json = json.replace('DAG', '","DAG": "');
            json = json.replace('DAI', '","DAI": "');
            json = json.replace('DAJ', '","DAJ": "');
            json = json.replace('DAK', '","DAK": "');
            json = json.replace('DCF', '","DCF": "');
            json = json.replace('DCG', '","DCG": "');
            json = json.replace('DAZ', '","DAZ": "');
            json = json.replace('DAW', '","DAW": "');
            json = json.replace('DCK', '","DCK": "');
            json = json.replace('DDA', '","DDA": "');
            json = json.replace('DDB', '","DDB": "');
            json = json.replace('DDK', '","DDK": "');
            json = json.replace('DDL', '","DDL": "');
            json = json.replace('DBD', '","DBD": "');
            json = json.replace('DBE', '","DBE": "');
            json = json.replace('DBF', '","DBF": "');
            json = json.replace('DBG', '","DBG": "');
            json = json.replace('DAH', '","DAH": "');
            json = json.replace('DAA', '","DAA": "');
            json = json.replace('DAB', '","DCS": "');
            json = json.replace('DBH', '","DBH": "');
            json = json.replace('DBI', '","DBI": "');
            json = json.replace('DCU', '","DCU": "');
            json = json.replace('DCD', '","DCD": "');
            json += '"}';
            json = json.replace('"":', '');
            json = json.replace('""','');
            json = json.replace('"",', '');
            json = json.replace(',,', ',');

         
            obj = JSON.parse(json);

            return obj;

        } catch (error) {
            return null;
        }
    }

    const second_attempt = () => {
        let json = "";
        try {
            let obj = {};
            let json = dl_data.replace('ANSI','{"ANSI":"');
            json = json.replace('DAQ', '","DAQ": "');
            json = json.replace('DCS', '","DCS": "');
            json = json.replace('DDE', '","DDE": "');
            json = json.replace('DAC', '","DAC": "');
            json = json.replace('DDF', '","DDF": "');
            json = json.replace('DAD', '","DAD": "');
            json = json.replace('DDG', '","DDG": "');
            json = json.replace('DCA', '","DCA": "');
            json = json.replace('DCB', '","DCB": "');
            json = json.replace('DBD', '","DBD": "');
            json = json.replace('DBB', '","DBB": "');
            json = json.replace('DBA', '","DBA": "');
            json = json.replace('DBC', '","DBC": "');
            json = json.replace('DAU', '","DAU": "');
            json = json.replace('DAY', '","DAY": "');
            json = json.replace('DAG', '","DAG": "');
            json = json.replace('DAI', '","DAI": "');
            json = json.replace('DAJ', '","DAJ": "');
            json = json.replace('DAK', '","DAK": "');
            json = json.replace('DCF', '","DCF": "');
            json = json.replace('DCG', '","DCG": "');
            json = json.replace('DAZ', '');
            json = json.replace('DAW', '","DAW": "');
            json = json.replace('DCK', '","DCK": "');
            json = json.replace('DDA', '","DDA": "');
            json = json.replace('DDB', '","DDB": "');
            json = json.replace('DDK', '","DDK": "');
            json = json.replace('DDL', '","DDL": "');
            json = json.replace('DBD', '","DBD": "');
            json = json.replace('DBE', '","DBE": "');
            json = json.replace('DBF', '","DBF": "');
            json = json.replace('DBG', '","DBG": "');
            json = json.replace('DAB', '","DCS": "');
            json = json.replace('DBH', '","DBH": "');
            json = json.replace('DCD', '","DCD": "');
            json = json.replace('DCU', '","DCU": "');
            json = json.replace('DCD', '","DCD": "');
            json = json.replace('DAA', '","DAA": "');
            json = json.replace('DBH', '","DBH": "');
            json = json.replace('DBI', '","DBI": "');
            json = json.replace('DAH', '","DAH": "');
            json += '"}';
            json = json.replace('"":', '');
            json = json.replace('""','');
            json = json.replace('"",', '');
            json = json.replace(',,', ',');

            obj = JSON.parse(json);
            return obj;

        } catch (error) {
            return null;
        }
    }

    const third_attempt = () => {
        let json = "";

        let val = dl_data;

        try {
            let obj = {};
            let pos1 = val.indexOf('DAQ');
            let pos2 = val.indexOf('DCS');
            let txt = val.substring((pos1 + 3),pos2);

            let json = `{"DAQ": "${txt}",`;
            
            pos1 = val.indexOf('DCS');
            pos2 = val.indexOf('DDE');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DCS": "${txt}",`;

            pos1 = val.indexOf('DAC');
            pos2 = val.indexOf('DDF');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DAC": "${txt}",`;

            pos1 = val.indexOf('DAD');
            pos2 = val.indexOf('DDG');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DAD": "${txt}",`;

            pos1 = val.indexOf('DCU');
            pos2 = val.indexOf('DCA');

            if(pos1 > 0) {
                txt = val.substring((pos1 + 3),pos2);
                json += `"DCU": "${txt}",`;
            }

            pos1 = val.indexOf('DAH');
            pos2 = val.indexOf('DAI');

            if(pos1 > 0) {
                txt = val.substring((pos1 + 3),pos2);
                json += `"DAH": "${txt}",`;
            }

            
            pos1 = val.indexOf('DBB');
            pos2 = val.indexOf('DBA');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DBB": "${txt}",`;

            pos1 = val.indexOf('DBC');
            pos2 = val.indexOf('DAU');
            txt = val.substring((pos1 + 3),pos2);

            if(txt.length > 1) {
                throw new Error('Fields Out of Synch');
            }

            json += `"DBC": "${txt}",`;

            pos1 = val.indexOf('DAG');
            pos2 = val.indexOf('DAI');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DAG": "${txt}",`;

            pos1 = val.indexOf('DAI');
            pos2 = val.indexOf('DAJ');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DAI": "${txt}",`;

            pos1 = val.indexOf('DAJ');
            pos2 = val.indexOf('DAK');
            txt = val.substring((pos1 + 3),pos2);
            
            json += `"DAJ": "${txt}"`;
            json += `}`

            obj = JSON.parse(json);
            return obj;

        } catch (error) {
            return null;
        }
    }

    const fourth_attempt = () => {
        let json = "";

        let val = dl_data;

        try {
            let obj = {};
            let pos1 = val.indexOf('DAQ');
            let pos2 = val.indexOf('DAA');
            let txt = val.substring((pos1 + 3),pos2);

            let json = `{"DAQ": "${txt}",`;
            
            pos1 = val.indexOf('DAB');
            pos2 = val.indexOf('DAC');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DCS": "${txt}",`;

            pos1 = val.indexOf('DAC');
            pos2 = val.indexOf('DAD');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DAC": "${txt}",`;

            pos1 = val.indexOf('DAD');
            pos2 = val.indexOf('DAG');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DAD": "${txt}",`;

            pos1 = val.indexOf('DCU');
            pos2 = val.indexOf('DCA');

            if(pos1 > 0) {
                txt = val.substring((pos1 + 3),pos2);
                json += `"DCU": "${txt}",`;
            }

            pos1 = val.indexOf('DAH');
            pos2 = val.indexOf('DAI');

            if(pos1 > 0) {
                txt = val.substring((pos1 + 3),pos2);
                json += `"DAH": "${txt}",`;
            }



            pos1 = val.indexOf('DBB');
            pos2 = val.indexOf('DBA');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DBB": "${txt}",`;

            pos1 = val.indexOf('DBC');
            pos2 = val.indexOf('DBH');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DBC": "${txt}",`;

            pos1 = val.indexOf('DAG');
            pos2 = val.indexOf('DAI');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DAG": "${txt}",`;

            pos1 = val.indexOf('DAI');
            pos2 = val.indexOf('DAJ');
            txt = val.substring((pos1 + 3),pos2);

            json += `"DAI": "${txt}",`;

            pos1 = val.indexOf('DAJ');
            pos2 = val.indexOf('DAK');
            txt = val.substring((pos1 + 3),pos2);
            
            json += `"DAJ": "${txt}"`;
            json += `}`

        obj = JSON.parse(json);
        return obj;

        } catch (error) {
            return null;
        }
    }

	useEffect(() => {
		let cancelRequest = false;
		if (!dl_data || !dl_data.trim()) return;

		const parseData = async () => {
			dispatch({ type: 'PARSING' });
			
				try {
                  
                    //attempt to parse the data
                    let parse = {};

                    parse = first_attempt();
                   

                    if(parse === null){ 
                        parse = second_attempt();
                    }

                    if(parse === null){
                        parse = third_attempt();
                    }

                    if(parse === null){
                        parse = fourth_attempt();
                    }

                    if(parse === null){
                        throw new Error('Unable to parse data');
                    }
					
					if (cancelRequest) return;
					dispatch({ type: 'PARSED', payload: parse });
				} catch (error) {
					if (cancelRequest) return;
					dispatch({ type: 'PARSE_ERROR', payload: error.message });
				}
			
		};

		parseData();

		return function cleanup() {
			cancelRequest = true;
		};
	}, [dl_data]);

	return state;
};

export default useDLParse;