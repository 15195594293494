import React, {useEffect, useState} from 'react';
import Toast from './toaster';
import LoadingButton from '../theme/loading-button';
import Table from './table';
import styled from 'styled-components';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import ReactLoading from 'react-loading';
import ScanEntry from './scan-entry';
import { getSession, getAPI, parseGS1Barcode } from '../utility';
import FormHeader from './form-header';
import Overlay from '../theme/overlay';
import arrow_down_right from '../assets/arrow_down_right.png';
import arrow_up from '../assets/arrow_up.png';
import arrow_down from '../assets/arrow_down.png';


const InventoryReceiveWrapper = styled.div`

    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    z-index: 998;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .receive_container { 
       position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 80vh;
        width: 70%;
        
       
        /* background-color: rgba(0,0,0,.6); */
        background-color: var(--color-grey-dark-2);
        z-index: 999;
        opacity: 1;
        transition: all .3s;
   
     min-height: 43.3rem;
     border: 2rem solid  var(--color-primary);
     border-radius: 5px;

      }
     

    font-size: 1.8rem;
    color: navy;
     /* color: var(--color-primary); */
    
    .receive_inventory__form_header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;
        margin-top: 2rem;
        padding-top: 1.7rem;
        border-top: var(--line);
        border-bottom: var(--line);
        padding-bottom: 1.7rem;
        color: var(--color-primary); 
    }

    .receive_inventory__btn-bar {
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
    }

    .rcv_dsp {
        max-width: 25rem;
        padding: 1rem 2rem;
        margin-left: 7rem;
    }

    .active_dsp {
        text-transform: uppercase;
        font-family: 'BenchNine', sans-serif;
        font-size: 2.2rem;
        margin: 0 auto;
    }

    .recent__table {

        
    }



`;

const InventoryReceive = (props) => {

    const [query, setQuery] = useState('');
    const [usr, setUsr] = useState('');
    const [cln, setCln] = useState(getSession("cln"));
    const [url, setURL] = useState('');
    const [scan, setScan] = useState(false);
    
    const { status, data, error } = useFetch(url);
   
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");
    
    const [receive, setReceive] = useState([]);
    const [table_meta, setTableMeta] = useState([]);

    const [disabled, setDisabled] = useState(false);
    const [overlay, setOverlay] = useState(false);

  
    useEffect(() => {
        setTableMeta([
            {"col_width": "col-15", "data_label": "INVOICE","key": "INVOICE"},
            {"col_width": "col-50", "data_label": "Drug","key": "LN"},
            {"col_width": "col-15", "data_label": "NDC","key": "NDC"},
            {"col_width": "col-15", "data_label": "LOT","key": "LOT"},
            {"col_width": "col-10", "data_label": "Units","key": "UnitsOrdered"},
            {"col_width": "col-10", "data_label": "Unit Qty","key": "UnitQty"}
        ]);
      
        
        //GET CLN IDX FROM session object
        setCln(getSession("cln"));

        const query = 1;
		
        if (query) {
			setQuery(query);
	    }


         //GET USR IDX FROM session object
      
        const usr = getSession("usr");
        if(usr) {
            setUsr(usr);
        }


    }, []);

    useEffect(() => {
        
        setURL(`${URL}inventoryNonReceivedList?idx=${cln}&query=${query}`);
    }, [query]);

 
    const selectRow = (e) => {
       
        const sel = document.getElementById(e.target.id);
        
        
        if(sel) {
            sel.classList.add("table-row--light__selected");
                       
            setReceive(receive => [...receive, sel.id]);
        }
        
    }


    const clearReceive = () => {

        let els = document.getElementsByClassName('table-row--light__selected');
        setReceive([]);
        while (els[0]) {
            els[0].classList.remove('table-row--light__selected');
        }
    }

   
    const submitReceive = () => {

       setDisabled(true);
        if(receive.length > 0) {

            receiveInv();
            

                      

        }   else {
            console.log("Nothing to Receive");
            setDisabled(false);
        }
               
            //launch toast
            
       
    }

    const receiveScan = (val) => {
       
        const scan = parseGS1Barcode(val);
       


        fetch(`${URL}inventoryReceiveScan?cln=${getSession("cln")}&ndc=${scan.ndc}&lot=${scan.lot}&usr=${getSession("usr")}`, {
            method: "POST",
            headers: { "Content-Type": "application/json", "x-functions-key" : getAPI() },
            body: JSON.stringify(receive)
        })
          .then((res) => res.json())
          .then((result) => {
           
            setURL('');
            setQuery(Math.floor(Math.random() * 100));
            setToastIcon("success");
            setToastTitle("Success");
            setToastBackground("#5cb85c");
            setToastMsg("Inventory Received Successfully");
            setToastState(true);
           
    
          })
          .catch(err => {
            setToastIcon("error");
            setToastTitle("Error");
            setToastBackground("#d9534f");
            setToastMsg("There was a Problem Receiving the Inventory. Please Try Again.");
            setToastState(true);
            
        });
    }

    const receiveInv = () => {

        fetch(`${URL}inventoryReceiveBatch?idx=${usr}`, {
            method: "POST",
            headers: { "Content-Type": "application/json", "x-functions-key" : getAPI() },
            body: JSON.stringify(receive)
        })
        .then (response => {
            if (response.ok === false) {  throw new Error('Network response was not ok.') };
             
            setQuery(Math.floor(Math.random() * 100));
            setToastIcon("success");
            setToastMsg("Inventory Received Successfully");
            setToastState(true);
            setDisabled(false);
           
    
          })
          .catch(err => {
            setToastIcon("error");
            setToastTitle("Error");
            setToastBackground("#d9534f");
            setToastMsg("There was a Problem Receiving the Inventory. Please Try Again.");
            setToastState(true);
            setDisabled(false);
            
        });
      }
    

          
  const closeOverlay = () => {
    setOverlay(false);
}

    return(
       <InventoryReceiveWrapper>
        <div className="receive_container">
         <FormHeader onClose={props.onClose} title="RECEIVE INVENTORY" addClose={true} class_style="form_header--light"></FormHeader>
         { getSession("help") ? <svg style={{marginRight: "3rem", cursor:"pointer", position:"absolute", top:"20", left: "500"}} onClick={() => setOverlay(true)} width="40px" height="40px" viewBox="0 0 12 12" enable-background="new 0 0 12 12"  version="1.1"  ><path d="M6,0C2.6862793,0,0,2.6862793,0,6s2.6862793,6,6,6s6-2.6862793,6-6S9.3137207,0,6,0z M6.5,9.5h-1v-1h1V9.5z   M7.2651367,6.1738281C6.7329102,6.5068359,6.5,6.6845703,6.5,7v0.5h-1V7c0-0.9023438,0.7138672-1.3486328,1.2348633-1.6738281  C7.2670898,4.9931641,7.5,4.8154297,7.5,4.5c0-0.5517578-0.4487305-1-1-1h-1c-0.5512695,0-1,0.4482422-1,1V5h-1V4.5  c0-1.1025391,0.8969727-2,2-2h1c1.1030273,0,2,0.8974609,2,2C8.5,5.4023438,7.7861328,5.8486328,7.2651367,6.1738281z" fill="#ffffff"></path></svg> : null }
                        

           <div className="recent__table">

                {status === 'error' && <div>{error}</div>}
				{status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
				{status === 'fetched' && (

                      
					  <Table data={data} meta={table_meta} row_class="table-row" include_header="true" select={selectRow} table_height="tbl"></Table>
				)}

              
                    
            </div>


           
           <div className="receive_inventory__btn-bar">

                <LoadingButton title="CLEAR SELECTED" id="check" select={clearReceive} />
              
                <LoadingButton title="SCAN TO RECEIVE" id="check2" select={() => setScan(true)} />

            { disabled ? <div></div> : <LoadingButton id="check3" title="SUBMIT RECEIVE" select={submitReceive} />}
               
                        
            </div>

            {/* <Toast icon="flag" message={toast_msg}></Toast> */}
            { scan ? <ScanEntry title="SCAN INVENTORY" close={() => setScan(false)} submit={(val) => receiveScan(val)}></ScanEntry> : null  }
        </div>

        <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>


            {overlay ? <Overlay close={() => setOverlay(false)} >
              <>
                        
                        <div  className="item-end" style={{gridRow: "5/6",gridColumn: "2/3", marginLeft: "20px", alignSelf: "end", justifySelf:"end"}}>
                            <span>Clear Selected Items Here.</span>
                            <img src={arrow_down_right} alt="arrow" className="arrow" />
                        </div>
                        
                        <div style={{gridColumn: "3/5",gridRow: "6/7",alignSelf:"end", justifySelf: "start", marginLeft:"90px"}}>
                            
                            <span >Click Here to Scan to Receive the Package Label.</span>
                        </div>

                                                                     
                            <div style={{gridRow:"6/7", gridColumn:"5/-1", alignSelf:"end", marginLeft: "30px"}}>
                              
                                <span >Submit all Manually Selected Items by clicking here.</span>
                            </div>

                            <div style={{gridRow:"3/4", gridColumn:"2/6", alignSelf:"start", }}>
                              
                                <span >Click the Item you wish to Receive. It will be highlighted Orange when selected. </span>
                            </div>
                            
                            
                       
                        
                        <span className="close_overlay" onClick={closeOverlay} style={{gridColumn: "3/5", gridRow:"2/3",  fontFamily: "BenchNine", fontSize:"3rem",cursor:"pointer"}}>Click Here to Close</span>
        </> 
           </Overlay> : null}

        </InventoryReceiveWrapper>
    );

}
export default InventoryReceive;