import { useEffect, useReducer } from 'react';
import { getAPI } from '../utility';

export const useFetch = (url) => {
	
	const initialState = {
		status: 'idle',
		error: null,
		data: null,
	};

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'FETCHING':
				return { ...initialState, status: 'fetching' };
			case 'FETCHED':
				return { ...initialState, status: 'fetched', data: action.payload };
			case 'FETCH_ERROR':
				return { ...initialState, status: 'error', error: action.payload };
			default:
				return state;
		}
	}, initialState);

	useEffect(() => {
		let cancelRequest = false;
		if (!url || !url.trim()) return;

		const fetchData = async () => {
			dispatch({ type: 'FETCHING' });
			
				try {
					const response = await fetch(url, {
						method: 'GET',
						headers: {
							'x-functions-key': getAPI(),	
						}
					});
					
					
					const rslt = await response.json();
					let data = "";
					try {
						data = await(JSON.parse(rslt));
					} catch {
						data = rslt;
					}
					
					
					if (cancelRequest) return;
					dispatch({ type: 'FETCHED', payload: data });
				} catch (error) {
					if (cancelRequest) return;
					dispatch({ type: 'FETCH_ERROR', payload: error.message });
				}
			
		};

		fetchData();

		return function cleanup() {
			cancelRequest = true;
		};
	}, [url]);

	return state;
};

export default useFetch;