import React, {useState, useEffect} from 'react';
import Tabs from './tabs';
import Table from './table';
import styled from 'styled-components';



const SideListWrapper = styled.div`   
    font-family: 'BenchNine', sans-serif;
    font-weight: 700;
    font-size: 1.8rem;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .side_list__title {
        /* border-bottom: .2rem solid #fff; */
        padding: 1.1rem 1.4rem;
        // width: 100%;
        
    }

    .side_list__hr {
        border-bottom: .2rem solid #fff;
        padding: 0 1.4rem;
        width: 60%;
        margin-bottom: 2rem;
    }




`;


const SideList = (props) => {
    const [tabs, setTabs] = useState(['Active', 'InActive']);
    const [table_meta, setTableMeta] = useState([]);
  
    useEffect(() => {
        setTableMeta([
            {"col_width": "col-100", "data_label": "Dispenser","key": "problem"},
           
        ]);

    }, []);

    const selectItem = (e) => {
      
        props.select(e.target.id);
        
        
    }

    return(
        <SideListWrapper>
            <span className='side_list__title'>{props.title}</span>
            <Tabs  tabs={tabs} active={props.tab_change}></Tabs>
            <div className='side_list__hr'></div>
            <Table data={props.data} meta={props.meta} select={selectItem} row_class="table-row--side" include_header={false}></Table>
        </SideListWrapper>
    );
}
export default SideList;
