import React, {useState} from 'react';
import styled from 'styled-components';

const TabWrapper = styled.div`

    display: flex;
    flex-direction: column;
    align-items: space-around;
    justify-content: space-around;
    width: 100%;
 

    .tabs-body {
       font-size: 1.8rem;
       color: #fff;
       display: inline;
     
   }



   .tabs-header {

    display: flex;
    justify-content:space-around;
     width: 70%;
     list-style-type: none;
     margin:  1.5rem auto;

     .tabs li {
       
        color: ${props => props.isSelected ? 'var(--color-primary)' :  '#ECF0F1'};
        cursor: pointer;
     }


     .active {
         color: var(--color-primary);
     }

     .tab li > a {
       display: block;
       padding:  2rem;
       background: #212F3D;
       transition: all .2s ease-in;
       transform: skew(-40deg);
     }

     .tab li > a span {
       display: inline-block;
       transform: skew(40deg);
     }
     .tab li.active > a {
       background: #2471A3;
       color: var(--color-primary);
       
     }
   }

       

.anchor {
   pointer-events: none;
}

.tab:hover {
   color:#2471A3;
   transition: all 1s ;
    cursor: pointer;
   
}



`;

export default function Tabs(props) {
    
    const [activeTab, setActive] = useState("1");
    const [tab_data, setData] = useState(null);

    const changeTabOnClick = (e) => {
        
        let index = e.target.id;
        setActive(index);

        props.active(e);
    }

    return(
        <TabWrapper props>
            <div className="tabs-body">
                <ul className="tabs-header">
                    <li id="1" onClick={changeTabOnClick} className={`tab ${(activeTab === "1" ? 'active' : '')}`}>
                        <a className='anchor'><span>{props.tabs[0]}</span></a>
                    </li>
                    <li id="2" onClick={changeTabOnClick}  className={`tab ${(activeTab === "2" ? 'active' : '')}`}>
                        <a className='anchor'><span>{props.tabs[1]}</span></a>
                    </li>
                    {props.tabs.length > 2 ?
                    <li id="3" onClick={changeTabOnClick}  className={`tab ${(activeTab === "3" ? 'active' : '')}`}>
                        <a className='anchor'><span>{props.tabs[2]}</span></a>
                    </li>
                    : null}
                    {props.tabs.length > 3 ?
                    <li id="4" onClick={changeTabOnClick}  className={`tab ${(activeTab === "4" ? 'active' : '')}`}>
                        <a className='anchor'><span>{props.tabs[3]}</span></a>
                    </li>
                    : null}
                     {props.tabs.length > 4 ?
                    <li id="5" onClick={changeTabOnClick}  className={`tab ${(activeTab === "5" ? 'active' : '')}`}>
                        <a className='anchor'><span>{props.tabs[4]}</span></a>
                    </li>
                    : null}
                     {props.tabs.length > 5 ?
                    <li id="6" onClick={changeTabOnClick}  className={`tab ${(activeTab === "6" ? 'active' : '')}`}>
                        <a className='anchor'><span>{props.tabs[5]}</span></a>
                    </li>
                    : null}
                </ul>
            </div>
        </TabWrapper>
    );
}

