import React, {useEffect, useState} from 'react';
import Table from './table';
import styled from 'styled-components';
import FormHeader from './form-header';
import { URL } from '../constants';
import useFetch from '../hooks/useFetch';
import ReactLoading from 'react-loading';
import { getSession } from '../utility';
import { Toggle } from '../theme/toggle';
import { Tooltip } from 'react-tooltip';
import LabelTemplate from './label-position';


const DispenseReprintWrapper = styled.div`
        height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    z-index: 997;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .reprint_container { 
       position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        overflow-y: auto;
        height: 70vh;
        width: 70%;
        
       
        /* background-color: rgba(0,0,0,.6); */
        background-color: var(--color-grey-dark-2);
        z-index: 998;
        opacity: 1;
        transition: all .3s;
   
     min-height: 43.3rem;
     border: 2rem solid  var(--color-primary);
     border-radius: 5px;

     font-size: 1.8rem;
     color: navy;
     /* color: var(--color-primary); */

      }
    .receive_inventory__form_header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;
        margin-top: 2rem;
        padding-top: 1.7rem;
        border-top: var(--line);
        border-bottom: var(--line);
        padding-bottom: 1.7rem;
    }

    .receive_inventory__btn-bar {
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
    }

    .rcv_dsp {
        max-width: 25rem;
        padding: 1rem 2rem;
        margin-left: 7rem;
    }

    .active_dsp {
        text-transform: uppercase;
        font-family: 'BenchNine', sans-serif;
        font-size: 2.2rem;
        margin: 0 auto;
    }

    .recent__table {

        
    }

    .tog {
        margin-left: 1rem;
        margin-bottom: 1rem;
    }



`;

const DispenseReprint = (props) => {

  
    const [table_meta, setTableMeta] = useState([]);
    const [lblSaved, setLblSaved] = useState('');  
    const [toggle_label, setToggleLbl] = useState("Label");
    const [print_type, setPrintType] = useState('ZR');
    const [id, setID] = useState('');

    const [template, setTemplate] = useState(false);

    const [url, setURL] = useState('');
	
    const { status, data, error } = useFetch(url);

  
    useEffect(() => {
        setTableMeta([
            {"col_width": "col-15", "data_label": "Patient","key": "lastname"},
            {"col_width": "col-50", "data_label": "Drug","key": "ln"},
            {"col_width": "col-10", "data_label": "Qty","key": "qty"},
            {"col_width": "col-15", "data_label": "DOB","key": "dob"}
        ]);

        setURL(`${URL}dispenseReturnReprintLabels?cln=${getSession("cln")}`);

        if(getSession("lbl") === undefined || getSession("lbl") === null){
            setLblSaved('ZR');
            setPrintType('ZR');
        } else {

            setLblSaved(getSession("lbl"));
            setPrintType(getSession("lbl"));
        }

        

    }, []);

    useEffect(() => {
        console.log(data);
    },[data]);

    const selectRow = (e) => {
       
        console.log(e.target.id);
        
        if(e.target.id !== undefined) {

            setID(e.target.id);
           
            // let obj = {};
            // obj = data.find(x => x.idx.toString() === e.target.id.toString());
            // obj.lblType = print_type;

            // console.log(obj);
            if(lblSaved === 'LB') {         
                setTemplate(true);
            } else {
                let obj = {};
                obj = data.find(x => x.idx.toString() === e.target.id.toString());
                obj.lblType = print_type;
                
                console.log(obj);

                props.setLabel(obj);
            }
        
        }
        
    }

    const selectLabel = (position) => {

        setTemplate(false);


        let obj = {};
        obj = data.find(x => x.idx.toString() === id.toString());
        obj.lblType = print_type;
        obj.lblPosition = position;

        console.log(obj);

        props.setLabel(obj);
    }

    const handleToggleChange = (state) => {

        if(state === true){
          //label
            setPrintType('ZR');
            setToggleLbl("Label");
        
        } else {
          //monograph
          setPrintType('MO');
          setToggleLbl("Monograph"); 
        }
        
  
     
  
      }


   

    return(
       <DispenseReprintWrapper>

         <Tooltip id="rpr-tooltip" place="left" variant="info"  style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }}/>


         <div className="reprint_container">
         <FormHeader onClose={props.onClose} title="REPRINT DISPENSE" addClose={true} class_style="form_header--light"></FormHeader>
            <div className="tog"> {lblSaved === 'ZR' ?  <a data-tooltip-id="rpr-tooltip"  data-tooltip-content="Switch Between RePrint Label & Print Monograph"><Toggle label={toggle_label} toggled={true} onClick={handleToggleChange} /></a> : null}</div>

           <div className="recent__table">

            
                {status === 'error' && <div>{error}</div>}
				{status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
				{status === 'fetched' && (

                    <Table data={data} meta={table_meta} row_class="table-row" include_header="true" select={selectRow}></Table>
				)}

                
                       
            </div>
        

          
            </div>

            {template ? <LabelTemplate select={(pos) => selectLabel(pos)} close={() => setTemplate(false)} /> : null}

        </DispenseReprintWrapper>
    );

}
export default DispenseReprint;