import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AutoCompleteDropdown from '../theme/autocomplete-dropdown';
import ScanEntry from './scan-entry';
import { getSession, parseGS1Barcode  } from "../utility";
import { URL } from "../constants";

const NdcLotScanWrapper = styled.div`

margin-bottom: 2rem;

.ndc_lot_search {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ndc_lot_scan {
    cursor: pointer;
}

`;


const NdcLotScan = (props) => {

    const [scan, setScan] = useState(false);

    const [lots, setLots] = useState([]);

    const selectNDC = (val) => {
       
        setLots(val.LOTS);

        
    }

    const scanResult = (val) => {
        console.log('scan', val);
        if(val.length > 0) {
            const scan = parseGS1Barcode(val);
            let ndc = scan.ndc
            let lot = scan.lot;
            console.log(ndc);
            console.log(lot);
            
            props.select(ndc, lot);
        }
    }

    const selectLot = (val) => {
        console.log('lot',val);

        console.log(val.NDC);
        console.log(val.LOT);

        props.select(val.NDC, val.LOT);
    }

return(
    <NdcLotScanWrapper>
        <div  className="ndc_lot_search">
                 <AutoCompleteDropdown Label="LN"  url={`${URL}inventoryAutofill?cln=${getSession("cln")}&type=${'LN'}`}  data=""  select={(val) => selectNDC(val)} />
                 <AutoCompleteDropdown id="lot_input" Label="LOT" url="" data={lots}  select={(val) => selectLot(val)}/>
                
                 <div onClick={() => setScan(true)} className="ndc_lot_scan"><svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="40" width="40" viewBox="0 -980 960 960" ><path d="M40-120v-182h60v122h122v60H40Zm697 0v-60h122v-122h60v182H737ZM153-231v-499h80v499h-80Zm121 0v-499h42v499h-42Zm122 0v-499h83v499h-83Zm125 0v-499h121v499H521Zm163 0v-499h42v499h-42Zm83 0v-499h38v499h-38ZM40-658v-182h182v60H100v122H40Zm819 0v-122H737v-60h182v182h-60Z"/></svg></div>
        </div>

        {scan ? <ScanEntry title="SCAN BOTTLE BARCODE" submit={(val) => scanResult(val)} close={() => setScan(false)} /> : null}

    </NdcLotScanWrapper>
 );

}

export default NdcLotScan;

