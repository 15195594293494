import React, { useState } from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';


const PatientDuplicateWrapper = styled.div`

    z-index:9998;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
   
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .modal_container { 
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
       
        height: 29rem;
        width: 50vw;
        background-color: var(--color-grey-dark-2);
        opacity: 1;
        transition: all .3s;
        min-height: 36.3rem;
        border: 2rem solid  var(--color-primary);
        border-radius: 5px;

      }

      .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        margin-top: 3rem;
      }

      .modal-body {
        font-size: 2rem;
        color: navy;
        display: flex;
        flex-direction: column; 
        align-items: center;
        justify-content: center;
        margin-top: var(--line);
        margin-bottom: var(--line);
      }
     

      .modal-nav-timer {
        margin-bottom: 3rem;
        background-color:navy;
      }

      .order_responsive-table {
      max-height: 25rem;
      width:57vw;
      overflow-y: scroll;
      }
  
    li {
      border-radius: 3px;
      padding: .5rem .6rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: .75rem;
    }
    .order_table-header {
      background-color: var(--color-primary);
      font-size: 1.1rem;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      color: #fff;
    }
    .order_table-row {
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
      color: var(--color-primary);
      transition: all .9s;
      cursor: pointer;
      text-transform: uppercase;
      font-size:1.75rem;
      font-weight: 700;

      &:hover {
          background-color: rgb(243, 184, 162);
          color: var(--color-primary);
      }

      &__selected {
        background-color: rgb(247, 104, 9);
        color:#fff;
      }
    }

    .order-row--light__selected {
        background-color: rgb(247, 104, 9);
        color:#fff;
   }
    .col-5 {
        flex-basis: 5%;
        pointer-events: none;
      }
      .col-10 {
        flex-basis: 10%;
        pointer-events: none;
      }
      .col-15 {
        flex-basis: 15%;
        pointer-events: none;
      }
      .col-input {
        flex-basis: 10%;
        // pointer-events: none;
      }
      .col-20 {
        flex-basis: 20%;
        pointer-events: none;
      }
    .col-25 {
      flex-basis: 25%;
      pointer-events: none;
    }
    .col-30 {
      flex-basis: 30%;
      pointer-events: none;
    }
    .col-35 {
      flex-basis: 35%;
      pointer-events: none;
    }
    .col-40 {
      flex-basis: 40%;
      pointer-events: none;
    }
    .col-50 {
        flex-basis: 50%;
        pointer-events: none;
      }
      .col-60 {
        flex-basis: 60%;
        pointer-events: none;
      }
      .col-75 {
        flex-basis: 75%;
        pointer-events: none;
      }
      .col-100 {
        flex-basis: 100%;
        pointer-events: none;
        text-align: center;
      }

 .recent__table {
  width: 100%;
 }

 .dupes_name {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1rem;
        font-size: 2rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        color: #fff;
        text-align: center;
      

 }

 .dupes_firstname {
        flex-basis: 50%;
        text-align: center;
        margin-right: 1rem;
 }

 .dupes_lastname {

        flex-basis: 50%;
        text-align: center;
        margin-left: 1rem;
 }

 .dupes_dob {
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
 }

   

`;


const PatientDuplicate = (props) => {

    const cancel = () => {
        props.cancel();
    }

    const select = () => {
        props.select(); 
    }

     

    return(
        <PatientDuplicateWrapper>
          <div className="modal_container">
           <FormHeader onClose={props.close} title={props.title} addClose={false} class_style="form_header--light"></FormHeader>
           

            <div className="modal-body">
                <p>
                   This Patient appears to already exist in the database. 
                </p>
                <p>
                Please confirm the patient details below.
                </p>
               
                <div className="dupes">
                    <div className="dupes_name">
                        <div className="dupes_firstname">{props.firstName}</div>
                        <div className="dupes_lastname">{props.lastName}</div>
                    </div>
                        <div className="dupes_dob">{props.dob}</div>
                       
                </div>
               
            </div>
            <div className="modal-footer">
                <ButtonExpand title="CANCEL PATIENT SUBMIT"  click={cancel} />
                    
                
                <ButtonExpand title="CONTINUE PATIENT SUBMIT" click={select} />
                    
            </div>
            </div>
        </PatientDuplicateWrapper>
    );
}

export default PatientDuplicate;