import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import clearIcon from '../assets/clear-input.svg';


const AutoCompleteFormWrapper = styled.div`


.search-form {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 49rem;
            /* margin: 0 auto; */
.search-form__input {
    font-family: inherit;
    font-size: initial;
    background-color: var(--color-grey-light-4);
    border: none;
    color: inherit;
    padding: .7rem 2rem;
    border-radius: 100px;
    transform: all .3s;
    margin-right: -5rem;
    transition: all .5s;
    z-index: 10;
    width: 40rem;

    &:focus {
        outline: none;
        /* width: 80%; */
        background-color: var(--color-grey-light-3);
    }

    &::input-placeholder {
        font-weight: 100;
        color: var(--color-grey-light-4);
    }
}
    &__input:focus  {
        background-color: var(--color-grey-light-3);
    }

}

        .search-form:focus {
                outline: none;
                background-color: var(--color-grey-light-3);
            }
        .search-form::input-placeholder {
                font-weight: 100;
                color: var(--color-grey-light-4);
        }
        
        .search-form:focus  {
                background-color: var(--color-grey-light-3);
            }

 .fadein, .fadeout {
    opacity: 0;
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}
.fadein {
    opacity: 1;
}

.autoContainer {
    position: absolute;
    top: 100%;
    left:0;
    width:35rem;
    height: auto;
    background-color: navy;
    color:#fff;
   
    margin-left: 0;
   
    
}

.option {
    
    padding: 0.5rem;
    z-index: 299;
    width: 100%;
    cursor: pointer;
    &:hover {
        background-color: var( --color-primary-light);
        color: navy;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.PName {
    font-size: 1.75rem;
    font-weight: 700;
    margin-right: .5rem;
    width: 100%;
    border-bottom: var(--line-wht);
}

.PDOB {
    font-size: 1.5rem;
    font-weight: 400;
    margin-right: 1rem;
}

.left-inner-addon {
    position: relative;
    
}
.left-inner-addon input {
    display:flex;
    padding-right: 10px;
    width: 170px; /* 200px - 30px */
}
.left-inner-addon img {
    position: absolute;
    right: 0px;
    cursor: pointer;
    margin-top: .7rem;
    margin-right: 1rem;
    width:'30px';
}

#ato {
    width: 270px;
}


.keySelected {
    background-color: var( --color-primary-light);
    color: navy;
}


`;

const AutoCompleteForm = (props) => {

    const [display, setDisplay] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const wrapperRef = useRef(null);
    const [query, setQuery] = useState("");
    const [url, setUrl] = useState("");
    const [val, setVal] = useState("");

    const [patient, setPatient] = useState({});
    
    
    const { status, data, error } = useFetch(url);

    
    const [pos, setPos] = useState(0);
    const [x, setActive] = useState([]);



    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    
    useEffect(() => {
        if (query.length === 3) {
            setUrl(`${props.url}&srch=${query}`);
        }
        
     
    }, [query]);

    useEffect(() => {
       
       if (x.length > 0) {
          try { x[pos - 1].classList.add("keySelected"); } catch { console.log("error"); }
       }
   
      
   }, [pos]);

    useEffect(() => {
        if (status === "fetched") {
            
            if (data.length > 0) {
                setOptions(data);
                setDisplay(true);
            } else {
                setDisplay(false);
            }
           
        }
    }, [data]);

    const handleClickOutside = event => {
        const { current: wrap } = wrapperRef;
        if (wrap && !wrap.contains(event.target)) {
            setDisplay(false);
        }
    };

    const clearInput = () => {
        setSearch("");
        setDisplay(false);
        document.getElementById("ato").focus();

    }

    const updatePokemon = pokemon => {
      
        setSearch(pokemon.FullName);
        setPatient(pokemon);
        setDisplay(false);
        props.select(pokemon);
    };

    const handleSearch = (event) => {

        // onChange={event => setSearch(event.target.value)}
        let srch = event.target.value;
        setSearch(srch.toUpperCase());
        
        if(srch.length === 3) 
        {   
            
            //pass search string to azure function and wait for results
            setVal(srch.toUpperCase());
            setQuery(srch);
            return;
           
        } else if (srch.length < 3) {
           
            setDisplay(false);
           return;
        }
        else {
          
            setDisplay(true);
            return;
        }
    }

    const hideAutoContainer = () => {
        setDisplay(false);
    }

    const handleKeyDown = (e) => {

        let list_items = 0;
        let which = 0;  
        let list = "";
        try {list_items = document.getElementById("autoList").children.length; } catch {list_items = 0;}
       
        
         list = document.getElementById("autoList");
        if (list_items > 0 ) {
            setActive(list.children);
        }
        
            //ArrowUp && ArrowDown && Enter
            if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Enter") {
                // e.preventDefault();
                if (e.key === "ArrowUp") {
    
                 
                    
                    removeActive(list.children);
                    if (pos > list_items) {
                        setPos(0);
                          
                     } else if (pos < 0) {
                            setPos(0);    
                    
                     } else {
    
                        which = pos - 1;
                        setPos(which)
                     }
                      
                }
                if (e.key === "ArrowDown") {
                   
                        removeActive(list.children);
                        if (pos > list_items) {
                            setPos(0);
                                       
                        
                         } else if (pos < 0) {
                            setPos(0);
                         } else {
    
                            which = pos + 1;
                            setPos(which);
                         }
                        
                       
                    
                    
                }
                if (e.key === "Enter") {
                  
                    let ptt =  x[pos - 1].dataset.set;
                    let p = options.filter(({IDX}) => IDX.toString() === ptt);
                    setUrl("");
                    setPos(0);
                    setDisplay(false);
                    setSearch(p[0].FullName);
                    setPatient(p[0]);
                    props.select(p[0]);
    
    
                }
            }
        }
    
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
              x[i].classList.remove("keySelected");
            }
          }


    return(
        <AutoCompleteFormWrapper>
            <div className="search-form">
               <div className="left-inner-addon">
                    <input  type="search" name="whatever"  id="ato" className="form_input--light" placeholder={props.Label} autoComplete="new-password" onKeyDown={(e) => handleKeyDown(e)}   onChange={(event) => handleSearch(event)} value={search}  />
                    {/* <img onClick={clearInput} role="img"  src={clearIcon} /> */}
                </div>
            {display && (
                <div id="autoList" className="autoContainer">
                    {options
                        .filter(({ FullName }) => FullName.indexOf(search.toUpperCase()) > -1)
                        .map((value, i) => {
                            return (
                                <div onClick={() => updatePokemon(value)} data-set={value.IDX}className="option" key={i} tabIndex="0">
                                    <div className="PName">{value.FullName}</div>
                                  
                                </div>
                            );
                        })}
                   
                    
                </div>
                
            )}

               
            </div>
        </AutoCompleteFormWrapper>
    );
            
}

export default AutoCompleteForm;
