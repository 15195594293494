import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';
import UserName from './username';
import Login2Auth from './login-2auth'
import Password from './password';
import { isValidString,getSession, getAPI  } from '../utility';
import { URL } from '../constants';
import { UserAgent } from './login-usr-agent';  

const LogInUsrWrqpper = styled.div`
    
    z-index:9999;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
   
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .login-usr_container { 
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: white;
        height: 50vh;
        width: 40vw;
        background-color: var(--color-grey-dark-2);
        opacity: 1;
        transition: all .3s;
        min-height: 36.3rem;
        border: 2rem solid  rgba(15, 215, 239, 1);
        border-radius: 5px;
        margin-right:3rem;

        @media only screen and (max-width: 1200px) {
            width: 60vw;
      } 
    }

      .login-header {
        font-size: 2rem;
        margin-left: 3rem;
      }

      .login-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 3rem;
        margin-right: 3rem;
      }

      #cln_container {
        width: 255px;
        position: relative;
        display: flex;
        align-items: center; 
        justify-content: center;
        margin: 0 auto;
        margin-top: 2rem;
       
       
      }

      #cln {
        font-family: inherit;
        color:navy;
        font-size: 1.75rem;
        padding: .7rem 2rem;
        border-radius: 2px;
        border: none;
        border-bottom: 3px solid var(--color-primary);
        transition: all .5s;
        outline: none;
        background-color: var(--color-grey-dark-2);
        margin-left: 2rem;
      }

      #error_list {
        margin-right: 10rem;
      }

      .login-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }

`;

const LogInUsr = (props) => {

    
    const [auth, setAuth] = useState(false);
    const [show_pwd, setShowPwd] = useState(false);

    const [grp, setGrp] = useState('');
    const [cln, setClinic] = useState('');
    const [usr, setUsr] = useState('');
    const [IP, setIP] = useState('');
    const [pwd, setPwd] = useState('');
    const [device, setDevice] = useState('');
    const [IDX, setIDX] = useState('');
    const [clinics, setClinicList] = useState([]);
    const [valid_err, setValErr] = useState([]);

    const [TWOFA, setTWOFA] = useState(true);

    useEffect(() => {
         retrieveUserIP();
         let sess = localStorage.getItem('session');
          if(sess !== null && sess !== undefined) {
            let session = JSON.parse(sess);
            
          }
          let dvc = localStorage.getItem('device');
          if (dvc !== null && dvc !== undefined) {
            let dc = JSON.parse(dvc);
            setDevice(dc.deviceToken);
            setClinic(dc.CLN_IDX)
            setGrp(dc.GRP_IDX);
          }

          
    }, []);

    const selectPwd = (pwd) => {
        console.log(pwd);
        setPwd(pwd);
    }

   
    const submit = () => {  
       
        
        const errors = [];
        setValErr([]);

        let obj = {};
        obj.usr =  isValidString(usr) ? usr : errors.push("User Name Is Required."); //*usr;
        obj.pwd = isValidString(pwd) ? pwd : errors.push("Password Is Required."); //*pwd;
        obj.ip = IP;
        obj.device = device;
        obj.grp = grp;
        obj.idx = IDX;
        obj.cln =isValidString(cln) ? cln : errors.push("Clinic Is Required."); 

        console.log(obj);
        let json = JSON.stringify(obj);
        console.log(json);
        console.log(errors.length);
 
        if (errors.length > 0) {
            setValErr(errors);

        } else {

          // if (response.ok === false) {  throw new Error('Network response was not ok.') };
          //POST DATA
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
            body: json
            };
            fetch(`${URL}authLoginUser`, requestOptions)
                .then(response => response.ok ? response :  (function(){throw new Error('Network response was not ok.')}()))
                .then( response => response.json())
                .then(data => {
                    let rslt = "";
                    try {
                      rslt = (JSON.parse(data));
                    } catch {
                      rslt = data;
                    }

                    console.log(rslt);
 
                    if(rslt === "User Credentials are NOT VALID.") {
                        setPwd('');
                        setClinic('0');
                        errors.push("User Credentials are NOT VALID. Please Try Again.");
                        setValErr(errors);
                    } else if(rslt.ERROR_MSG === "DEVICE IS NOT VALID.") {
                        localStorage.setItem('session', JSON.stringify(rslt));
                       TWOFA ? setAuth(true) : manualDeviceSetup();
                      
                    } else {

                      localStorage.setItem('session', JSON.stringify(rslt));

                      let d = rslt.device;
                     
                      localStorage.setItem('device', d);

                      UserAgent();

                      setAuth(false);
                      props.close(rslt.UserFullName);

                    // if(rslt.device === undefined) {
                    //     setAuth(true);
                       
                    // } else {
                    //     setAuth(false);
                    //     props.close();
                    //}

                    }
        
                    
                })            
                .catch(err => {
                  console.log(err);
                  errors.push("There was a problem logging in. Please Try Again.");
                  setValErr(errors);

                });
        
            }
      
    }

    const manualDeviceSetup = () => {

      
      let obj = {};
        obj.ip = IP;
        obj.usr = IDX;
        obj.cln = cln;

        console.log(obj);
        let json = JSON.stringify(obj);

        //POST DATA
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
            body: json
            };
            fetch(`${URL}authConfirmDevice`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    let rslt = "";
                    try {
                      rslt = (JSON.parse(data));
                      console.log(rslt);
                      localStorage.setItem('session', JSON.stringify(rslt));
                      let d = rslt.device;

                      UserAgent();
                      
                      localStorage.setItem('device', d);
                      props.close();

                    } catch {
                      rslt = data;
                     
                    }
                   
                })            
                .catch(err => {
                    console.log(err);
                });

    }

    const retrieveUserIP = () => {
        fetch(`https://geolocation-db.com/json/`)
        .then(res => res.json())
        .then(json => setIP(json.IPv4));
    }

    //check if browser tab is open in another window
   
    const checkUser = (usr) => {
        const errors = [];
        setValErr([]);
            
        if(usr === '0') {
                
                console.log('no user');
                errors.push("User Name does not exist. Please try again.");
                setValErr(errors);
                setShowPwd(false);
        } else {

            console.log(usr);
            setShowPwd(true);
            setUsr(usr.UserName);
            setGrp(usr.GRP_IDX);
            setIDX(usr.USR_IDX)
            setClinicList(usr.ClinicList);
        }

    }

    const completeLogin = () => {
        
        setAuth(false);
        props.close(getSession("UserFullName"));
    }

    const getClinics = () => {
        
            try {
             
                if (Array.isArray(clinics)) {
                  return clinics.map((item, index)=>{
                   
                      return (
                           
                       <option key={item.CLN_IDX} value={item.CLN_IDX}>{item.Clinic}</option>
                        
                       );
                  });
              } else {
                return (
                   
                       <option></option>
                   
                );
              }
              } catch (err) {
                return;
              }
            
        
        }

        const err = () => {
            try {
                if (Array.isArray(valid_err)) {
                  return valid_err.map((item, index)=>{
                      return (
                            <ul key={index}>
                                  <li className="err_list">{item}</li>
                                 
                            </ul>
                       );
                  });
              } else {
                return (
                  <tr><td colSpan='8'><div></div></td></tr>
                );
              }
              } catch (err) {
                return;
              }
        }
    
        
 
    return (
        <LogInUsrWrqpper>
            <div className="login-usr_container">
            <FormHeader title="LOGIN TO CUSTOM RX" addClose={false} class_style="form_header--light"></FormHeader>
                <h1 className="login-header">WELCOME BACK</h1>
               <div className="login-form">
                <UserName select={(usr) => checkUser(usr)}></UserName>
                { show_pwd ? <Password pwd={(pwd) => selectPwd(pwd)}  passcode={pwd}></Password> : null}

                { show_pwd ?
                    <div id="cln_container">
                        <select id="cln" onChange={e => setClinic(e.target.value)}  value={cln} className="form_input req">
                            <option value="0">SELECT CLINIC</option>
                            {getClinics()}
                        </select>
                        
                    </div>
                : null}
                 { show_pwd ?
                  <div className="login-footer">
                      <div id="error_list" >{err()}</div>
                      <ButtonExpand title="LOGIN" click={submit} />
                  </div>
                : null}
                </div>
            </div>

            { auth ? <Login2Auth cln={cln} usr={IDX} ip={IP} close={completeLogin}></Login2Auth> : null}
           
        </LogInUsrWrqpper>
    );
}

export default LogInUsr;







