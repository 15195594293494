import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';
import InputMask from 'react-input-mask';
import Toast from './toaster';
import AutoCompleteForm from '../theme/autocomplete-form';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import { getAPI } from '../utility';


const ProblemAddWrapper = styled.div`
    width: 60rem;
    height: 40rem;
    margin: auto;
    background-color: var(--color-grey-dark-2);
    color: #fff;
    
    border: 2rem solid  rgba(15, 215, 239, 1);
    border-radius: 5px;
    
   
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    font-size: 2rem;
    white-space: nowrap;
    box-shadow: 0 1rem 3rem rgba(#fff, .2);

    .prb-mini-form {
       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: 1fr 1fr;
       grid-gap: 2rem;
       padding-top: 1rem;
       padding-bottom: 1rem;
       font-size: 2rem;
       margin-left: 2rem;
       margin-right: 2rem;
     }
     .prb-btn-bar {
       display: flex;
       justify-content: flex-end;
       margin-right:2rem;
       margin-top:3rem;
     }
     #problem-container {
        grid-column: 1 / -1;
        
    }

    #provider-container {
        grid-column: 1 / 2;
    }

    #onset-date-container {
        grid-column: 2 / 3;
    }

     
`;

const ProblemAdd = (props) => {

    const [valid_err, setValErr] = useState([]);
    
    const [onsetDate, setOnsetDate] = useState('');
    const [problem, setProblem] = useState('');
    const [provider, setProvider] = useState('');
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");
    const [disable, setDisable] = useState(false);

    
    const submit = () => {

        setDisable(true);

        setValErr([]);
        let errors = [];

        let data = {
            PAT_IDX: props.ptt,
            pat_code_system: "ICD9CM",
            pat_code_version: "9CM",
            pat_code_value: problem.ICD9CM,
            pat_problem_desc: problem.FullName,
            pat_onset_date: onsetDate,
            pvd_name: provider,
            prb_source: "CRx"
           
        }
        console.log(data);
        let json = JSON.stringify(data);
        

        //POST DATA
        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
        body: json
        };
        fetch(`${URL}problemInsert`, requestOptions)
        .then (response => {
            if (response.ok === false) {  throw new Error('Network response was not ok.') };
                setValErr([]);
                setToastIcon("success");
                setToastMsg("Patient Problem Added Successfully");
                setToastState(true);
                setToastBackground("#5cb85c");
                setToastTitle("Success");
                setDisable(false);

                props.select();
            })            
            .catch(err => {
                setToastIcon("error");
                setToastTitle("Error");
                setToastBackground("#d9534f");
                setToastMsg("There was a Problem Adding the Patient Problem");
                setToastState(true);
                setDisable(false);
            });

    }

    const selectProblem = (prb) => {

        console.log(prb);
        setProblem(prb);
    }


    return (
        <ProblemAddWrapper>
                            
                    <FormHeader onClose={props.close} title="ADD PATIENT PROBLEM"addClose={true} class_style="form_header--light" ></FormHeader>
                    <div className="prb-mini-form">
                        <div id="problem-container">
                                <AutoCompleteForm Label="PROBLEM DESCRIPTION" id="prbl" maxLength="35"  required autoComplete="off" select={(prb) => selectProblem(prb)}   url={`${URL}autocompleteProblem?`}/>
                                    
                                   
                        </div>
                                <div id="provider-container">
                                    <input type="text" className="form_input--light req" placeholder="Provider" id="provider" maxLength="35" required autoComplete="off"  onChange={(e) => setProvider(e.target.value)}/>
                                    <label for="provider" className="form_label">Provider</label>
                                </div>
                                <div id="onset-date-container">
                                        <InputMask type="tel" className="form_input--light req" mask="99/99/9999" placeholder="Onset Date"   id="onsetDate" autoComplete="off" required onChange={(e) => setOnsetDate(e.target.value)} /> 
                                        {/* <label for="onsetDate" className="form_label">Onset Date</label> */}
                                </div>
                    </div>
                    <div className="prb-btn-bar"> {disable ? <div></div> :<ButtonExpand title="SUBMIT" click={submit} />}</div>
                
                    <div className="toasty">
                        {toast ?  <Toast 
                            id={74}
                            position={'bottom-center'}
                            autoDelete={true}
                            autoDeleteTime={3000}
                            title={toast_title}
                            description={toast_msg}
                            backgroundColor={toast_background}
                            icon={toast_icon}
                            close={() => setToastState(false)}

                        /> : null }
                    </div>
                    </ProblemAddWrapper>
    );
};

    export default ProblemAdd;