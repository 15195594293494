import { useRef } from "react";
import {
    Document,
    Page,
    Font,
    Text,
    View,
    StyleSheet,
    PDFViewer,
  } from "@react-pdf/renderer";
import {URL} from '../constants';
import useFetch from '../hooks/useFetch';
import BenchNine from './fonts/BenchNine/BenchNine-Regular.ttf';
import ReactLoading from 'react-loading';
import useResizeObserver from 'use-resize-observer';
import { getSession } from '../utility';



Font.register({
  family: "BenchNine",
  format: "truetype",
  src: BenchNine,
})

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 40
const COLN_WIDTH = (100 - COL1_WIDTH) / 6


const styles = StyleSheet.create({
  body: {
    padding: 10,
    fontFamily: "BenchNine",
  },
  viewer: {
   
    width: window.innerWidth / 1.78,
    height: window.innerHeight / 1.60,
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderRightWidth: 1, 
    borderBottomWidth: 1 
  }, 

  tableRow: { 
    margin: "auto", 
    flexDirection: "row",
    backgroundColor: "#E0F9FF"
  
  }, 

  tableHeaderRow: { 
    margin: "auto", 
    flexDirection: "row",
    backgroundColor: "#FFF3D4"
  
  }, 

  tableBreakRow: { 
    margin: "auto", 
    flexDirection: "row",
    backgroundColor: "transparent"
  
  }, 

  tableTitleRow: {
    marginLeft: "12%",
    flexDirection: "row",
    backgroundColor: "#FFFFFF"
  },
  tableCol1Title: { 
    width: '33%', 
    
  }, 

  tableCol2Title: { 
    width: "33%" 
    
  }, 

  tableCol3Title: { 
    width: "33%"
    
  }, 


  'tableRow:nth-child(odd)': {
    backgroundColor: "#E0F9FF"
  },

  'tableRow:nth-child(even)': {
    backgroundColor: "#CDCDCD"
  },

  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    borderRightWidth: 0
  },  
  tableNoReportHeader: {
    width: '100%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
    
},   
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    borderRightWidth: 0
  },   
  tableBreakHeader: {
    width: "90%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 0, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  

  tableColClinic: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },   
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },   
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: 5, 
    fontSize: 12,
    fontWeight: 500
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  }
});

const CurrentInventoryReport = (props) => {

  const ref = useRef(null);
  const { width = 1, height = 1 } = useResizeObserver<HTMLDivElement>({ ref, box: "border-box" });

  const query = getSession("cln");
   

    const url = query && `${URL}inventorySnapshotReport?idx=${getSession("cln")}`;
    const { status, data, error } = useFetch(url);
    
     function data_table(which) {
        try {
           
           let d =  data.filter((item) => item.type === which);
            
            if (Array.isArray(d)) {
              return d.map((item, index)=>{
               
                  return (
                    <View key={index} style={styles.tableRow}> 
                    <View style={styles.tableCol1Header}> 
                      <Text style={styles.tableCellHeader}>{item.ln}</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>{item.ndc}</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>{item.lot}</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>{item.qty}</Text> 
                    </View>
                    <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>{item.expDate}</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>{item.inv}</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>{item.totalStock}</Text> 
                    </View>
                  </View>
                    
                );
              });
          } else {
            return (
              <View style={styles.tableNoReportHeader}> 
                <Text style={styles.tableCellHeader}>No Data To Report</Text> 
              </View> 
            );
          }
          } catch (err) {
            return;
          }
        
    }
  

      return (
        <>
        {status === 'error' && <div>{error}</div>}
	      {status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
        {status === 'fetched' && (
          <div ref={ref}>
          
        <PDFViewer download style={styles.viewer} className="react-pdf__Page__canvas">
            <Document>
            <Page style={styles.body}>
                <View style={styles.table} > 
                        <View style={styles.tableTitleRow}> 
                            <View style={styles.tableCol1Title}> 
                            <Text style={styles.tableCellHeader}>Inventory Snapshot</Text> 
                            </View> 
                            <View style={styles.tableCol2Title}> 
                            <Text style={styles.tableCellHeader}> {`${getSession("cln_display")}`}</Text> 
                            </View> 
                            <View style={styles.tableCol3Title}> 
                            <Text style={styles.tableCellHeader}> {`Print Date: ${props.print_date}`}</Text> 
                            </View> 
                            
                        </View>
                        <View style={styles.tableBreakRow}> 
                            <View style={styles.tableBreakHeader}> 
                                <Text style={styles.tableCellHeader}>CONTROLLED INVENTORY</Text> 
                            </View>
                        </View> 
                        <View style={styles.tableHeaderRow}> 
                            <View style={styles.tableCol1Header}> 
                               <Text style={styles.tableCellHeader}>DRUG</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                               <Text style={styles.tableCellHeader}>NDC</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                                <Text style={styles.tableCellHeader}>LOT</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                                <Text style={styles.tableCellHeader}>UNIT QTY</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                                <Text style={styles.tableCellHeader}>EXP DATE</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                                <Text style={styles.tableCellHeader}>Current Inv.</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                               <Text style={styles.tableCellHeader}>Total Inv.</Text> 
                            </View> 
                        </View>
                        {data_table('Controlled')}
                        <View style={styles.tableBreakRow}> 
                            <View style={styles.tableBreakHeader}> 
                                <Text style={styles.tableCellHeader}>NON-CONTROLLED INVENTORY</Text> 
                            </View>
                        </View> 
                        <View style={styles.tableHeaderRow}> 
                            <View style={styles.tableCol1Header}> 
                            <Text style={styles.tableCellHeader}>DRUG</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>NDC</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>LOT</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                                <Text style={styles.tableCellHeader}>UNIT QTY</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>EXP DATE</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>Current Inv.</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>Total Inv.</Text> 
                            </View> 
                        </View>
                        {data_table("Non-Controlled")}
                        <View style={styles.tableBreakRow}> 
                            <View style={styles.tableBreakHeader}> 
                                <Text style={styles.tableCellHeader}>OTC INVENTORY</Text> 
                            </View>
                        </View> 
                        <View style={styles.tableHeaderRow}> 
                            <View style={styles.tableCol1Header}> 
                            <Text style={styles.tableCellHeader}>DRUG</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>NDC</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>LOT</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                                <Text style={styles.tableCellHeader}>UNIT QTY</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>EXP DATE</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>Current Inv.</Text> 
                            </View> 
                            <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>Total Inv.</Text> 
                            </View> 
                        </View>
                        {data_table("OTC")}
                        
                </View>
            
            
            
            </Page>
            </Document>
            </PDFViewer>
            </div>  
            )}
            
         </> 
      );

}

export default CurrentInventoryReport;