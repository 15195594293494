import React from 'react';
import styled from 'styled-components';
import Tooltip from '../theme/tooltip';

const FooterWrapper = styled.div`
    
    // padding: 1rem;
    font-size: 1.2rem;
    background-color: var(--color-dark-grey-2);
    color:#fff;
    display:flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 8rem;
    
 
    &_navigation {
        display: inline-block;
       

    }

    &_list {
        list-style: none;
        border-top: 1px solid  var(--color-primary);
        border-bottom: 1px solid  var(--color-primary);
    }

    &_item {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 1.5rem;
        }
    }

    &_link {
       &:link,
       &:visited {
           color: var(--color-dark-grey-1);
           background-color:  var(--color-dark-grey-3);
           text-decoration: none;
           text-transform: uppercase;
           display: inline-block;
           transition: all .2s;
       } 
       &:hover,
       &:active {
           color: var(--color-primary);
           box-shadow: 0 1rem 2rem rgba(#000, .4);
           transform: rotate(0deg) scale(1.3);
       }
    }

    .copyright {
        padding-top:  .7rem;
        width: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
}


`;

const Footer = () => {
  return (
    <FooterWrapper>
         <Tooltip top="top" tooltip="Information"></Tooltip>
      
{                           /*        
                            <ul className="footer_list">
                                <li className="footer_item"><a href="#" className="footer_link">Company</a></li>
                                <li className="footer_item"><a href="#" className="footer_link">Contact us</a></li>
                                <li className="footer_item"><a href="#" className="footer_link">Privacy Policy</a></li>
                                <li className="footer_item"><a href="#" className="footer_link">Careers</a></li>
                                <li className="footer_item"><a href="#" className="footer_link">Terms</a></li>
                            </ul> */}
                      
                          {/* <p className="copyright">&copy; Copyright 2020 Temprano Software Solutions Inc.</p> */}
      
    </FooterWrapper>
  );
}

export default Footer;