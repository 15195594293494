import React, { useState, useEffect, startTransition } from 'react';
import styled from 'styled-components';
import count_0 from '../assets/count_0.png';
import count_1 from '../assets/count_1.png';
import count_2 from '../assets/count_2.png';
import count_3 from '../assets/count_3.png';
import count_4 from '../assets/count_4.png';
import count_5 from '../assets/count_5.png';
import count_6 from '../assets/count_6.png';
import count_7 from '../assets/count_7.png';
import count_8 from '../assets/count_8.png';
import count_9 from '../assets/count_9.png';

import count_colon from '../assets/count_colon.png';

const SessionTimerWrapper = styled.div`
     
     margin-top: .5rem;

`;

          

const SessionTimer = (props) => {

    const arr = [count_0,count_1,count_2,count_3,count_4,count_5,count_6,count_7,count_8,count_9];

  
    const [min1, setMin1] = useState(arr[0]);
    const [min2, setMin2] = useState(arr[0]);
    const [sec1, setSec1] = useState(arr[0]);
    const [sec2, setSec2] = useState(arr[0]);

   

    let timer = 0;

    useEffect(() => {
         resetTimer();

    }, []);

    const resetTimer = () => {

        
        startTimer();
    }

    const startTimer = () => { 
  
            if (timer === 0) {
               timer = setInterval(countdown, 1000);
         
            }
         
    }

    const timeout = () => {
        clearInterval(timer);
        timer = 0;
        props.on_timeout();
    }

    // const secondsToTime = (secs) => {
     
    //   let hours = Math.floor(secs / (60 * 60));

    //   let divisor_for_minutes = secs % (60 * 60);
    //   let minutes = Math.floor(divisor_for_minutes / 60);

    //   let divisor_for_seconds = divisor_for_minutes % 60;
    //   let seconds = Math.ceil(divisor_for_seconds);

    //   let obj = {
    //     'h': hours,
    //     'm': minutes,
    //     's': seconds,
    //   };

    //   return obj;
    // }

    const countdown = () => {
        // Remove one second, set state so a re-render happens.
     
      let seconds = parseInt(localStorage.getItem('session_seconds'));

      if(seconds < 10000) {
      let second = seconds - 1;
      if (second >= 0) {
            localStorage.setItem('session_seconds', second.toString());
      
            let divisor_for_minutes = second % (60 * 60);
            let minutes = Math.floor(divisor_for_minutes / 60);
            let divisor_for_seconds = divisor_for_minutes % 60;
            let secs = Math.ceil(divisor_for_seconds);

            let min1;
            let min2;
            let sec1;
            let sec2;

                if (minutes < 10) {
                    min1 = arr[0];
                    min2 = arr[minutes];
                } else {
                    min1 = arr[parseInt(minutes.toString().charAt(0))];
                    min2 = arr[parseInt(minutes.toString().charAt(1))];
                }

                if (secs < 10) {
                    sec1 = arr[0];
                    sec2 = arr[secs];
                } else {
                    sec1 = arr[parseInt(secs.toString().charAt(0))];
                    sec2 = arr[parseInt(secs.toString().charAt(1))];
                }  

                //set state variables
                setMin1(min1);
                setMin2(min2);
                setSec1(sec1);
                setSec2(sec2);
    } else {
        timeout();
    }
}

    };




    return(
        <SessionTimerWrapper>
               <div className="user-nav__counter">
                    <img src={min1} alt="1" className="header__count1"/>
                    <img src={min2} alt="2" className="header__count2"/>
                    <img src={count_colon} alt=":" className="header__count_colon"/>
                    <img src={sec1} alt="3" className="header__count3"/>
                    <img src={sec2} alt="4" className="header__count4"/>
                </div>
        </SessionTimerWrapper>
    );
};

export default SessionTimer;

