import React, {useState, useEffect} from 'react';
import InputMask from 'react-input-mask'
import LoadingButton from '../theme/loading-button';
import FormHeader from './form-header';
import {validateDate,validatePhone, isValidString,  isSSN, isPhone, isZip5, getSession, getAPI } from '../utility';
import {us_states, mx_states, cn_states, prefix, suffix} from '../constants';
import styled from 'styled-components';
import useDLParse from '../hooks/useDLParse';
import ScanEntry from './scan-entry';
import Toast from './toaster';
import ReactLoading from 'react-loading';
import { URL } from '../constants';
import moment from 'moment';
import Overlay from '../theme/overlay';
import arrow_down_right from '../assets/arrow_down_right.png';
import arrow_up from '../assets/arrow_up.png';
import arrow_down from '../assets/arrow_down.png';
import PatientDuplicate from './patient-duplicate-warning';

const PatientAddWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 998;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }


    .patient_new_content {
        height: 85vh;
        width: 85vw;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 999;
        opacity: 1;
        transition: all .3s;
        background-color:  rgba(0,0,0, 0.6); 
        backdrop-filter: blur(19px);
        -webkit-backdrop-filter: blur(19px);
        border-radius: 10px;
        border: 1px solid rgba(255,255,255,0.6);
        font-size: 2rem;
    }

    .patient_new__header {
        grid-row: 1/-1;
        display: flex;
        align-items: center;
    }
  
    .patient_new_close {
           
            text-decoration: none;
            transition: all .3s;
            line-height: 1;
            margin-right: 2rem;
            cursor: pointer;
            fill: var(--color-primary);
            transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

         .patient_new_close :hover {
                fill: red;
            }

        

        &:hover {
        color:var(--color-primary);
        }
    }

    .patient_new_dob {
        grid-row: 3/4;
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        padding: .5rem 2rem;
        align-items: center;
        justify-content: center;
    }

    #gender_container {
        margin-top: 0;
        grid-column: 4/-1;
    }

    .patient_new_name {
        grid-row: 2/3;
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 2rem;
        align-items: center;
        justify-content: center;
        padding:.5rem 2rem;
       
    }
    #prefix_container {
       max-width: 10rem;
    }

    #firstname_container {
        grid-column: 1/2;
    }

    #lastname_container {
        grid-column: 2/3;
    }

    #middlename_container {
        grid-column: 3/4;
    }

    #suffix_container {
        grid-column: 4/-1;
    }

    .patient_new_ssn {
        grid-row: 4/5;
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        align-items: center;
        justify-content: center;
        padding: .5rem 2rem;
    }

    #phone_container {
        grid-column: 1/2;
    }

    #dl_container {
        grid-column: 2/3;
    }
    #dl_state_container {
        grid-column: 3/4;
    }

    #dl {
        margin-top: 0;
        margin-left:1.5rem;
        max-width:25rem;
    }

    #dl_state {
        margin-top: 0;
        margin-left:1.5rem;
        max-width:20rem;
    }

    #ssn_container {
        grid-column: 4/-1;
    }

    .patient_new_use_default {
        grid-row: 5/6;
        grid-column: 1/-1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: .5rem 2rem;
    }

    .patient_new_address {
        grid-row: 6/7;
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        align-items: center;
        justify-content: center;
        padding: .5rem 2rem;
    }

    #address1_container {
        grid-column: 1/2;
    }

    #address2_container {
        grid-column: 4/-1;
    }

   
    .patient_new_country {
        grid-row: 7/8;
        grid-column: 1/-1;
        display: grid;
        grid-gap: 1.5rem;
        grid-template-columns:.5fr .5fr .5fr .73fr;
        align-items: center;
        justify-content: center;
        padding: .5rem 2rem;
        width:100%
    }

    #country_container {
        grid-column: 1/2;
        margin-top:0;
    }

    #state_container {
        grid-column: 3/4;
        margin-top: 0;
    }

    #city_container {
        grid-column: 2/3;
    }

    #zip_container {
        grid-column: 4/-1;
    }

    .patient_new__btn-bar {
        grid-row: 8/9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 1rem;
    }

    .patient_new__header {
        grid-row: 1/2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: var(--line);
        padding: 2rem;
    }
    .patient_new__title {
        font-size: 2rem;
        color: var(--color-primary);
        margin-left: 2rem;
        

    }
   

    .patient_new_left {
        width: 33.33333%;
        display: table-cell;
    }

    .patient_new_right {
        width: 66.6666667%;
        display: table-cell;
        vertical-align: middle;
        padding: 3rem 5rem;
    }

    .patient_new_img {
        display: block;
        width: 100%;

    }

    .patient_new_text {
        font-size: 1.4rem;
        margin-bottom: 4rem;

        column-count: 2;
        column-gap: 4rem;
        column-rule: 1px solid var(--color-grey-dark-2);

        -moz-hyphens: auto;
        -ms-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }

  
`;

const LoadingButtonWrapper = styled.div`
    .ctn {
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
  }
  #check {
    display: none;
  }

  #check2 {
    display: none;
  }
  .btn-label {
   
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5rem;
    font-family: 'BenchNine', serif;
    font-size: 2rem;
    color: #fffeee;
    margin-top: 20%;
    height: 50px;
    width: 200px;
  }
  .btn-text-wait {
    font-family: 'BenchNine', serif;
    font-size: 1.6rem;
    color: #fffeee;
  }
  .load {
    display: none;
    width: 20px;
    height: 20px;
    border: 5px solid #fff;
    border-radius: 100%;
  }
  .open {
    border-top: 5px solid transparent;
    -webkit-animation: load-animate infinite linear 1s;
            animation: load-animate infinite linear 1s;
  }
  // #check:checked ~ .btn-label > .btn-text {
  //   display: none;
  // }
  #check:checked ~ .btn-label > .load {
    display: inline-block;
  }
  @-webkit-keyframes load-animate {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      opacity: 0.35;
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes load-animate {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      opacity: 0.35;
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.btn-label--normal_light {
  background-color: #fff;
  color: var(--color-primary);
}


.btn-label--normal {
  background-color: var(--color-primary);
  transition: all .8s;

  &:hover {
    background-color:var(--color-grey-dark-1);
  }
}

.btn-label--success {
  background-color:rgb(12, 243, 31);
}

.btn-label--error {
  background-color:rgb(243, 12, 23);
}


.lds-facebook {
  display: inline-block;
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  z-index:99;
  margin-left: -3rem;
  margin-top: -5rem;
  
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  
}
.lds-facebook div:nth-child(1) {
  left: 0;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 2.4rem;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 4.8rem;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

`;


const PatientAdd = (props) => {

    const [states, setStates] = useState(us_states);
    const [ptt, setPTT] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [dob, setDOB] = useState('');
    const [dl, setDL] = useState('');
    const [dl_state, setDLState] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('0');
    const [ssn, setSSN] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [country, setCountry] = useState('US');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [_suffix, setSuffix] = useState('');
    const [email, setEmail] = useState('');
    const [_prefix, setPrefix] = useState('');

    const [valid_err, setValErr] = useState([]);
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");

    const [dl_data, setDLData] = useState('');
    const[scan, setScan] = useState(false);
    const[reset, setReset] = useState(false);

    const [disable, setDisable] = useState(false);

    const [overlay, setOverlay] = useState(false);

    const [dupes, setDupes] = useState([]);
    const [showDupes, setShowDupes] = useState(false);

    const [prse, setPrse] = useState(false);
    const { parse_status, parse, parse_error } = useDLParse(dl_data);

  
    useEffect(() => {
       
        let start = document.getElementById("firstname");
        start.focus();
        setReset(false);

    
    }, []);


   

    useEffect(() => { 

      console.log(parse_status);

        if (parse_status === 'parsed') {

          console.log(parse);

          setFirstName(parse.DAC);
          setLastName(parse.DCS);
          setMiddleName(parse.DAD);
          setDOB(parseDOB(parse.DBB));
          setGender(parseGender(parse.DBC));
          setAddress1(parse.DAG ? parse.DAG : '');
          setAddress2(parse.DAH ? parse.DAH : '');
          setCountry('US');
          setState(parse.DAJ ? parse.DAJ : '');
          setCity(parse.DAI ? parse.DAI : '');
          setZip(parseZip(parse.DAK));
          setDL(parse.DAQ ? parse.DAQ : '');
          setDLState(parse.DAJ ? parse.DAJ : '');
          setSuffix(parse.DCU ? parse.DCU : '');
          setPrefix('');

        } else if (parse_status === 'parse_error') {
            console.log(parse_error);
            //toast error
        }
    }, [parse]);

    useEffect(() => {

      console.log('resetting screen');
      
      setFirstName('');
      setLastName('');
      setMiddleName('');
      setDOB('');
      setGender('');
      setPhone('');
      setSSN('');
      setAddress1('');
      setAddress2('');
      setCountry('US');
      setState('');
      setCity('');
      setZip('');
      setDL('');
      setDLState('');
      setSuffix('');
      setEmail('');
      setPrefix('');

      setDLData('');
      
    
    }, [reset]);
   

    
    const resetScreen = () => {

      console.log('reset screen');
          
    
        setReset(!reset);
    }

    const submitScan = (dl) => {

      
         setDLData(dl);
    }

    const scanDL = () => {
      setReset(!reset);
      setScan(true);
        
        // @ANSI 636026080102
        // DL00410285ZA03260015DL
        // DAQ D05932026 DL#
        // DCS EARLEY LastName
        // DDE N
        // DAC JOSEPH FirstName
        // DDFN
        // DAD MICHAEL MiddleName
        // DDG N
        // DCA D
        // DCB NONE
        // DC NONE
        // DBD 05042022
        // DBB 11101965 DOB
        // DBA 05042030
        // DBC 1 Gender (1=M 2=F 3= UN)
        // DAU 075 in
        // DAY BLU
        // DAG 2209 E WAHALLA LN Address
        // DAI PHOENIX City
        // DAJ AZ State
        // DAK 850241200  Zip
        // DCF 00460112EE122505
        // DCG USA Country
        // DAW 225
        // DAZ GRY
        // DCK 22124AZ0016714920301
        // DDA F
        // DDB 02142014
        // DDK 1
        // DDL 0ZAZAANZABZAC
    };

  

    const parseGender = (val) => {

      if (val == null) return '';

        switch (val) {
            case '1': return 'M';
            case '2': return 'F';
            case '3': return 'U';
            default: return 'U';
        }
      }; 

      const parseDOB = (val) => {

        let dobMonth = "";

        if (val == null) return '';

        console.log("DBB", val);
       

        try {

            let month = val.substring(0,2);
            let day = val.substring(2,4);
            let year = val.substring(4,8);

            dobMonth = month + '/' + day + '/' + year;

            console.log("dobMonth before isDate", dobMonth);

            console.log(validateDate(dobMonth));

            if (validateDate(dobMonth) ) {
              return dobMonth;
            } else {
              year = val.substring(0,4);
              month = val.substring(4,6); 
              day = val.substring(6,8);
              dobMonth = month + '/' + day + '/' + year;

             console.log("dobMonth isDate", dobMonth);
              return dobMonth;

            }
        } catch (e) {
 
          dobMonth = '';
        }

        return dobMonth;
      };

      const parseZip  = (val) => {

    
        if (val == null) return '';

        let zip = '';
        zip = val.substring(0,5);
        

        return zip;

      }

    const savePatient = (e) => {
        e.preventDefault();



        setDisable(true);

        const errors = [];
       
        setValErr([]);
        
         let obj = {};
         obj.CLN_IDX = getSession("cln");
         obj.USR_IDX = getSession("usr");
         obj.FirstName = isValidString(firstName) ? firstName.toUpperCase() : errors.push("Patient First Name Is Required."); //*
         obj.LastName = isValidString(lastName) ? lastName.toUpperCase() : errors.push("Patient Last Name Is Required."); //*
         obj.MiddleName = middleName.toUpperCase();
         obj.PRE = isValidString(lastName) ? convertPRE() : errors.push("PRE could not be created.");
         obj.Suffix = _suffix;
         obj.Prefix = _prefix;
         obj.DOB = validateDate(dob) ? dob : errors.push("The Patient DOB Is either InValid or is in the Future."); //*
         obj.Gender = gender !== '0' ? gender : errors.push("Patient Gender Is Required."); //*
         obj.SSN = isValidString(ssn) ? ssn : "";
         obj.PrimaryPhone = validatePhone(phone) ? phone : errors.push("Valid Phone Number in (XXX) XXX-XXXX is Required.");
         obj.Address1 = isValidString(address1) ? address1.toUpperCase() : errors.push("Patient Address1 Is Required."); //*
         obj.Address2 = isValidString(address2) ? address2.toUpperCase() : "";
         obj.City = isValidString(city) ? city.toUpperCase() : errors.push("Patient City Is Required."); //*
         obj.State = isValidString(state) ? state : errors.push("Patient State Is Required."); //*
         obj.Country = isValidString(country) ? country : errors.push("Patient Country Is Required."); //*
         obj.ZipCode = isZip5(zip) ? zip : errors.push("Valid Patient Zip Code (5) Is Required."); //*
         obj.DL = isValidString(dl) ? dl : "";
         obj.DL_State = isValidString(dl_state) ? dl_state : ""; 
         obj.Email = isValidString(email) ? email : "";
        
        
        let json = JSON.stringify(obj);

        console.log(json);

        //check if patient is duplicated
        const pt = dupes.find(element => element.FirstName === obj.FirstName && element.LastName === obj.LastName && element.DOB === obj.DOB);
       
        console.log('found patient',pt);

        if(pt !== undefined && Object.keys(pt).length) {
            
            //duplicate found launch warning
            errors.push("Patient May Already Exist."); 
          
            setShowDupes(true);
            setPTT(json);
          
      }
      
 
        if (errors.length > 0) {
            setDisable(false);
            setValErr(errors);
     
           
        } else {

         //POST DATA
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'x-functions-key': getAPI() },
          body: json
          };
          fetch(`${URL}CRxPatientInsert`, requestOptions)
                .then((response) => {
                 
                    if (response.ok === false) {  throw new Error('Network response was not ok.') };
                        
                  setValErr([]);
                  setToastIcon("success");
                  setToastMsg("Patient Added Successfully");
                  setToastState(true);
                  setToastTitle("Success");
                  setToastBackground("#5cb85c");
                  setDisable(false);
                  setReset(false);
                  setReset(true);
              })            
              .catch(err => {
                  setToastIcon("error");
                  setToastTitle("Error");
                  setToastBackground("#d9534f");
                  setToastMsg("There was a Problem Adding the Patient, Please Try Again.");
                  setToastState(true);
                  setDisable(false);
              });

            }
 
                
     }

   const convertPRE = () => {
    if (lastName.length > 2) {
        let name = lastName.toUpperCase();
        let pre = name.substring(0, 3);
        return pre;
    } else if (lastName.length === 2) {
        let name = lastName.toUpperCase();
        let pre = name.substring(0, 2);
        return pre;
    } else {
       return null; 
    }

   }

    const err = () => {
        try {
            if (Array.isArray(valid_err)) {
              return valid_err.map((item, index)=>{
                  return (
                        <ul>
                              <li className="err_list">{item}</li>
                             
                        </ul>
                   );
              });
          } else {
            return (
              <tr><td colSpan='8'><div></div></td></tr>
            );
          }
          } catch (err) {
            return;
          }
    }

    let prefixs = prefix.map((item, index) => {
        return (<option value={item}>{item}</option>);
    });

    let suffixs = suffix.map((item, index) => {
        return (<option value={item}>{item}</option>);
    });

    let _states = states.map((item, index) => {
        return (<option key={index} value={item}>{item}</option>);
    });

    const mapStates = (e) => {

        setCountry(e.target.value);

       switch(e.target.value) {
           case "US" : setStates(us_states);
           break;
           case "MX": setStates(mx_states);
           break;
           case "CN" : setStates(cn_states);
           break;
           default: setStates(us_states);
       }
        
       
    }

const checkPTT = async(ptt) => {
  if (ptt.length === 3)
  {
  

   const response = await fetch(`${URL}patientDuplicateCheck?cln=${getSession("cln")}&pre=${ptt.toUpperCase()}`, {
    method: 'GET',
        headers: {
            'x-functions-key': getAPI(),	
        }
    });

  const duplicates = await response.json();

  console.log(duplicates);
  setDupes(duplicates);

}

  setLastName(ptt);

}

const submitDuplicate = (val) => {
  let json = ptt;
  console.log(json);
  setShowDupes(false);


  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-functions-key': getAPI() },
    body: json
    };
    fetch(`${URL}CRxPatientInsert`, requestOptions)
          .then(() => {
            setValErr([]);
            setToastIcon("success");
            setToastMsg("Patient Added Successfully");
            setToastState(true);
            setToastTitle("Success");
            setToastBackground("#5cb85c");
            setDisable(false);
            setReset(false);
            setReset(true);
        })            
        .catch(err => {
            setToastIcon("error");
            setToastTitle("Error");
            setToastBackground("#d9534f");
            setToastMsg("There was a Problem Adding the Patient, Please Try Again.");
            setToastState(true);
            setDisable(false);
        });

}

const cancelDuplicate = () => {
          setShowDupes(false);
          setReset(!reset);
          setPTT({});
          setValErr([]);
}
    
  const closeOverlay = () => {
    setOverlay(false);
}

    return (
        <PatientAddWrapper>
                 <div id="new_content" className="patient_new_content ">
                <FormHeader onClose={props.onClose}  title="ADD NEW PATIENT" addClose={true} class_style="form_header--dark"></FormHeader>
                <svg style={{marginRight: "3rem", cursor:"pointer", position:"absolute", top:"20", left: "650"}} onClick={() => setOverlay(true)} width="40px" height="40px" viewBox="0 0 12 12"  version="1.1"  ><path d="M6,0C2.6862793,0,0,2.6862793,0,6s2.6862793,6,6,6s6-2.6862793,6-6S9.3137207,0,6,0z M6.5,9.5h-1v-1h1V9.5z   M7.2651367,6.1738281C6.7329102,6.5068359,6.5,6.6845703,6.5,7v0.5h-1V7c0-0.9023438,0.7138672-1.3486328,1.2348633-1.6738281  C7.2670898,4.9931641,7.5,4.8154297,7.5,4.5c0-0.5517578-0.4487305-1-1-1h-1c-0.5512695,0-1,0.4482422-1,1V5h-1V4.5  c0-1.1025391,0.8969727-2,2-2h1c1.1030273,0,2,0.8974609,2,2C8.5,5.4023438,7.7861328,5.8486328,7.2651367,6.1738281z" fill="#ffffff"></path></svg>
                        
                    <div className="patient_new_name">
                            <div id="prefix_container">
                                <select id="prefix" onChange={e => setPrefix(e.target.value)} value={_prefix}  className="form_input req">
                                    {prefixs}
                                </select>
                                <label htmlFor="prefix" className="form_label">Prefix</label>
                               
                            </div>
                            <div id="error_list" >{err()}</div>

                            <div id="firstname_container">
                                <input  onChange={e => setFirstName(e.target.value)}  value={firstName} type="text" className="form_input req" placeholder="First Name" id="firstname" maxLength="35" required autoComplete="off"  />
                                <label htmlFor="firstname" className="form_label">First Name*</label>
                            </div>
                            <div id="lastname_container">
                                <input onChange={e => checkPTT(e.target.value)}  value={lastName} type="text" className="form_input req" placeholder="Last Name" id="lastname" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="lastname" className="form_label">Last Name*</label>
                            </div>
                            <div id="middlename_container">
                                <input  onChange={e => setMiddleName(e.target.value)}  value={middleName} type="text" className="form_input" placeholder="Middle Name" id="name" autoComplete="off" />  
                                <label htmlFor="name" className="form_label">Middle Name</label>
                            </div>
                            <div id="suffix_container">
                                <select id="suffix" onChange={e => setSuffix(e.target.value)}  value={_suffix}  className="form_input req">
                                    {suffixs}
                                </select>
                                <label htmlFor="suffix" className="form_label">Suffix</label>
                            </div>
                    </div>

                    <div className="patient_new_dob">
                            <div id="dob_container">
                                <InputMask type="text"  onChange={e => setDOB(e.target.value)}  value={dob}  className="form_input req" mask="99/99/9999" placeholder="DOB"   id="dob" autoComplete="off" required /> 
                                <label htmlFor="dob" className="form_label">DOB*</label>
                            </div>
                            <div id="gender_container">
                                <select id="gender" onChange={e => setGender(e.target.value)}  value={gender} className="form_input req">
                                    <option value="0">GENDER</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                    <option value="U">Undefined</option>
                                </select>
                                <label htmlFor="gender" className="form_label">Gender*</label>
                            </div>
                            
                    </div>
                    <div className="patient_new_ssn">
                            <div id="phone_container">
                                <InputMask type="text" onChange={e => setPhone(e.target.value)} value={phone} className="form_input" mask="(999) 999-9999" placeholder="Phone"   id="phone" autoComplete="off" /> 
                                <label htmlFor="phone" className="form_label">Phone*</label>
                            </div>
                            <div id="dl_container">
                                <InputMask type="text" onChange={e => setDL(e.target.value)} value={dl}  className="form_input"  placeholder="DL"   id="dl" autoComplete="off" /> 
                                <label htmlFor="dl" className="form_label">DL</label>
                            </div>
                            <div id="dl_state_container">
                                <select id="dl_state" onChange={e => setDLState(e.target.value)} value={dl_state}  className="form_input req">
                                    {_states}
                                </select>
                                <label htmlFor="dl_state" className="form_label">DL State</label>
                            </div>
                            <div id="ssn_container">
                                <InputMask type="text" onChange={e => setSSN(e.target.value)}  className="form_input" mask="999-99-9999" placeholder="SSN"  id="ssn" autoComplete="off" /> 
                                <label htmlFor="ssn" className="form_label">SSN</label>
                            </div>
                            
                    </div>

                    <div className="patient_new_address">
                        <div id="address1_container">
                                <input type="text" onChange={e => setAddress1(e.target.value)} value={address1} className="form_input req" placeholder="Address1" id="address1" maxLength="35" autoComplete="off"  />
                                <label htmlFor="address1" className="form_label">Address 1*</label>
                        </div>
                        <div id="address2_container">
                                <input onChange={e => setAddress2(e.target.value)}  value={address2}   type="text" className="form_input req" placeholder="Address2" id="address2" maxLength="35" autoComplete="off"/>  
                                <label htmlFor="address2" className="form_label">Address 2</label>
                        </div>
                    </div>

                    <div className="patient_new_country">
                            
                            <div id="country_container">
                                <select id="country" onChange={mapStates} className="form_input req" value={country} >
                                    <option value="US">US</option>
                                    <option value="CN">CN</option>
                                    <option value="MX">MX</option>
                                    
                                </select>
                                <label htmlFor="country" className="form_label">Country*</label>
                            </div>

                            <div id="city_container">
                                <input onChange={e => setCity(e.target.value)}  value={city}  type="text" className="form_input req" placeholder="City" id="city" maxLength="35" autoComplete="off"  />
                                <label htmlFor="city" className="form_label">City*</label>
                            </div>

                            <div id="state_container">
                                <select id="state" onChange={e => setState(e.target.value)} value={state}  className="form_input req">
                                    {_states}
                                </select>
                                <label htmlFor="state" className="form_label">State*</label>
                            </div>
                            
                           


                            <div id="zip_container">
                                <input type="text"    onChange={e => setZip(e.target.value)} value={zip} className="form_input req" placeholder="Zip Code" maxLength="5"  id="zip" autoComplete="off" required /> 
                                <label htmlFor="zip" className="form_label">Zip Code*</label>
                            </div>
                            
                    </div>
                   
                    <div className="patient_new__btn-bar">
                        
                          
                        <LoadingButtonWrapper>
                            <div className="ctn" onClick={resetScreen}>
                                <input type="checkbox" id="check2" />
                                <label htmlFor="check2" id="save" className="btn-label btn-label--normal">
                                    <div id="btn2_animate" className=""><div></div><div></div><div></div></div>
                                        <p className="btn-text-wait">RESET SCREEN</p> 
                                </label>
                            </div>
                        </LoadingButtonWrapper>
                   
                        <LoadingButton id="check3" title="SCAN DL" select={scanDL} />
                       
                        
                        { disable ? <div></div> : <LoadingButtonWrapper>
                            <div className="ctn" onClick={savePatient}>
                                <input type="checkbox" id="check2" />
                                <label htmlFor="check2" id="save" className="btn-label btn-label--normal">
                                    <div id="btn2_animate" className=""><div></div><div></div><div></div></div>
                                        <p className="btn-text-wait">SAVE PATIENT</p> 
                                </label>
                            </div>
                        </LoadingButtonWrapper>}
                </div>

                   

      
                </div>
                {scan ? <ScanEntry  close={() => setScan(false)}  submit={val =>  submitScan(val)} title="SCAN LICENSE" /> : null}

                {showDupes ? <PatientDuplicate  close={() => setShowDupes(false)} firstName={firstName} lastName={lastName} dob={dob} cancel={val =>  cancelDuplicate(val)} select={val =>  submitDuplicate(val)} title="DUPLICATE PATIENT WARNING" /> : null}

              
                <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
              </div>

              {overlay ? <Overlay close={() => setOverlay(false)} >
              <>
                        
                        <div  className="item-end" style={{gridRow: "6/7",gridColumn: "1/2", marginLeft: "20px", alignSelf: "end", justifySelf:"end"}}>
                            <span>Reset Screen Here.</span>
                            <img src={arrow_down_right} alt="arrow" className="arrow" />
                        </div>
                        
                        <div style={{gridColumn: "3/5",gridRow: "6/7",alignSelf:"end", justifySelf: "start", marginLeft:"90px"}}>
                            
                            <span >Click Here to Scan Patient's Drivers License.</span>
                        </div>

                                                                     
                            <div style={{gridRow:"6/7", gridColumn:"5/-1", alignSelf:"end", marginLeft: "30px"}}>
                              
                                <span >Save the Patient Demographics Here.</span>
                            </div>

                            <div style={{gridRow:"8/-1", gridColumn:"2/6", alignSelf:"start", }}>
                              
                                <span >Be Sure to Validate Info Transfered from License </span>
                            </div>
                            
                            
                       
                        
                        <span className="close_overlay" onClick={closeOverlay} style={{gridColumn: "3/5", gridRow:"2/3",  fontFamily: "BenchNine", fontSize:"3rem",cursor:"pointer"}}>Click Here to Close</span>
        </> 
           </Overlay> : null}

        </PatientAddWrapper>

    );


}

export default PatientAdd;