
import React, {useEffect, useState} from 'react';
import Table from './table';
import styled from 'styled-components';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import ReactLoading from 'react-loading';
import FormHeader from './form-header';



const GlobalAdminLogWrapper = styled.div`

    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    z-index: 998;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .receive_container { 
       position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 80vh;
        width: 70%;
        
       
        /* background-color: rgba(0,0,0,.6); */
        background-color: var(--color-grey-dark-2);
        z-index: 999;
        opacity: 1;
        transition: all .3s;
   
     min-height: 43.3rem;
     border: 2rem solid  var(--color-primary);
     border-radius: 5px;

      }
     

    font-size: 1.8rem;
    color: navy;
     /* color: var(--color-primary); */
    
    .receive_inventory__form_header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;
        margin-top: 2rem;
        padding-top: 1.7rem;
        border-top: var(--line);
        border-bottom: var(--line);
        padding-bottom: 1.7rem;
        color: var(--color-primary); 
    }

    .receive_inventory__btn-bar {
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
    }

    .rcv_dsp {
        max-width: 25rem;
        padding: 1rem 2rem;
        margin-left: 7rem;
    }

    .active_dsp {
        text-transform: uppercase;
        font-family: 'BenchNine', sans-serif;
        font-size: 2.2rem;
        margin: 0 auto;
    }

    .recent__table {

        
    }

`;



const GlobalAdminLog = (props) => {

    const [query, setQuery] = useState('');
    const [url, setURL] = useState('');

    const [edit, setEdit] = useState(false);
  
     
    const { status, data, error } = useFetch(url);
    
   
 
    const [table_meta, setTableMeta] = useState([]);

    
  
    useEffect(() => {
        setTableMeta([
            // {"col_width": "col-15", "data_label": "Type","key": "atna_event_type"},
            {"col_width": "col-25", "data_label": "Activity","key": "atna_activity"},
            {"col_width": "col-50", "data_label": "Detail","key": "atna_detail"},
            {"col_width": "col-50", "data_label": "Comment","key": "atna_comment"},
            {"col_width": "col-10", "data_label": "Date","key": "EventDate"}
           
        ]);
		
        setQuery(Math.floor(Math.random() * 100));
	

    }, []);

    useEffect(() => {
        setURL(`${URL}/atnaReturnLog`);
    }, [query]);


    const selectRow = () => {
        console.log("selectRow");
    }



    return(
       <GlobalAdminLogWrapper>
        <div className="receive_container">
         <FormHeader onClose={props.onClose} title="ATNA LOG" addClose={true} class_style="form_header--light"></FormHeader>
            

           <div className="recent__table">

                {status === 'error' && <div>{error}</div>}
				{status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
				{status === 'fetched' && (

                            
					  <Table data={data} meta={table_meta} row_class="table-row" include_header="true" select={selectRow} table_height="tbl-lng"></Table>
				)}

              
                    
            </div>
          
        </div>
            
        </GlobalAdminLogWrapper>
    );

}
export default GlobalAdminLog;