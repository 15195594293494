import { useEffect, useState } from 'react';
import {
    Document,
    Page,
    Font,
    StyleSheet,
    View,
    PDFViewer,
  } from "@react-pdf/renderer";
import {URL} from '../constants';
import useFetch from '../hooks/useFetch';
import BenchNine from './fonts/BenchNine/BenchNine-Regular.ttf';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import DispenseLabel from './label';  
import PlaceholderLabel from './placeholder_label';


const LoadingWrapper = styled.div`
   .vw {
    transform: scale(0.6);
   }

`;

Font.register({
  family: "BenchNine",
  format: "truetype",
  src: BenchNine,
})

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 30
const COLN_WIDTH = (100 - COL1_WIDTH) / 4


const styles = StyleSheet.create({
  body: {
    padding: 10,
    fontFamily: "BenchNine",
    fontSize: 6,
    marginTop: '24px',
    marginLeft: '3px',
    width: "700px", 
  },
  viewer: {
    width: window.innerWidth / .95,
    height: window.innerHeight / .95,
   
   
    
  },
  lbl: {
    // marginTop: '7%',
    marginLeft: '-2%',
  },
  p2: {
    marginLeft: '52%',
    marginTop: '-144px',
  },
  p3: {
    marginLeft: '0%',
    marginTop: '0px',
  },
  p4: {
    marginLeft: '52%',
    marginTop: '-144px',
  },
  p5: {
    marginLeft: '0%',
    marginTop: '0px',
  },
  p6: {
    marginLeft: '52%',
    marginTop: '-144px',
  },
  p7: {
    marginLeft: '0%',
    marginTop: '0px',
  },
  p8: {
    marginLeft: '52%',
    marginTop: '-144px',
  },
  p9: {
    marginLeft: '0%',
    marginTop: '0px',
  },
  p10: {
    marginLeft: '52%',
    marginTop: '-144px',
  },
  table: { 
    display: "table", 
    width: "auto", 
    
  }, 

  tableRow: { 
    margin: "auto", 
    flexDirection: "row",
    backgroundColor: "#E0F9FF"
  
  }, 

  tableHeaderRow: { 
    margin: "auto", 
    flexDirection: "row",
    backgroundColor: "#FFF3D4"
  
  }, 

  tableTitleRow: {
    marginLeft: "12%",
    flexDirection: "row",
    backgroundColor: "#FFFFFF"
  },
  tableCol1Title: { 
    width: '33%', 
    
  }, 

  tableCol2Title: { 
    width: "100%" 
    
  }, 

  tableCol3Title: { 
    width: "33%"
    
  },
  
  tableCol5Title: { 
    width: "10%" 
    
  },


  'tableRow:nth-child(odd)': {
    backgroundColor: "#E0F9FF"
  },

  'tableRow:nth-child(even)': {
    backgroundColor: "#CDCDCD"
  },

  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    borderBottomWidth: 0
  },     
  tableNoReportHeader: {
    width: '100%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
    
}, 
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },   

  tableColClinic: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    borderBottomWidth: 0 
  },   
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },   
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: 5, 
    fontSize: 11,
    fontWeight: 500
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  },
  tableCellTiny: {
    margin: 3, 
    fontSize: 8,
    fontWeight: 200
  },  
  left: {
    // width: '33%',//<- working alternative
    flexGrow: 0,
    flexShrink: 1,
    // flexBasis: 100,
  },

  right: {
   
  },
});

const AverySheetLabel = (props) => {

  const [query, setQuery] = useState('');
 
  useEffect(() => {

        setQuery(props.idx);
        console.log(props.pos);

    }, []);


    const url = query && `${URL}dispenseReturnSheetLabel?idx=${props.idx}`;
    const { status, data, error } = useFetch(url);

   
return (
  <>
   {status === 'error' && <div>{error}</div>}
   {status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
   {status === 'fetched' && (
    <LoadingWrapper>
  <PDFViewer className="vw" style={styles.viewer}>
    <Document style={styles.lbl}>
      <Page style={styles.body} size="A4">
        <View>
           { props.pos === 1 ? <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/> : <PlaceholderLabel />}  
        </View>
        <View style={styles.p2}>
            { props.pos === 2 ? <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>   : <PlaceholderLabel />} 
        </View>
        <View style={styles.p3}>
            { props.pos === 3 ?  <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>  : <PlaceholderLabel />} 
        </View>
        <View style={styles.p4}>
            { props.pos === 4 ? <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>  : <PlaceholderLabel />} 
        </View>
        <View style={styles.p5}>
           { props.pos === 5 ? <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>  : <PlaceholderLabel />} 
        </View>
        <View style={styles.p6}>
           { props.pos === 6 ? <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>  : <PlaceholderLabel />} 
        </View>
        <View style={styles.p7}>
            { props.pos === 7 ? <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>  : <PlaceholderLabel />} 
        </View>
        <View style={styles.p8}>
           { props.pos === 8 ? <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>  : <PlaceholderLabel />} 
        </View>
        <View style={styles.p9}>
          { props.pos === 9 ? <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>  : <PlaceholderLabel />} 
     
        </View>
        <View style={styles.p10}>
          { props.pos === 10 ?  <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>  : <PlaceholderLabel />} 
        </View>

        {/* <View>
           <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>   
        </View>
        <View style={styles.p2}>
            <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>    
        </View>
        <View style={styles.p3}>
             <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>   
        </View>
        <View style={styles.p4}>
            <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>   
        </View>
        <View style={styles.p5}>
           <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>   
        </View>
        <View style={styles.p6}>
           <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>   
        </View>
        <View style={styles.p7}>
            <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>   
        </View>
        <View style={styles.p8}>
           <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>   
        </View>
        <View style={styles.p9}>
          <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>   
     
        </View>
        <View style={styles.p10}>
            <DispenseLabel  data={data} print_date={props.print_date}  firstname={props.firstname} middlename={props.middlename} lastname={props.lastname} dob={props.dob}/>   
        </View> */}

       
      </Page>
     </Document>
    </PDFViewer>
    </LoadingWrapper>
    )}
            
  </> 
);

}

export default AverySheetLabel;