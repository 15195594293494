import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';
import useFetch from '../hooks/useFetch';
import Toast from './toaster';
import { URL } from '../constants';

const AddCustomDosageWrapper = styled.div`
   
    width: 40rem;
    height: 31rem;
    margin: auto;
    background-color: var(--color-grey-dark-2);
    color: #fff;
    
    border: 2rem solid  rgba(15, 215, 239, 1);
    border-radius: 5px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    font-size: 2rem;
    white-space: nowrap;
    box-shadow: 0 1rem 3rem rgba(#fff, .2);
    
   
    #scan__title {
        font-family: 'BenchNine' sans-serif;
        font-size: 2.4rem;
        font-weight: 700;
        text-transform: uppercase;
        align-self: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
       
    }

   

    .value__input {
       
        
        color: #000;
        width:20rem;
        border:none;
        background-color: var(--color-grey-light-2);
        outline: none;
        height: 10rem;
        padding-left: 1rem;
        
    }

    .title__input {
       
        
       color: #000;
       width:20rem;
       border:none;
       background-color: var(--color-grey-light-2);
       outline: none;
       height: 2rem;
       padding-left: 1rem;
       margin-bottom: 1rem;
       
   }
    

    #note__input {

    font-family: inherit;
    
    font-size: 1.75rem;
    font-weight: 400;   
    border-radius: 2px;
    background-color: var(--color-grey-dark-2);
    border: none;
    width: 20rem;
    height: 10rem;
   

    }

    .header {
        width: 100%;
        margin-top: -2rem;
     }

   .submit_note {
         margin-top: 2rem;
         width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

   }




`;




const  AddCustomDosage = (props)  => {
  const [value, setValue] = useState('');
  const [title, setTitle] = useState('');
  const [toast, setToastState] = useState(false);
  const [toast_msg, setToastMsg] = useState("");
  const [toast_icon, setToastIcon] = useState("");
  const [toast_title, setToastTitle] = useState("Success");
  const [toast_background, setToastBackground] = useState("#5cb85c");
  const [disabled, setDisabled] = useState(false);

  const [url, setUrl] = useState(undefined);

  const { status, data, error } = useFetch(url);
  
   
    useEffect(() => {
        if(status === 'fetched') {
            console.log(data);
            setToastIcon("success");
            setToastMsg("Custom Dosage Added Successfully");
            setToastState(true);
        
            setDisabled(false);
            props.submit(value);
            props.close();
        }
       
      
    }, [data]);

    
    
  const submitNote = () => {

    setDisabled(true);

    setValue(value);
    setTitle(title);
     
    

    setUrl(`${URL}inventoryCustomDosageInsert?ndc=${props.ndc}&lot=${props.lot}&grp=${props.grp}&drg=${props.drg}&name=${title}&txt=${value}`);
        
    }
  
   


    return (
        <AddCustomDosageWrapper>

         
           <div className="header"><FormHeader onClose={props.close} title="Add Custom Dosage" addClose={true} class_style="form_header--light"></FormHeader></div>
             <input onChange={(e) => setTitle(e.target.value)} autoFocus type="text" placeholder="Custom Dosage Name" className="title__input" maxLength="40"  />
             <div id='note__input'>
                <textarea  rows="3" cols="20"  className="value__input" placeholder="Custom Dosage Text" onChange={(e) => setValue(e.target.value)} maxLength="140"></textarea>
            </div>
            <div className="submit_note">{ disabled ? <div></div> : <ButtonExpand title="SUBMIT" click={submitNote} />}</div>


            <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>

        </AddCustomDosageWrapper>
    );
}

export default AddCustomDosage;
