import React from 'react';
import styled from 'styled-components';

const FormHeaderWrapper = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
border-bottom: var(--line);
padding: 2rem;
z-index: 100;

.form_header-close {
    text-decoration: none;
    transition: all .3s;
    line-height: 1;
    margin-right: 2rem;
    cursor: pointer;
    fill: var(--color-primary);
    transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

    &:hover {
        fill: red;
    }

    
}

    .form_header-title{
        color: var(--color-primary);
        font-family: 'BenchNine', sans-serif;
        font-weight: 700;;
        font-size: 2.5rem;
    }

    .form_header--light {
        color:#fff;
        fill:#fff;
    }
    .form_header--dark {
        color: var(--color-primary);
        fill:  var(--color-primary);
}


`;




const FormHeader = (props) => {
    return(
        <FormHeaderWrapper props>
                <span className="form_header-title">{props.title}</span>
                {props.addClose ? <svg  onClick={props.onClose} className="form_header-close"  width="32" height="32"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g><path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path></g></svg>
                : null}
        </FormHeaderWrapper>
    );
}

export default FormHeader;
