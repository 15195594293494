import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Divide as Hamburger } from 'hamburger-react';
import PatientAdd from './patient-add';
import MenuDetail from './menu-detail';
import ActiveListButton from '../theme/active_list_button'; 
import InventoryReceive from './inventory-receive';
import ReportEngine from './report-engine';
import OrderProcess from './inventory-order-process';
import DispenseReprint from './dispense-reprint';
import AdminClinic from './admin-clinic';
import AdminGlobal from './admin-global';
import AutoComplete from '../theme/autocomplete';
import BasicDocument from '../pdf/basic_document';
import { getSession, isTablet, isAdmin, isGlobal, getAPI, formatDate } from '../utility';
import { URL } from '../constants';
import useWindowSize from '../hooks/useWindowSize';
import { Toggle } from '../theme/toggle';
import { saveAs } from 'file-saver';
import { Tooltip } from 'react-tooltip';
import Settings from './settings';
import LabelPosition from './label-position';




import PATUpload from './glb-upload-ptt';
import DSPUpload from './glb-upload-dsp';
import DRGUpload from './glb-upload-drg';

const SearchWrapper = styled.div`
    
    // background-color: var(--color-grey-dark-1);
    background: var(--color-primary);
    display: grid;
    grid-template-columns: 1fr 1.25fr .75fr 1fr 1fr 1fr;
    align-items: center;

        &-site_detail  {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-transform: uppercase;
        }

        &-site {
            font-size: 1.3rem;
            margin-top: -1rem;
            color: #fff;
        }

        &-team_txt {
            font-size: 2.8rem;
            color: #fff;
            margin: 0 auto;
        }

        .auto {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            grid: 2/3;
            width: 70%;
            z-index:1;
            justify-self: end;
            margin-right: 2rem;

        }

    
        .cspmp_btn {

            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;
            border-radius: 3px;
            padding: .5rem;
            width: 12rem;
            height: 3rem;
            color: #fff;
            cursor: pointer;
            font-size: 1.3rem;
            text-decoration: none;

            &:hover {
                background-color: navy;
                text-decoration: none;
                
            }
     }

.icon-btn {
    width: 3.5rem;
    height: 3.5rem;
    border: 1px solid #cdcdcd;
    background: white;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    transition: width 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 2.5rem;
    grid:3/4;
  }
  .add-btn:hover {
    width: 11rem;
  }
  .add-btn::before,
  .add-btn::after {
    transition: width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
    content: "";
    position: absolute;
    height: 4px;
    width: 10px;
    top: calc(50% - 2px);
    background: var( --color-grey-dark-1);
  }
  .add-btn::after {
    right: 14px;
    overflow: hidden;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .add-btn::before {
    left: 14px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .icon-btn:focus {
    outline: none;
  }
  .btn-txt {
    opacity: 0;
    transition: opacity 0.2s;
    color:var( --color-grey-dark-1);
  }
  .add-btn:hover::before,
  .add-btn:hover::after {
    width: 4px;
    border-radius: 2px;
  }

  .add-btn:hover  .search-icon--plus {
      opacity: 0;
    
      
  }

  .add-btn:hover  .search-icon--binoculars {
    opacity: 0;
  
    
}
  .add-btn:hover .btn-txt {
    opacity: 1;
    /* transform: translateY(0rem); */
  }
  .add-icon::after,
  .add-icon::before {
    transition: all 0.2s ease-in-out;
    content: "";
    position: absolute;
    height: 20px;
    width: 2px;
    top: calc(50% - 10px);
    // background: red;
    overflow: hidden;
  }
  .add-icon::before {
    left: 2.2rem;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .add-icon::after {
    right: 22px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .add-btn:hover .add-icon::before {
    left: 1.5rem;
    height: 4px;
    top: calc(50% - 2px);
  }
  .add-btn:hover .add-icon::after {
    right: 15px;
    height: 4px;
    top: calc(50% - 2px);
  }

  .menu_toggle {
    max-width:5rem;
    grid: 1/2;
    z-index:11;
  
  }

  .menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  margin: 0 1rem;
  overflow: hidden;
}


.active-list-button {
  transform: scale(.8);
 	position: absolute;
	top:4rem;
	left: 50%;
	transform: translate(-50%);
  z-index:999;
  

}

.toggle {
  grid:6/7;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  justify-self: end;
  margin-right: 2rem;
}

.quickstart {
  
      grid: 4/5;
}

.lnl {
  text-decoration: none;
}




`;


const Search = (props) => {

    const [ptt_open, setPTT_OPEN] = useState(false);
    const [vwe_open, setVWE_OPEN] = useState(false);
    const [rec_inv_open, setREC_INV_OPEN] = useState(false);
    const [rpt_open, setREPORT_OPEN] = useState(false);
    const [ord_open, setORDER_OPEN] = useState(false);
    const [rep_open, setREPRINT_OPEN] = useState(false);
    const [adm_open, setADMIN_OPEN] = useState(false);
    const [gbl_open, setGLOBAL_OPEN] = useState(false);
    const [lb, setPDF] = useState(false);
    const [idx, setIDX] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [dob, setDOB] = useState('');
    const [lblPosition, setLblPos] = useState(0);
   
    const [sizeRestrict, setSizeRestrict] = useState(false);
    const [opt_in, setOptIn] = useState(getSession("opt_in"));
    const [roleRestrict, setRoleRestrict] = useState(false);
    const [globalRestrict, setGlobalRestrict] = useState(false);

    const [isOpen, setOpen] = useState(false);

    const {screenWidth, screenHeight} = useWindowSize();
    
   

    const [settings, setSettings] = useState(false);

    const [load_ptt, setLoadPTT] = useState(false);
    const [load_drg, setLoadDRG] = useState(false);

    const [stop, setStop] = useState(true);

    const [toggle_label, setToggleLabel] = useState("");
    const [toggle_label2, setToggleLabel2] = useState("");
    const [toggle_label3, setToggleLabel3] = useState("");
    const [opt_label, setOptLabel] = useState("");

    const [lbl_type, setLblType] = useState("");
    const [hlp_type, setHlpType] = useState("");
    const [mode_type, setModeType] = useState("");
    const [opt_type, setOptType] = useState("");

    const [lbl_state, setLblState] = useState(true);
    const [hlp_state, setHlpState] = useState(true);
    const [mode_state, setModeState] = useState(true);
    const [opt_state, setOptState] = useState(true);

    const [pos, setPos] = useState(false);


    useEffect(() => {
      setSizeRestrict(getSession("tablet"));
      setRoleRestrict(isAdmin());
      setGlobalRestrict(isGlobal());

      setupLabelType();

    }, []);


    useEffect(() => {

      if (lblPosition === 0) {
        setPos(false);
      } else {
        setPos(true);

      }

     
    }, [lblPosition]);


    useEffect(() => {

      if (lb === true) {
        setPos(false);
      } 
     
    }, [lb]);


const setupLabelType = () => {
  getSession("lbl") === 'ZR' ?  setLblType("ZR") : setToggleLabel("LB");

  getSession("optIn") === true ?  setOptLabel("Opt-Out CSPMP Real Time Reporting") : setOptLabel("Opt-In CSPMP Real Time Reporting");
}

    function patient_new_launch() {
       
        setPTT_OPEN(!ptt_open);
        
    }

 

    const print_lbl = (obj) => {
     
      if(obj !== undefined) {
        setFirstname(obj.firstname);
        setLastname(obj.lastname);
        setDOB(obj.dob);
        setIDX(obj.idx);
        setLblType(getSession("lbl"));
         
      
      }

      
      if (getSession("lbl") === 'ZR') {

       setPDF(true);
      } else {

        setPos(true);
      }

    }

    const reprint_lbl = (obj) => {

      console.log('in reprint_lbl', obj);
            
     
     
      if(obj !== undefined) {
        setFirstname(obj.firstname);
        setLastname(obj.lastname);
        setDOB(obj.dob);
        setIDX(obj.idx);
        setLblPos(obj.lblPosition);
        

        if((obj.lblType === undefined) || (obj.lblType === null)) {
          obj.lblType = "ZR";
        }

        setLblType(obj.lblType);
      }

      
      setPDF(true);

    }
    
  

    const runCSPMP = async() => {

      let current = new Date();
      let fileName = formatDate(current);
          
     
      let ul = (`${URL}cspmpDailyReport?cln=${getSession("cln")}`);
      try {
        const response = await fetch(ul, {
          method: 'GET',
          headers: {
              'x-functions-key': getAPI(),
           }	
          });

        if (response.ok === false) {  throw new Error('Network response was not ok.') };

        const rslt = await response.json();
      
        const file = new Blob([rslt], { type: 'text/plain;charset=utf-8' });
        saveAs(file, fileName + '.dat');
       
        
        
       
    } catch (error) {
          console.log(error);
    }

    

    }

   const openQuickstart = () => { 
    let url = "https://temprano.blob.core.windows.net/clinics/CRx.mp4";
    window.open(url, "_blank", "noreferrer");
   }
    const importData = () => {
      setStop(false);
      console.log("import");
      setLoadPTT(true);
    }


    const importPAT =async () => {
      setStop(false);
      console.log("import");

      const response = await fetch(URL + `importPatientStage`, {
        method: 'GET',
            headers: {
                'x-functions-key': getAPI(),	
            }
        });
    
    
        const inact = await response.json();
        conole.log('done', inact);


    }

    const showSettings = () =>
    {
      // let obj = localStorage.getItem('session');
      // let lbl = ""; 
      // let hlp = "";
      // let mode = "";
      
      // let session = {};

      // if (obj !== undefined) {
         
      //     session = JSON.parse(obj);

      //     lbl = session.lblType;
      //     if (lbl === undefined) {
      //         lbl = "ZR";
      //         setToggleLabel("Zebra Label Print");
      //         setLblType("ZR");
      //     } else {
             
            
      //         session.lblType === "ZR" ? toggleState("Zebra Label Print",true,"1") : toggleState("Sheet Label Print",false,"1");
      //         setLblType(session.lblType);

      //     }

      //     hlp = session.helpType;
      //     if (hlp === undefined) {
      //         hlp = "ON";
      //         setToggleLabel2("CRx Help ON");
      //         setHlpType("ON");
              
      //     } else {
              
      //         session.helpType === "ON" ? toggleState("CRx Help ON",true,"2") : toggleState("CRx Help OFF",false,"2");
      //         setHlpType(session.helpType);

      //     }

      //     mode = session.modeType;
      //     if (mode === undefined) {
      //         mode = "PC";
      //         setToggleLabel3("PC Mode");
      //         setModeType("PC");
      //     } else {
             
      //         session.modeType === "PC" ? toggleState("PC Mode",true,"3") : toggleState("Tablet Mode",false,"3");
      //         setModeType(session.modeType);

      //     }

      //    if(opt_in === false) {
      //     setOptLabel("Opt-In");
      //     setOptType("Opt-In");

      //    } else {
      //     setOptLabel("Opt-Out");
      //     setOptType("Opt-Out");  
      //    }

          
          setSettings(true);
      }

      const getLabelPosition = (position) => {
		
        setLblPos(position);
        setPDF(true);
    
       
      }

    

    const toggleState = (lbl,state, which) => {
      switch (which) {
          case "1" :  setToggleLabel(lbl); setLblState(state);
          break;
          case "2" : setToggleLabel2(lbl); setHlpState(state);
          break;
          case "3" : setToggleLabel3(lbl); setModeState(state);
          break;
      } 
  }

  const closeSettings = () => {
    setSettings(false);
    // window.location.reload();
  }  
    
  return (
    <SearchWrapper>
        {ptt_open ? <PatientAdd onClose={patient_new_launch}></PatientAdd> : null}
        {rec_inv_open ? <InventoryReceive onClose={() => setREC_INV_OPEN(false)}></InventoryReceive> : null}
        {rpt_open ? <ReportEngine onClose={() => setREPORT_OPEN(false)}></ReportEngine> : null}
        {ord_open ? <OrderProcess   onClose={() => setORDER_OPEN(false)}></OrderProcess> : null}
        {rep_open ? <DispenseReprint  onClose={() => setREPRINT_OPEN(false)} setLabel={(obj) => reprint_lbl(obj)}></DispenseReprint> : null}
        {adm_open && roleRestrict ? <AdminClinic onClose={() => setADMIN_OPEN(false)}></AdminClinic> : null}
        {gbl_open && globalRestrict ? <AdminGlobal  onClose={() => setGLOBAL_OPEN(false)}></AdminGlobal> : null}

        <Tooltip id="src-tooltip" place="top" variant="info"  style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }}/>
        <Tooltip id="src-tooltip2" place="bottom" variant="info" style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }} />
        <Tooltip id="src-tooltip3" place="top-start" variant="info" style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }} />

         {isOpen ? <MenuDetail item1="RECEIVE INVENTORY" click1={() => {setREC_INV_OPEN(true); setOpen(false)}} item2="REPORT ENGINE" click2={() => {setREPORT_OPEN(true); setOpen(false)} } item3="ORDER PROCESS" click3={() => {setORDER_OPEN(true); setOpen(false)}} item4="RE-PRINT DISPENSE" click4={() => {setREPRINT_OPEN(true); setOpen(false)}}  item5="CLINIC ADMIN" click5={() => { setADMIN_OPEN(true); setOpen(false)}}  item6="GLOBAL ADMIN" click6={() => {setGLOBAL_OPEN(true); setOpen(false)}} ></MenuDetail> : null}
         {sizeRestrict ? null
          : <div className="menu_toggle">
          <Hamburger color="#FFFFFF"  toggled={isOpen} toggle={setOpen} />
         </div> }
        <div>
            {/* <div>width: {screenWidth}</div>
            <div>height: {screenHeight}</div>  */}
            {/* { stop ? <button onClick={importPAT} >IMPORT DATA</button> : null} */}
            { !opt_in  ? <a data-tooltip-id="src-tooltip2"  data-tooltip-content="Download Daily CSPMP Report">
              <div onClick={runCSPMP} className="cspmp_btn">RUN CSPMP REPORT</div>
            </a> : null}
        </div>

        
       
        <div  className="auto">
             <AutoComplete select={(pokemon) => props.select(pokemon)}  cln={getSession("cln")} placeholder="LastName FirstName" />
        </div>

       { !sizeRestrict ? <a data-tooltip-id="src-tooltip2"  data-tooltip-content="Add New Patients">
        <div className="add-patient">
                <button className="icon-btn add-btn"  onClick={patient_new_launch}>
                <div className="add-icon" >
                  
                </div>
                    <div className="btn-txt">Add Patient</div>
                </button>
        </div>
        </a> : null}
            


        { getSession("help") ? <div className="cspmp_btn" onClick={openQuickstart}>VIEW CRx QUICKSTART</div> : null}

            <a data-tooltip-id="src-tooltip"  data-tooltip-content="Application Settings">
            <div className="toggle" onClick={showSettings}>
            <svg  fill="#FFFFFF" height="35px" width="35px" version="1.1" viewBox="0 0 512 512" >
              <g>
                <g>
                  <path d="M451.528,198.531c-4.088-13.938-9.657-27.369-16.645-40.14l42.774-42.773l-81.273-81.274l-42.774,42.773
                    c-12.771-6.987-26.201-12.557-40.14-16.644V0H198.531v60.472c-13.939,4.088-27.369,9.657-40.14,16.644l-42.774-42.773
                    l-81.273,81.274l42.774,42.773c-6.988,12.771-12.558,26.202-16.645,40.14H0v114.939h60.472
                    c4.088,13.938,9.657,27.369,16.645,40.14l-42.774,42.773l81.273,81.274l42.774-42.773c12.771,6.987,26.201,12.557,40.14,16.644
                    V512h114.939v-60.472c13.939-4.088,27.369-9.657,40.14-16.644l42.774,42.773l81.273-81.274l-42.774-42.773
                    c6.988-12.771,12.558-26.202,16.645-40.14H512v0V198.531H451.528z M480.653,282.122h-53.755l-2.769,12.204
                    c-4.301,18.952-11.756,36.932-22.158,53.441l-6.672,10.589l38.026,38.025l-36.942,36.942l-38.027-38.026l-10.589,6.672
                    c-16.507,10.402-34.487,17.856-53.44,22.157l-12.204,2.771v53.755h-52.245v-53.755l-12.205-2.77
                    c-18.953-4.301-36.933-11.755-53.44-22.157l-10.589-6.672l-38.027,38.026l-36.941-36.943l38.027-38.026l-6.672-10.589
                    c-10.402-16.508-17.857-34.489-22.158-53.441l-2.77-12.203H31.347v-52.245h53.755l2.769-12.204
                    c4.301-18.952,11.756-36.932,22.158-53.441l6.672-10.589l-38.026-38.025l36.942-36.942l38.027,38.026l10.589-6.672
                    c16.507-10.402,34.487-17.856,53.44-22.157l12.204-2.771V31.347h52.245v53.755l12.205,2.77
                    c18.953,4.301,36.933,11.755,53.44,22.157l10.589,6.672l38.027-38.026l36.942,36.942L395.3,153.643l6.672,10.589
                    c10.402,16.508,17.857,34.489,22.158,53.441l2.769,12.204h53.755V282.122z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M256,135.837c-66.258,0-120.163,53.905-120.163,120.163c0,66.258,53.905,120.163,120.163,120.163
                    c66.258,0,120.163-53.905,120.163-120.163S322.258,135.837,256,135.837z M256,344.816c-48.973,0-88.816-39.843-88.816-88.816
                    s39.843-88.816,88.816-88.816s88.816,39.843,88.816,88.816S304.973,344.816,256,344.816z"/>
                </g>
              </g>
              
            </svg>
            </div>
            </a>

           
        <div  className="active-list-button">

          { sizeRestrict ? null : <ActiveListButton cln={getSession("cln")} setLabel={(obj) => print_lbl(obj)} />}
            
        
          
           
        </div>
       

        <div className="search-buttons">
       
         
        </div>

        {load_ptt ? <DSPUpload /> : null}

          {settings ? <Settings close={closeSettings}/>: null}

          {pos ? <LabelPosition select={(pos) => getLabelPosition(pos)} close={() => setPos(false)} /> : null}

       
        { lb ?  <BasicDocument close={() => setPDF(false)} active_report={lbl_type} title={`PRINT DISPENSE`} idx={idx} firstname={firstname} lastname={lastname} dob={dob} lblPosition={lblPosition} /> : null}
    </SearchWrapper>
  );
}

export default Search;