import { useState } from "react";
import styled from 'styled-components';

const CheckboxWrapper = styled.div`

.chk_lbl {
    font-size: 1.95rem;
    font-weight: 100;
    margin-left:5rem;
}

`;

const Checkbox = ({ label,checked, ...props }) => {

    const defaultChecked = checked ? checked : false;
    
    const [isChecked, setIsChecked] = useState(defaultChecked);

    return (
      <div className="checkbox-wrapper">
        <label>
          <input type="checkbox" checked={isChecked}  {...props} onChange={() => setIsChecked((prev) => !prev)}/>
          <span className="chk_lbl">{label}</span>
        </label>
      </div>
    );
  };
  export default Checkbox;