import React, { useState, useEffect } from 'react';
import FormHeader from './form-header';
import {allergen_profile, reactions} from '../constants';
import Toast from './toaster';
import Table from './table';
import ButtonExpand from '../theme/btn_expand';
import styled from 'styled-components';
import AllergenAdd from './allergen-add';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import ReactLoading from 'react-loading';
import { getAPI } from '../utility';


const PatientAllergenProfileWrapper = styled.div`

 
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 998;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;



        @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
            -webkit-backdrop-filter: grayscale(80%) blur(5px);
            backdrop-filter: grayscale(70%) blur(5px);
            background-color: rgba(#000, .4);

        }



.allergen__header {
    grid-row: 1/2;
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--line);
    padding: 2rem;
}
.allergen__title {
    font-size: 2rem;
    color: var(--color-primary);
    margin-left: 2rem;
    

}

.allergen_close {
       
        text-decoration: none;
        transition: all .3s;
        line-height: 1;
        margin-right: 2rem;
        cursor: pointer;
        fill: var(--color-primary);
        transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

        &:hover {
            fill: red;
        }

    

.allergen_close:hover {
    color:var(--color-primary);
}
}
.allergen_content {
        height: 70vh;
        width: 80vw;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 999;
        opacity: 1;
        transition: all .3s;
        background-color:  rgba(0,0,0, 0.6); 
        backdrop-filter: blur(19px);
        -webkit-backdrop-filter: blur(19px);
        border-radius: 10px;
        border: 1px solid rgba(255,255,255,0.6);
        font-size: 2rem;

}

.allergen__add-header {
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--line);
    padding: 2rem;
}

.allergen__btn-bar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.allergen__table {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  
}

.allergen__add {
    position: absolute;
    top:-100%;
    width: 100%;
    height: 40rem;
    background-color: var(--color-primary);
    transition: all .7s cubic-bezier(0.36, 0, 0.66, -0.56);

    .allergen__add-close {
        text-decoration: none;
        transition: all .3s;
        line-height: 1;
        margin-right: 2rem;
        cursor: pointer;
        fill: #fff;
        transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

        .allergen__add-close:hover {
            fill: red;
        }

    
    }

    .allergen__add-title {
        color: #fff;
        font-size: 2rem;
    }

}

.allergen__open-add {
    top: 0;
}

.btn_disabled {
    text-transform: uppercase;
    text-decoration: none;
    padding: .75rem 3rem; 
    display: inline-block;
    border-radius: 10rem;
    transition: all .2s;
    position: relative;
    border:none;
    background-color: #ccc;
}



`;

const PatientAllergenProfile = (props)  => {
    
    const [alg_idx, setIDX] = useState('');
    const [alg_data, setAlgData] = useState([]);
    const [allergen, setAllergen] = useState('');
    const [type, setType] = useState('');
    const [onsetDate, setOnsetDate] = useState('');
    const [reactionName, setReaction] = useState('');
    const [reactionType, setReactionType] = useState('');
    const [severity, setSeverity] = useState('');
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");
    
    const [table_meta, setTableMeta] = useState([]);
    const [add, setAdd] = useState(false);
    const [query, setQuery] = useState('');
    const [url, setUrl] = useState("");
    const { status, data, error } = useFetch(url);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        setTableMeta([
            {"col_width": "col-40", "data_label": "Allergen","key": "Allergen"},
            {"col_width": "col-15", "data_label": "Onset Date","key": "OnsetDate"},
            {"col_width": "col-25", "data_label": "Reaction","key": "Reaction"},
            {"col_width": "col-25", "data_label": "Reaction Type","key": "ReactionType"},
            {"col_width": "col-25", "data_label": "Severity","key": "Severity"}
        ]);

        setUrl(`${URL}patientAllergenProfile?idx=${props.idx}`);
        
    }, []);

    useEffect(() => {
        setUrl(`${URL}patientAllergenProfile?idx=${props.idx}`);
    }, [query]);

    useEffect(() => {

       
        if(status === 'fetched') {
        
          
            setAlgData(data);

        }
   
    }, [status]);


    const selectRow = (e) => {
        setDisabled(false);
       
        setIDX(e.target.id);
        const sel = document.getElementById(e.target.id);
        let els = document.getElementsByClassName('table-row__selected');
        
        while (els[0]) {
            els[0].classList.remove('table-row__selected')
        }

        sel.classList.add("table-row__selected");
    }

   

    function open_add() {
               
        setAdd(true);
          
    }

    const deactivate_alg = async () => {
        const sel = document.getElementsByClassName('table-row__selected');
        if (sel.length > 0) {
          

            let ul = (`${URL}allergenDeActivate?idx=${alg_idx}`);
            try {
              const response = await fetch(ul, {
                method: 'GET',
                    headers: {
                        'x-functions-key': getAPI(),	
                    }
                });
              const rslt = await response.json();
              let data = "";
              try {
                  
                  setToastIcon("success");
                  setToastTitle("Success");
                  setToastBackground("#5cb85c");
                  setToastMsg("Allergen DeActivated Successfully.");
                  setToastState(true);

                  setDisabled(true);

                  setUrl('');
                  setQuery(Math.floor(Math.random() * 100));

              } catch {
                  data = rslt;
              }
              
              
             
          } catch (error) {
                setToastIcon("error");
                setToastTitle("Error");
                setToastBackground("#d9534f");
                setToastMsg("There was a Problem DeActivating this Allergen, Please Try Again.");
                setToastState(true);
                
          }
        }
    }

    const delete_alg = async() => {
        const sel = document.getElementsByClassName('table-row__selected');
        if (sel.length > 0) {
          

            let ul = (`${URL}allergenDelete?idx=${alg_idx}`);
            try {
              const response = await fetch(ul, {
                method: 'GET',
                    headers: {
                        'x-functions-key': getAPI(),	
                    }
                });
              const rslt = await response.json();
              let data = "";
              try {
                  
                  setToastIcon("success");
                  setToastTitle("Success");
                  setToastBackground("#5cb85c");
                  setToastMsg("Allergen Deleted Successfully.");
                  setToastState(true);

                  setDisabled(true);

                  setUrl('');
                  setQuery(Math.floor(Math.random() * 100));

              } catch {
                  data = rslt;
              }
              
              
             
          } catch (error) {
                setToastIcon("error");
                setToastTitle("Error");
                setToastBackground("#d9534f");
                setToastMsg("There was a Problem Deleting this Allergen, Please Try Again.");
                setToastState(true);
                
          }
        }
    }

    const reloadAllergen = () => {
        setAdd(false);
        setUrl('');
        setQuery(Math.floor(Math.random() * 100));
        
    };

    const closeAllergen = () => {
        let  alg_string = "";

        const arr = Object.values(alg_data);

        arr.forEach((element) => {
            alg_string += element.Allergen + ",";
        })
        
        props.onClose(alg_string);
    }


        return(
            <PatientAllergenProfileWrapper>
                <div id="alg_content" className="allergen_content">
                
                    <FormHeader onClose={closeAllergen} addClose={true} title="PATIENT ALLERGEN PROFILE" class_style="form_header--dark"></FormHeader>
                                        <div className="allergen__btn-bar">
                            <ButtonExpand title="ADD NEW" click={open_add} />
                            { disabled ? <div className="btn_disabled">DEACTIVATE</div> : <ButtonExpand title="DEACTIVATE" click={deactivate_alg} />}
                            { disabled ? <div className="btn_disabled">DELETE</div> : <ButtonExpand title="DELETE" click={delete_alg} />}
                           
                    </div>
                    <div>
                    {status === 'error' && <div>{error}</div>}
                    {status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
                    {status === 'fetched' && (
                        <Table data={data} meta={table_meta} row_class="table-row" include_header="true" select={selectRow} table_height="tbl-med"></Table>
                    )}
                    </div>
                </div>
                <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
              </div>
            
            
            {add ? <AllergenAdd ptt={props.idx} close={() => setAdd(false)} select={reloadAllergen} /> : null}

            </PatientAllergenProfileWrapper>
        );
} 
export default PatientAllergenProfile;


