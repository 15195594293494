import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-tooltip/dist/react-tooltip.css'

if(navigator.serviceWorker) {
  //register sw
  navigator.serviceWorker.register('./sw.js').catch(console.error);
  
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

//serviceWorker.register();