import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { URL } from '../constants';
import useFetch from '../hooks/useFetch';
import ReactLoading from 'react-loading';
import { useInterval } from '../hooks/useInterval';	
import { getSession, getAPI } from '../utility';
import Tooltip from './tooltip';



const Button = styled.button`

    pointer-events: none;
    z-index: 9998;
	font-size: 1.8rem;
	text-transform: uppercase;
	font-family: inherit;
	font-weight: 700;
	letter-spacing: 0.1rem;
	padding: 1.2rem 2.4rem;
	border: none;
	border-radius: 0.8rem;
	color: #fff;
	border: 0.2rem solid #fff;
	background: none;
	position: relative;
	cursor: pointer;
	min-width: 0;
	min-height: 0;
	transition: 300ms color ease-in-out, 300ms background ease-in-out,
		300ms min-width 0ms ease-in-out, 300ms min-height 300ms ease-in-out;
	.buttonText {
		position: relative;
		top: 0;
		transition: 300ms top 280ms ease-in-out;
		
	}
	/* &::before {
		content: "";
		letter-spacing: 0;
		color: #e6e1d6;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: 2.4rem;
		padding: 0 0.7rem;
		height: 2.4rem;
		background: radial-gradient(orangered 50%, red 75%);
		border-radius: 1.2rem;
		position: absolute;
		top: -1.2rem;
		left: 0.7rem;
	} */
	&:hover {
		background: #362f21;
		color: #e6e1d6;
	}
	&:focus,
	&:active {
		background: #e6e1d6;
		color: #362f21;
	}
	.active & {
		min-width: 36rem;
		min-height: 45rem;
        background: #e6e1d6;
        color: #362f21;
      
       
	
		&::before {
			animation: 600ms ease-in 500ms 1 forwards notiPop;
		}
	}
	.active & .buttonText {
		top: -20rem;
	}


.clicked:not(.active) button::before {
	display: block;
}

@keyframes itemLoad {
	to {
		opacity: 1;
		max-height: 20rem;
		outline: 2px solid transparent;
     
	}
}

@keyframes notiPop {
	to {
		transform: scale(0);
	}
}

.activity {
	opacity: 0;
	height: 40rem;
	transition: 300ms all ease;
	position: absolute;
	left: 50%;
	top: 4.8rem;
	transform: translatex(-50%);
    clear: both;
   
    

    .active & {
		opacity: 1;
		width: 100%;
		
		pointer-events: auto;
        overflow-y: scroll;
        
        
	}
	

  
	
}

.buttonQue {
	    letter-spacing: 0;
		color: #e6e1d6;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: 2.4rem;
		padding: 0 0.7rem;
		height: 2.4rem;
		background: radial-gradient(orangered 50%, red 75%);
		border-radius: 1.2rem;
		position: absolute;
		top: -1.2rem;
		left: 0.7rem;
}

.item {
        display: flex;
        
        flex-direction: column;
        justify-content: center;
        align-items: center;
		max-height: 0;
		opacity: 1;
		margin: 1rem;
		padding: 3rem;
		font-weight: 500;
		text-transform: none;
		letter-spacing: 0;
		font-size: 1.8rem;
		text-align: left;
		background: fade(#362f21, 10%);
        border-bottom: var(--line);
		transition: 300ms background ease-in-out;
		
		p {
			margin: 0;
		}
	}

    .item:hover {
			background: #cdcdcd;
            color: #fff;
		}

.active .activity .item {
	animation: 800ms ease-in 500ms 1 forwards itemLoad;
}

.plbl {
	pointer-events: none;
}

.basic {
	
    z-index:9998;
    height: 100vh;
    width: 100%;
    position: absolute;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
	cursor: pointer;
   
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);
	}
}


`;


const ActiveListButton = (props) => {
    const [url, setURL] = useState('');
	const[ count, setCount] = useState(0);
	const [que, setQueue] = useState([]);
	const [cln, setCln] = useState(getSession("cln"));
	
	const [lbl, setLabel] = useState(getSession("lbl"));
	const [id, setID] = useState("");
	
    const { status, data, error } = useFetch(url);
	
		useInterval(() => {
			loadQueue();
		}, 60000);
  

	useEffect(() => {
		
		if(cln !== null) {
			setURL(`${URL}dispenseReturnDispenseQue?cln=${props.cln}`);
		}

		
	}, []);

	useEffect(() => {
		if (status === 'fetched') {
			setCount(data.length);
			setQueue(data);
		}
	}, [status]);

	

	const loadQueue = () => {
		if(cln !== null) {
			fetch(url, {
				method: 'GET',
				headers: {
					'x-functions-key': getAPI(),	
				}
			})  
			.then(response => response.json())
			.then(data => {
				
				setQueue(data);
				setCount(data.length);
				
			})  
			.catch(err => {
				console.log(err);
			});
	  }	
	}


    const expand_trigger = (event) => {

        
        let  trigger = event.target;       
	    trigger.classList.add("clicked");
	    trigger.classList.toggle("active");

    }

	const deactivateQue = (idx) => {

		fetch(`${URL}dispenseDeActivateQue?que=${idx}`, {
			method: 'GET',
				headers: {
					'x-functions-key': getAPI(),	
				}
			})  
		.then(response => response.json())
		.then(data => {
		 
		  loadQueue();
		 
		  
		})  
	  .catch(err => {
		console.log(err);
	  });
	}


	const labelPrint = (event) => {
	
		

		parent = document.getElementById(event.target.id);
		let arr = parent.children[1].innerHTML.split(" ");
		
		
		let repDOB = arr[2].replace('(' , "").replace(')' , "")	;

		let obj = {};
		obj.idx = event.target.id;
		obj.firstname = arr[0];
		obj.lastname = arr[1];
	    obj.dob = repDOB;


		props.setLabel(obj);

		deactivateQue(event.target.id);

		close = document.getElementById("container");
		close.classList.remove("clicked");
	    close.classList.toggle("active");

		

		
	}

	
        
    const data_table = () => {

		
        try {
         
            if (Array.isArray(que)) {
				
              return que.map((item, index)=>{
               
                  return (
					<div onClick={labelPrint} key={index}  id={item.DSP_IDX} className="item">
                            <div className="plbl">{`${item.ln} (${item.lot})`}</div>
                            <div className="plbl">{`${item.firstname} ${item.lastname} (${item.dob})`}</div>
                    </div>
                   );
              });
          } else {
            return (
               
                    <li className={props.row_class}>
                            <div>The Data List is Empty.</div>
                    </li>
               
            );
          }
          } catch (err) {
            return;
          }
        
    }


    return (
		<>
        <div id="container" onClick={expand_trigger}>
		<Tooltip position="top" tooltip="Information" />
            <Button >
			<div className="buttonQue">{count}</div>
			<span className="buttonText">Dispense Queue</span>
           
                    <section className="activity">
						{data_table()}
                    </section>
			
       
		
            </Button>

			
        </div>
		
		
		</>
    );
}

export default ActiveListButton;