import React, {useEffect, useState} from 'react';
import Table from './table';
import styled from 'styled-components';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import FormHeader from './form-header';
import ReactLoading from 'react-loading';
import DateRange from './date-range';
import { returnTodayDate, returnCurrentMonth, returnDateLastMonth,returnDateTwoWeeksAgo,returnDateOneWeekAgo, getSession } from '../utility';
import BasicDocument from '../pdf/basic_document';


const ReportEngineWrapper = styled.div`

    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    z-index: 998;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .report_container { 
       position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        
        height: 80vh;
        width: 65%;
        
       
        /* background-color: rgba(0,0,0,.6); */
        background-color: var(--color-grey-dark-2);
        z-index: 999;
        opacity: 1;
        transition: all .3s;
   
     min-height: 43.3rem;
     border: 2rem solid  var(--color-primary);
     border-radius: 5px;

     font-size: 1.8rem;
     color:navy;

      }

       

     .report_engine__btn-bar {
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 2rem;
    }

    .rpt_show {
    visibility: visible;
}
    .rpt_hide {
        visibility: hidden;
    }
`;

const ReportEngine = (props)  => {

   
    const [valid_err, setValErr] = useState([]);
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [month, setMonth] = useState('');
    const [print_date, setPrintDate] = useState(returnTodayDate());
    const [active_report, setActiveReport] = useState('');
    const [active_report_name, setActiveReportName] = useState('');
    const [hasRange, setHasRange] = useState(false);
    const [show_range, setShowRange] = useState(false);
    const [hasMonth, setHasMonth] = useState(false);
    const [show_rpt, setShowReport] = useState(false);
    const [table_meta, setTableMeta] = useState([]);
    const [ml, setML] = useState(false);

    useEffect(() => {
        setTableMeta([
            {"col_width": "col-25", "data_label": "Report","key": "name"},
            {"col_width": "col-50", "data_label": "Description","key": "description"}
        ]);

        const query = 1;

        if (query) {
			setQuery(query);
	    }

        setMonth(returnCurrentMonth());



             
    }, []);


    const [query, setQuery] = useState('');

	const url = query && `${URL}reportGetReportList?idx=${query}`;

    const { status, data, error } = useFetch(url);

   

    
    const selectRow = (e) => {
        
        let id = e.target.id;
        const sel = document.getElementById(e.target.id);
        let els = document.getElementsByClassName('table-row--light__selected');

        
        var found = data.filter((item) => { return item.idx === id});
        let obj = new Object();
            obj = found[0];

       

        let daterange = found[0].daterange;
        if (daterange === "1") {
            
            setHasRange(true);
            let period = obj.default_range_period;
            let range = obj.default_range;
            if(period === "week") {
                period = range + period;
            }
        
            console.log('date last month', returnDateLastMonth());
         
            setActiveReportName(obj.name);
            setActiveReport(obj.report);

            switch (period) {
                case 'month':setStart(returnDateLastMonth()); setEnd(returnTodayDate()); setShowRange(true);
                break;
                case '1week':setStart(returnDateOneWeekAgo()); setEnd(returnTodayDate()); setShowRange(true);
                break;
                case '2week':setStart(returnDateTwoWeeksAgo()); setEnd(returnTodayDate()); setShowRange(true);
                break;
            };


        } else {
                console.log(obj);
                
                switch (obj.report) {
                    case 'DP':setHasRange(false); setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);
                    break;
                    case 'PV':setHasRange(false); setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);
                    break;
                    case 'US':setHasRange(false); setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);
                    break;
                    case 'AL':setHasRange(false); setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);
                    break;
                    case 'AD':setHasRange(false); setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);
                    break;
                    case 'DM':setHasRange(false); setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);
                    break;
                    case 'CI':setHasRange(false); setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);
                    break;
                    case 'VI':setHasRange(false); setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);
                    break;
                    case 'EX':setHasRange(false); setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);
                    break;
                    case 'DR':setHasRange(false); setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);
                    break;
                    case 'IM':setHasRange(false); setHasMonth(true); setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);
                    break;

                    case 'ML': setHasRange(false);  setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);  
                    break;
                          
                    default:  setHasRange(false); setActiveReportName(obj.name); setActiveReport(obj.report); setShowReport(true);
                }

        }
       
       
        
    }

    

    const submitReport = () => {

        setShowRange(false);
        setShowReport(true);
    }

  

      const err = () => {
        try {
            if (Array.isArray(valid_err)) {
              return valid_err.map((item, index)=>{
                  return (
                        <ul>
                              <li className="err_list">{item}</li>
                             
                        </ul>
                   );
              });
          } else {
            return (
              <tr><td colSpan='8'><div></div></td></tr>
            );
          }
          } catch (err) {
            return;
          }
    }

  

    return(
        <ReportEngineWrapper>
            <div className='report_container'>
           <FormHeader onClose={props.onClose}  addClose={true} class_style="form_header--light"  title="REPORT ENGINE"></FormHeader>
            <div className="recent__table">

                {status === 'error' && <div>{error}</div>}
				{status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
				{status === 'fetched' && (
                    
                    <Table data={data} meta={table_meta} row_class="table-row" include_header="true" select={selectRow} table_height="tbl-lng"></Table>
				)}
                   
            </div>
                  
                     { show_range ? <DateRange has_range={hasRange} has_month={hasMonth} submit={submitReport} close={() => setShowRange(false)} title={active_report_name} start={start} end={end} month={month} endChange={(v) => setEnd(v)} startChange={(v) => setStart(v)} monthChange={(e) => setMonth(e.target.value)}></DateRange> : null }
                    
                     { show_rpt ? <BasicDocument close={() => setShowReport(false)} active_report={active_report} title={active_report_name.toUpperCase()} query={1} start={start} end={end} month={month} clinic={getSession("cln_display")}/> : null }
                    
                    
            </div>
        </ReportEngineWrapper>
    );
}

export default ReportEngine;