import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';
import { Toggle } from '../theme/toggle';
import { Tooltip } from 'react-tooltip';
import { getSession, getAPI, getSettings } from '../utility';
import { URL } from '../constants';
import Toast from './toaster';
import ButtonSlide  from '../theme/btn-slide';
import Disclaimer from './cspmp_opt_in';



const SettingsWrapper = styled.div`

    z-index:9998;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
   
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .modal_container { 
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
       
        height: 52vh;
        width: 35vw;
        background-color: var(--color-grey-dark-2);
        opacity: 1;
        transition: all .3s;
        min-height: 36.3rem;
        border: 2rem solid  rgba(15, 215, 239, 1);
        border-radius: 5px;

        @media screen and (max-width: 980px) {
            height: 30vh;
            width: 45vw;
        }

        @media screen and (max-height: 950px) {
            height: 50vh;
           
        }

        @media screen and (max-height: 815px) {
            height: 60vh;
           
        }

      }

      .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        margin-top: 3rem;
      }

      .modal-body {
        font-size: 2rem;
        color: navy;
        display: flex;
        flex-direction: column; 
        align-items: center;
        justify-content: center;
        margin-top: var(--line);
        margin-bottom: var(--line);
        row-gap: 2rem;
      }
     

      .modal-nav-timer {
        margin-bottom: 3rem;
        background-color:navy;
      }

      .toggle {

      }

      .version_info {
        color:#fff;
        font-size: 1.6rem;
      }

 
   

`;


const Settings = (props) => {

    
    const [opt_in, setOptIn] = useState(false);
    const [opt, setOpt] = useState(0);
    const [opt_in_waiting, setOptInWaiting] = useState(false);

    const [disclaimer, setDisclaimer] = useState('');   


    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");

    const [reset, setReset] = useState(false);
    const [currentVersion, setCurrentVersion] = useState('');

    const [settings, setSettings] = useState(getSettings());
    const [reset_setting, setResetSettings] = useState(0);

    
    useEffect(() => {
      if ('caches' in window){
        caches.keys().then((keyList) =>
      Promise.all(
        keyList.map((key) => {
          console.log(key);
          let arr = key.split('-');
          if(arr.length > 0) {
            if(arr[0] === 'static') {
              setCurrentVersion(arr[1])
            }  
          }
        })))
      }

    

    }, []);

    useEffect(() => {

      if (reset_setting !== 0) {
        
        setSettings(getSettings());
        handleSave();
  

      }
    
  }, [reset_setting]);

    useEffect(() => {
        if (reset === true) {
           
            props.close();
        }

    }, [reset]);

    useEffect(() => {
      
      if (opt !== 0) {
        setOptIn(true);

      }
       
    }, [opt]);
  

    const handleToggleChange = (state) => {

      let txt = localStorage.getItem('session');
      let sess = JSON.parse(txt);

        if(state === true){
          //zebra

         sess.lblType = "ZR";
         localStorage.setItem('labelType', 'ZR');
         localStorage.setItem('session', JSON.stringify(sess));

       
        } else {
          //sheet
         sess.lblType = "LB";
         localStorage.setItem('labelType', 'LB');
         localStorage.setItem('session', JSON.stringify(sess));
        
        }

        setResetSettings(Math.floor(Math.random() * 100));
      
      }

    
      const handleToggleChange2 = (state) => {

        let txt = localStorage.getItem('session');
        let sess = JSON.parse(txt);

        if(state === true){
                 
          sess.helpType = "ON";
          localStorage.setItem('session', JSON.stringify(sess));
 
        
        } else {
        
          sess.helpType = "OFF";
          localStorage.setItem('session', JSON.stringify(sess));
        
        }
        
        setResetSettings(Math.floor(Math.random() * 100));

        console.log('new settings',getSettings());
  
       
  
      }

      const handleToggleChange3 = (state) => {

        let txt = localStorage.getItem('session');
        let sess = JSON.parse(txt);

        if(state === true){
                 
          sess.modeType = "PC";
          localStorage.setItem('session', JSON.stringify(sess));

        } else {
         
         sess.modeType = "TAB";
          localStorage.setItem('session', JSON.stringify(sess));
          
        }

       

        setResetSettings(Math.floor(Math.random() * 100));

    
      }

      
      const handleToggleChange4 = (state) => {

        let txt = localStorage.getItem('session');
        let sess = JSON.parse(txt);

        if(state === true){
          setOptInWaiting(true);       
          // sess.optIn = true;
          // localStorage.setItem('session', JSON.stringify(sess));
          setDisclaimer('You have Opted-In to send automatic dispense based CSPMP Reports to the State CSPMP Clearing House.  By Selecting the Accept button below, you are agreeing to periodically check your CSPMP Importer Portal to ensure that required reports are being filed without error. If you find any decrepencies or errors you will contact Apotheca Inc. immedieately to investigate.  Please ensure that Apotheca Inc. has a record of all valid usernames and passwords utilized in the SFTP reporting process.');

        } else {
         
          setOptInWaiting(false);
          // sess.optIn = false;
          // localStorage.setItem('session', JSON.stringify(sess));
          setDisclaimer('You have chosen to Opted-Out of sending automatic dispense based CSPMP Reports to the State CSPMP Clearing House. By selecting the Accept button below, you are now commiting to sending manual CSPMP reports via the RUN CSPMP REPORT option daily.');
          
        }

        
        setOpt(Math.floor(Math.random() * 100));
        // setResetSettings(!reset_setting);

        

        
    
      }

      const optInCancel = (tmp) => {
        setOptIn(false);
        setReset(true);
     
      }

      const optInAccept = (tmp) => {
        setOptIn(false);

        let txt = localStorage.getItem('session');
        let sess = JSON.parse(txt);

        sess.optIn = tmp;
        localStorage.setItem('session', JSON.stringify(sess));

        console.log('selected',getSession('cln') + '  ' + tmp);

        let ul = `${URL}cspmpUpsertAutoCSPMP?cln=${getSession("cln")}&opt=${tmp}`;

        fetch(ul, {
          method: 'GET',
              headers: {
                  'x-functions-key': getAPI(),	
              }
          })  //
        .then((rslt) => {
           
            setToastIcon("success");
            setToastMsg("CSPMP Option Change was Successful");
            setToastState(true);
            setToastBackground("#5cb85c");
            setToastTitle("Success");
            
            setReset(true);
        
        })
        .catch(err => {
            setToastIcon("error");
            setToastTitle("Error");
            setToastBackground("#d9534f");
            setToastMsg("There was a Problem Changing the CSPMP Option. Please Try Again Later.");
            setToastState(true);
        });

      }

      const handleSave = () => {   
        //write user settings to local storage

        localStorage.getItem('session');
        let session = JSON.parse(localStorage.getItem('session'));
        let lbl_type = session.lblType;
        let hlp_type = session.helpType;
        let mode_type = session.modeType;
        
        //write user setting to database
     let ul = `${URL}usrUpsertSettings?idx=${getSession("usr")}&lbl=${lbl_type}&help=${hlp_type}&mode=${mode_type}`;

    
    fetch(ul, {
     method: 'GET',
         headers: {
             'x-functions-key': getAPI(),	
         }
     })  //
   .then((rslt) => {
      
       setToastIcon("success");
       setToastMsg("User Settings Change was Successful");
       setToastState(true);
       setToastBackground("#5cb85c");
       setToastTitle("Success");

      
   
   })
   .catch(err => {
       setToastIcon("error");
       setToastTitle("Error");
       setToastBackground("#d9534f");
       setToastMsg("There was a Problem Changing the User Settings. Please Try Again Later.");
       setToastState(true);
   });

}


const resetApp = () => {
 
  localStorage.removeItem('session');
  localStorage.removeItem('device');

  caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
  
  window.location.reload();
  
}


    return(
        <SettingsWrapper>
            <Tooltip id="set-tooltip" place="top" variant="info"  style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }}/>
            <Tooltip id="set-tooltip2" place="bottom" variant="info" style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }} />
            <Tooltip id="set-tooltip3" place="top-start" variant="info" style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }} />
            <Tooltip id="set-tooltip4" place="top-start" variant="info" style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }} />

          <div className="modal_container">
           <FormHeader onClose={props.close} title="APPLICATION SETTINGS" addClose={true} class_style="form_header--light"></FormHeader>
             <div className="modal-body">

                 <a data-tooltip-id="set-tooltip"  data-tooltip-content="Switch between Sheet & Zebra Labels">
                         <div className="toggle">
                              <Toggle label={settings[0].label} toggled={settings[0].state} onClick={handleToggleChange} />
                         </div>
                </a>

                <a data-tooltip-id="set-tooltip2"  data-tooltip-content="Turn ON/OFF CRx Help Guidance">
                         <div className="toggle">
                              <Toggle label={settings[1].label} toggled={settings[1].state} onClick={handleToggleChange2} />
                         </div>
                </a>

                <a data-tooltip-id="set-tooltip3"  data-tooltip-content="Switch between PC & Tablet Mode">
                         <div className="toggle">
                              <Toggle label={settings[2].label} toggled={settings[2].state} onClick={handleToggleChange3} />
                         </div>
                </a>

                <a data-tooltip-id="set-tooltip4"  data-tooltip-content="Opt-In to Automatic CSPMP Reporting">
                         <div className="toggle">
                              <Toggle label={settings[3].label} toggled={settings[3].state} onClick={handleToggleChange4} />
                         </div>
                </a>

                <div className="last_container"><ButtonSlide width={'148%'} click={resetApp} title="RESET/UPDATE CRx" /></div> 

                <div className="version_info">{`The latest version of CRx is ${getSession("version")}. The Installed Version is ${currentVersion} `}</div>
            </div>
            {/* <div className="modal-footer">
                 <ButtonExpand title="SAVE CHANGES"  click={handleSave} />
            </div> */}
            </div>

            {opt_in ? <Disclaimer select={(tmp) => optInAccept(tmp)} cancel={(tmp) => optInCancel(tmp)} disc={disclaimer} opt_temp={opt_in_waiting} /> : null}

            <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
              </div>

        </SettingsWrapper>
    );
}

export default Settings;