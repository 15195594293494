export const postData = async(url, body) => {
    try {

        await   fetch(url,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                        
                    },
                    body: JSON.stringify(body),
                })
                .then(function(response){
                    response.json().then(function(data) {
                        return data;
                    });
                }).catch(function(error) {
                    console.log('Fetch Error:', error);
                });
       
    } catch (error) {
        return error;
    }
}

export const fetchData = async (url) => {
    
    try {
      const res = await fetch(url);
      if(res !== "") {
      const json = await res.json();
      return  json;
      } else {
          return JSON.parse("[]");
      }
     
    } catch (error) {
        return error;
     
    }
  };


  //  FORM VALIDATION
/////////////////////////////////
export const isValidNumber = (x) => {
    let fn = false;
    try {
        fn =  !isNaN(x);
    } catch (err) {
       fn = false;
    }

   return fn;
};

export const isValidString = (x) => {
    let fn = false;
    try {
        fn =  (x != '') && (x != undefined);
    } catch (err) {
       fn = false;
    }

   return fn;
};

export const isDate = (x) => {

    let fn = false;
    
    if (Object.prototype.toString.call(x) === "[object Date]") {
        // it is a date
        if (isNaN(x)) { // d.getTime() or d.valueOf() will also work
          // date object is not valid
          fn = false;
        } else {
          // date object is valid
          fn = true;
        }
      } else {
        // not a date object
        fn = false;
      }
   return fn;
};



export const isPhone = (x) => {
    let fn = false;
    try {
        var patt = /^((\+0?1\s)?)\(?\d{3}\)?[\s.\s]\d{3}[\s.-]\d{4}$/;
        fn =  patt.test(x);
    } catch (err) {
       fn = false;
    }

   return fn;
};

export const isSSN = (x) => {
    let fn = false;
    try {
        var patt = /^\d{3}-\d{2}-\d{4}$/;
        fn = patt.test(x);
    } catch (err) {
       fn = false;
    }

   return fn;
};

export const isZip5 = (x) => {
    let fn = false;
    try {
        var patt = /^\d{5}$/;
        fn = patt.test(x);
    } catch (err) {
       fn = false;
    }

   return fn;
};

export const returnTodayDate = () => {
    const today = new Date(); 
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    let  yyyy = today.getFullYear();

    if (mm < 10) {
        mm = `0${mm}`;
    }

    if(dd < 10) {
        dd = `0${dd}`;
    }
  
    return `${mm}/${dd}/${yyyy} `;
};

export const returnCurrentMonth = () => {
    const today = new Date(); 
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    let  yyyy = today.getFullYear();
  
    return `${mm}`;
}

export const returnDateLastMonth = () => { 
   const today = new Date(); 


   today.setDate(today.getDate() - 30);

    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    let  yyyy = today.getFullYear();

    if (mm < 10) {
        mm = `0${mm}`;
    }

    if(dd < 10) {
        dd = `0${dd}`;
    }
  
  
    return `${mm}/${dd}/${yyyy} `;
};



export const returnDateTwoWeeksAgo = () => { 
    
    const today = new Date(Date.now() - 12096e5);
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    let  yyyy = today.getFullYear();

    if (mm < 10) {
        mm = `0${mm}`;
    }

    if(dd < 10) {
        dd = `0${dd}`;
    }
  
  
    return `${mm}/${dd}/${yyyy} `;
};

//return date a week ago
export const returnDateOneWeekAgo = () => { 
  
    const today = new Date(Date.now() - 6048e5);
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    let  yyyy = today.getFullYear();
  
    if (mm < 10) {
        mm = `0${mm}`;
    }

    if(dd < 10) {
        dd = `0${dd}`;
    }
  
    return `${mm}/${dd}/${yyyy} `;
};

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('');
}

export function validateDate(date) {
    
    let dateString = date;
    const dateObj = new Date(dateString);
    let isDateValid = !isNaN(dateObj.getTime());

    if (dateObj > Date.now()) {
        isDateValid =  false;
    }

    return isDateValid;

}

export function validatePhone(phone) {
    var patt = /^((\+0?1\s)?)\(?\d{3}\)?[\s.\s]\d{3}[\s.-]\d{4}$/;
    return patt.test(phone);
}


export function parseGS1Barcode(barcode) {


    let len = barcode.length;
    let gtin = "";
    let ndc = "";
    let exp = "";
    let lot = "";
    let serial = "";

    let ndc5 = "";
    let ndc3 = "";
    let ndc2 = "";

    let pos = 0;

    // 01
    // 003
    // 12634188819
    // 17
    // 200331
    // 10
    // 2971427
    // 21
    // 1278540

    //GTIN
    if (len > 4) {
       gtin = barcode.substring(0, 5);
    }
    //NDC
    len = barcode.length;
    if (len > 14) {
        barcode = barcode.replace(gtin, "");
        
        ndc5 = barcode.substring(0, 5);
        ndc3 = barcode.substring(5, 8);
        ndc2 = barcode.substring(8, 10);
        ndc = ndc5 + "0" + ndc3 + ndc2;
    }
      
    //EXP
   
    barcode = barcode.replace(ndc5, "");
    barcode = barcode.replace(ndc3, "");
    barcode = barcode.replace(ndc2, "");
    len = barcode.length;
    if (len > 6) {
        barcode = barcode.replace("17", "");
        exp = barcode.substring(1, 7);
    }

    //LOT
    barcode = barcode.replace(exp, "");
    
    if(barcode.indexOf("21") > -1) {
        pos = (barcode.indexOf("21") - 2);
    } else {
        pos = barcode.length;
    }
    
    len = barcode.length;
    if (len > 7) {
        barcode = barcode.replace("10", "");
        lot = barcode.substring(1, pos);
    }
    //SERIAL
    barcode = barcode.replace(lot, "");
    len = barcode.length;
    if (len > 4) {
        barcode = barcode.replace("21", "");
            serial = barcode.substring(1, barcode.length);
    }

    let obj = {};
    obj.gtin = gtin;
    obj.ndc = ndc;
    obj.exp = exp;
    obj.lot = lot;
    obj.serial = serial;

    return obj;

}

/////////////////////////////////////////////////////
//               SESSION STORAGE                   //
/////////////////////////////////////////////////////

export const getSession = (key) => {
   try {
    let session = JSON.parse(localStorage.getItem('session'));
    let device = JSON.parse(localStorage.getItem('device'));

    if(session !== null) {
        switch(key) {
            case 'UserFullName': return session.UserFullName;
                break;
             case 'PIN': return session.pin_hsh;
                break;
            case 'Role': return session.Role;
                break;
            case 'DeviceToken': return device.deviceToken;
                break;
            case "cln": return device.CLN_IDX;
                break;
            case "usr": return session.USR_IDX;
                break;
            case "grp": return device.GRP_IDX; 
                break
            case "sessionToken": return session.sessionToken;
                break;
            case "cln_display" : return device.clnDisplayName;
            break;
            case "cln_alias" : return device.clnAlias;
            break;
            case "tablet" : return session.modeType === "TAB" ? true : false;
            break;
            case "help" : return session.helpType === "ON" ? true : false;
            break;
            case "opt_in" : return session.optIn;
            break;
            case "lbl": return session.lblType;
            break;
            case "version": return session.app_version;
        
            default: return null;
       }
    } else {
        return null;
    }
    
    } catch (err) {
        return null;
    }

};

export const getAPI = () => {
    return 'D8pDHeV8sRGb7EO4/91s89rE2kr/qq+wZ3O2HgyvtZ9I/qyiYC/rzg==';
}

  export const openTabs = () => {
    var stor = window.localStorage;

   stor.openpages = Date.now();
    window.addEventListener('storage', function (e) {
        if(e.key == "openpages") {
            // Listen if anybody else is opening the same page!
            stor.page_available = Date.now();
        }
        if(e.key == "page_available") {
            console.log("Page is already open!");
            
        } else {
           console.log("Page is not open!");
        }
    }, false);
   
    

   
  }

 export const getOS = () => {

    let os = navigator.userAgent;
    let finalOs="";
    if (os.search('Windows')!==-1){
        if (window.navigator.pointerEnabled && navigator.maxTouchPoints > 1) {
            finalOs="Surface";
        } else {
            finalOs="Windows";
        }
        
    }
    else if (os.search('Mac')!==-1){
        finalOs="MacOS";
    }
    else if (os.search('X11')!==-1 && !(os.search('Linux')!==-1)){
        finalOs="UNIX";
    }
    else if (os.search('Linux')!==-1 && os.search('X11')!==-1){
        finalOs="Linux"
    } else if (os.search('Andriod')!==-1) {
            finalOs="Andriod";
    } else if (os.search('iPhone')!==-1) {
            finalOs="iPhone";
    } else if (os.search('iPad')!==-1) {
            finalOs="iPad";
    }
    return finalOs;

}

export const isTablet = () => {
    let os = navigator.userAgent;
    let rslt = false;

    if (os.search('Windows')!==-1){
        if (window.navigator.pointerEnabled && navigator.maxTouchPoints > 1) {
            rslt = true;
        } else {
           rslt = false;
        }
        
    }
    else if (os.search('Mac')!==-1){
        rslt = false;
    }
    else if (os.search('X11')!==-1 && !(os.search('Linux')!==-1)){
        rslt = false;
    }
    else if (os.search('Linux')!==-1 && os.search('X11')!==-1){
        rslt = false;
    } else if (os.search('Andriod')!==-1) {
        rslt = true;
    } else if (os.search('iPhone')!==-1) {
        rslt = true;
    } else if (os.search('iPad')!==-1) {
        rslt = true;
    }
    return rslt;


}

export const isGlobal = () => {
    let session = JSON.parse(localStorage.getItem('session'));
    
    let role = "";
    let rslt = false;

    if(session !== undefined && session !== null) {
        role = session.Role;

        if(role === 'UEsa3@bvDU7') {
            rslt = true;
        }
        
   
    } else {    
        rslt = false;

    }

   

    return rslt;
}

export const isAdmin = () => {



    let session = JSON.parse(localStorage.getItem('session'));
  
    let role = "";
    let rslt = false;

    if(session !== undefined && session !== null)    {
        role = session.Role;

        if(role === 'UEsa3@bvDU7') { return true;  }
        
        
        if(role === 'TRed54$eWqA' ) {
            rslt = true;
        }
        
   
    } else {    
        rslt = false;

    }

   

    return rslt;

}

export const userAgentDetails = () =>
{
    var nVer = navigator.appVersion;
var nAgt = navigator.userAgent;
var browserName  = navigator.appName;
var fullVersion  = ''+parseFloat(navigator.appVersion); 
var majorVersion = parseInt(navigator.appVersion,10);
var nameOffset,verOffset,ix;

// In Opera, the true version is after "OPR" or after "Version"
if ((verOffset=nAgt.indexOf("OPR"))!=-1) {
 browserName = "Opera";
 fullVersion = nAgt.substring(verOffset+4);
 if ((verOffset=nAgt.indexOf("Version"))!=-1) 
   fullVersion = nAgt.substring(verOffset+8);
}
// In MS Edge, the true version is after "Edg" in userAgent
else if ((verOffset=nAgt.indexOf("Edg"))!=-1) {
 browserName = "Microsoft Edge";
 fullVersion = nAgt.substring(verOffset+4);
}
// In MSIE, the true version is after "MSIE" in userAgent
else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
 browserName = "Microsoft Internet Explorer";
 fullVersion = nAgt.substring(verOffset+5);
}
// In Chrome, the true version is after "Chrome" 
else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
 browserName = "Chrome";
 fullVersion = nAgt.substring(verOffset+7);
}
// In Safari, the true version is after "Safari" or after "Version" 
else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
 browserName = "Safari";
 fullVersion = nAgt.substring(verOffset+7);
 if ((verOffset=nAgt.indexOf("Version"))!=-1) 
   fullVersion = nAgt.substring(verOffset+8);
}
// In Firefox, the true version is after "Firefox" 
else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
 browserName = "Firefox";
 fullVersion = nAgt.substring(verOffset+8);
}
// In most other browsers, "name/version" is at the end of userAgent 
else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
          (verOffset=nAgt.lastIndexOf('/')) ) 
{
 browserName = nAgt.substring(nameOffset,verOffset);
 fullVersion = nAgt.substring(verOffset+1);
 if (browserName.toLowerCase()==browserName.toUpperCase()) {
  browserName = navigator.appName;
 }
}
// trim the fullVersion string at semicolon/space if present
if ((ix=fullVersion.indexOf(";"))!=-1)
   fullVersion=fullVersion.substring(0,ix);
if ((ix=fullVersion.indexOf(" "))!=-1)
   fullVersion=fullVersion.substring(0,ix);

majorVersion = parseInt(''+fullVersion,10);
if (isNaN(majorVersion)) {
 fullVersion  = ''+parseFloat(navigator.appVersion); 
 majorVersion = parseInt(navigator.appVersion,10);
}

    let obj = {};
    obj.browser = browserName;
    obj.fullVersion = fullVersion;
    obj.majorVersion = majorVersion;
    obj.appName = navigator.appName;
    obj.userAgent = navigator.userAgent;

    return obj;

}

export const getSettings = () => {

    try {
    let session = JSON.parse(localStorage.getItem('session'));

    if(session !== null) {

        let labelType = new Object();
        labelType.value = session.lblType;
        labelType.label = session.lblType === "ZR" ? "Zebra Label Print" : "Sheet Label Print";
        labelType.state = session.lblType === "ZR" ? true : false;

        let viewHelp = new Object();
        viewHelp.value = session.helpType;
        viewHelp.label = session.helpType === "ON" ? "CRx Help is ON" : "CRx Help is OFF";
        viewHelp.state = session.helpType === "ON" ? true : false;

        
        let modeType = new Object();
        modeType.value = session.modeType;
        modeType.label = session.modeType === "PC" ? "PC Mode" : "Tablet Mode";
        modeType.state = session.modeType === "PC" ? true : false;

        let autoCSPMP = new Object();
        autoCSPMP.value = session.optIn;
        autoCSPMP.label = session.optIn === true ? "Auto CSPMP is ON" : "Auto CSPMP is OFF";
        autoCSPMP.state = session.optIn === true ? true : false;

        let settings = new Array();
        settings.push(labelType);
        settings.push(viewHelp);
        settings.push(modeType);
        settings.push(autoCSPMP);

        return settings;

    } else {
        return null;
    }

    } catch (err) {
        return null;
    }
}
