export const URL = "https://crx-func.azurewebsites.net/api/";


export const us_states = [
    '--', 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
    'AS', 'DC', 'FM', 'GU', 'MH', 'MP', 'PR', 'PW', 'VI',
];

export const mx_states = [
'--', 'AG', 'BC', 'BS', 'CM', 'CS', 'CH', 'CO', 'CL', 'DF', 'DG', 'GT', 'GR', 'HG',
'JA', 'MX', 'MI', 'MO', 'NA', 'NL', 'OA', 'PU', 'QT', 'QR', 'SL', 'SI', 'SO', 'TB', 'TM',
'TL', 'VE', 'YU', 'ZA',
];


export const cn_states = [
    '--', 'AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT',
    ];

export const reason_codes = ['--------', 'Damaged During Shipping', 'Dispensed Incorrect Drug', 'Mislabeled Drug', 'Expiration Date Exceeded', 'Removed From Inventory', 'Other', 'Inventory Adjusted By Apotheca', 'Drug Utilized IN HOUSE', 'Inventory Recalled to Apotheca', 'Inventory Transferred'];

export const reason_code_idx = ['0', '1', '2', '3', '4', '5', '7', '8', '9', '6'];

export const prefix = ['----',
'Mr.',
'Mrs.',
'Ms.',
'Miss',
'Dr.',
'Sr.',
'Sra.',
'Fr.',
'Rev.',
];


export const suffix = ['----',
'DDS',
'DO',
'Jr.',
'LVN',
'MD',
'NP',
'PA',
'RN',
'Sr.',
'I',
'II',
'III',
'PhD',
'PharmD',
'RPh',
'MA',
'OD',
'CNP',
'CNM',
'RPAC',
'FACC',
'FACP',
'LPN',
'Esq.',
'IV',
'DPM',
'PAC',
'CNS',
'RD',
'CRNP',
'FNP',
'ANP',
'GNP',
'PNP',
'APRN',
'ARNP',
'ABOC',
];

export const shape_codes = [
   {
     "text": "BULLET",
     "value": "C48335"
   },
   {
     "text": "CAPSULE",
     "value": "C48336"
   },
   {
     "text": "CLOVER",
     "value": "C48337"
   },
   {
     "text": "DIAMOND",
     "value": "C48338"
   },
   {
     "text": "DOUBLE CIRCLE",
     "value": "C48339"
   },
   {
     "text": "FREEFORM",
     "value": "C48340"
   },
   {
     "text": "HEXAGON",
     "value": "C48343"
   },
   {
     "text": "OCTAGON",
     "value": "C48344"
   },
   {
     "text": "OVAL",
     "value": "C48345"
   },
   {
     "text": "PENTAGON",
     "value": "C48346"
   },
   {
     "text": "RECTANGLE",
     "value": "C48347"
   },
   {
     "text": "ROUND",
     "value": "C48348"
   },
   {
     "text": "SEMI-CIRCLE",
     "value": "C48349"
   },
   {
     "text": "SQUARE",
     "value": "C48350"
   },
   {
     "text": "TEAR",
     "value": "C48351"
   },
   {
     "text": "TRAPEZOID",
     "value": "C48352"
   },
   {
     "text": "TRIANGLE",
     "value": "C48353"
   }
 ];

 export const color_codes = [
   {
     "text": "BLACK",
     "value": "C48323"
   },
   {
     "text": "BLUE",
     "value": "C48333"
   },
   {
     "text": "BROWN",
     "value": "C48332"
   },
   {
     "text": "GRAY",
     "value": "C48324"
   },
   {
     "text": "GREEN",
     "value": "C48329"
   },
   {
     "text": "ORANGE",
     "value": "C48331"
   },
   {
     "text": "PINK",
     "value": "C48328"
   },
   {
     "text": "PURPLE",
     "value": "C48327"
   },
   {
     "text": "RED",
     "value": "C48326"
   },
   {
     "text": "TURQUOISE",
     "value": "C48334"
   },
   {
     "text": "WHITE",
     "value": "C48325"
   },
   {
     "text": "YELLOW",
     "value": "C48330"
   }
 ];

export const dl_fields = [
    'DAA', 'DAB', 'DCS', 'DAC', 'DAD', 'DAG', 'DAI', 'DAJ', 'DAK', 'DAQ', 'DBC', 'DBB',
];

export const months = [{ value: '1', label: 'January' }, { value: '2', label: 'February' }, { value: '3', label: 'March' }, { value: '4', label: 'April' }, { value: '5', label: 'May' }, { value: '6', label: 'June' }, { value: '7', label: 'July' }, { value: '8', label: 'August' }, { value: '9', label: 'September' }, { value: '10', label: 'October' }, { value: '11', label: 'November' }, { value: '12', label: 'December' }];

export const inv = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
    10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
    30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
    50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
    70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
    80, 81, 82, 83, 84, 85, 86, 87, 88, 89,
    90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
    ];

export const reactions = [
        'Reactions...',
        'Abdominal Pain',
        'Anaphylaxis',
        'Anemia',
        'Arrhythmia',
        'Asthma',
        'Bradycardia',
        'Constipation',
        'Cough',
        'Shock',
        'Skin Itching',
        'Hives',
        'General Swelling',
        'Wheezing',
        'Headache',
        'Nausea',
        'Diarrhea',
        'Eye Itch',
        'Runny Nose',
        'Watery Eyes',
        'Rash',
        'Photoallergy',
        'Fever',
        'Swelling of the Tongue',
        'Muscle Aches',
        'Lymph Node Swelling',
        'Kidney Inflamation',
        'Throat Tightness',
        'Irregular Heartbeat',
        'Swelling of the Face',
        'Fainting',
        'Shortness of Breath',
        'Flushing',
        'Other',
        ];

        export const ptt = {"idx": 23456, "firstName":"John", "lastName":"Kelly","middleName":"", "dob":"11/23/1993","gender": "M", 
        "phone":"(602) 278-9999", "ssn":"000-00-0099", "address1": "One Brewer Way", "city": "Milwaukee", "state": "AZ", "country": "US", "zipCode": "85024" };
       
        export const rx_history = [
           {"idx": 12,"rxNumber": "001232435", "ln": "Amoxicillian 400 MG Tablet", "writtenDate": "11/19/2017", "source": "Dispense"},
           {"idx": 13, "rxNumber": "001232345", "ln": "CEPHALEXIN 500 MG CAPSULE",  "writtenDate": "10/20/2018", "source": "Dispense"},
           {"idx": 14, "rxNumber": "001232978", "ln": "Amoxicillian 400 MG Tablet", "writtenDate": "11/19/2019", "source": "Dispense"},
           {"idx": 15, "rxNumber": "001234589", "ln": "Amoxicillian 400 MG Tablet", "writtenDate": "11/19/2019", "source": "Dispense"},
           {"idx": 16, "rxNumber": "001232435", "ln": "Amoxicillian 400 MG Tablet", "writtenDate": "11/19/2019", "source": "Dispense"},
           {"idx": 17, "rxNumber": "001232435", "ln": "Amoxicillian 400 MG Tablet", "writtenDate": "11/19/2019", "source": "Dispense"}
        ];
       
        export const allergen_profile = [
            {"idx": 12, "allergen": "Tylenol-Codeine #2", "onsetDate": "09/22/2008", "type": "Codified", "reaction": "Cough", "reactionType": "Intolerence", "severity": "Moderate"},
            {"idx": 13, "allergen": "Tylenol-Codeine #2", "onsetDate": "09/22/2008", "type": "Codified", "reaction": "Cough", "reactionType": "Intolerence", "severity": "Severe"},
            {"idx": 14, "allergen": "Tylenol-Codeine #2", "onsetDate": "09/22/2008", "type": "Codified", "reaction": "Cough", "reactionType": "True Allergy", "severity": "Mild"}
        ];
       
        export const problem_list = [
            {"idx": 12, "problem": "Acute Dislocation", "onsetDate": "06/23/2017", "codeSystem": "ICD-10", "code": "314-78", "provider": "Wilson"},
            {"idx": 13, "problem": "BiPlolar Disorder", "onsetDate": "09/20/2017", "codeSystem": "ICD-10", "code": "319-67", "provider": "Wilson"}
        ];
       
        export const active_patients = [
           {"idx": 100, "name": "Grant Dayton", "dob": "11/25/1987"},
           {"idx": 101, "name": "Mike Foltynewicz","dob": "10/07/1991"},
           {"idx": 102, "name": "Max Fried", "dob": "01/18/1994"},
           {"idx": 103, "name": "Shane Greene", "dob": "11/17/1988"},
           {"idx": 104, "name": "Cole Hamels", "dob": "12/27/1983"},
           {"idx": 105, "name": "Luke Jackson", "dob": "08/24/1991"},
           {"idx": 106, "name": "Chris Martin", "dob": "06/02/1986"},
           {"idx": 107, "name": "Mark Melancon", "dob": "03/28/1985"},
           {"idx": 108, "name": "Sean Newcomb", "dob": "06/12/1993"},
           {"idx": 109, "name": "Darren O'Day", "dob": "10/22/1982"},
           {"idx": 110, "name": "Will Smith", "dob": "07/10/1989"},
           {"idx": 111, "name": "Mike Soroka", "dob": "08/04/1997"},
           {"idx": 112, "name": "Jeremy Walker", "dob": "06/12/1995"},
           {"idx": 113, "name": "Kyle Wright", "dob": "10/02/1995"},
           {"idx": 114, "name": "Travis d'Arnaud", "dob": "02/10/1989"},
           {"idx": 115, "name": "Tyler Flowers", "dob": "01/24/1986"},
           {"idx": 116, "name": "Ozzie Albies", "dob": "01/07/1997"},
           {"idx": 117, "name": "Johan Camargo", "dob": "12/13/1993"},
           {"idx": 118, "name": "Freddie Freeman", "dob": "09/12/1989"},
           {"idx": 119, "name": "Adeiny Hechavarría", "dob": "04/15/1989"},
           {"idx": 120, "name": "Dansby Swanson", "dob": "02/11/1994"},
           {"idx": 121, "name": "Ronald Acuña Jr.", "dob": "12/18/1997"},
           {"idx": 122, "name": "Adam Duvall", "dob": "09/04/1988"},
           {"idx": 123, "name": "Ender Inciarte", "dob": "10/29/1990"},
           {"idx": 124, "name": "Nick Markakis", "dob": "11/17/1983"},
           {"idx": 125, "name": "Marcell Ozuna", "dob": "11/12/1990"},
           {"idx": 126, "name": "Austin Riley", "dob": "04/02/1997"}
        ];
       
        export const recent_patients = [
           {"idx": 107, "name": "Mark Melancon", "dob": "03/28/1985"},
           {"idx": 108, "name": "Sean Newcomb", "dob": "06/12/1993"},
           {"idx": 109, "name": "Darren O'Day", "dob": "10/22/1982"},
           {"idx": 110, "name": "Will Smith", "dob": "07/10/1989"},
           {"idx": 111, "name": "Mike Soroka", "dob": "08/04/1997"},
           {"idx": 112, "name": "Jeremy Walker", "dob": "06/12/1995"}
        ];
       
        export const pvd_list = [
           {"idx": 107, "firstName": "Mark", "lastName": "Melancon","FullName": "MELANCON MARK","dea": "BM1276354", "dea_expire": "03/28/2022", "country": 'US', 'title':'DO'},
           {"idx": 108, "firstName": "Kevin", "lastName": "Johnson","FullName": "JOHNSON KEVIN","dea": "MJ1276354", "dea_expire": "06/28/2022", "country": 'US', 'title':'DO'},
           {"idx": 109, "firstName": "Tom", "lastName": "Chambers","FullName": "CHAMBERS TOM","dea": "TC1276354", "dea_expire": "04/28/2022", "country": 'US', 'title':'DO'},
           {"idx": 110, "firstName": "Chad", "lastName": "Hostel","FullName": "HOSTEL CHAD","dea": "CH1276354", "dea_expire": "05/28/2022", "country": 'CN', 'title':'DO'},
        ];
       
        export const pvd_active = { "idx": 107, "firstName": "Mark", "lastName": "Melancon","dea": "BM1276354", "dea_expire": "03/28/2022"};
       
       
        export const dsp_list = [
           {"idx": 120, "firstName": "Matt", "lastName": "Masters","FullName": "MASTERS MATT", "title": "DO", "alias": "Field bag 1", "shipto": "0005", "country": "US","license": "10002558", "license_state": "GA"},
           {"idx": 121, "firstName": "Dean", "lastName": "Smith","FullName": "SMITH DEAN", "title": "DO", "alias": "Field bag 2", "shipto": "0005", "country": "US","license": "10002558", "license_state": "GA"},
           {"idx": 122, "firstName": "Chuck", "lastName": "Riley","FullName": "RILEY CHUCK", "title": "DO", "alias": "Field bag 3", "shipto": "0005", "country": "US","license": "10002558", "license_state": "AZ"},
           {"idx": 123, "firstName": "Terry", "lastName": "Curtis","FullName": "CURTIS TERRY", "title": "DO", "alias": "Field bag 4", "shipto": "0005", "country": "CN","license": "10002558", "license_state": "AB"}
        ];
       
        export const usr_list = [
           {"idx": 150, "firstName": "Ken", "lastName": "Smith","FullName": "Ken Smith","username": "ksmith","password": "1234", "role": "2", "pin": "234", "mobilePhone": "(602) 363-9045", "email": "ken@gmail"},
           {"idx": 151, "firstName": "Bill", "lastName": "Baily","FullName": "Bill Baily","username": "bill","password": "2X3eq6", "role": "3", "pin": "451", "mobilePhone": "(602) 363-9023", "email": "bill@gmail"},
           {"idx": 152, "firstName": "Larry", "lastName": "Thomas","FullName": "Larry Thomas","username": "larry","password": "21hello", "role": "1", "pin": "123", "mobilePhone": "(602) 363-9035", "email": "larry@gmail"}
        ];
       
        export const site_list = [
           {"idx": 200, "cln_name": "Atlanta Braves-Season Site", "cln_number": "602334", "cln_shipto": "0001", "phone": "(602) 258-9999", "fax": "(602) 456-7825", "contact":"Kevin Johnson", "email": "kj@atlbraves.com", "address1": "1 Braves Way", "city": "Atlanta", "zip": "30524","state": "GA" },
           {"grp": 1, "cln_name": "Atlanta Braves-Spring Training Site", "cln_number": "602334", "cln_shipto": "0002", "phone": "(602) 258-9999", "fax": "(602) 456-7825", "contact":"Kevin Johnson", "email": "kj@atlbraves.com","country": "US", "address1": "106 Elm Place", "city": "Kissimee", "zip": "20522","state": "FL" }
        ];
       
        export const orders = [
           {"idx": 1002, "last_order_qty": 15, "unit_qty": 60, "current_inv_qty": 2, "ln": "ADVAIR 250-50 DISKUS" },
           {"idx": 1003, "last_order_qty": 30, "unit_qty": 10, "current_inv_qty": 1, "ln": "ALLERGY RELIEF-NASAL DECONG TB" },
           {"idx": 1004, "last_order_qty": 5, "unit_qty": 30, "current_inv_qty": 11, "ln": "AMOXICILLIN 500 MG CAPSULE" },
           {"idx": 1005, "last_order_qty": 2, "unit_qty": 6, "current_inv_qty": 10, "ln": "AZITHROMYCIN 250 MG TABLET" },
           {"idx": 1006, "last_order_qty": 10, "unit_qty": 30, "current_inv_qty": 9, "ln": "BACTROBAN 2% CREAM" },
           {"idx": 1007, "last_order_qty": 25, "unit_qty": 5, "current_inv_qty": 2, "ln": "BETAMETHASONE AC-SP 6 MG/ML VL" },
           {"idx": 1008, "last_order_qty": 12, "unit_qty": 7, "current_inv_qty": 5, "ln": "CELEBREX 200 MG CAPSULE" },
           {"idx": 1009, "last_order_qty": 10, "unit_qty": 10, "current_inv_qty": 2, "ln": "CETIRIZINE HCL 10 MG TABLET" },
           {"idx": 1010, "last_order_qty": 10, "unit_qty": 10, "current_inv_qty": 1, "ln": "CYCLOBENZAPRINE 10 MG TABLET" },
           {"idx": 1011, "last_order_qty": 20, "unit_qty": 10, "current_inv_qty": 6, "ln": "DEPO-MEDROL 40 MG/ML VIAL" },
           {"idx": 1012, "last_order_qty": 60, "unit_qty": 10, "current_inv_qty": 12, "ln": "DEXAMETHASONE 100 MG/10 ML VL" },
           {"idx": 1013, "last_order_qty": 10, "unit_qty": 30, "current_inv_qty": 4, "ln": "DICLOFENAC POT 50 MG TABLET" },
           {"idx": 1014, "last_order_qty": 5, "unit_qty": 16, "current_inv_qty": 9, "ln": "FLUTICASONE PROP 50 MCG SPRAY" },
           {"idx": 1015, "last_order_qty": 25, "unit_qty": 8, "current_inv_qty": 5, "ln": "INDOMETHACIN ER 75 MG CAPSULE" },
           {"idx": 1016, "last_order_qty": 5, "unit_qty": 10, "current_inv_qty": 7, "ln": "KETOROLAC 10 MG TABLET" },
           {"idx": 1017, "last_order_qty": 12, "unit_qty": 60, "current_inv_qty": 14, "ln": "LIDOCAINE HCL 1% VIAL" },
           {"idx": 1018, "last_order_qty": 10, "unit_qty": 30, "current_inv_qty": 17, "ln": "MELOXICAM 15 MG TABLET" }
        ];
       
        export const shipto_list = [
           {"idx": 200, "name": "Atlanta Braves-Season Site", "cln_number": "602334", "shipto": "0001"},
           {"idx": 201, "name": "Atlanta Braves-Spring Training Site", "cln_number": "602334", "shipto": "0002"},
           {"idx": 120, "name": "Matt Masters", "shipto": "0003"},
           {"idx": 121, "name": "Dean Smith",  "shipto": "0004"},
           {"idx": 122, "name": "Chuck Riley", "shipto": "0005"},
           {"idx": 123, "name": "Terry Curtis", "shipto": "0006"}
       
        ];
       
        export const reports = [
           {"idx": 170, "report":"Current Inventory", "desc": "Snapshot of current digital inventory on hand.", "hasRange": "rpt_hide"},
           {"idx": 171, "report":"Dispensing Report", "desc": "Overall dispense activity for this clinic.", "hasRange": "rpt_show"},
           {"idx": 173, "report":"Adjustment Report", "desc": "Listing of all recent manual adjustments made to digital inventory.", "hasRange": "rpt_show"},
           {"idx": 174, "report":"Inventory Management", "desc": "Manage Inventory based on usage history.", "hasRange": "rpt_show"},
           {"idx": 175, "report":"Received Inventory", "desc": "Inventory received into Custom Rx.", "hasRange": "rptshow"},
           {"idx": 176, "report":"Provider Activity", "desc": "Provider based dispensing history.", "hasRange": "rpt_show"},
           {"idx": 177, "report":"Drug Activity", "desc": "Drug based dispensing history.", "hasRange": "rpt_show"},
           {"idx": 178, "report":"Drug Pricing", "desc": "Current Drug Pricing Information.", "hasRange": "rpt_hide"},
           {"idx": 179, "report":"Active Patients", "desc": "Patients currently active in the system..", "hasRange": "rpt_hide"},
           {"idx": 180, "report":"Audit Report", "desc": "Dispensing Activity formatted for auditing.", "hasRange": "rpt_show"},
           {"idx": 181, "report":"Controlled Substance", "desc": "Control Substance dispense history.", "hasRange": "rpt_show"},
           {"idx": 182, "report":"Alert Report", "desc": "Patient Active Alerts in Custom Rx.", "hasRange": "rpt_hide"},
           {"idx": 183, "report":"Adherence Report", "desc": "Current on-going Drug Thearpies..", "hasRange": "rpt_hide"},
           {"idx": 184, "report":"Active Providers", "desc": "Providers currently active in the system..", "hasRange": "rpt_hide"},
           {"idx": 185, "report":"Active Users", "desc": "Users currently active in the system..", "hasRange": "rpt_hide"},
           {"idx": 186, "report":"Daily Dispense", "desc": "Provider based Daily Dispening..", "hasRange": "rpt_show"},
           {"idx": 187, "report":"Expiring MultiDose", "desc": "MultiDose Drugs nearing Expiration..", "hasRange": "rpt_hide"},
           {"idx": 188, "report":"Virtual Dispensing", "desc": "Virtual Inv. Dispensing Activity.", "hasRange": "rpt_show"},
           {"idx": 189, "report":"Dispenser Virtual Inventory", "desc": " Snapshot Dispenser Virtual Inventory.", "hasRange": "rpt_hide"}
        ];
       
       
        export const lots = [
           {"idx": 200, "ln": "ADVAIR 250-50 DISKUS", "lot": "1000987-2", "minLevel": 3, "unitPrice": "$4.50", "inv": 22},
           {"idx": 201, "ln": "ADVAIR 250-50 DISKUS", "lot": "BA304-1A", "minLevel": 3, "unitPrice": "$4.50", "inv": 2},
           {"idx": 202, "ln": "ADVAIR 250-50 DISKUS", "lot": "10004", "minLevel": 3, "unitPrice": "$4.50", "inv": 10},
           {"idx": 203, "ln": "ADVAIR 250-50 DISKUS", "lot": "AB102", "minLevel": 3, "unitPrice": "$4.50", "inv": 9}
        ];
       

        
        export const reprint = [
            {"idx": 200, "name": "Carol Brown","ln": "ADVAIR 250-50 DISKUS"},
            {"idx": 201, "name": "Angi Bruss", "ln": "ADVAIR 250-50 DISKUS"},
            {"idx": 120, "name": "Matt Masters", "ln": "ADVAIR 250-50 DISKUS"},
            {"idx": 121, "name": "Dean Smith",  "ln": "ADVAIR 250-50 DISKUS"},
            {"idx": 122, "name": "Chuck Riley", "ln": "ADVAIR 250-50 DISKUS"}
        
         ];

       
        export const drg_base = [];
        export const pat_base = [];
        export const dsp_base = [];
        export const latest_dsp_base = [];



