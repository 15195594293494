import React from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import ButtonExpand from '../theme/btn_expand';


const WarningModalWrapper = styled.div`

    z-index:9998;
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
   
    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .modal_container { 
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
       
        height: 20vh;
        width: 40vw;
        background-color: var(--color-grey-dark-2);
        opacity: 1;
        transition: all .3s;
        min-height: 36.3rem;
        border: 2rem solid  rgba(255, 0, 0, 0.5);
        border-radius: 5px;

        @media screen and (max-width: 1029px) {
            height: 27vh;
            width: 45vw;
        }

      }

      .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        margin-top: 3rem;
      }

      .modal-body {
        font-size: 3rem;
        color: #fff;
        display: flex;
        flex-direction: column; 
        align-items: center;
        justify-content: center;
        margin-top: var(--line);
        margin-bottom: var(--line);
        padding:2rem;
        border: 1rem solid  var(--color-primary);
      }
     

      .modal-nav-timer {
        margin-bottom: 3rem;
        background-color:navy;
      }

      .warn_body {
        font-size: 2.75rem;  
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        @media screen and (max-width: 1229px) {
            font-size: 2rem;

      }

    }



`;


const WarningModal = (props) => {

  

    return(
        <WarningModalWrapper>
          <div className="modal_container">
           <FormHeader onClose={props.close} title={props.title} addClose={false} class_style="form_header--light"></FormHeader>
           

            <div className="modal-body">
                <p>
                   {props.header}
                </p>
                <p className="warn_body">
                    {props.body}
                </p>
            </div>
            
            { props.show_btn ? <div className="modal-footer">
                <ButtonExpand title="CANCEL DISPENSE"  click={props.cancel} />
                    
                
                <ButtonExpand title="OVERRIDE WARNING" click={props.accept} />
                    
            </div> : null}


            </div>
        </WarningModalWrapper>
    );
}

export default WarningModal;