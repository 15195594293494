import { useState, useEffect } from "react";
import {
    Document,
    Page,
    Font,
    Text,
    View,
    StyleSheet,
    PDFViewer,
  } from "@react-pdf/renderer";
import {URL} from '../constants';
import useFetch from '../hooks/useFetch';
import BenchNine from './fonts/BenchNine/BenchNine-Regular.ttf';
import ReactLoading from 'react-loading';
import { getSession } from '../utility';

Font.register({
    family: "BenchNine",
    format: "truetype",
    src: BenchNine,
  })
  
  const BORDER_COLOR = '#bfbfbf'
  const BORDER_STYLE = 'solid'
  const COL1_WIDTH = 40
  const COLN_WIDTH = (100 - COL1_WIDTH) / 4
  
  
  const styles = StyleSheet.create({
    body: {
      padding: 10,
      fontFamily: "BenchNine",
    },
    viewer: {
      width: window.innerWidth / 1.78,
      height: window.innerHeight / 1.60,
    },
    table: { 
      display: "table", 
      width: "auto", 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0 
    }, 
  
    tableRow: { 
      
      flexDirection: "row",
      backgroundColor: "#E0F9FF"
    
    }, 
  
    tableHeaderRow: { 
      width: "100%",
      margin: "auto", 
      flexDirection: "row",
      backgroundColor: "#FFF3D4"
    
    }, 
  
    tableTitleRow: {
     
      marginLeft: "12%",
      flexDirection: "row",
      backgroundColor: "#FFFFFF"
    },
    tableCol1Title: { 
      width: '33%', 
      
    }, 
  
    tableCol2Title: { 
      width: "33%" 
      
    }, 
  
    tableCol3Title: { 
      width: "33%"
      
    }, 
  
  
    'tableRow:nth-child(odd)': {
      backgroundColor: "#E0F9FF"
    },
  
    'tableRow:nth-child(even)': {
      backgroundColor: "#CDCDCD"
    },
  
    tableCol1Header: { 
      width: '40%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },    
    tableCol2Header: { 
      width: '30%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },   
    tableCol3Header: { 
      width: '30%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },    
    tableColHeader: { 
      width: COLN_WIDTH + "%", 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },  
    tableNoReportHeader: {
        width: '100%',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
        
    },
  
    tableColClinic: { 
      width: COL1_WIDTH + '%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    },   
    tableCol1: { 
      width: COL1_WIDTH + '%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    },   
    tableCol: { 
      width: COLN_WIDTH + "%", 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    }, 
    tableCellHeader: {
      margin: 5, 
      fontSize: 12,
      fontWeight: 500
    },  
    tableCell: { 
      margin: 5, 
      fontSize: 10 
    }
  });

  const InventoryManagement = (props) => {

    const [query, setQuery] = useState('');
    const [month_string, setMonthString] = useState('');


    useEffect(() => {

       setQuery(props.month);

        switch(props.month){
            case '1': setMonthString('January'); break;
            case '2': setMonthString('February'); break;
            case '3': setMonthString('March'); break;
            case '4': setMonthString('April'); break;
            case '5': setMonthString('May'); break;
            case '6': setMonthString('June'); break;
            case '7': setMonthString('July'); break;
            case '8': setMonthString('August'); break;
            case '9': setMonthString('September'); break;
            case '10': setMonthString('October'); break;
            case '11': setMonthString('November'); break;
            case '12': setMonthString('December'); break;
            
        }

    }, []);

    const url = query && `${URL}reportInventoryManagement?idx=${getSession("cln")}&month=${props.month}`;
    const { status, data, error } = useFetch(url);

    const data_table = () => {
      try {
          console.log(data);
          if (Array.isArray(data)) {
            return data.map((item, index)=>{
             
                return (
                <View style={styles.tableRow}> 

                    <View style={styles.tableCol1Header}> 
                        <Text style={styles.tableCellHeader}>{item.LN}</Text> 
                    </View> 
                    {/* <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCellHeader}>{item.BaseStock}</Text> 
                    </View> */}
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCellHeader}>{item.BaseStockOneMonth}</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCellHeader}>{item.QtyOnHand}</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCellHeader}>{item.QtyOnReceive}</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCellHeader}>{item.QtyOnOrder}</Text> 
                    </View> 
                            
                </View>
                  
              );
            });
        } else {
          return (
        <View style={styles.tableNoReportHeader}> 
            <Text style={styles.tableCellHeader}>No Data To Report</Text> 
        </View> 
          );
        }
        } catch (err) {
          return;
        }
      
  }


    return(

        <>
        {status === 'error' && <div>{error}</div>}
        {status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
        {status === 'fetched' && (
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page style={styles.body}>
               <View style={styles.table}> 
                        <View style={styles.tableTitleRow}> 
                          <View style={styles.tableCol1Title}> 
                            <Text style={styles.tableCellHeader}>Inventory Management Report</Text> 
                          </View> 
                          <View style={styles.tableCol2Title}> 
                            <Text style={styles.tableCellHeader}> {getSession("cln_display")}</Text> 
                          </View> 
                          <View style={styles.tableCol3Title}> 
                            <Text style={styles.tableCellHeader}> {`Print Date: ${props.print_date}`}</Text> 
                            <Text style={styles.tableCellHeader}> {`Month: ${month_string}`}</Text> 
                            
                          </View> 
                          
                        </View>
                       <View style={styles.tableHeaderRow}> 
                          
                          <View style={styles.tableCol1Header}> 
                            <Text style={styles.tableCellHeader}>Drug</Text> 
                          </View> 
                          {/* <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>Base Stock</Text> 
                          </View>  */}
                          <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>Base Stock 1 Month</Text> 
                          </View> 
                          <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>Qty On Hand</Text> 
                          </View> 
                          <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>Qty On Receive</Text> 
                          </View> 
                          <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>Qty On Order</Text> 
                          </View> 
                         
                          
                        </View>
                      {data_table()}
              </View>
             
           
             
            </Page>
           </Document>
          </PDFViewer>
          )}
                  
        </> 
    );
  }

    export default InventoryManagement;