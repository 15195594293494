import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import FormHeader from "../components/form-header";
import DrugPricingReport from "./drug_pricing";
import ActiveProvidersReport from '../pdf/active_provider';
import ActiveUsersReport from '../pdf/active_users';
import AuditReport from '../pdf/audit_report';
import CurrentAdherenceReport from '../pdf/adherence_report';
import AdjustmentReport from '../pdf/adjustment_report';
import DrugActivityReport from '../pdf/drug_activity';
import ProviderActivityReport from '../pdf/provider_activity';
import CurrentInventoryReport from '../pdf/current_inventory';
import DailyDispenseReport from '../pdf/daily_dispense';
import DispensingReport from '../pdf/dispensing_report';
import ReceivedInventoryReport from '../pdf/received_inventory';
import VirtualInventoryReport from '../pdf/virtual_inventory';
import VirtualDispenseReport from '../pdf/virtual_dispensing';
import InventoryManagement from './inventory_management';
import AlertReport from '../pdf/alert_report';
import DispenseSheetLabel from './dispense_sheet-label';
import PrintLabel from '../pdf/print_label';
import PrintMonograph from '../pdf/print_monograph';
import ZebraLabel from '../pdf/zebra-label';
import SignatureLog from '../pdf/signature-log';
import ManualPatientLog from '../pdf/manual_patient_log';
import AverySheetLabel from '../pdf/avery_sheet_label';

import { returnTodayDate } from '../utility';

const BasicDocumentViewer = styled.div`
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.8);
    opacity: 1;
    z-index: 998;



`;



// Create Document Component
function BasicDocument(props) {

    const [idx, setIDX] = useState(props.query);

    const [drg_price, setDrugPrice] = useState(false);
    const [act_pvd , setActiveProviders] = useState(false);
    const [act_usr, setActiveUsers] = useState(false);
    const [alrt, setAlerts] = useState(false);
    const [current_adherence, setCurrentAdherence] = useState(false);
    const [daily_dsp, setDailyDispense] = useState(false);
    const [current_inv, setCurrentInventory] = useState(false);
    const [virtual_inv, setVirtualInventory] = useState(false);
    const [dsp_report, setDispensingReport] = useState(false);
    const [adj_report, setAdjustReport] = useState(false);
    const [inv_manage, setInvManage] = useState(false);
    const [rcv_inv, setReceiveInv] = useState(false);
    const [drg_act, setDrugAct] = useState(false);
    const [pvd_act, setProviderAct] = useState(false);
    const [vrt_dsp, setVrtDsp] = useState(false);
    const [audt, setAudit] = useState(false);
    const [lbl, setLBL] = useState(false);
    const [_lbl, setMLBL] = useState(false);
    const [dsp_lbl, setDSP_LBL] = useState(false);
    const [monograph, setMonograph] = useState(false);
    const [zbr, setZBR] = useState(false);
    const [sig, setSig] = useState(false);
    const [ml, setML] = useState(false);

   
    useEffect(() => {

        

        if(props.active_report === 'DP'){
            setDrugPrice(true);
        }
        if(props.active_report === 'PV'){
            setActiveProviders(true);
        }
        if(props.active_report === 'US'){
            setActiveUsers(true);
        }
        if(props.active_report === 'AL'){
            setAlerts(true);
        }
       
        if(props.active_report === 'DM'){
            setDailyDispense(true);
        }
        if(props.active_report === 'CI'){
            setCurrentInventory(true);
        }
        if(props.active_report === 'VI'){
            setVirtualInventory(true);
        }
        if(props.active_report === 'DR'){
            setDispensingReport(true);
        }
        if(props.active_report === 'AR'){
            setAdjustReport(true);
        }
        if(props.active_report === 'IM'){
            setInvManage(true);
        }
        if(props.active_report === 'RI'){
            setReceiveInv(true);
        }
        if(props.active_report === 'DA'){
            setDrugAct(true);
        }
        if(props.active_report === 'PA'){
            setProviderAct(true);
        }
        if(props.active_report === 'VD'){
            setVrtDsp(true);
        }
        if(props.active_report === 'AU'){
            setAudit(true);
        }
        if(props.active_report === 'LB'){
            setLBL(true);
        }
        if(props.active_report === 'DS'){
            setDSP_LBL(true);
        }
        if(props.active_report === 'MO'){
            setMonograph(true);
        }
        if(props.active_report === 'ZR'){
            setZBR(true);
        }
        if(props.active_report === 'SG'){
            setSig(true);
        }
        if(props.active_report === 'ML'){
            setML(true);
        }
    }, []);

  return (
    <BasicDocumentViewer>
            <FormHeader onClose={props.close}  title={props.title} addClose={true} class_style="form_header--dark"></FormHeader>
                    
            { drg_price ? <DrugPricingReport close={() => setDrugPrice(false)} title={props.active_report_name} print_date={returnTodayDate()} /> : null }
            { act_pvd ? <ActiveProvidersReport close={() =>  setActiveProviders(false)} title={props.active_report_name} print_date={returnTodayDate()} /> : null }
            { act_usr ? <ActiveUsersReport close={() => setActiveUsers(false)} title={props.active_report_name} print_date={returnTodayDate()} /> : null }
            { alrt ? <AlertReport close={() => setAlerts(false)} title={props.active_report_name} print_date={returnTodayDate()} /> : null }
            { current_adherence ? <CurrentAdherenceReport close={() => setCurrentAdherence(false)} title={props.active_report_name} print_date={returnTodayDate()} /> : null }
            { daily_dsp ? <DailyDispenseReport close={() => setDailyDispense(false)} title={props.active_report_name} print_date={returnTodayDate()} /> : null }
            { current_inv ? <CurrentInventoryReport close={() => setCurrentInventory(false)} title={props.active_report_name} print_date={returnTodayDate()} /> : null }
            { virtual_inv ? <VirtualInventoryReport close={() => setVirtualInventory(false)} title={props.active_report_name} print_date={returnTodayDate()} /> : null }
            { dsp_report ? <DispensingReport close={() => setDispensingReport(false)} title={props.active_report_name} print_date={returnTodayDate()}  start={props.start} end={props.end}/> : null }
            { adj_report ? <AdjustmentReport close={() => setAdjustReport(false)} title={props.active_report_name} print_date={returnTodayDate()} start={props.start} end={props.end}/> : null }
            { inv_manage ? <InventoryManagement close={() => setInvManage(false)} title={props.active_report_name} print_date={returnTodayDate()} month={props.month} start={props.start} end={props.end}/> : null }
            { rcv_inv ? <ReceivedInventoryReport close={() => setReceiveInv(false)} title={props.active_report_name} print_date={returnTodayDate()} month={props.month} start={props.start} end={props.end}/> : null }
            { drg_act ? <DrugActivityReport close={() => setDrugAct(false)} title={props.active_report_name} print_date={returnTodayDate()} month={props.month}  start={props.start} end={props.end}/> : null }
            { pvd_act ? <ProviderActivityReport close={() => setProviderAct(false)} title={props.active_report_name} print_date={returnTodayDate()} month={props.month}  start={props.start} end={props.end}/> : null }
            { vrt_dsp ? <VirtualDispenseReport close={() => setVrtDsp(false)} title={props.active_report_name} print_date={returnTodayDate()} month={props.month}  start={props.start} end={props.end}/> : null }
            { audt ? <AuditReport close={() => setAudit(false)} title={props.active_report_name} print_date={returnTodayDate()} month={props.month}  start={props.start} end={props.end}/> : null }
            { _lbl ? <DispenseSheetLabel close={() => setLBL(false)} title={props.active_report_name} print_date={returnTodayDate()}  idx={props.idx} firstname={props.firstname} lastname={props.lastname} dob={props.dob}/> : null }
            {dsp_lbl ? <PrintLabel close={() => setDSP_LBL(false)} width={"800px"} height={"600px"} title={props.active_report_name} print_date={returnTodayDate()}  idx={props.idx} firstname={props.firstname} lastname={props.lastname} dob={props.dob}/> : null }
            {monograph ? <PrintMonograph close={() => setMonograph(false)}  idx={props.idx} /> : null }
            {zbr ? <ZebraLabel close={() => setDSP_LBL(false)} width={"800px"} height={"600px"} title={props.active_report_name} print_date={returnTodayDate()}  idx={props.idx} firstname={props.firstname} lastname={props.lastname} dob={props.dob}/> : null }
            { sig ? <SignatureLog close={() => setSig(false)} title={props.active_report_name} print_date={returnTodayDate()}  start={props.start} end={props.end}/> : null }
            { ml ? <ManualPatientLog close={() => setML(false)} title={props.active_report_name} /> : null }
            { lbl ? <AverySheetLabel close={() => setLBL(false)} title={props.active_report_name} print_date={returnTodayDate()} pos={props.lblPosition}  idx={props.idx} firstname={props.firstname} lastname={props.lastname} dob={props.dob}/> : null }
     </BasicDocumentViewer>
  );
}
export default BasicDocument;