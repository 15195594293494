import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Toast from './toaster';
import Table from './table';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import ReactLoading from 'react-loading';
import { getSession, getAPI } from '../utility';
import NdcLotScan from './ndc-lot-scan';

const ClnAdminCrxWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: .5fr .5fr 1fr 1fr 1fr;
    height: 100%;
    width: 100%;

    .tosty{
        position:relative;
    }

    .total {
        grid-row: 1 / 2;
        grid-column: 1 / -1;
        display: flex;
        flex-direction: column;
    }
    .total_bar_hdr {
       
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin:auto;
        background-color: #cdcdcd;
        border: 1px solid var(--color-primary);
        height: 3rem;
        width:75%;
        font-size: 1.25rem;
        color: #000;
        padding: 1rem;
    }

    .total_bar {
       
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin:auto;
        background-color: #fff;
        border: 1px solid var(--color-primary);
        height: 8rem;
        width:75%;
        font-size: 1.75rem;
        color: navy;
        padding: 1rem;
        margin-top:-.5rem;
    }

    .info1 {
        border-left: 3px solid limegreen;
        padding:1rem;
    }
    .info2 {
        border-left: 3px solid orange;
        padding:1rem;
    }
    .info3 {
        border-left: 3px solid yellow;
        padding:1rem;
    }

    .info4 {
        border-left: 3px solid blue;
        padding:1rem;
    }

    .title_selected {
        color: navy;
        font-weight: bold;
    }

    .total_title {
        cursor: pointer;
    }

    .detail {
        grid-row: 2 / 3;
        grid-column: 1 / -1;
        display: flex;
        flex-direction: column;
    }

    .detail_bar {
       
       display: flex;
       align-items: center;
       justify-content: space-around;
       margin:auto;
       background-color: #fff;
       border: 1px solid var(--color-primary);
       height: 10rem;
       width:75%;
       font-size: 1.5rem;
       color: navy;
       padding: 1rem;
       margin-top:-2.3rem;
   }

   .detail_info1 {
    width: 15rem;
    height: 5rem;
    border: 1px solid var(--color-primary);
    cursor: pointer;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
   }

   .clr1 {
        background-color: blue;
   }

   .clr2 {
        background-color: orange;
   }

   .clr3 {
        background-color: limegreen;
   }

   
   .clr4 {
        background-color: navy;
   }
   .detail_title {
    font-size: 1.75rem;
    color: #fff;
    padding: 1rem;
   }
   .detail_view {
        grid-row: 3 / 5;
        grid-column: 1 / -1;
        display: flex;
        flex-direction: column;
   }

   .detail_view_bar {
       display: flex;
       align-items: center;
       justify-content: flex-start;
       margin:auto;
       background-color: tr;
       border: 1px solid var(--color-primary);
       height: 60rem;
       width:75%;
       font-size: 1.65rem;
       color: navy;
       padding: 1rem;
      
   }

   .view_tbl {
         width: 100%;
   }

   .loading {
        margin: auto;
   }

   .ndc_search_bar {
        display: flex;
        flex-direction: column;
        width: 100%;

        

   }
`;


const ClnAdminCRx = (props) => {

    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [days, setDays] = useState(7);
    const [table_meta, setTableMeta] = useState([]);
    const [total_dispensed, setTotalDispensed] = useState("");
    const [total_encounter, setTotalEncounter] = useState("");
    const [total_new_patient, setTotalNew] = useState("");
    const [total_return_patient, setTotalReturn] = useState("");

    const [cln, setCln] = useState(getSession("cln"));

    const [ndc, setNDC] = useState("");
    const[lot, setLot] = useState("");

    const [ndc_lot, setNdcLot] = useState(true);

    const [toast_background, setToastBackground] = useState("#5cb85c");
   

    const [url, setURL] = useState('');
    
    const { status, data, error } = useFetch(url);

    
    useEffect(() => {
        getTotal();
    }, []);

    useEffect(() => {   
        getTotal();
    }, [days]);

    useEffect(() => { 
        if (status === "fetched") {
            
           
        }
    }, [status]);


    const selectRow = (e) => {
       
       
    }

    const getTotal = async () => {
        let url = `${URL}vueReturnTotals?cln=${cln}&days=${days}`;
        const response = await fetch(url, {
            method: 'GET',
                headers: {
                    'x-functions-key': getAPI(),	
                }
            });
        const rslt = await response.json();
        let data = "";
        try {
            data = await(JSON.parse(rslt));
           
            setTotalDispensed(data[2].Total);
            setTotalEncounter(data[3].Total);
            setTotalNew(data[1].Total);
            setTotalReturn(data[0].Total);
        } catch {
            data = rslt;
        }
        
        
    }

    const switchDays = (day) => {
        setDays(day);
       

        let title = document.getElementById(day);
        title.classList.add("title_selected");

        if (day === "7") {
            let title = document.getElementById("30");
            title.classList.remove("title_selected");
        } else {
            let title = document.getElementById("7");
            title.classList.remove("title_selected");
        }

    }

    const getDrgDisp = () => {
        setTableMeta([
            {"col_width": "col-25", "data_label": "LN","key": "LN"},
            {"col_width": "col-25", "data_label": "TOTAL DISPENSED","key": "TOTAL"},
            {"col_width": "col-25", "data_label": "PERCENT OF TOTAL","key": "PercentTotal"},
           
        ]);
        setURL(`${URL}vueDrugsDispensed?cln=${cln}&time=${days}&top=10`);
    }

    const getPvdDisp = () => {
        setTableMeta([
            {"col_width": "col-25", "data_label": "Provider","key": "ProviderFullName"},
            {"col_width": "col-25", "data_label": "TOTAL DISPENSED","key": "TOTAL"},
            {"col_width": "col-25", "data_label": "PERCENT OF TOTAL","key": "PercentTotal"},
           
        ]);
        setURL(`${URL}vueDrugsDispensedProvider?cln=${cln}&time=${days}&top=10`);
    }
     

    const getDsrDisp = () => {
        setTableMeta([
            {"col_width": "col-25", "data_label": "Drug","key": "LN"},
            {"col_width": "col-15", "data_label": "NDC","key": "NDC"},
            {"col_width": "col-15", "data_label": "LOT","key": "LOT"},
            {"col_width": "col-15", "data_label": "UNIT QTY","key": "DF"},
            {"col_width": "col-15", "data_label": "INV","key": "INV"},
            {"col_width": "col-15", "data_label": "EXP DATE","key": "ExpDate"},
            {"col_width": "col-15", "data_label": "Days Until Expired","key": "DaysUntilExpired"},
           
        ]);
        setURL(`${URL}vueExpiringInv?cln=${cln}`);
    }

    const getNDCLot = () => {
        

        setNdcLot(true);
        setTableMeta([
            {"col_width": "col-15", "data_label": "Action","key": "Action"},
            {"col_width": "col-25", "data_label": "LN","key": "LN"},
            {"col_width": "col-10", "data_label": "QTY","key": "QTY"},
            {"col_width": "col-25", "data_label": "Date","key": "ActionDate"},
            {"col_width": "col-10", "data_label": "Units","key": "Units"},
            

        ]);
        setURL(`${URL}vueNdcLotUsage?cln=${getSession("cln")}&ndc=${ndc}&lot=${lot}`);
       
         
        
    }

    const selectParams = (ndc, lot) => {
       
        setTableMeta([
            {"col_width": "col-15", "data_label": "Action","key": "Action"},
            {"col_width": "col-25", "data_label": "LN","key": "LN"},
            {"col_width": "col-10", "data_label": "QTY","key": "QTY"},
            {"col_width": "col-25", "data_label": "Date","key": "ActionDate"},
            {"col_width": "col-10", "data_label": "Units","key": "Units"},
          
        ]);
        setURL(`${URL}vueNdcLotUsage?cln=${getSession("cln")}&ndc=${ndc}&lot=${lot}`);
    }
     
     

    return (
        <ClnAdminCrxWrapper>
            
            {/* <Tabs tabs={tabs} active={updateActiveTab} isSelected={selected}></Tabs> */}
           <div className="total">
            <div className="total_bar_hdr">
                <div onClick={() => switchDays("7")} id="7" className="total_title title_selected">PAST WEEK ACTIVITY</div>
                <div onClick={() => switchDays("30")} id="30" className="total_title" style={{marginLeft:"auto"}}>PAST MONTH ACTIVITY</div>
            </div>
            <div className="total_bar">
                <div className="info1">
                    <div className="info_title">TOTAL DRUGS DISPENSED</div>
                    <div className="info_value">{total_dispensed}</div>
                </div>
                <div className="info2">
                    <div className="info_title">TOTAL ENCOUNTERS</div>
                    <div className="info_value">{total_encounter}</div>
                </div>
                <div className="info3">
                    <div className="info_title">NEW PATIENTS ADDED</div>
                    <div className="info_value">{total_new_patient}</div>
                </div>
                <div className="info4">
                    <div className="info_title">RETURNING PATIENTS</div>
                    <div className="info_value">{total_return_patient}</div>
                </div>
                </div> 
             
             </div>
            
            <div className="detail">
                <div className="detail_bar">
                    <div onClick={getDrgDisp} className="detail_info1 clr1">
                        <span className="detail_title">DRUGS DISPENSED</span>
                    </div>
                    <div onClick={getPvdDisp} className="detail_info1 clr2">
                        <span className="detail_title">PROVIDER DISPENSED</span>
                    </div>
                    <div onClick={getDsrDisp} className="detail_info1 clr3">
                        <span className="detail_title">EXPIRING INVENTORY</span>
                    </div>
                    <div onClick={getNDCLot} className="detail_info1 clr4">
                        <span className="detail_title">NDC/LOT HISTORY</span>
                    </div>
                </div>
            </div>

            <div className="detail_view">
                <div className="detail_view_bar">
                    {status === 'error' && <div>{error}</div>}
                    {status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
                    {status === 'fetched' && (
                        <div className="ndc_search_bar">
                            { ndc_lot ? <NdcLotScan select={selectParams}></NdcLotScan> : null}
                            <div className="view_tbl"><Table data={data} meta={table_meta} row_class="table-row" include_header="true" select={selectRow} table_height="tbl-lng"></Table></div>
                        </div>
                          )}
                </div>
            </div>
           
            <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>
        </ClnAdminCrxWrapper>
    );
}
export default ClnAdminCRx;