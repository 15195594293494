
import {
   
    Font,
    Text,
    View,
    StyleSheet,
    Svg,
    Line
    
  } from "@react-pdf/renderer";
  
import BenchNine from './fonts/BenchNine/BenchNine-Regular.ttf';



Font.register({
    family: "BenchNine",
    format: "truetype",
    src: BenchNine,
  });

  const styles = StyleSheet.create({ 
    label: { 
        display: "flex", 
        width: "300px", 
        height: "200px",
        flexDirection: "column",
        overflow: "hidden",
      
         
    }, 

    labelPos: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "70%",
       
    },
    
  labelDispense: {

    display: "flex", 
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
     marginTop: '1%', 
    marginLeft: "1%",
    marginBottom: "1%",
    width: '75%',
    padding: '1px',   
    
    
  },

  labelWarn: {
    marginTop: '2%',
    marginRight: "2%",
    marginBottom: "2%",
    marginLeft: "1%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    width: '25%',
    fontSize: 7,
    
   
  },

  labelTearOff: {

    marginTop: '1%', 
    marginLeft: "1%",
    marginBottom: "1%",
    width: '100%',
    height: '30%',
    display: "flex",
    flexDirection: "row",
  },

labelRow: {

flexDirection: "row",
backgroundColor: "#FFFFFF",
fontSize: 8,
},
      
  tableTitleRow: {
    display: "flex",
   
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    // fontSize: 9,
    fontSize: 8,
    width: "100%",
   
  },




  tableCol1Title: { 
    width: '33%', 
    
  }, 

  tableCol2Title: { 
    width: "100%", 
    fontSize: 10,
    fontWeight: "bold", 
    
    
  }, 

  tableCol2: {  
    fontSize: 8,

  }, 
  tableCol56: {
    fontSize: 5,
  },


  tableCol2Yellow: {    
    width: "100%",
    backgroundColor: "#FFFF00",
  },

  tableCol3Title: { 
    width: "33%"
    
  },

  tableCol4Title: { 
    width: "50%",
   
   
    
  },
  
  tableCol5Title: { 
    width: "10%" 
    
  },
  tableCol6Title: { 
    width: "25%" 
    
  },

  tableCellHeaderBold: {
    fontWeight: "bold",
  },

  tableCellHeaderRight: {
    marignLeft: "10%",
  },

  tableTitleBorder: {
  
    },

    TinyTxt: {
        // fontSize: 6,
        fontSize: 6,
        textAlign: "center",
    },



  });

  const Zebra = (props) => {

    const lbl_warnings = () => {
       
        try {
          
            let line = 0;
  
          if (Array.isArray(props.data.LabelWarnings)) {
           
            return props.data.LabelWarnings.map((item, index)=>{
                if((item.LBL_TEXTSN === 1) && (index !== 0)) {
                    line = 0;
                } else {
                    line++;   
                }
                return (
                 <>
                     {line === 0   ?
                        <Svg key={index} height="10" width="495">
                            <Line x1="0" y1="5" x2="280" y2="5" strokeWidth={1} stroke="rgb(205,205,205)" />
                        </Svg>
                        : null}
                 <View key={index} style={styles.tableCol56}> 
                        <Text style={styles.tableCellHeader}>{item.LBL_DESC}</Text> 
                 </View> 
                
                 </>
                 );
            });
        } else {
          return (
            <View key="1" style={styles.tableCol1Title}> 
               <Text style={styles.tableCellHeader}>No Warnings</Text> 
            </View> 
          );
        }
        } catch (err) {
          return;
        }
    
    };

    return (
       
        
        //entire label
        <View style={styles.label}>
             
             <View style={styles.labelTearOff}>
                <View style={styles.TearOffLeft}>
                        <View style={styles.tableTitleRow}>
                        <Text style={styles.tableCellHeaderRight}> {`${props.print_date}        Rx:  ${props.data.RxNumber}`}</Text> 
                        <Text style={styles.tableCellHeaderRight}>{}</Text>
                        </View>
                        <View style={styles.tableCol2Title}> 
                            <Text style={styles.tableCellHeaderBold}> {`${props.firstname} ${props.middlename === undefined ? '' : props.middlename} ${props.lastname}`}</Text> 
                        </View> 
                    
                        <View style={styles.tableTitleRow}>
                        <View style={styles.tableCol2Title}> 
                                    <Text style={styles.tableCellHeaderBold}> {`${props.data.LN}`}</Text> 
                        </View>
                        </View>
                        <View style={styles.tableCol2Title}> 
                            <Text style={styles.tableCellHeaderBold}> {`NDC:  ${props.data.NDC}      LOT:  ${props.data.LOT}`}</Text> 
                        </View> 
                </View>

                <View style={styles.TearOffRight}>
                        <View style={styles.tableTitleRow}>
                        <Text style={styles.tableCellHeaderRight}> {`${props.print_date}        Rx:  ${props.data.RxNumber}`}</Text> 
                        <Text style={styles.tableCellHeaderRight}>{}</Text>
                        </View>
                        <View style={styles.tableCol2Title}> 
                            <Text style={styles.tableCellHeaderBold}> {`${props.firstname} ${props.middlename === undefined ? '' : props.middlename} ${props.lastname}`}</Text> 
                        </View> 
                    
                        <View style={styles.tableTitleRow}>
                        <View style={styles.tableCol2Title}> 
                                    <Text style={styles.tableCellHeaderBold}> {`${props.data.LN}`}</Text> 
                        </View>
                        </View>
                        <View style={styles.tableCol2Title}> 
                            <Text style={styles.tableCellHeaderBold}> {`NDC:  ${props.data.NDC}      LOT:  ${props.data.LOT}`}</Text> 
                        </View> 
                </View>

                   
              </View>
             
             
              

            <View style={styles.labelPos}>
                
                <View style={styles.labelDispense}>
                    <View style={styles.tableTitleRow}> 
                            <View style={styles.tableCol2Yellow}> 
                                <Text style={styles.tableCellHeaderBold}> {`Rx: ${props.data.RxNumber}`}</Text> 
                            </View> 
                             <View style={styles.tableCol4Title}> 
                                <Text style={styles.tableCellHeaderRight}> {`${props.print_date}`}</Text> 
                            </View> 
                    </View>
                    <View  style={styles.tableTitleRow}> 
                        <View style={styles.tableCol2Title}> 
                            <Text style={styles.tableCellHeaderBold}> {`${props.firstname} ${props.middlename === undefined ? '' : props.middlename} ${props.lastname}`}</Text> 
                        </View> 
                        
                        
                        <View style={styles.tableCol4Title}> 
                            <Text style={styles.tableCellHeader}> {`DOB: ${props.dob}`}</Text> 
                        </View> 
                    </View>
                    <View style={styles.tableTitleBorder}>
                    <View style={styles.tableTitleRow}> 
                        <View style={styles.tableCol2Title}> 
                            <Text style={styles.tableCellHeaderBold}> {`${props.data.LN}`}</Text> 
                        </View> 
                    </View>

                    <View style={styles.tableTitleRow}> 
                    
                        <View style={styles.tableCol2Title}> 
                        <Text style={styles.tableCellHeader}> {`${props.data.Directions1}`}</Text> 
                        </View> 
                    </View>
                    <View style={styles.tableTitleRow}> 
                    
                        <View style={styles.tableCol2Title}> 
                        <Text style={styles.tableCellHeader}> {`${props.data.Directions2}`}</Text> 
                        </View> 
                    </View>
                    <View style={styles.tableTitleRow}> 
                    
                        <View style={styles.tableCol2Title}> 
                        <Text style={styles.tableCellHeader}> {`${props.data.Directions3}`}</Text> 
                        </View> 
                    </View>
                    </View>

                    <View style={styles.tableTitleRow}> 
                    
                        <View style={styles.tableCol6Title}> 
                        <Text style={styles.tableCellHeader}> {`${props.data.NDC}`}</Text> 
                        </View> 
                        <View style={styles.tableCol6Title}> 
                        <Text style={styles.tableCellHeader}> {`${props.data.LOT}`}</Text> 
                        </View> 
                        <View style={styles.tableCol6Title}> 
                        <Text style={styles.tableCellHeaderBold}> {`Qty: ${props.data.UnitQty}`}</Text> 
                        </View> 

                        <View style={styles.tableCol1Title}> 
                        <Text style={styles.tableCellHeader}> {`Exp: ${props.data.ExpDate}`}</Text> 
                        </View> 
                    
                    </View>

                    <View style={styles.tableTitleRow}> 
                    
                    <View style={styles.tableCol4Title}> 
                        <Text style={styles.tableCellHeader}> {`${props.data.ProviderFirstName} ${props.data.ProviderLastName} ${props.data.ProviderSuffix}`}</Text> 
                    </View> 
                    <View style={styles.tableCol2Title}> 
                        <Text style={styles.tableCellHeader}> {`${props.data.ClinicPhone}`}</Text> 
                    </View> 
                    
                    
                </View>

              

                <View style={styles.tableTitleRow}>
                    <View style={styles.tableCol1Title}> 
                        <Text style={styles.tableCellHeader}> </Text> 
                    </View> 
                    <View style={styles.tableCol2Title}> 
                        <Text style={styles.tableCellTiny}>  {`${props.data.ClinicName}`}</Text> 
                    </View>

                </View>
                <View style={styles.tableTitleRow}>
                    <View style={styles.tableCol1Title}> 
                        <Text style={styles.tableCellHeader}> </Text> 
                    </View> 
                    <View style={styles.tableCol2Title}> 
                        <Text style={styles.tableCellTiny}> {`${props.data.ClinicAddress1} ${props.data.ClinicAddress2}  `}</Text> 
                    </View>
                 </View>
                    
              
                <View style={styles.tableTitleRow}>
                    <View style={styles.tableCol1Title}> 
                        <Text style={styles.tableCellHeader}> </Text> 
                    </View> 
                    <View style={styles.tableCol2Title}> 
                        <Text style={styles.tableCellTiny}> {`${props.data.ClinicCity} ${props.data.ClinicState} ${props.data.ClinicZipCode}`}</Text> 
                    </View>

                </View>
                <View style={styles.tableTitleRow}>
                    <View style={styles.tableCol5Title}> 
                        <Text style={styles.tableCellHeader}> </Text> 
                    </View> 
                    <Text style={styles.TinyTxt}>Do Not Take Other Medicines Without Checking with your Doctor First.</Text>
                </View>
            </View> 

            <View style={styles.labelWarn}> 
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}> {`IMPRINT: ${props.data.IMPRINT !== undefined ? props.data.IMPRINT : ""}`}</Text> 
                </View>
                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}> {`COLOR: ${props.data.Color !== undefined ? props.data.Color : ""}`}</Text> 
                </View>

                <View style={styles.tableCol2}> 
                    <Text style={styles.tableCell}> {`SHAPE: ${props.data.Shape !== undefined ? props.data.Shape : ""}`}</Text> 
                </View>

                <Svg height="10" width="495">
                <Line x1="0" y1="5" x2="280" y2="5" strokeWidth={1} stroke="rgb(205,205,205)" />
                </Svg>
        
                {lbl_warnings()}
            </View>

            </View> 
               
                 
        </View>

       
    );
  }

  export default Zebra;