import React, {useEffect, useState} from 'react';
import Toast from './toaster';
import ButtonExpand from '../theme/btn_expand';
import styled from 'styled-components';
import FormHeader from './form-header';
import {isValidNumber, isValidString, isDate, isSSN, isPhone, isZip5} from '../utility';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import { getAPI } from '../utility';


const CSPMPSetupWrapper = styled.div`

    width: 75rem;
    height: 50rem;
    margin: auto;
    background-color: var(--color-grey-dark-2);
    color: #fff;
    
    border: 2rem solid  rgba(15, 215, 239, 1);
    border-radius: 5px;
    
   
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    font-size: 2rem;
    white-space: nowrap;
    box-shadow: 0 1rem 3rem rgba(#fff, .2);

    .form-container {
       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: 1fr ;
       grid-gap: 2rem;
       padding-top: 1rem;
       padding-bottom: 1rem;
       font-size: 1.75rem;
       height: auto;
    }

    .form-title {
        margin-top: 1rem;
        margin-left: 1rem;
        text-transform: uppercase;
    }

    .grp-btn-bar {
        
        display: flex;
        align-items: space-between;
        justify-content: space-around;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        width: 100%;
     
    }

    .adm-lbl {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .adm-txt {
        font-size: 2.1rem;
        font-weight: bold;
    }


   

`;


const CSPMPSetup = (props) => {

   
    const [idx, setIdx] = useState('');
    const [cln, setClinic] = useState(props.cln);
    const [usr, setUsr] = useState('');
    const [pwd, setPwd] = useState('');
    const [region, setRegion] = useState('AZ');
    const [region_key, setKey] = useState('');
    const [primary, setPrimary] = useState('');
    const [dea, setDEA] = useState('');

    const[grp, setGrp] = useState(props.clinic.grp);
    const[clinicAlias, setClinicAlias] = useState(props.clinic.clinicAlias);
    const[clinicPhone, setClinicPhone] = useState(props.clinic.clinicPhone);
    const[clinicAddress1, setClinicAddress1] = useState(props.clinic.clinicAddress1);
    const[clinicAddress2, setClinicAddress2] = useState(props.clinic.clinicAddress2);
    const[clinicCity, setClinicCity] = useState(props.clinic.clinicCity);
    const[clinicState, setClinicState] = useState(props.clinic.clinicState);
    const[clinicZip, setClinicZip] = useState(props.clinic.clinicZip);

    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");


    const [valid_err, setValErr] = useState([]);
    const [disabled, setDisabled] = useState(false);

    const [url, setUrl] = useState("");
    const { status, data, error } = useFetch(url);



  useEffect(() => {
    console.log(props.cln);
    if(cln !== null && cln !== undefined)  {
        setUrl(`${URL}cspmp_ReturnCSPMP?idx=${cln}`); 


    }
    }, []);

    useEffect(() => {

        if (status === "fetched") {
            console.log(data);
            if (data !== null && data !== undefined) {
                setUsr(data.Usr);
                setPwd(data.Pwd);
                setRegion(data.Region);
                setIdx(data.idx);
                setKey(data.RegionKey);
                setPrimary(data.Primary);
                setDEA(data.DEA);
            }

        }
             

    }, [status]);

    const submit = () => {
        setDisabled(true);

        setValErr([]);
        let errors = [];

        let obj = {};
        if (region !== null) {
            obj.idx = idx;
            obj.cln = cln;
            obj.region = region === undefined  ? 'AZ' : region;
            obj.key = region_key === undefined ? '' : region_key;
            obj.usr = isValidString(usr) ? usr : errors.push("UserName Is Required.");
            obj.pwd = isValidString(pwd) ? pwd : errors.push("Password Is Required.");
            obj.dea = isValidString(dea) ? dea : errors.push("Valid DEA Is Required.");
            obj.primaryName = isValidString(primary) ? primary : errors.push("Primary Provider Is Required.");
            
            obj.grp = isValidString(grp) ? grp : errors.push("Group Is Required.");
            obj.clinicAlias = isValidString(clinicAlias) ? clinicAlias : errors.push("Clinic Alias Is Required.");
            obj.clinicPhone = isValidString(clinicPhone) ? clinicPhone : errors.push("Clinic Phone Is Required.");
            obj.address1 = isValidString(clinicAddress1) ? clinicAddress1 : errors.push("Clinic Address Is Required.");
            obj.address2 = isValidString(clinicAddress2) ? clinicAddress2 : "";
            obj.city = isValidString(clinicCity) ? clinicCity : errors.push("Clinic City Is Required.");
            obj.state = isValidString(clinicState) ? clinicState : errors.push("Clinic State Is Required.");
            obj.zip = isValidString(clinicZip) ? clinicZip : errors.push("Clinic Zip Is Required.");

        }

        console.log(obj);
        let json = JSON.stringify(obj);
        console.log(json);

        console.log('errors',errors.length);

        if (errors.length > 0) {
            setValErr(errors);
            setDisabled(false);
            
        } else {

            
            let ur = idx !== undefined ? URL + "cspmpUpdate" : URL + "cspmpInsert";

            //POST DATA
     const requestOptions = {
       method: 'POST',
       headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
       body: json
       };
       fetch(`${ur}`, requestOptions)
           .then(response => response.json())
           .then(data => {
               console.log(data);
               setIdx(data); 
               setValErr([]);
               setToastIcon("success");
               setToastMsg("CSPMP Saved Successfully");
               setToastState(true);
               setDisabled(false);
              
           })            
           .catch(err => {
               setToastIcon("error");
               setToastTitle("Error");
               setToastBackground("#d9534f");
               setToastMsg("There was a Problem Saving the CSPMP Setup. Please Try Again.");
               setToastState(true);
               setDisabled(false);
           });
            setDisabled(false);


            props.select(obj);
        }
    }
    
    const err = () => {
        try {
            if (Array.isArray(valid_err)) {
              return valid_err.map((item, index)=>{
                  return (
                        <ul>
                              <li className="err_list">{item}</li>
                             
                        </ul>
                   );
              });
          } else {
            return (
              <tr><td colSpan='8'><div>No Errors to Report.</div></td></tr>
            );
          }
          } catch (err) {
            return;
          }
    }

    return (
        <CSPMPSetupWrapper>
             <FormHeader onClose={props.close} title="CSPMP EDIT"addClose={true} class_style="form_header--light" ></FormHeader>
          
             <div id="error_list" >{err()}</div>

            
            <div className="form_row1-col3">
                        <div className="form_col3_fld1">
                                <select id="region" value={region} className="form_input req adm-txt" onChange={e => setRegion(e.target.value)}>
                                    <option value="AZ">AZ</option>
                                    <option value="NV">NV</option>
                                    <option value="CO">CO</option>
                                
                                </select>
                                <label for="region" className="form_label--light adm-lbl">Region</label>
                         </div> 
                         {/* <div className="form_col3_fld2">
                            <input onChange={e => setRegionKey(e.target.value)} value={region_key} type="text" className="form_input--light adm-txt req" placeholder="Region Key"  id="region_key" maxLength="35" autoComplete="new-user-last-name"  />  
                            <label htmlFor="region_key" className="form_label--light adm-lbl">Region Key</label>
                         </div> */}
                         <div className="form_col3_fld3">
                            <input onChange={e => setPrimary(e.target.value)} value={primary} type="text" className="form_input--light adm-txt req" placeholder="Primary Name"  id="primary" maxLength="35" autoComplete="new-user-last-name"  />  
                            <label htmlFor="primary" className="form_label--light adm-lbl">Primary Name</label>
                         </div>
                         
                </div>

                <div className="form_row1-col3">
                       <div className="form_col3_fld1">
                            <input onChange={e => setUsr(e.target.value)} value={usr} type="text" placeholder="UserName" className="form_input--light adm-txt req"   id="usr" maxLength="35" autoComplete="new-user-last-name"  />  
                            <label htmlFor="usr" className="form_label--light adm-lbl">UserName</label>
                         </div>
                         <div className="form_col3_fld2">
                            <input onChange={e => setPwd(e.target.value)} value={pwd} type="text" className="form_input--light adm-txt req" placeholder="Password"  id="pwd" maxLength="35" autoComplete="new-user-last-name"  />  
                            <label htmlFor="pwd" className="form_label--light adm-lbl">Password</label>
                         </div>
                         <div className="form_col3_fld3">
                            <input onChange={e => setDEA(e.target.value)} value={dea} type="text" className="form_input--light adm-txt req" placeholder="Primary DEA"  id="dea" maxLength="35" autoComplete="new-user-last-name"  />  
                            <label htmlFor="dea" className="form_label--light adm-lbl">DEA</label>
                         </div>
                         
                </div>
                

             
             <div className="grp-btn-bar">
                  
                    { disabled ? <div></div> : <ButtonExpand className="grp-btn-right" title="SUBMIT" click={submit}  /> }

                </div>
             

                <div className="toasty">
                    {toast ?  <Toast 
                        id={74}
                        position={'bottom-center'}
                        autoDelete={true}
                        autoDeleteTime={3000}
                        title={toast_title}
                        description={toast_msg}
                        backgroundColor={toast_background}
                        icon={toast_icon}
                        close={() => setToastState(false)}

                    /> : null }
                </div>
          
        </CSPMPSetupWrapper>
    );
}

export default CSPMPSetup;
