import React, {useEffect, useState} from 'react';
import Toast from './toaster';
import ButtonExpand from '../theme/btn_expand';
import styled from 'styled-components';
import FormHeader from './form-header';
import { isValidString, getAPI } from '../utility';
import {URL} from '../constants';

const GroupEditWrapper = styled.div`

    width: 75rem;
    height: 30rem;
    margin: auto;
    background-color: var(--color-grey-dark-2);
    color: #fff;
    
    border: 2rem solid  rgba(15, 215, 239, 1);
    border-radius: 5px;
    
   
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    font-size: 2rem;
    white-space: nowrap;
    box-shadow: 0 1rem 3rem rgba(#fff, .2);

    .form-container {
       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: 1fr ;
       grid-gap: 2rem;
       padding-top: 1rem;
       padding-bottom: 1rem;
       font-size: 1.75rem;
       height: auto;
    }

    .form-title {
        margin-top: 1rem;
        margin-left: 1rem;
        text-transform: uppercase;
    }

    .grp-btn-bar {
        
        display: flex;
        align-items: space-between;
        justify-content: space-around;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        margin-top: 1rem;
        width: 100%;
     
    }

    .adm-lbl {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .adm-txt {
        font-size: 2.1rem;
        font-weight: bold;
    }


   

`;


const GroupEdit = (props) => {

    const [name, setName] = useState('');
    const [acctname, setAcctName] = useState('');
    const [acctid, setAcctId] = useState('');
    const [idx, setIdx] = useState('0');

    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");

    const [showUse, setShowUse] = useState(false);

    const [valid_err, setValErr] = useState([]);

    useEffect(() => {

        props.group.name === "Add New Group" ? setName('') : setName(props.group.name);
        setAcctName(props.group.AccountName);
        setAcctId(props.group.AccountID);
        setIdx(props.group.idx);

    }, [props.group]);

    const submit = () => {
        console.log('submit');
        setValErr([]);
        let errors = [];

       let obj = {};
       obj.idx = idx;
       obj.name = isValidString(name) ? name.toUpperCase() : errors.push("Grpup Name Is Required."); 
       obj.acctid = isValidString(acctid) ? acctid : errors.push("Account ID Is Required.");
       obj.acctname = isValidString(acctname) ? acctname.toUpperCase() : errors.push("Account Name Is Required.");
      
   
         console.log(obj);
         let json = JSON.stringify(obj);
         console.log(json);

         console.log('errors',errors.length);
           
        if (errors.length > 0) {
            setValErr(errors);
            
        } else {

           

             

               //POST DATA
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
          body: json
          };
          fetch(`${URL}admUpsertGroup`, requestOptions)
              .then(response => response.json())
              .then((data) => {

                console.log('return grp idx',data);
                setIdx(data);
                  setValErr([]);
                  setToastIcon("success");
                  setToastMsg("Group Saved Successfully");
                  setToastState(true);
                  setShowUse(true);
              })            
              .catch(err => {
                  setToastIcon("error");
                  setToastTitle("Error");
                  setToastBackground("#d9534f");
                  setToastMsg("There was a Problem Saving the Group. Please Try Again.");
                  setToastState(true);
                  setShowUse(false);
              });
        }

    }

    const useGroup = () => {    

        console.log('use group');
        props.select({idx:idx, name: name, AccountName: acctname, AccountID: acctid});

    }

    const err = () => {
        try {
            if (Array.isArray(valid_err)) {
              return valid_err.map((item, index)=>{
                  return (
                        <ul>
                              <li className="err_list">{item}</li>
                             
                        </ul>
                   );
              });
          } else {
            return (
              <tr><td colSpan='8'><div>No Errors to Report.</div></td></tr>
            );
          }
          } catch (err) {
            return;
          }
    }

    return (
        <GroupEditWrapper>
             <FormHeader onClose={props.close} title="GROUP EDIT"addClose={true} class_style="form_header--light" ></FormHeader>
             
             <div id="error_list" >{err()}</div>

            
            <div className="form_row1-col3">
                <div className="form_col3_fld1">
                    <input onChange={e => setName(e.target.value)} value={name} type="text" className="form_input--light adm-txt req" placeholder="Group Name"  id="grp_name" maxLength="35" autoComplete="new-user-last-name"  />  
                    <label htmlFor="grp_name" className="form_label--light adm-lbl">Group Name</label>
                </div>
             <div className="form_col3_fld2">
                <input onChange={e => setAcctName(e.target.value)} value={acctname} type="text" className="form_input--light adm-txt req" placeholder="Account Name"  id="acct_name" maxLength="35" autoComplete="new-user-last-name"  />  
                <label htmlFor="acct_name" className="form_label--light adm-lbl">Account Name</label>
             </div>
             <div className="form_col3_fld3">
                <input onChange={e => setAcctId(e.target.value)} value={acctid} type="text" className="form_input--light adm-txt req" placeholder="Account ID"  id="acct_id" maxLength="35" autoComplete="new-user-last-name"  />  
                <label htmlFor="acct_id" className="form_label--light adm-lbl">Account ID</label>
             </div>
             
    </div>

 
             <div className="grp-btn-bar">
                   { showUse ? <ButtonExpand title="USE THIS GROUP" click={useGroup}  /> : <div></div>}
                    <ButtonExpand className="grp-btn-right" title="SUBMIT" click={submit}  /> 

                </div>
                
                <div className="toasty">
                    {toast ?  <Toast 
                        id={74}
                        position={'bottom-center'}
                        autoDelete={true}
                        autoDeleteTime={3000}
                        title={toast_title}
                        description={toast_msg}
                        backgroundColor={toast_background}
                        icon={toast_icon}
                        close={() => setToastState(false)}

                    /> : null }
                </div>
          
        </GroupEditWrapper>
    );
}

export default GroupEdit;
