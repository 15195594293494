import  { useState, useEffect } from "react";
import {
  Font,
  Text,
  View,
  StyleSheet
} from "@react-pdf/renderer";
import {URL} from '../constants';
import BenchNine from './fonts/BenchNine/BenchNine-Regular.ttf';
import { getAPI } from "../utility";


Font.register({
  family: "BenchNine",
  format: "truetype",
  src: BenchNine,
})


const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 30
const COLN_WIDTH = (100 - COL1_WIDTH) / 4

const styles = StyleSheet.create({
  body: {
    padding: 10,
    fontFamily: "BenchNine",
    fontSize: 6,

  },
  viewer: {
    width: window.innerWidth / .95,
    height: window.innerHeight / .95,
   
   
    
  },
  table: { 
    display: "table", 
    width: "auto", 
    
  }, 

  tableRow: { 
    margin: "auto", 
    flexDirection: "row",
    backgroundColor: "#E0F9FF"
  
  }, 

  tableHeaderRow: { 
    margin: "auto", 
    flexDirection: "row",
    backgroundColor: "#FFF3D4"
  
  }, 

  tableTitleRow: {
    marginLeft: "12%",
    flexDirection: "row",
    backgroundColor: "#FFFFFF"
  },
  tableCol1Title: { 
    width: '33%', 
    
  }, 

  tableCol2Title: { 
    width: "100%" 
    
  }, 

  tableCol3Title: { 
    width: "33%"
    
  },
  
  tableCol5Title: { 
    width: "10%" 
    
  },


  'tableRow:nth-child(odd)': {
    backgroundColor: "#E0F9FF"
  },

  'tableRow:nth-child(even)': {
    backgroundColor: "#CDCDCD"
  },

  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    borderBottomWidth: 0
  },     
  tableNoReportHeader: {
    width: '100%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
    
}, 
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },   

  tableColClinic: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    borderBottomWidth: 0 
  },   
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },   
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: 5, 
    fontSize: 11,
    fontWeight: 500
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  },
  tableCellTiny: {
    margin: 3, 
    fontSize: 8,
    fontWeight: 200
  },  
  left: {
    // width: '33%',//<- working alternative
    flexGrow: 0,
    flexShrink: 1,
    // flexBasis: 100,
  },


});
  

  
  const DispenseMonograph = (props) => {
      const [title, setTitle] = useState('');
      const [brand, setBrand] = useState('');
      const [missed, setMissed] = useState('');
      const [phonetic, setPhonetic] = useState('');
      const [how, setHow] = useState('');
      const [interaction, setInteraction] = useState('');
      const [alert, setAlert] = useState('');
      const [notes, setNotes] = useState('');
      const [overdose, setOverdose] = useState('');
      const [precautions, setPrecautions] = useState('');
      const [storage, setStorage] = useState('');
      const [side, setSide] = useState('');
      const [uses, setUses] = useState('');
      const [warning, setWarning] = useState('');
      const [disclaimer, setDisclaimer] = useState('');

      useEffect(() => {
        returnMonograph();
      }, []);

      const  returnMonograph = async () => {

        const url2 =  `${URL}dispenseGetDrugMonograph?idx=${props.frm}`;
        try {
          const response = await fetch(url2, {
            method: 'GET',
                headers: {
                    'x-functions-key': getAPI(),	
                }
            });
          const rslt = await response.json();
          let dta = "";
          try {
            
            dta = await(JSON.parse(rslt));
            
           
  
            let T = dta.filter((item) => item.PEMTXTEI === "T");
            let t = format_mono(T);
            setTitle(t);
  
            let I = dta.filter((item) => item.PEMTXTEI === "I");
            
            let i = format_mono(I);
           
            setInteraction(i);
           
  
            let B = dta.filter((item) => item.PEMTXTEI === "B");
            console.log('Brand',B);
            if(B.length > 0) {
              let b = format_mono(B);
              setBrand(b);
            }
  
            let M = dta.filter((item) => item.PEMTXTEI === "M");
            let m = format_mono(M);
            setMissed(m);
  
            let P = dta.filter((item) => item.PEMTXTEI === "F");
            let p = format_mono(P);
            setPhonetic(p);
  
            let H = dta.filter((item) => item.PEMTXTEI === "H");
            let h = format_mono(H);
            setHow(h);
  
            let D = dta.filter((item) => item.PEMTXTEI === "D");
            let d = format_mono(D);
            setInteraction(d);
  
            let A = dta.filter((item) => item.PEMTXTEI === "A");
            let a = format_mono(A);
            setAlert(a);
  
            let N = dta.filter((item) => item.PEMTXTEI === "N");
            let n = format_mono(N);
            setNotes(n);
  
            let O = dta.filter((item) => item.PEMTXTEI === "O");
            let o = format_mono(O);
            setOverdose(o);
  
            let C = dta.filter((item) => item.PEMTXTEI === "C");
            let c = format_mono(C);
            setPrecautions(c);
  
            let S = dta.filter((item) => item.PEMTXTEI === "S");
            if(S.length > 0) {
              let s = format_mono(S);
              setSide(s);
            }
  
            let E = dta.filter((item) => item.PEMTXTEI === "E");
            if (E.length > 0) {
              let e = format_mono(E);
              setStorage(e);
            }
  
            let U = dta.filter((item) => item.PEMTXTEI === "U");
            let u = format_mono(U);
            setUses(u);
  
            let W = dta.filter((item) => item.PEMTXTEI === "W");
            let w = format_mono(W);
            setWarning(w);
            
            let Z = dta.filter((item) => item.PEMTXTEI === "Z");
            let z = format_mono(Z);
            setDisclaimer(z);
  
            
  
            console.log("monograph",dta);
          } catch(err) {
            dta = rslt;
            console.log('Error parsing JSON data', err);
          }
          
        }
        catch (err) {
          console.log('Error fetching data', err);
        }
      
      }

      const  format_mono = (section) => {
     
        let arr = [];
       
  
        section.map((item, index) => {
          if(item.PEMTXTE !== undefined) {
            arr.push(item.PEMTXTE);
          } else {
            arr.push(" ");
          }
        }
        );
  
        
          return (arr.join(" ").trim());
      };

      return (
    
      <View style={styles.table}> 
            
                
            <View style={styles.right}> 
               
                  <Text style={styles.tableCellHeader}> {`${title}  ${phonetic}`}</Text> 
                 
                
            </View>

                              
           { brand !== '' ? <View style={styles.right}> 
                  <Text style={styles.tableCellHeader}> {`${brand}`}</Text> 
             </View> : null}
             { how !== '' ?
             <View style={styles.right}> 
                  <Text style={styles.tableCellHeader}> {`${how}`}</Text> 
             </View> : null}
             { side !== '' ?
            <View style={styles.right}> 
              <Text style={styles.tableCellHeader}> {`${side}`}</Text> 
            </View> : null}
            { precautions !== '' ?
              <View style={styles.right}> 
                <Text style={styles.tableCellHeader}> {`${precautions}`}</Text> 
              </View>  : null}
              { interaction !== '' ?
              <View style={styles.right}> 
                <Text style={styles.tableCellHeader}> {`${interaction}`}</Text> 
              </View> : null}
              { overdose !== '' ?
                 <View style={styles.right}> 
                  <Text style={styles.tableCellHeader}> {`${overdose}`}</Text> 
                </View>
              : null}
              { uses !== '' ?
                <View style={styles.right}> 
                  <Text style={styles.tableCellHeader}> {`${uses}`}</Text> 
                </View>  : null}
                { notes !== '' ?
                <View style={styles.right}> 
                  <Text style={styles.tableCellHeader}> {`${notes}`}</Text> 
                </View> : null}
                {missed !== '' ?
                <View style={styles.right}> 
                  <Text style={styles.tableCellHeader}> {`${missed}`}</Text> 
                </View> : null}
                {storage !== '' ?
                <View style={styles.right}> 
                  <Text style={styles.tableCellHeader}> {`${storage}`}</Text> 
                </View> : null}
                { warning !== '' ?
                <View style={styles.right}> 
                  <Text style={styles.tableCellHeader}> {`${warning}`}</Text> 
                </View> : null }
               { disclaimer !== '' ?
                <View style={styles.right}> 
                  <Text style={styles.tableCellHeader}> {`${disclaimer}`}</Text> 
                </View>
                : null}
             
    </View>
    

      );
    };
  
 export default DispenseMonograph;
  
  
  
  
  
  
  
  