import React, {Component} from 'react';
import styled from 'styled-components';

const PINWrapper = styled.div`

    /* PIN */
#wusr_pin_1 {
    opacity: 1;
}
.wht {
    color:#fff;
   
}

.pin_cover {
    opacity: 0;
}

.pin_reverse {
    opacity: 1;
}

.btn_pin {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'BenchNine' sans-serif;
}

.rst {
    margin-left: 2rem;
    transform: scale(.7);
    cursor: pointer;

    &:hover {
        color: var(--color-primary);
    }
}



.lpin {
    position:relative;
    background-color: #7C7777;
    display:inline block;
    font-weight: 300;
    font-size: 30px;

}

.hpin {
    position:relative;
    background-color: #7C7777;
    display:inline block;
    font-weight: 300;
    font-size: 2rem;
   

}
.pin1 {
    position: absolute;
    background-color: red;
    color:black;
    font-weight: 300;
    font-size: 2rem;
}
.pin2 {
    position: absolute;
    background-color: blue;
    color:black;
    font-weight: 300;
    font-size: 2rem;
}
.pin3 {
    position: absolute;
    background-color: green;
    color:black;
    font-weight: 300;
    font-size: 2rem;
}
.pin4 {
    position: absolute;
    background-color: yellow;
    color:black;
    font-weight: 300;
    font-size: 2rem;
}
.pin5 {
    position: absolute;
    background-color: purple;
    color:black;
    font-weight: 300;
    font-size: 2rem;
}

/* END PIN */
`;

class PIN extends Component {
    constructor() {
        super();
        this.state = {
            PIN1: false,
            PIN2: false,
            PIN3: false,
            PIN4: false,
            PIN5: false,
            LBL1: undefined,
            LBL2: undefined,
            LBL3: undefined,
            LBL4: undefined,
            LBL5: undefined,
            PIN: [],
            filled: false,
        };
    }

    componentDidMount() {
        if (this.props.pin > 0) {
           if (this.props.pin.length > 0) {
            this.setupPIN(this.props.pin);
           }
       }
    }

    setupPIN(pin) {
        this.setState({PIN: pin});
        let arr = [];
        arr[0] = pin.substring(0, 1);
        arr[1] = pin.substring(1, 2);
        arr[2] = pin.substring(2, 3);
        this.handlePINClear();
        for (let i = 0; i < 3; i++) {
            switch (arr[i]) {
                case '1': this.setState({PIN1: true, LBL1: (i + 1)});
                break;
                case '2': this.setState({PIN2: true, LBL2: (i + 1)});
                break;
                case '3': this.setState({PIN3: true, LBL3: (i + 1)});
                break;
                case '4': this.setState({PIN4: true, LBL4: (i + 1)});
                break;
                case '5': this.setState({PIN5: true, LBL5: (i + 1)});
                break;
                default: this.setState({PIN5: true, LBL5: (i + 1)});
            }
        }
    };

    handlePIN(event) {
        let pos = (this.state.PIN.length + 1);
        if (this.state.PIN.length < 3) {
           

          switch (event.target.id) {
              case 'wusr_pin_1': this.setState({PIN1: true, PIN: this.state.PIN.concat([1]), LBL1: pos});
              this.props.onSavePin(this.state.PIN.concat([1]));
              break;
              case 'wusr_pin_2': this.setState({PIN2: true, PIN: this.state.PIN.concat([2]), LBL2: pos});
              this.props.onSavePin(this.state.PIN.concat([2]));
              break;
              case 'wusr_pin_3': this.setState({PIN3: true, PIN: this.state.PIN.concat([3]), LBL3: pos});
              this.props.onSavePin(this.state.PIN.concat([3]));
              break;
              case 'wusr_pin_4': this.setState({PIN4: true, PIN: this.state.PIN.concat([4]), LBL4: pos});
              this.props.onSavePin(this.state.PIN.concat([4]));
              break;
              case 'wusr_pin_5': this.setState({PIN5: true, PIN: this.state.PIN.concat([5]), LBL5: pos});
              this.props.onSavePin(this.state.PIN.concat([5]));
              break;
              default: this.setState({PIN5: true, PIN: this.state.PIN.concat([5]), LBL5: pos});
              this.props.onSavePin(this.state.PIN.concat([5]));
          }
      };
    };
    handlePINClear() {
      this.setState({PIN: [], PIN1: false, PIN2: false, PIN3: false, PIN4: false, PIN5: false, LBL1: undefined, LBL2: undefined, LBL3: undefined, LBL4: undefined, LBL5: undefined});
    };

    render() {
        const style = {
            border: '2px solid #000',
            width: '35px',
            height: '35px',
            margin: '0',
            padding: '0 5 0 0',
       };

        return (
            <PINWrapper>
                <div className='form-row mgn-top'>
                    <div className='form-group mgn-top'>
                        <table>
                            <tbody>
                        <tr>
                                <td style={{width: '35px', height: '35px'}} ><div id='wusr_pin_1' onClick={this.handlePIN.bind(this)} className='btn_pin pin1' style={style}>{this.state.LBL1}</div>
                                <div id='wusr_pin_1'onClick={this.handlePIN.bind(this)} className={this.state.PIN1 ? 'pin_cover ' : 'btn_pin hpin'} style={style}>1</div></td>
                                <td style={{width: '35px', height: '35px'}}><div id='usr_pin_2' onClick={this.handlePIN.bind(this)} className='btn_pin pin2' style={style}>{this.state.LBL2}</div>
                                    <div id='wusr_pin_2' className={this.state.PIN2 ? 'pin_cover ' : 'btn_pin hpin'} onClick={this.handlePIN.bind(this)} style={style}>2</div></td>
                                <td style={{width: '35px', height: '35px'}}><div id='usr_pin_3' onClick={this.handlePIN.bind(this)} className='btn_pin pin3' style={style}>{this.state.LBL3}</div>
                                    <div id='wusr_pin_3' className={this.state.PIN3 ? 'pin_cover ' : 'btn_pin hpin'} onClick={this.handlePIN.bind(this)} style={style}>3</div></td>
                                <td style={{width: '35px', height: '35px'}}><div id='usr_pin_4' onClick={this.handlePIN.bind(this)} className='btn_pin pin4' style={style}>{this.state.LBL4}</div>
                                    <div id='wusr_pin_4' className={this.state.PIN4 ? 'pin_cover ' : 'btn_pin hpin'} onClick={this.handlePIN.bind(this)} style={style}>4</div></td>
                                <td style={{width: '35px', height: '35px'}}><div id='usr_pin_5' onClick={this.handlePIN.bind(this)} className='btn_pin pin5' style={style}>{this.state.LBL5}</div>
                                    <div id='wusr_pin_5' className={this.state.PIN5 ? 'pin_cover ' : 'btn_pin hpin'} onClick={this.handlePIN.bind(this)} style={style}>5</div></td>
                                <td><a  onClick={this.handlePINClear.bind(this)} ><i className='btn btn--white rst'>RESET</i></a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                </div>
            </PINWrapper>
    );
    }
};

export default PIN;
