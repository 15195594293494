import React from 'react';
import styled from 'styled-components';


const ButtonExpandWrapper = styled.div`
    .btn {
    &,
    &:link,
    &:visited {
        text-transform: uppercase;
        text-decoration: none;
        padding: 1.5rem 4rem;
        display: inline-block;
        border-radius: 10rem;
        transition: all .2s;
        position: relative;
        border:none;
        cursor: pointer;

    }

    &:hover {
        /* transform: translateY(-.3rem); */
        box-shadow: 0 1rem 2rem rgba(var(--color-secondary), .2);

        &::after {
            transform: scaleX(1.4) scaleY(1.6);
            opacity: 0;
    
    
        }

    }

    &:active,
    &:focus  {
        outline: none;
        /* transform: translateY(-1rem); */
        box-shadow: 0 .5rem 1rem rgba(#000, .2);
    }

    &--small {
      font-size: .9rem;
        text-transform: uppercase;
        text-decoration: none;
        padding: .5rem 1rem;
        display: inline-block;
        border-radius: 10rem;
        transition: all .2s;
        position: relative;
        border:none;
        cursor: pointer;
        background-color: rgb(12, 243, 31);
    }
    &--white {
        background-color: #fff;
        color: var(--color-grey-dark-1);
        

        &::after{
            background-color: #fff;
        }

    }

    &--primary {
        background-color: var(--color-primary);
        color: var(--color-secondary);
        
        &::after{
            background-color: var(--color-primary);
        }

    }

    &--secondary {
        background-color: var(--color-secondary);
        color: #fff;
        
        &::after{
            background-color:  var(--color-secondary);
        }

    }

    &::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 100px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .4s;

    }

    &--animated {
        animation: moveInBottom .5s ease-out  .75s;
        animation-fill-mode: backwords;
    }

    &--table {
         background-color: var(--color-primary);
         color: #fff;
         border: none;
         border-radius: 5rem;
         padding: 1rem 3rem;
         align-self: center;
         cursor: pointer;

        

         &:hover {
           background-color: #fff;
           border: 2px solid var(--color-primary);
           color: var(--color-primary);
         }
  }

    &-text {
        &:link,
        &:visited {
            font-size: 1.6rem;
            color: var(--color-primary);
            display: inline-block;
            text-decoration: none;
            border-bottom: 1px solid var(--color-primary);
            padding: 3px;
            transition: all .2s;

        }

        &:hover {
            background-color: var(--color-primary);
            color: #fff;
            box-shadow: 0 1rem 2rem rgba(#000, .15);
            transform: translateY(-2px);
        }

        &:active {

            box-shadow: 0 1rem 2rem rgba(#000, .15);
            transform: translateY(0);

        }
    }

    &-hover-slide {
        border: none;
        display: block;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        outline: none;
        overflow: hidden;
        position: relative;
        color: #fff;
        font-weight: 700;
        font-size: 1.1rem;
        background-color: #222;
        padding: 10px 50px;
        margin: 0 auto;
        box-shadow: 0 5px 15px rgba(0,0,0,0.20);

        & span {
            position: relative; 
            z-index: 1;
          }

          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 490%;
            width: 150%;
            background: var(--color-tertiary);
            -webkit-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;
            -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
            transform: translateX(-98%) translateY(-25%) rotate(45deg);
          }
          
          &:hover:after {
            -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
            transform: translateX(-9%) translateY(-25%) rotate(45deg);
          }
    }
}

`;

const ButtonExpand = (props) => {
    return(
        <ButtonExpandWrapper>
             <button onClick={props.click} className="btn btn--white">{props.title}</button>
        </ButtonExpandWrapper>
    );
}

export default ButtonExpand;