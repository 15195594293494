import React, { useState, useEffect } from "react";
import Header from './components/header';
import Search from './components/search';
import Main from './components/main';
import Footer from './components/footer';
import LoginUsr from './components/login-usr';
import styled from 'styled-components';
import { getSession, getAPI } from './utility';
import WarningModal from './components/warning-model';
import { URL } from "./constants";



const AppWrapper = styled.div``;

const App = () => {
  const [ptt, setPTT] = useState('');
  const [pat, setPAT] = useState(false);
  const [main, setMain] = useState(false);
  const [auth, setAuth] = useState(true);
  const [userFullName, setUserFullName] = useState('');
  const [cln, setClinic] = useState(getSession("cln"));
  const [warn, setWarn] = useState(false);


  useEffect(() => {

    checkOpenTab();

    let json = localStorage.getItem('session');
    let dvc = localStorage.getItem('device');

    let device = null;
    let sess = null;
    
    if (json !== null && json !== undefined) {
      
      let session = JSON.parse(json);
    
     if(session !== undefined && session !== null) {
       sess = session.sessionToken;
       

       if(dvc !== null && dvc !== undefined) {

        let dc = {};
        
        try {
          dc = JSON.parse(dvc);
          device = dc.deviceToken;
          
          if(sess !== undefined && device !== undefined) {
            checkSession(sess);
          } 

        


        } catch (e) {
          console.log(e);
          localStorage.removeItem('device');
          setAuth(false);
        }

       

       }
       
      
  
      
 
    } else {
      setAuth(false);
      
      
    }

    } else {
      setAuth(false);
    }

   
    
  }, []);

  useEffect(() => {
    if(Object.keys(ptt).length > 0) {
      setPAT(true);
    }
  }, [ptt]);

  const checkSession = async(sess) => {
    let ul = (`${URL}authConfirmSession?session=${sess}`);
   
      const response = await fetch(ul, {
        method: 'GET',
            headers: {
                'x-functions-key': getAPI(),	
            }
        });
      const rslt = await response.json();

      if (Object.keys(rslt).length === 0) {
        setAuth(false);
      } else {
        setAuth(true);
        setUserFullName(getSession("UserFullName"));
      }
    
  }
       
   const loadMain = (pt) => {

  
    setPTT(pt);
    
  }

  const checkOpenTab = () => {
    var stor = window.localStorage;

    stor.openpages = Date.now();
     window.addEventListener('storage', function (e) {
         if(e.key == "openpages") {
             // Listen if anybody else is opening the same page!
             stor.page_available = Date.now();
         }
         if(e.key == "page_available") {
             console.log("Page is already open!");
             setWarn(true);
             //launch warning modal here
             
         } else {
            console.log("Page is not open!");
         }
     }, false);

    }

    const authComplete = (val) => {
        setAuth(true);
        setUserFullName(val);
        window.location.reload();
    }

  return (
  <div>
      { auth ? null : 
     
        <LoginUsr close={(val) => authComplete(val)}/> 
      }
     
      <Header usr_full={userFullName}/> 
     
      
      <Search  cln={cln} select={(pt) => loadMain(pt)} />
      {pat ? <Main pat={ptt} close={() => setPAT(false)}  /> : null }
      <Footer />
     
     { warn ? <WarningModal show_btn={false} title={`CRx Instance Already Active Warning`} body={`CRx is already open in another TAB please close this Tab and return to the original.`} /> : null }

  </div>
  );

};
export default App;