import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import Toast from './toaster';
import FormHeader from './form-header';
import SideList from './side-list';
import useFetch from '../hooks/useFetch';
import PIN from './pin';
import { URL } from '../constants';
import { isValidNumber, isValidString, isPhone, getSession, getAPI } from '../utility';
import {suffix} from '../constants';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

var CryptoJS = require("crypto-js");

const ClnAdminUsrWrapper = styled.div`
    
    font-size: 2rem;
    z-index: 9999;
    color:#fff;
    height: 70%;
    width: 100%;
    opacity: 1;
    transition: all .3s;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);
    }

   

`;

const LoadingButtonWrapper = styled.div`
    .ctn {
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;

    @media only screen and (max-height: 750px) {
        margin-top: -3rem;
    }
  }
  #check {
    display: none;
  }

  #check2 {
    display: none;
  }

  #check3 {
    display: none;
  }
  .btn-label {
   
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5rem;
    font-family: 'BenchNine', serif;
    font-size: 2rem;
    color: #fffeee;
    margin-top: 20%;
    height: 50px;
    width: 200px;
  }
  .btn-text-wait {
    font-family: 'BenchNine', serif;
    font-size: 1.6rem;
    color: #fffeee;
  }
  .load {
    display: none;
    width: 20px;
    height: 20px;
    border: 5px solid #fff;
    border-radius: 100%;
  }
  .open {
    border-top: 5px solid transparent;
    -webkit-animation: load-animate infinite linear 1s;
            animation: load-animate infinite linear 1s;
  }
 
  #check:checked ~ .btn-label > .load {
    display: inline-block;
  }
  @-webkit-keyframes load-animate {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      opacity: 0.35;
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes load-animate {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      opacity: 0.35;
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.btn-label--normal_light {
  background-color: #fff;
  color: var(--color-primary);
}


.btn-label--normal {
  background-color: var(--color-primary);
  transition: all .8s;

  &:hover {
    background-color:var(--color-grey-dark-1);
  }
}

.btn-label--success {
  background-color:rgb(12, 243, 31);
}

.btn-label--error {
  background-color:rgb(243, 12, 23);
}


.lds-facebook {
  display: inline-block;
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  z-index:99;
  margin-left: -3rem;
  margin-top: -5rem;
  
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  
}
.lds-facebook div:nth-child(1) {
  left: 0;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 2.4rem;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 4.8rem;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

`;


const ClnAdminUsr = (props) => {

    const [table_meta, setTableMeta] = useState([]);
   
    const [valid_err, setValErr] = useState([]);
    
   
    const [usr_idx, setUSR_IDX] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [pin, setPIN] = useState([]);
    const [email, setEmail] = useState("");
    const [mobilePhone, setMobilePhone] = useState('');
    const [key_phrase, setKeyPhrase] = useState("");
    const [role, setRole] = useState("");
    const [usr, setUSR] = useState("");
   
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");
   
    const [idx, setIDX] = useState(getSession("grp"));
    const [usr_list, setUSRData] = useState([]);
    const [act_data, setACTData] = useState([]);
    const [inact_data, setINACTData] = useState([]);
   
    const [btn_text, setBtnText] = useState("DEACTIVATE USER");

    const [usr_loaded, setUSRLoaded] = useState(false);
   


    const [disable, setDisable] = useState(false);
    const [userNameUnique, setUserNameUnique] = useState(true);

   
    const [query, setQuery] = useState('');
    const [url, setURL] = useState('');

	  // const url = query && `${URL}userGetActiveUsers?idx=${getSession("grp")}`;

    const { status, data, error } = useFetch(url);

    


    useEffect(() => {
        setTableMeta([
            {"col_width": "col-100", "data_label": "User","key": "name"},
          
        ]);

        setQuery(Math.floor(Math.random() * 100));          

    }, []);

    useEffect(() => {
     
      setURL(`${URL}userGetActiveUsers?idx=${getSession("grp")}`);
      setBtnText("DEACTIVATE USER");
  }, [query]);


    useEffect(() => {

      if (status === "fetched") {
          
          setACTData(data);
          setUSRData(data);
      }
      
  }, [status]);

  
      const ReturnInActiveUserList = async() => {
      

           
          try {
            const response = await fetch(`${URL}userGetInActiveUsers?idx=${getSession("grp")}`, {
              method: 'GET',
                  headers: {
                      'x-functions-key': getAPI(),	
                  }
              });
            const inact = await response.json();

           
          
            inact.forEach(function (item, index) {
              
              item.role = item.role_string;
            });
            
            setINACTData(inact);
            setUSRData(inact);
            setBtnText("REACTIVATE USER");

          } catch (error) {
            console.log(error);
            setINACTData([]);
            setUSRData([]);

            setBtnText("DEACTIVATE USER"); 
          }
         
      }

  

    const deactivateUser = () => {
      const errors = [];
      let ul = '';
      
      let it = isValidNumber(usr_idx) ? usr_idx : errors.push("User Index Is Not Valid. Please Reload Provider and try again.");  //*
      if (errors.length > 0) {
          setValErr(errors);
         
      } else {

           setValErr([]);

           if (btn_text === "DEACTIVATE USER") {

              ul = `${URL}userDeActivate?idx=${it}`;

        
           } else {

              ul = `${URL}userReActivate?idx=${it}`;

           }

           fetch(ul, {
            method: 'GET',
                headers: {
                    'x-functions-key': getAPI(),	
                }
            })  //
          .then((rslt) => {
              
              setToastIcon("success");
              setToastMsg("User Activation Change was Successful");
              setToastState(true);
              setToastBackground("#5cb85c");
              setToastTitle("Success");

              setURL('');
              setQuery(Math.floor(Math.random() * 100));

          
          })
          .catch(err => {
              setToastIcon("error");
              setToastTitle("Error");
              setToastBackground("#d9534f");
              setToastMsg("There was a Problem Changing the Activation of the User. Please Try Again Later.");
              setToastState(true);
          });

      }
       
    }

    const clearForm = () => {

      setUSRLoaded(false);
      setDisable(false);
      setValErr([]);
      

      setUSR_IDX("");
      setFirstName("");
      setLastName("");
      setMiddleName("");
      setUserName("");
      setPassword("");
      setRole("0");
      setMobilePhone("");
      setEmail("");
      setPIN("");
      setUSR('');

      setUSRLoaded(false);
      

    }

    const selectRow = (id) => {

          
        
        let obj = usr_list.find(obj => obj.idx == id);

        
        setValErr([]);
      

        setUSRLoaded(true);
      
        setUSR_IDX(obj.idx);
        setFirstName(obj.firstName);
        setLastName(obj.lastName);
        setMiddleName(obj.middleName);
        setUserName(obj.userName);
        setPassword(obj.password);
        setRole(obj.role);
        setMobilePhone(obj.mobilePhone);
        setEmail(obj.email);
        setPIN(obj.pin.split(''));
        setUSR(obj);
        
     }

    const saveUser = () => {

      setDisable(true);

        setValErr([]);
        let errors = [];

         let obj = {};
        
         userNameUnique ? '' : errors.push("User Name is already in system. Please choose a different User Name"); 
         
         obj.idx =usr_idx;
         obj.GRP_IDX = idx;
         obj.grp = idx;
         obj.firstName = isValidString(firstName) ? firstName.toUpperCase() : errors.push("Provider First Name Is Required."); //*
         obj.lastName = isValidString(lastName) ? lastName.toUpperCase() : errors.push("Provider Last Name Is Required."); //*
         obj.middleName = isValidString(middleName) ?  middleName.toUpperCase() : "";
         obj.usr = isValidString(userName) ? userName : errors.push("User Name Is Required."); //*;
         obj.pwd = isValidString(password) ? password : errors.push("Password Is Required."); //*;
         obj.pin = pin.length === 3 ? pin.toString().replace(/,/g, '') : errors.push("Three Color PIN Is Required."); 
         obj.email = email;
         obj.eml = email;
         obj.phone = isPhone(mobilePhone) ? mobilePhone : errors.push("Valid Phone Is Required."); 
         obj.cell = mobilePhone; 
         obj.usr_role = isValidNumber(role) ? role : errors.push("User Role Is Required.");
         obj.active ="true";
         obj.newcrop_role = "0";
         obj.newcrop_type = "0";
         obj.newcrop_user = "0";


        //  var uname = CryptoJS.SHA256(obj.userName);
        //  console.log('username', uname.toString(CryptoJS.enc.Base64));
        //  obj.userName = uname.toString(CryptoJS.enc.Base64);
        //  var upwd = CryptoJS.SHA256(obj.password);
        //  console.log('password', upwd.toString(CryptoJS.enc.Base64));
        //  obj.password = upwd.toString(CryptoJS.enc.Base64);
        //  var upin = CryptoJS.SHA256(obj.pin);
        //  console.log('pin', upin.toString(CryptoJS.enc.Base64));
        //  obj.pin = upin.toString(CryptoJS.enc.Base64);

        //  obj.idx = 7;
        //  obj.GRP_IDX = 4;
        //  obj.grp = 4;
        //  obj.firstName = "JESSICA"; //*
        //  obj.lastName = "CARLSON"; //*
        //  obj.middleName = "";
        //  obj.usr = "Jessica1"; //*;
        //  obj.pwd = "Carlson1!"; //*;
        //  obj.pin = "523"; 
        //  obj.email = "";
        //  obj.eml = "";
        //  obj.phone = ""; 
        //  obj.cell = ""; 
        //  obj.usr_role = 2;
        //  obj.active ="true";
        //  obj.newcrop_role = "0";
        //  obj.newcrop_type = "0";
        //  obj.newcrop_user = "0";

           
         
         let json = JSON.stringify(obj);
        
           
        if (errors.length > 0) {
            setValErr(errors);
            setDisable(false);
            
        } else {

             setUSR(json);

            let ul = usr_loaded ? URL + "userPostUpdate" : URL + "userPostInsert";

        
               //POST DATA
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
          body: json
          };
          fetch(ul, requestOptions)
          .then((response) => {
                 
            if (response.ok === false) {  throw new Error('Network response was not ok.') };
             
                  setValErr([]);
                  setToastIcon("success");
                  setToastMsg("User Saved Successfully");
                  setToastState(true);

                  setDisable(false);
                  setURL('');
                  setQuery(Math.floor(Math.random() * 100));
                  
              })            
              .catch(err => {
                  setToastIcon("error");
                  setToastTitle("Error");
                  setToastBackground("#d9534f");
                  setToastMsg("There was a Problem Saving the User. Please Try Again.");
                  setToastState(true);

                  setDisable(false);
              });
        }

     }
 
    
 
    const inputPIN = (which) => {
        
        setPIN(which);
    }

    

     const updateActiveTab = (e) => {
        
        let index = e.target.id;
        switch(index) {
            case "1":   setUSRData(act_data); 
             break;
            case "2":  ReturnInActiveUserList();  setBtnText("REACTIVATE USER");
             break;
            default:   setUSRData(act_data); 
        }

    }

     const switchToMask = (e) => {

        if (e.target.id === "mobile") {

            const val_mobile = document.getElementById("mobile_set");
            const enter_mobile = document.getElementById("mobile");
           
            val_mobile.style.display = "none";
            enter_mobile.style.display = "block";

        } 
        
      
    }

    const switchToInput = (e) => {

        if (e.target.id === "mobile") {

            const val_mobile = document.getElementById("mobile_set");
            const enter_mobile = document.getElementById("mobile");
           
           
            if(!isPhone(mobilePhone)) {
                setMobilePhone(usr.phone);
            }

            val_mobile.style.display = "block";
            enter_mobile.style.display = "none";

        } 
        
      
    }
 
    
 
     let suffixs = suffix.map((item, index) => {
         return (<option value={item}>{item}</option>);
     });
 
   
     const err = () => {
         try {
             if (Array.isArray(valid_err)) {
               return valid_err.map((item, index)=>{
                   return (
                         <ul>
                               <li className="err_list">{item}</li>
                              
                         </ul>
                    );
               });
           } else {
             return (
               <tr><td colSpan='8'><div>No Errors to Report.</div></td></tr>
             );
           }
           } catch (err) {
             return;
           }
     }

     
    const checkUSR = async() => {
       
      
      const response = await fetch(`${URL}usernameDuplicateCheck?usr=${userName}`, {
       method: 'GET',
           headers: {
               'x-functions-key': getAPI(),	
           }
       });
   
     const duplicates = await response.json();
   
     
     if (duplicates.length > 0) {
      setUserNameUnique(false);
      setToastIcon("error");
      setToastTitle("Error");
      setToastBackground("#d9534f");
      setToastMsg("This User Name already exists. User Names must be unique. ");
      setToastState(true);
 
      } else {
          setUserNameUnique(true);
      }  
   
   }

 
    return(
        <ClnAdminUsrWrapper>
           
                <div className="form__container">
                    <div  className="form_content--side ">
                        {status === 'error' && <div>{error}</div>}
                        {status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
                        {status === 'fetched' && (
                            
                            <SideList data={usr_list} meta={table_meta} title="" select={(id) => selectRow(id)} tab_change={updateActiveTab}></SideList>
                        )}

                    </div>
                    <div  className="form_content ">
                    <FormHeader title="MANAGE USER" addClose={false} class_style="form_header--light"></FormHeader>
                    
                    <div id="error_list" >{err()}</div>

                        <div className="form_row1-col3">
                            <div className="form_col3_fld1">
                                <input onChange={e => setFirstName(e.target.value)} value={firstName}  type="text" className="form_input--light req" placeholder="First Name" id="firstname" maxLength="35" autoComplete="new-user-first-name"  />
                                <label htmlFor="firstname" className="form_label--light">First Name*</label>
                            </div>
                            <div className="form_col3_fld2">
                                <input onChange={e => setLastName(e.target.value)} value={lastName} type="text" className="form_input--light req" placeholder="Last Name"  id="lastname" maxLength="35" autoComplete="new-user-last-name"  />  
                                <label htmlFor="lastname" className="form_label--light">Last Name*</label>
                            </div>
                            <div className="form_col3_fld3">
                                <input onChange={e => setMiddleName(e.target.value)} value={middleName} type="text" className="form_input--light" placeholder="Middle Name" id="name" autoComplete="new-user-middle-name"  />  
                                <label htmlFor="name" className="form_label--light">Middle Name</label>
                            </div>
                        </div>
                        {/* <div className=" form_row2-col1">
                            
                            <div className="form_col1_fld1">
                                <input onChange={e => setKeyPhrase(e.target.value)} value={key_phrase}  type="text" className="form_input--light" placeholder="Key Phrase" id="key_phrase" maxLength="100" autoComplete="new-user-key-phrase"   />
                                <label htmlFor="key_phrase" className="form_label--light">User Key Phrase</label>
                            </div>
                         
                        </div> */}
                        <div className="form_row3-col3">
                            <div className="form_col3_fld1">
                                <input onChange={e => setUserName(e.target.value)} onBlur={checkUSR} value={userName} type="text" className="form_input--light req" placeholder="User Name"  id="username" maxLength="35" aautocomplete="new-user-name" />  
                                <label htmlFor="username" className="form_label--light">User Name*</label>
                            </div>
                            <div className="form_col3_fld2">
                                <input onChange={e => setPassword(e.target.value)} value={password}  type="text" className="form_input--light req" placeholder="Password" id="password" maxLength="35" autoComplete="new-user-password-name"   />
                                <label htmlFor="password" className="form_label--light">Password*</label>
                            </div>
                            <div className="form_col3_fld3">
                                <select id="role"  onChange={e => setRole(e.target.value)} className="form_input--light" value={role} >
                                    <option value="0">---------</option>
                                    <option value="1">USER</option>
                                    <option value="2">LOCAL ADMIN</option>
                                    <option value="3">USER NO DISPENSE</option>
                                </select>
                                <label htmlFor="role" className="form_label--light">Role*</label>
                            </div>
                        </div>
                        <div className=" form_row4-col1">
                           <PIN  onSavePin={inputPIN} pin={ pin.toString().replace(/,/g, '') }></PIN>
                           <div>PIN*: {pin}</div>
                         
                        </div>
                        <div className=" form_row5-col2">
                            <div className="form_col2_fld1">
                           
                                <InputMask type="text" onChange={e => setMobilePhone(e.target.value)} value={mobilePhone} className="form_input--light" mask="(999) 999-9999" placeholder="Mobile Phone"   id="mobile_set" autoComplete="off" /> 
                                <label htmlFor="phone" className="form_label--light">Authentication Phone*</label>
                           
                            {/* {usr !== '' ?
                                <>
                                <input onFocus={switchToMask}   value={mobilePhone} type="text" className="form_input--light"  id="mobile_set"  />  
                                <InputMask onBlur={switchToInput} style={{display:"none"}} onChange={e => setMobilePhone(e.target.value)}  type="text" className="form_input--light req" mask="99/99/9999" placeholder="Mobile Phone"  id="mobile" autoComplete="off" required /> 
                                <label htmlFor="mobile" className="form_label--light">Authentication Phone*</label>
                                </>
                                :
                                <>
                                <InputMask type="text" onChange={e => setMobilePhone(e.target.value)} value={mobilePhone} className="form_input--light  req" mask="(999) 999-9999" placeholder="Mobile Phone"   id="mobile" autoComplete="off" required /> 
                                <label htmlFor="mobile" className="form_label--light">Authentication Phone*</label>
                                </>
                                } */}
                                
                            </div>
                            <div className="form_col2_fld2">
                                <input onChange={e => setEmail(e.target.value)} value={email}  type="email" className="form_input--light" placeholder="Email" id="email" maxLength="100" required autoComplete="off"  />
                                <label htmlFor="email" className="form_label--light">Authentication Email*</label>
                            </div>
                         
                        </div>

                        
                    <div className="form_btn_bar">

                        <LoadingButtonWrapper>
                            <div className="ctn" onClick={deactivateUser}>
                                <input type="checkbox" id="check" />
                                <label htmlFor="check" id="save" className="btn-label btn-label--normal">
                                    <div id="btn2_animate" className=""><div></div><div></div><div></div></div>
                                        <p className="btn-text-wait">{btn_text}</p> 
                                </label>
                            </div>
                        </LoadingButtonWrapper>

                        <LoadingButtonWrapper>
                            <div className="ctn" onClick={clearForm}>
                                <input type="checkbox" id="check3" />
                                <label htmlFor="check3" id="save" className="btn-label btn-label--normal">
                                    <div id="btn2_animate" className=""><div></div><div></div><div></div></div>
                                        <p className="btn-text-wait">CLEAR FORM</p> 
                                </label>
                            </div>
                        </LoadingButtonWrapper>
                            
                            

                       { disable ? null : <LoadingButtonWrapper>
                            <div className="ctn" onClick={saveUser}>
                                <input type="checkbox" id="check2" />
                                <label htmlFor="check2" id="save" className="btn-label btn-label--normal">
                                    <div id="btn2_animate" className=""><div></div><div></div><div></div></div>
                                        <p className="btn-text-wait">SAVE USER</p> 
                                </label>
                            </div>
                        </LoadingButtonWrapper>}

                          
                        
                    </div>
                      
                    </div>
                </div>
                <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>
        </ClnAdminUsrWrapper>
    );
}
export default ClnAdminUsr;