import React, {useEffect, useState} from 'react';
import Toast from './toaster';
import LoadingButton from '../theme/loading-button';
import Table from './table';
import styled from 'styled-components';
import useFetch from '../hooks/useFetch';
import { URL,  shipto_list } from '../constants';
import ReactLoading from 'react-loading';
import ScanEntry from './scan-entry';
import { getAPI } from '../utility';
import FormHeader from './form-header';


const CRxOrderWrapper = styled.div`

    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    z-index: 998;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .receive_container { 
       position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 80vh;
        width: 70%;
        
       
        /* background-color: rgba(0,0,0,.6); */
        background-color: var(--color-grey-dark-2);
        z-index: 999;
        opacity: 1;
        transition: all .3s;
   
     min-height: 43.3rem;
     border: 2rem solid  var(--color-primary);
     border-radius: 5px;

      }
     

    font-size: 1.8rem;
    color: navy;
     /* color: var(--color-primary); */
    
    .receive_inventory__form_header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;
        margin-top: 2rem;
        padding-top: 1.7rem;
        border-top: var(--line);
        border-bottom: var(--line);
        padding-bottom: 1.7rem;
        color: var(--color-primary); 
    }

    .receive_inventory__btn-bar {
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
    }

    .rcv_dsp {
        max-width: 25rem;
        padding: 1rem 2rem;
        margin-left: 7rem;
    }

    .active_dsp {
        text-transform: uppercase;
        font-family: 'BenchNine', sans-serif;
        font-size: 2.2rem;
        margin: 0 auto;
    }

    .recent__table {

        
    }



`;

const CRxOrder = (props) => {

   
    const [url, setURL] = useState('');
   
    const [query, setQuery]  = useState('');
	
    
    const { status, data, error } = useFetch(url);
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");
    const [receive, setReceive] = useState([]);
   
    const [table_meta, setTableMeta] = useState([]);
    const [disable, setDisable] = useState(false);

  
    useEffect(() => {
        setTableMeta([

            {"col_width": "col-15", "data_label": "Clinic","key": "ClinicAlias"},
            {"col_width": "col-10", "data_label": "Clinic Number","key": "ClinicNumber"},
            {"col_width": "col-10", "data_label": "ShipTo Number","key": "ClinicShipToNumber"},
            {"col_width": "col-10", "data_label": "ITEMNUM","key": "ITEMNMBR"},
            {"col_width": "col-10", "data_label": "Order Qty","key": "OrderQty"},
            {"col_width": "col-10", "data_label": "Unit Qty","key": "UnitQty"}
        ]);
      
        setURL(`${URL}admCRxOrderSelect`);
       

    }, []);


    useEffect(() => {
        console.log(query);
        setURL(`${URL}admCRxOrderSelect`);
    }, [query]);
  

    useEffect(() => {

        if (status === "fetched") {
            console.log(data);
        }
        
    }, [status]);



    const selectRow = (e) => {
        console.log(e.target.id);
        const sel = document.getElementById(e.target.id);
       
        
        if(sel) {
            sel.classList.add("table-row--light__selected");
             
            setReceive(receive => [...receive, sel.id]);
        }
        
    }


   
    const submitReceiveOrders = () => {
        console.log('submitReceive');
        setDisable(true);
        console.log(JSON.stringify(receive));

        if(receive.length > 0) {

            receiveInv();
            setToastIcon("success");
            setToastMsg("Dispenser Saved Successfully");
            setToastState(true);
            setToastBackground("#5cb85c");
            setToastTitle("Success");
            setDisable(false);
            setReceive([]);

        }   else {
            console.log("Nothing to Process");
            setToastIcon("error");
            setToastTitle("Error");
            setToastBackground("#d9534f");
            setToastMsg("There were No Orders Chosen to Process. Please Try Again Later.");
            setToastState(true);
            setDisable(false);
            setReceive([]);
        }
               
          
       
    }

    const reset_orders = () => {

        console.log('reset');
        setReceive([]);
        
        let ul =   `${URL}admResetProcessedOrders`;  

        fetch(ul, {
            method: 'GET',
                headers: {
                    'x-functions-key': getAPI(),	
                }
            })  
			.then(response => response.json())
			.then(data => {

                console.log(data);
                setURL('');
				setQuery(Math.floor(Math.random() * 100));
				
			})  
			.catch(err => {
				console.log(err);
			});

    }
    

    const receiveInv = () => {

      

        fetch(`${URL}admCRxOrderProcess?idx=1`, {
            method: "POST",
            headers: { "Content-Type": "application/json", 'x-functions-key' : getAPI() },
            body: JSON.stringify(receive)
        })
          .then((res) => res.json())
          .then((result) => {
            console.log(result);
            setURL(``);
            setQuery(Math.floor(Math.random() * 100));
            
            setDisable(false);
          })
          .catch((err) => console.log('error'));

      }
    





    return(
       <CRxOrderWrapper>
        <div className="receive_container">
         <FormHeader onClose={props.onClose} title="PROCESS CRx ORDERS" addClose={true} class_style="form_header--light"></FormHeader>
          

           <div className="recent__table">

                {status === 'error' && <div>{error}</div>}
				{status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
				{status === 'fetched' && (

                      
					  <Table data={data} meta={table_meta} row_class="table-row" include_header="true" select={selectRow} table_height="tbl"></Table>
				)}

              
                    
            </div>


           
           <div className="receive_inventory__btn-bar">

              

                { disable ? <div></div> : <LoadingButton title="PROCESS ORDER" select={submitReceiveOrders} id="check21" />}
                 <LoadingButton title="REFRESH ORDERS" select={reset_orders} id="check3" />
               
                        
            </div>

           
        </div>

            <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>
        </CRxOrderWrapper>
    );

}
export default CRxOrder;