import React, {useEffect, useState} from 'react';
import Toast from './toaster';
import LoadingButton from '../theme/loading-button';
import Table from './table';
import styled from 'styled-components';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import { getAPI } from '../utility';
import ReactLoading from 'react-loading';
import FormHeader from './form-header';
import ImportEdit from './import-edit';


const GlobalAdminImportWrapper = styled.div`

    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    z-index: 998;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .receive_container { 
       position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 80vh;
        width: 70%;
        
       
        /* background-color: rgba(0,0,0,.6); */
        background-color: var(--color-grey-dark-2);
        z-index: 999;
        opacity: 1;
        transition: all .3s;
   
     min-height: 43.3rem;
     border: 2rem solid  var(--color-primary);
     border-radius: 5px;

      }
     

    font-size: 1.8rem;
    color: navy;
     /* color: var(--color-primary); */
    
    .receive_inventory__form_header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;
        margin-top: 2rem;
        padding-top: 1.7rem;
        border-top: var(--line);
        border-bottom: var(--line);
        padding-bottom: 1.7rem;
        color: var(--color-primary); 
    }

    .receive_inventory__btn-bar {
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        float: right;
    }

    .rcv_dsp {
        max-width: 25rem;
        padding: 1rem 2rem;
        margin-left: 7rem;
    }

    .active_dsp {
        text-transform: uppercase;
        font-family: 'BenchNine', sans-serif;
        font-size: 2.2rem;
        margin: 0 auto;
    }

   
`;

const TableWrapper = styled.div`
   .tbl {
    height: 50rem;
    overflow-y:scroll;
    @media screen and (max-height: 950px) {
          height: 45rem;
      }

      @media screen and (max-height: 780px) {
          height: 30rem;
      }

   }

   .tbl-lng {
    height: 50rem;
    overflow-y:scroll;
   }

   .tbl-med {
    height: 44rem;
    overflow-y:scroll;
   }

   .table-row-green {
        background-color: #fff;
        box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
        transition: all .9s;
        cursor: pointer;
        text-transform: uppercase;
        border: 3px solid green;
  
      }
  
      .table-row-green:hover {
          background-color: rgb(243, 184, 162);
          color:#fff;
      }
  
      .table-row-green__selected {
          background-color: rgb(247, 104, 9);
          color:#fff;
        }

   .table-row-red {

    background-color: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    transition: all .9s;
    cursor: pointer;
    text-transform: uppercase;
    border: 3px solid red;
  

   }
   .table-row-red:hover {
          background-color: rgb(243, 184, 162);
          color:#fff;
      }
  
    .table-row-red__selected {
        background-color: rgb(247, 104, 9);
        color:#fff;
    }



`;

const GlobalAdminImport = (props) => {

    const [query, setQuery] = useState('');
    const [url, setURL] = useState('');
    const [imp, setImp] = useState('');

    const [edit, setEdit] = useState(false);
  
     
    const { status, data, error } = useFetch(url);

    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");

    
 
    const [table_meta, setTableMeta] = useState([]);

    
  
    useEffect(() => {
        setTableMeta([
            {"col_width": "col-10", "data_label": "Order","key": "OrderNumber"},
            {"col_width": "col-15", "data_label": "Clinic","key": "ClinicAlias"},
            {"col_width": "col-50", "data_label": "Drug","key": "LN"},
            {"col_width": "col-15", "data_label": "NDC","key": "NDC"},
            {"col_width": "col-15", "data_label": "LOT","key": "LOT"},
            {"col_width": "col-10", "data_label": "Ordered","key": "UnitsOrdered"},
            {"col_width": "col-10", "data_label": "Exp Date","key": "ExpDate"},
            {"col_width": "col-10", "data_label": "Ship Date","key": "ShipDate"}
        ]);

        		
        if (query) {
			setQuery(query);
	    }
       
    }, []);

    useEffect(() => {
        if(imp !== '' ) {
            setEdit(true);
        }
        
    }, [imp]);

    const refreshList = () =>  {
        setURL('');
        setQuery(Math.floor(Math.random() * 100));
        setEdit(false);
       
    }

    const data_table = () => {
        try {
         
            if (Array.isArray(data)) {
              return data.map((item, index)=>{
               
                  return (
                       
                    <li className={item.ErrorMsg === undefined ? `table-row-green`: `table-row-red`} key={index} id={item.IDX} onClick={selectRow}>
                        {table_meta.map((value, index) => {
                           return <div key={index} className={`col ${table_meta[index].col_width}`} data-label={`${table_meta[index].data_label}`}>{item[table_meta[index].key]}</div> 
                        })}
                    </li>
                    
                   );
              });
          } else {
            return (
               
                    <li className={`table-row-green`}>
                            <div>The Data List is Empty.</div>
                    </li>
               
            );
          }
          } catch (err) {
            return;
          }
        
    }


    useEffect(() => {
        setURL(`${URL}inventoryImportScreen`);
    }, [query]);

;

    const selectRow = (e) => {
        console.log(e.target.id);
        const sel = document.getElementById(e.target.id);

        let arr = data.find((item) => item.IDX.toString() === e.target.id);
        console.log(arr);
        
       setImp(arr);
        
    }


   
    const submitImport = () => {

      

        if(data.length > 0) {

            let arr = data.filter((item) => item.ErrorMsg === undefined);
            console.log(arr);

            let d = [];

            if(arr.length > 0) {
                arr.forEach(element => {
                    d.push(element.IDX);       
                });
            }

            console.log(d);
                
             let json = JSON.stringify(d);
             console.log(json);
    
            
               
            if (d.length > 0) {
                
                
                  //POST DATA
           const requestOptions = {
             method: 'POST',
             headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
             body: json
             };
             fetch(`${URL}admProcessImport`, requestOptions)
                 .then(response => response.json())
                 .then(() => {
                     
                     setToastIcon("success");
                     setToastMsg("Import Processed Successfully");
                     setToastState(true);

                     setURL('');
                     setQuery(Math.floor(Math.random() * 100));
                    
                 })            
                 .catch(err => {
                     setToastIcon("error");
                     setToastTitle("Error");
                     setToastBackground("#d9534f");
                     setToastMsg("There was a Problem Processing the Import. Please Try Again.");
                     setToastState(true);
                    
                 });
          
            } 
   
        }   else {
            console.log("Nothing to Import");
        }
      }


    return(
       <GlobalAdminImportWrapper>
        <div className="receive_container">
         <FormHeader onClose={props.onClose} title="IMPORT INVENTORY" addClose={true} class_style="form_header--light"></FormHeader>
            

           <div className="recent__table">

                {status === 'error' && <div>{error}</div>}
				{status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
				{status === 'fetched' && (

                          <TableWrapper>
                            <div className={`tbl`}>
                                <ul className="responsive-table">
                                    
                                    <li className="table-header">
                                        {table_meta.map((value, index) => {
                                        return <div key={index} className={`col ${table_meta[index].col_width}`}>{`${table_meta[index].data_label}`}</div>
                                        })}
                                    </li>
                                
                                    {data_table()}
                                </ul>
                            </div>
                            </TableWrapper>
				)}

              
                    
            </div>


           
           <div className="receive_inventory__btn-bar">

                {/* <LoadingButton title="CLEAR SELECTED" select={clearReceive} /> */}
              

                <LoadingButton title="IMPORT ALL INVENTORY" id="check2" select={submitImport} />
               
                        
            </div>

          
        </div>

        <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={"Success"}
                    description={toast_msg}
                    backgroundColor={"#5cb85c"}
                    icon="success"
                    close={() => setToastState(false)}

                /> : null }
            </div>

            {edit ? <ImportEdit data={imp} updated={refreshList} onClose={() => setEdit(false)} /> : null}
        </GlobalAdminImportWrapper>
    );

}
export default GlobalAdminImport;