import React from 'react';
import styled from 'styled-components';

const LoadingButtonWrapper = styled.div`
   .ctn {
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
  }

  .hide_chk {
    display: none;
  }
  /* #check {
    display: none;
  }

  #check2 {
    display: none;
  }
  #check3 {
    display: none;
  }

  #check4 {
    display: none;
  }
  #check5 {
    display: none;
  }

  #check6 {
    display: none;
  }
  #check7 {
    display: none;
  }

  #check8 {
    display: none;
  }
  
  #check21 {
    display: none;
  }
  #check22 {
    display: none;
  } */


  .btn-label {
   
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5rem;
    font-family: 'BenchNine', serif;
    font-size: 2rem;
    color: #fffeee;
    margin-top: 20%;
    height: 50px;
    width: 200px;
  }
  .btn-text-wait {
    font-family: 'BenchNine', serif;
    font-size: 1.6rem;
    color: #fffeee;
  }
  .load {
    display: none;
    width: 20px;
    height: 20px;
    border: 5px solid #fff;
    border-radius: 100%;
  }
  .open {
    border-top: 5px solid transparent;
    -webkit-animation: load-animate infinite linear 1s;
            animation: load-animate infinite linear 1s;
  }
  // #check:checked ~ .btn-label > .btn-text {
  //   display: none;
  // }
  #check:checked ~ .btn-label > .load {
    display: inline-block;
  }
  @-webkit-keyframes load-animate {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      opacity: 0.35;
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes load-animate {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      opacity: 0.35;
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.btn-label--normal_light {
  background-color: #fff;
  color: var(--color-primary);
}


.btn-label--normal {
  background-color: var(--color-primary);
  transition: all .8s;

  &:hover {
    background-color:var(--color-grey-dark-1);
  }
}

.btn-label--success {
  background-color:rgb(12, 243, 31);
}

.btn-label--error {
  background-color:rgb(243, 12, 23);
}


.lds-facebook {
  display: inline-block;
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  z-index:99;
  margin-left: -3rem;
  margin-top: -5rem;
  
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  
}
.lds-facebook div:nth-child(1) {
  left: 0;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 2.4rem;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 4.8rem;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}


`;

const LoadingButton = (props) => {

    const submit = () => {
      console.log('submit');
            props.select();
    };
    return(

        <LoadingButtonWrapper>
             <div className="ctn" onClick={submit}>
                <input type="checkbox" id={props.id} className="hide_chk"/>
                <label htmlFor={props.id}  className="btn-label btn-label--normal">
                    <div id="btn2_animate" className=""><div></div><div></div><div></div></div>
                        <p className="btn-text-wait">{props.title}</p> 
                </label>
            </div>
        </LoadingButtonWrapper>
    );
}

export default LoadingButton;