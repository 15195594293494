import React, { useState, useEffect } from "react";
import styled from 'styled-components';

const TwoFactorWrapper = styled.div`

display: flex;
align-items: flex-start;
justify-content: center;
width: 40rem;
margin: 0 auto;

.two-factor-content {       
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border: 1px solid var(--color-primary);
    border-radius: 7px;
    margin-top: 1rem;
    background-color: white;
    padding: 3rem;
}

.two-factor-content__input {
    width: 5rem;
    height: 5rem;
    border: 3px solid var(--color-primary);
    font-family: inherit;
    color:navy;
    font-size: 3.25rem;
    text-align: center;
}

.code_title {
    font-size: 5rem;
    font-weight: 100;
    color: var(--color-primary);
    margin-bottom: 2rem;
    align-self: flex-start;
    width: 100%;
    margin-left: 35rem;
}

`;



const twoFactor = (props) => {

    const [code, setCode] = useState([]);

    useEffect(() => {
        console.log(code);
        if (code.length === 5) {
            console.log(code.join(""));
            props.select(code.join(""));
            
        }
    }, [code]);

    const getCode = (e) => {

        if (e.target.value.length === 1) {
            setCode(code => code.concat(e.target.value) );
            if(e.target.id !== "5") {
                e.target.nextElementSibling.focus();
            } else {
                console.log("done");
            }
           
        }
    }

    return (
       
      
        <TwoFactorWrapper>
           
            <div className="two-factor-content">
                <input id="1" autoFocus onKeyUp={getCode} className="two-factor-content__input"></input>
                <input id="2" onKeyUp={getCode} className="two-factor-content__input"></input>
                <input id="3" onKeyUp={getCode} className="two-factor-content__input"></input>
                <input id="4" onKeyUp={getCode} className="two-factor-content__input"></input>
                <input id="5" onKeyUp={getCode} className="two-factor-content__input"></input>
              
            </div>
            
        </TwoFactorWrapper>
       
    );
}

export default twoFactor;