import React, { useState } from 'react';
import styled from 'styled-components';
import ButtonExpand from '../theme/btn_expand';
import FormHeader from './form-header';
import InputMask from 'react-input-mask';
import AutoCompleteForm from '../theme/autocomplete-form';
import { URL } from '../constants';
import { getAPI } from '../utility';
import Toast from './toaster';


const RxHistoryAddWrapper = styled.div`
    width: 65rem;
    height: 45rem;
    margin: auto;
    background-color: var(--color-grey-dark-2);
    color: #fff;
    
    border: 2rem solid  rgba(15, 215, 239, 1);
    border-radius: 5px;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    font-size: 2rem;
    white-space: nowrap;
    box-shadow: 0 1rem 3rem rgba(#fff, .2);

    .rx-mini-form {
       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: 1fr 1fr;
       grid-gap: 2rem;

      
       padding-top: 1rem;
       padding-bottom: 1rem;
       font-size: 2rem;
       margin-left: 2rem;
       margin-right: 2rem;

     }
     .rx-btn-bar {
       display: flex;
       justify-content: flex-end;
       margin-right:2rem;
       margin-top:3rem;
     }

     .header {
        width: 100%;
        margin-top: -1rem;
     }

     #rx-type_container {

        grid-column: 1 / 2;
        width: 200px;
     }

    #ln-container {
        grid-column: 2 / 3;
    }

    #written-date-container {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }

    #prescriber-container {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        width: 200px;
    }



`;


const RxHistoryAdd = (props) => {

    const [ln, setLN] = useState('');
    const [writtenDate, setWrittenDate] = useState('');
    const [source, setSource] = useState('');
    const [provider, setProvider] = useState('');
    const [type, setType] = useState('');
    const [disabled, setDisabled] = useState(false);

    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");



    const submit = () => {

      setDisabled(true);

        
        let data = {
            pat: props.ptt,
            ln: ln.LN,
            sig: "",
            rxcui: "",
            medid: ln.MEDID,
            prn: 'false',
            writtenDate: writtenDate,
            pvdName: provider,
            medType: type,
            medComment: ""
           
        }

        let errors = [];
      

        

        let json = JSON.stringify(data);
       
        if (errors.length > 0) {
            setValErr(errors);
            setDisabled(false);
          
        } else {

           

             //POST DATA
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key': getAPI(),  },
            body: json
            };
            fetch(`${URL}dispenseSupplementalInsert`, requestOptions)
                .then((response) => {
                    
                   
                        if (response.ok === false) {  throw new Error('Network response was not ok.') };
                            
                       
                    setToastIcon("success");
                    setToastMsg("Supplemental Medication Saved Successfully");
                    setToastState(true);
                    setToastBackground("#5cb85c");
                    setToastTitle("Success");
                    setDisabled(false);

                    props.select();
                })            
                .catch(err => {

                    console.log(err);
                    setToastIcon("error");
                    setToastTitle("Error");
                    setToastBackground("#d9534f");
                    setToastMsg("There was a Problem Saving the Supplemental Medication. Please Try Again.");
                    setToastState(true);
                    setDisabled(false);
                });
             
        }
    }

    const selectRx = (rx) => {
        setLN(rx);
        
    }

    return (
        <RxHistoryAddWrapper>
            <div className="header"><FormHeader onClose={props.close} title="ADD SUPPLEMENTAL MEDICATION" addClose={true} class_style="form_header--light"></FormHeader></div>
              <div className="rx-mini-form">
                        <div id="rx-type_container">
                                <select id="rx-type" onChange={(e) => setType(e.target.value)} value={type} className="form_input--light req">
                                    <option value="0">-----</option>
                                    <option value="F">RxOnly</option>
                                    <option value="O">OTC</option>
                                    
                                </select>
                                <label htmlFor="rx-type" className="form_label">Rx Type</label>
                        </div>
                        <div id="ln-container">
                            <AutoCompleteForm Label="MEDICATION" id="ln" maxLength="35" select={(rx) => selectRx(rx)} required autoComplete="off"   url={`${URL}autocompleteLN?idx=${type}`}/>
                            {/* <input type="text" className="form_input--light req" placeholder="Label Name" id="ln" maxLength="35"onChange={(e) => setLN(e.target.value)} required autoComplete="off"  /> */}
                          
                        </div>
                        <div id="written-date-container">
                                <InputMask type="text" className="form_input--light req" mask="99/99/9999" placeholder="Written Date"  onChange={(e) => setWrittenDate(e.target.value)}  id="writtenDate" autoComplete="off" required /> 
                                {/* <label for="writtenDate" className="form_label">Written Date</label> */}
                        </div>
                        <div id="prescriber-container">
                            <input type="text" className="form_input--light req" placeholder="Provider" id="provider" maxLength="35" onChange={(e) => setProvider(e.target.value)}required autoComplete="off"  />
                            {/* <label for="provider" className="form_label">Provider</label> */}
                        </div>
                    </div>
                    <div className="rx-btn-bar"> {disabled ? <div></div> : <ButtonExpand title="SUBMIT"  click={submit}/>}</div>

                    <div className="toasty">
                     {toast ?  <Toast 
                          id={74}
                          position={'bottom-center'}
                          autoDelete={true}
                          autoDeleteTime={3000}
                          title={toast_title}
                          description={toast_msg}
                          backgroundColor={toast_background}
                          icon={toast_icon}
                          close={() => setToastState(false)}
                /> : null }
            </div>


        </RxHistoryAddWrapper>
    );
}

export default RxHistoryAdd;

