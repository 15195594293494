import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import FormHeader from './form-header';
import Toast from './toaster';
import Table from './table';
import ButtonExpand from '../theme/btn_expand';
import ProblemAdd from './problem-add';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import ReactLoading from 'react-loading';
import { getAPI } from '../utility';

const PatientProblemListWrapper = styled.div`

    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 998;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;



        @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
            -webkit-backdrop-filter: grayscale(80%) blur(5px);
            backdrop-filter: grayscale(70%) blur(5px);
            background-color: rgba(#000, .4);

        }



.problem_list__header {
    grid-row: 1/2;
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--line);
    padding: 2rem;
}

.problem_list__title {
    font-size: 2rem;
    color: var(--color-primary);
    margin-left: 2rem;
    

}

.problem_list_close {
       
        text-decoration: none;
        transition: all .3s;
        line-height: 1;
        margin-right: 2rem;
        cursor: pointer;
        fill: var(--color-primary);
        transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

        .problem_list_close:hover {
            fill: red;
        }


}

.problem_list_content {
        height: 70vh;
        width: 80vw;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 999;
        opacity: 1;
        transition: all .3s;
        background-color:  rgba(0,0,0, 0.6); 
        backdrop-filter: blur(19px);
        -webkit-backdrop-filter: blur(19px);
        border-radius: 10px;
        border: 1px solid rgba(255,255,255,0.6);
        font-size: 2rem;

}


.problem_list__add-header {
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--line);
    padding: 2rem;
}

.problem_list__btn-bar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.problem_list__table {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  
}

.problem_list__add {
    position: absolute;
    top:-100%;
    width: 100%;
    height: 25rem;
    background-color: var(--color-primary);
    transition: all .7s cubic-bezier(0.36, 0, 0.66, -0.56);

    .problem_list__add-close {
        text-decoration: none;
        transition: all .3s;
        line-height: 1;
        margin-right: 2rem;
        cursor: pointer;
        fill: #fff;
        transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

        &:hover {
            fill: red;
        }

    
    }

    .problem_list__add-title {
        color: #fff;
        font-size: 2rem;
    }

}

.problem_list__open-add {
  
    top: 0;
}

.btn_disabled {
    text-transform: uppercase;
    text-decoration: none;
    padding: .75rem 3rem; 
    display: inline-block;
    border-radius: 10rem;
    transition: all .2s;
    position: relative;
    border:none;
    background-color: #ccc;
}


`;

const PatientProblemList = (props) => {

    
       
        const [prb_idx, setIDX] = useState('');
        const [problem, setProblem] = useState('');
        const [codeSystem, setCodeSystem] = useState('');
        const [onsetDate, setOnsetDate] = useState('');
        const [code, setCode] = useState('');
        const [provider, setProvider] = useState('');
        
        const [toast, setToastState] = useState(false);
        const [toast_msg, setToastMsg] = useState("");
        const [toast_icon, setToastIcon] = useState("");
        const [toast_title, setToastTitle] = useState("Success");
        const [toast_background, setToastBackground] = useState("#5cb85c");
        const [query, setQuery] = useState('');
        const [table_meta, setTableMeta] = useState([]);
        const [add, setAdd] = useState(false);
        const [disabled, setDisabled] = useState(true);

        const [url, setUrl] = useState("");
        const { status, data, error } = useFetch(url);
    
        useEffect(() => {
            setTableMeta([
                {"col_width": "col-40", "data_label": "Problem","key": "pat_problem_desc"},
                {"col_width": "col-15", "data_label": "Onset Date","key": "pat_onset_date"},
                {"col_width": "col-25", "data_label": "Code System","key": "pat_code_system"},
                {"col_width": "col-25", "data_label": "Code","key": "pat_code_value"},
                {"col_width": "col-25", "data_label": "Provider","key": "pvd_name"}
            ]);

            setUrl(`${URL}patientProblemList?idx=${props.idx}`);
            
        }, []);

        useEffect(() => {
            setUrl(`${URL}patientProblemList?idx=${props.idx}`);
        }, [query]);

            


        const selectRow = (e) => {
            setDisabled(false);
          
            setIDX(e.target.id);

            const sel = document.getElementById(e.target.id);
            let els = document.getElementsByClassName('table-row__selected');
            
            while (els[0]) {
                els[0].classList.remove('table-row__selected')
            }
    
            sel.classList.add("table-row__selected");
        }
    
      
        function open_add() {
                setAdd(true);
        }


        const deactivate_prb = async() => {
          const sel = document.getElementsByClassName('table-row__selected');
          if (sel.length > 0) {
             console.log(prb_idx);

              let ul = (`${URL}problemDeActivate?idx=${prb_idx}`);
              try {
                const response = await fetch(ul, {
                    method: 'GET',
                        headers: {
                            'x-functions-key': getAPI(),	
                        }
                    });
                const rslt = await response.json();
                let data = "";
                try {
                    
                    setToastIcon("success");
                    setToastTitle("Success");
                    setToastBackground("#5cb85c");
                    setToastMsg("Problem List Entry DeActivated Successfully.");
                    setToastState(true);

                    setDisabled(true);  

                    setUrl('');
                    setQuery(Math.floor(Math.random() * 100));

                } catch {
                    data = rslt;
                }
                
                
               
            } catch (error) {
                  setToastIcon("error");
                  setToastTitle("Error");
                  setToastBackground("#d9534f");
                  setToastMsg("There was a Problem DeActivating this entry, Please Try Again.");
                  setToastState(true);
                  
            }
          }
      }
  
      const delete_prb = async() => {

        const sel = document.getElementsByClassName('table-row__selected');
        if (sel.length > 0) {
           console.log(prb_idx);

            let ul = (`${URL}problemDelete?idx=${prb_idx}`);
            try {
              const response = await fetch(ul, {
                method: 'GET',
                    headers: {
                        'x-functions-key': getAPI(),	
                    }
                });
              const rslt = await response.json();
              let data = "";
              try {
                  
                  setToastIcon("success");
                  setToastTitle("Success");
                  setToastBackground("#5cb85c");
                  setToastMsg("Problem List Entry Deleted Successfully.");
                  setToastState(true);

                  setDisabled(true);

                  setUrl('');
                  setQuery(Math.floor(Math.random() * 100));

              } catch {
                  data = rslt;
              }
              
              
             
          } catch (error) {
                setToastIcon("error");
                setToastTitle("Error");
                setToastBackground("#d9534f");
                setToastMsg("There was a Problem Deleting this entry, Please Try Again.");
                setToastState(true);
                
          }
        }
      }

         const reloadProblem = () => {
        setAdd(false);
        setUrl('');
        setQuery(Math.floor(Math.random() * 100));
        
    };

        return(
            <PatientProblemListWrapper>
                <div id="prb_content" className="problem_list_content">
               
                <FormHeader onClose={props.onClose} title="PATIENT PROBLEM LIST"addClose={true} class_style="form_header--dark" ></FormHeader>
                  
                    <div className="problem_list__btn-bar">
                           <ButtonExpand title="ADD NEW" click={open_add} />
                            { disabled ? <div className="btn_disabled">DEACTIVATE</div> : <ButtonExpand title="DEACTIVATE" click={deactivate_prb} />}
                            { disabled ? <div className="btn_disabled">DELETE</div> : <ButtonExpand title="DELETE" click={delete_prb} />}
                            
                    </div>
                    <div>
                    {status === 'error' && <div>{error}</div>}
                    {status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
                    {status === 'fetched' && (
                        <Table data={data} meta={table_meta} row_class="table-row" include_header="true" select={selectRow} table_height="tbl-med"></Table>
                    )}
                    </div>
                </div>
                
                

                {add ? <ProblemAdd ptt={props.idx} close={() => setAdd(false)} select={reloadProblem} /> : null}

                 
                <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
              </div>


            </PatientProblemListWrapper>
        );

   
}

export default PatientProblemList;