import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PatientDetail from './patient-detail';
import ProviderDetail from './provider-detail';
import RxSearch from './rx-search';
import DrugDetail from './drug-detail';

const MainWrapper = styled.div`
    display: grid;
    grid-template-rows: 19vh 7vh 7vh 47vh 1vh; //8vh 6vh repeat(2, min-content);
    grid-template-columns: repeat( auto-fit, minmax(20rem, 1fr));
    max-width: 80vw;
    margin: 0;
    background-color: #ffffff;
    box-shadow: var(--shadow-dark);
  

   


`;

const  Main = (props) => {

  const [opn, setOPN] = useState(false);
  const [inv, setINV] = useState(false);
  const [rpt, setRPT] = useState(false);
  const [ord, setORD] = useState(false);
  const [drg_data, setDrugData] = useState(undefined);

  const [dsr, setDSR] = useState(undefined);
  const [pvd, setPVD] = useState(undefined);

  const [rx, setRx] = useState(false);
  const [pt, setPTT] = useState(false);
  
  useEffect(() => {

    switch(props.screen) {
      case 'dsp': setOPN(true); setINV(false); setRPT(false); setORD(false);
        break;
      case 'inv': setOPN(false); setINV(true); setRPT(false); setORD(false);
        break;
      case 'rpt': setOPN(false); setINV(false); setRPT(true); setORD(false);
        break;
      case 'ord': setOPN(false); setINV(false); setRPT(false); setORD(true);
        break;
      default: setOPN(false); setINV(false); setRPT(false); setORD(false);

    };

    setPTT(true);

    console.log(props.pat);

    //check for stale data if stale and app is online call clear and then load report
   // ReturnActiveDispensers();


}, []);



  const onCloseDSP = () => {
    setOPN(!opn);
  }

  
  const onCloseINV = () => {
    setOPN(false); setINV(false); setRPT(false); setORD(false);
  }

  
  const onCloseRPT = () => {
    setRPT(!rpt);
  }

  
  const onCloseORD = () => {
    setORD(!ord);
  }

  const setProvider = (pvd) => {
    setPVD(pvd);
    setRx(true);
  }

  const reloadPtt = () => {
    setPTT(false);
    props.close();
  }

  return (
    <MainWrapper>
        { pt ? <PatientDetail onClose={reloadPtt} pat={props.pat}></PatientDetail> : null}
        <ProviderDetail selectedDSR={(v) => setDSR(v)} selectedPVD={v => setProvider(v)}/>
        { rx ? <RxSearch select={(drg) => setDrugData(drg)}/> : null}
        {drg_data && <DrugDetail drg={drg_data} pat={props.pat} dsr={dsr} pvd={pvd}  encounterGUID={props.pat.EncounterGUID} close={() => setDrugData(undefined)}/>}
    </MainWrapper>
  );
}

export default Main;