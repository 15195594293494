import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Toast from './toaster';
import LoadingButton from '../theme/loading-button';
import FormHeader from './form-header';
import { URL } from '../constants';
import AutoCompleteDropdown from '../theme/autocomplete-dropdown';
import AutoCompleteForm from '../theme/autocomplete-form';
import { getAPI } from '../utility';



const FormularyEditWrapper = styled.div`
    
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    z-index: 998;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);
    }
    

    .frm__container {
        display: flex;
        justify-content: space-around;
        margin-top: 5rem;
    }

    .frm_content {
        
        width: 75%;
        height: 70rem;
        box-shadow: 0 .5rem 2rem rgba(#fff, .2);
        border-radius: 5px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, 1fr);
        overflow: hidden;
        position: relative;
        opacity: 1;
        border: .5rem solid var(--color-primary);
        transition: all 1s;
        background-color: transparent;
        background-size: cover;
        background-position: top;

      

        .edit_inventory--side {
            overflow: scroll;
            position: relative;
            width: 25%;
            height: 32rem;
            border-radius: 5px;
            border: .5rem solid rgba(#fff, .5);
        }

        

    }

    
    .rcv_dsp {
        max-width: 25rem;
        padding: 1rem 2rem;
        margin-left: 7rem;
        
    }

    .edit_inventory__search {
           
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:2rem;

        .edit_inventory__search__input {
            font-family: 'BenchNine', sans-serif;
            font-size: initial;
            background-color: var(--color-grey-light-2);
            border: none;
            color: var(--color-primary);
            padding: .7rem 2rem;
            border-radius: 1rem;
            width: 50%;
            transform: all .3s;
            transition: all .5s;
            z-index: 10;
            margin-top: 1rem;
            margin-left: 2.5rem;

            &:focus {
                outline: none;
                width: 70%;
                background-color: var(--color-grey-light-3);
            }

            &::input-placeholder {
                font-weight: 100;
                color: var(--color-grey-light-4);
            }
        }
        .edit_inventory__search__input:focus  {
                background-color: var(--color-grey-light-3);
       }

    
    }

    .edit_inventory__top-hdr {
        font-family: 'BenchNine', sans-serif;
        font-size: 2.5rem;
    }

    .edit_inventory__btn-bar {
           
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: -1rem;
    }

    .edit_inventory__metadata {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1rem;
    }

    .edit_inventory__adj {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1rem;
    }

   

 

    input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    .toggle {
        transform: scale(.6);
    }

.switch {
  cursor: pointer;
  text-indent: -9999px;
  width: 15rem;
  height: 6rem;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 5rem;
  height: 5rem;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

#switch:checked + label {
  background: var(--color-tertiary);

}

#switch:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 10rem;
}

.search_hdr {
    font-family: 'BenchNine', sans-serif;
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}


`;


const FormularyEdit = (props) => {

const [valid_err, setValErr] = useState([]);

const [toast, setToastState] = useState(false);
const [toast_msg, setToastMsg] = useState("");
const [toast_icon, setToastIcon] = useState("");
const [toast_title, setToastTitle] = useState("Success");
const [toast_background, setToastBackground] = useState("#5cb85c");

const [disabled, setDisabled] = useState(false);
    
 
  const [idx, setIDX] = useState(undefined);
  const [ln, setLN] = useState("");
  const [rxcui, setRXCUI] = useState("");
  const [medid, setMEDID] = useState("");
  const [gcn_seqn, setGCN_SEQN] = useState("");
  const [ndc, setNDC] = useState("");
  const [itemnum, setITEMNUM] = useState("");
  const [df, setDF] = useState("");
  const [prd, setPRD] = useState("");
  const [prd_code, setPRD_CODE] = useState("");

  const clearForm = () => {
       
        setIDX("");     
        setLN("");      
        setRXCUI("");   
        setMEDID("");   
        setGCN_SEQN("");
        setNDC("");     
        setITEMNUM("");
        setPRD("");
        setPRD_CODE("");
        setDF("0");

  }

  const submitForm = () => {

    setDisabled(true);

         const errors = [];
         let obj = {};
   
        
         let inv_loaded = false;
         if (idx === undefined || idx === "") {
            inv_loaded = false;
         } else {
            inv_loaded = true;
         }   

            obj.idx = idx;
            obj.prd = prd;
            obj.ln = ln;    
            obj.rxcui = rxcui;
            obj.medid = medid;
            obj.gcn = gcn_seqn;
            obj.itm = itemnum;
            obj.df = df;

        
        let json = JSON.stringify(obj);
        console.log(json);
 
        if (errors.length > 0) {
            setValErr(errors);
            
        } else {

            

            //set fetch url based on pvd loaded
            let ul = inv_loaded ? URL + "formularyUpdate" : URL + "formularyInsert";

             //POST DATA
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
            body: json
            };
            fetch(ul, requestOptions)
                 .then(() => {
                    setValErr([]);
                    setToastIcon("success");
                    setToastMsg("Inventory Saved Successfully");
                    setToastState(true);
                    setToastTitle("Success");
                    setToastBackground("#5cb85c");
                    setDisabled(false);
                })            
                .catch(err => {

                    console.log(err);   
                    setToastIcon("error");
                    setToastTitle("Error");
                    setToastBackground("#d9534f");
                    setToastMsg("There was a Problem Saving the Inventory. Please Try Again.");
                    setToastState(true);
                    setDisabled(false);
                });
             
        }
     
  }

  const selectPRD = (val) => {
        console.log(val);
        setPRD(val.idx);
  }

    const selectFRM = (val) => {
        console.log(val);
        clearForm();
        setLN(val.LN);
        setRXCUI(val.RXCUI);
        setMEDID(val.MEDID);
        setGCN_SEQN(val.GCN_SEQN);
        setITEMNUM(val.ITEMNUM);
        setIDX(val.IDX);
        setPRD(val.PRD_IDX);
        setPRD_CODE(val.ProductCode);
        setDF(val.DF);

    }
    
    const selectDRG = (val) => {
        console.log(val);
        clearForm();
        setLN(val.LN);
        setRXCUI(val.RXCUI);
        setMEDID(val.MEDID);
        setGCN_SEQN(val.GCN_SEQN);
        setNDC(val.NDC);
        setDF(val.DF);
    }
    return(
        <FormularyEditWrapper>
             
        <div className="frm__container">
                  
              
                    
                    <div  className="frm_content ">
                      <FormHeader title="MANAGE FORMULARY" onClose={props.close} addClose={true}  class_style="form_header--light"></FormHeader>
                    <form>

                    <div className="form_row1-col1">

                        <div className="form_col1_fld1">
                             <div className="search_hdr">       
                                <AutoCompleteDropdown Label="FORMULARY LN to UPDATE Existing"  url={`${URL}formularyAutofill?idx=1`}  data=""  select={(val) => selectFRM(val)} />
                                <AutoCompleteDropdown Label="DRUG DATABASE LN to INSERT New" url={`${URL}formularyNDDFAutofill?idx=1`}  data=""  select={(val) => selectDRG(val)}/>
                             </div>       
                        </div>
                        
                    </div>

                    <div className="form_row1-col1">
                            <div className="form_col1_fld1">
                                <input  type="text" onChange={(e) => setLN(e.target.value)} value={ln} className="form_input--light req" placeholder="LN"  id="ln" maxLength="60" autoComplete="off" required />  
                                <label htmlFor="ln" className="form_label--light">Label Name</label>
                           </div>

                    </div>

                    <div className="form_row2-col2">
                            <div className="form_col2_fld1">
                                <input  type="text" onChange={(e) => setRXCUI(e.target.value)} value={rxcui}   className="form_input--light req" placeholder="RXCUI"  id="rxcui" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="rxcui" className="form_label--light">RXCUI</label>
                           </div>
                           <div className="form_col2_fld2">
                                <input  type="text" onChange={(e) => setMEDID(e.target.value)} value={medid}  className="form_input--light req" placeholder="MEDID"  id="medid" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="medid" className="form_label--light">MEDID</label>
                           </div>

                    </div>
                   
                    <div className="form_row2-col2">
                            <div className="form_col2_fld1">
                                <input  type="text" onChange={(e) => setITEMNUM(e.target.value)} value={itemnum}   className="form_input--light req" placeholder="ITEMNUM"  id="itemnum" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="itemnum" className="form_label--light">ITEMNUM</label>
                           </div>
                           <div className="form_col2_fld2">
                                <input  type="text" onChange={(e) => setGCN_SEQN(e.target.value)} value={gcn_seqn}  className="form_input--light req" placeholder="GCN SEQN"  id="gcn_seqn" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="gcn_seqn" className="form_label--light">GCN SEQN</label>
                           </div>

                    </div>
                    <div className="form_row3-col2">

                           <div className="form_col2_fld1">
                                <select id="role"  onChange={e => setDF(e.target.value)} className="form_input--light" value={df} >
                                    <option value="0">---------</option>
                                    <option value="1">(each)</option>
                                    <option value="2">(ml)</option>
                                    <option value="3">(gr)</option>
                                    
                                </select>
                                <label htmlFor="role" className="form_label--light">Drug Form</label>
                            </div>
                            <div className="form_col2_fld2">
                                <AutoCompleteForm Label={prd_code} id="prd" maxLength="35"  autoComplete="off" select={(prd) => selectPRD(prd)}   url={`${URL}productCodeAutofill?idx=1`}/>
                                <label htmlFor="prd" className="form_label--light">Product Code</label>
                            </div>

                    </div>
                      
                        
                    </form>
                  
                  


                    <div className="edit_inventory__btn-bar">
                        <LoadingButton title="CLEAR FORM" select={clearForm}  ></LoadingButton>
                       
                        { disabled ? <div></div> : <LoadingButton title="SUBMIT" select={submitForm}  ></LoadingButton>}
                    </div>
                    
                    </div>
        </div>

            <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>
        </FormularyEditWrapper>
    );
}
export default FormularyEdit;