import React, {useState, useEffect} from 'react';
import InputMask from 'react-input-mask';
import { isDate } from '../utility';
import { months } from '../constants';
import styled from 'styled-components';
import LoadingButton from '../theme/loading-button';


const DateRangeWrapper = styled.div`
   
    width: 60rem;
    height: 40rem;
    margin: auto;
    background-color: var(--color-grey-dark-2);
    color: #fff;
    
    border: 2rem solid  rgba(15, 215, 239, 1);
    border-radius: 5px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    font-size: 2rem;
    white-space: nowrap;
    box-shadow: 0 1rem 3rem rgba(#fff, .2);
    
   
    #date_range__title {
        font-family: 'BenchNine' sans-serif;
        font-size: 2.4rem;
        font-weight: 700;
        text-transform: uppercase;
        align-self: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
       
    }

    .date_range__desc {
        color: #fff;
        padding: 16px;
        overflow: hidden;
        white-space: nowrap;
    }

    .date_range__input {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 2.5rem;

    }
    
    .date_range__btn-bar {

        display: flex;
        align-items: space-between;
        justify-content: space-between;
        border-top: var(--line);
        margin-right: 1rem;
        margin-left: 1rem;
        margin-bottom: 2rem;
        width: 100%;
        transform: scale(.8);
    }

    .date_range__input {

    font-family: inherit;
    color: var(--color-primary);
    font-size: 3rem;
    padding: 1rem 2rem;
    border-radius: 2px;
    background-color: var(--color-grey-dark-2);
    border: none;
    border-bottom: 3px solid var(--color-primary);
    width: 35rem;
    display: block;
    transition: all .3s;
    text-align: center;

    }

    .start {
        width: 80%;
    }

    .end {
        width: 80%;
    }




#date_range.show {
     visibility: visible;
//      -webkit-animation: fadein 0.5s, expand 0.5s 0.5s,stay 3s 1s, shrink 0.5s 2s, fadeout 0.5s 2.5s;
//      animation: fadein 0.5s, expand 0.5s 0.5s,stay 3s 1s, shrink 0.5s 4s, fadeout 0.5s 4.5s;
 }

@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes expand {
    from {min-width: 50px} 
    to {min-width: 350px}
}

@keyframes expand {
    from {min-width: 50px}
    to {min-width: 350px}
}
@-webkit-keyframes stay {
    from {min-width: 350px} 
    to {min-width: 350px}
}

@keyframes stay {
    from {min-width: 350px}
    to {min-width: 350px}
}
@-webkit-keyframes shrink {
    from {min-width: 350px;} 
    to {min-width: 50px;}
}

@keyframes shrink {
    from {min-width: 350px;} 
    to {min-width: 50px;}
}

@-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;} 
    to {bottom: 60px; opacity: 0;}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 60px; opacity: 0;}
}

`;


function DateInput(props) {
    return (
      <InputMask 
        className="form_date--light" 
        mask='99/99/9999'  
        defaultValue={props.value}
        onChange={props.onChange}
        >
       
      </InputMask>
    );
  }

const  DateRange = (props)  => {
    const [start, setStart] = useState(props.start);
    const [end, setEnd] = useState(props.end);

   
    const handleInput = ({ target: { value } }) => setStart(value);
    const handleInputEnd = ({ target: { value } }) => setEnd(value);

    useEffect(() => {
       setStart(props.start);
       setEnd(props.end);
    }, []);

   
 

    let _month = months.map((item, index) => {
        return (<option value={item.value}>{item.label}</option>);
        });

        const handleChange = (e) => {
            console.log(e.target.value);

            props.startChange(e.target.value);
        }
        
        const handleEndChange = (e) => {
            console.log(e.target.value);

            props.endChange(e.target.value);
        }

    return (
        <DateRangeWrapper>

           <div id="date_range__title">{props.title}</div>
           
             <div id='date_range__input'>
                { props.has_range ?
                <>
                <div className='start'>
                <DateInput 
                    value={start}
                    onChange={(e) => handleChange(e)}   
                 >
                         
                </DateInput>
                <span className="form_label--light">Start Date</span>
                  
                </div>
                <div className='end'>

                <DateInput props
                    value={end}
                    onChange={(e) => handleEndChange(e)}   >
                </DateInput>
                <span className="form_label--light">End Date</span>
                   
                </div>
                </>
                :
                <div id="month_container">
                <select id="month" className="form_date--light"  value={props.month}  onChange={props.monthChange}>
                    {_month}
                </select>
                <label for="month" className="form_label--light">Month</label>
            </div>
                }
            </div>

          
            <div className="date_range__btn-bar">

                <LoadingButton title="CANCEL" id="check" select={props.close} />


                <LoadingButton title="RUN REPORT" id="check3" select={props.submit} />

        
            </div>

        </DateRangeWrapper>
    );
}

export default DateRange;
