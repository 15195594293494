import { getSession, getAPI } from '../utility';
import { URL } from "../constants";
import Platform from 'react-platform-js'

export const UserAgent = () => {
    
    let userAgent = {};
    userAgent.session = getSession("sessionToken");
    userAgent.os = Platform.OS // OS name, Mac OS
    userAgent.os_version = Platform.OSVersion // OS version, 10.11
    userAgent.browser = Platform.Browser // Browser name, Chrome
    userAgent.browser_version = Platform.BrowserVersion // Browser Version
    userAgent.device_type = Platform.DeviceType === undefined ? "desktop" : Platform.DeviceType
    userAgent.device_model = Platform.DeviceModel === undefined ? "" : Platform.DeviceModel
    userAgent.device_vendor = Platform.DeviceVendor === undefined ? "" : Platform.DeviceVendor
    
    console.log('userAgent',userAgent);
    let json = JSON.stringify(userAgent);

    //POST DATA
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
        body: json
        };
        
        fetch(`${URL}usrAgentInsert`, requestOptions)
            .then(response => response.json())
            .then(data => {
                let rslt = "";
                try {
                  rslt = (JSON.parse(data));
                  console.log(rslt);
                  

                } catch {
                  rslt = data;
                 
                }
               
            })            
            .catch(err => {
                console.log(err);
            });
}