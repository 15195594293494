import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import useFetch from '../hooks/useFetch';
import { URL } from '../constants';
import { getSession } from '../utility';
import clearIcon from '../assets/clear-input.svg';
// import Toggle from '../theme/toggle';

const AutoCompleteWrapper = styled.div`


.search-form {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 25rem;
            margin: 0 auto;
.search-form__input {
    font-family: inherit;
    font-size: initial;
    background-color: var(--color-grey-light-4);
    border: none;
    color: inherit;
    padding: .7rem 2rem;
    border-radius: 100px;
    /* width: 25rem; */
    transform: all .3s;
    margin-right: -5rem;
    transition: all .5s;
    z-index: 10;

    &:focus {
        outline: none;
        /* width: 80%; */
        background-color: var(--color-grey-light-3);
    }

    &::input-placeholder {
        font-weight: 100;
        color: var(--color-grey-light-4);
    }
}
    &__input:focus  {
        background-color: var(--color-grey-light-3);
    }

}

        .search-form:focus {
                outline: none;
                background-color: var(--color-grey-light-3);
            }
        .search-form::input-placeholder {
                font-weight: 100;
                color: var(--color-grey-light-4);
        }
        
        .search-form:focus  {
                background-color: var(--color-grey-light-3);
            }

 .fadein, .fadeout {
    opacity: 0;
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}
.fadein {
    opacity: 1;
}

.autoContainer {
    position: absolute;
    top: 100%;
    left:0;
    width:20rem;
    height: auto;
    background-color: navy;
    color:#fff;
    z-index: 300;
    margin-left: 3rem;
   
    
}

.option {
    position: relative;
    padding: 0.5rem;
    z-index: 299;
    width: 20rem;
    cursor: pointer;
    &:hover {
        background-color: var( --color-primary-light);
        color: navy;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.keySelected {
    background-color: var( --color-primary-light);
    color: navy;
}

.PName {
    font-size: 1.75rem;
    font-weight: 700;
    margin-right: 1rem;
    width: 60%;
    border-bottom: var(--line-wht);
}

.PDOB {
    font-size: 1.5rem;
    font-weight: 400;
    margin-right: 1rem;
}

.left-inner-addon {
    position: relative;
    width: 175px;
}
.left-inner-addon input {
    padding-right: 10px;
    width: 170px; /* 200px - 30px */
}
.left-inner-addon img {
    position: absolute;
    right: 0px;
    cursor: pointer;
    margin-top: .7rem;
    margin-right: 1rem;
    width:'30px';
}


`;

const AutoComplete = (props) => {

    const [display, setDisplay] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const wrapperRef = useRef(null);
    const [query, setQuery] = useState("");
    const [url, setUrl] = useState("");
    const [val, setVal] = useState("");
    const [cln, setCln] = useState(getSession("cln"));

    const [patient, setPatient] = useState({});
    
    
    const { status, data, error } = useFetch(url);

    const [pos, setPos] = useState(0);
    const [x, setActive] = useState([]);



    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    
    useEffect(() => {
        if (cln !== null) {
            setUrl(`${URL}patAutocomplete?idx=${cln}&srch=${val}`);
        }
    
       
    }, [query]);

    useEffect(() => {
         
        if (x.length > 0) {
           try { x[pos - 1].classList.add("keySelected"); } catch { console.log("error"); }
        }
    
       
    }, [pos]);

    useEffect(() => {
        if (status === "fetched") {
            if (data.length > 0) {
                setOptions(data);
                setDisplay(true);
                
            }
        }
    }, [data]);

    const handleClickOutside = event => {
        const { current: wrap } = wrapperRef;
        if (wrap && !wrap.contains(event.target)) {
            setDisplay(false);
        }
    };

    const clearInput = () => {
        setSearch("");
        setDisplay(false);
        document.getElementById("ato").focus();

    }

    const updatePokemon = pokemon => {
      
        setSearch("");
        setDisplay(false);
        setUrl("");
        setPos(0);
        props.select(pokemon);
    };

    const handleSearch = (event) => {

        // onChange={event => setSearch(event.target.value)}
        let srch = event.target.value;
        setSearch(srch.toUpperCase());

        
        
        if(srch.length === 3) 
        {   
          
            //pass search string to azure function and wait for results
        
            setVal(srch.toUpperCase());
            setQuery(Math.floor(Math.random() * 100));
            return;
           
        } else if (srch.length < 3) {
          
            setDisplay(false);
            
           return;
        }
        else {
            
            setDisplay(true);
            return;
        }
    }

    const hideAutoContainer = () => {
        setDisplay(false);
    }

    const handleKeyDown = (e) => {

    let list_items = 0;
    let which = 0;  
    let list = "";
    try {list_items = document.getElementById("autoList").children.length; } catch {list_items = 0;}
   
    
     list = document.getElementById("autoList");
    if (list_items > 0 ) {
        setActive(list.children);
    }
    
        //ArrowUp && ArrowDown && Enter
        if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Enter") {
            // e.preventDefault();
            if (e.key === "ArrowUp") {

                
                
                removeActive(list.children);
                if (pos > list_items) {
                    setPos(0);
                      
                 } else if (pos < 0) {
                        setPos(0);    
                
                 } else {

                    which = pos - 1;
                    setPos(which)
                 }
                  
            }
            if (e.key === "ArrowDown") {
               
                    removeActive(list.children);
                    if (pos > list_items) {
                        setPos(0);
                                   
                    
                     } else if (pos < 0) {
                        setPos(0);
                     } else {

                        which = pos + 1;
                        setPos(which);
                     }
                    
                   
                
                
            }
            if (e.key === "Enter") {
              
                let ptt =  x[pos - 1].dataset.patient;
                let p = options.filter(({IDX}) => IDX.toString() === ptt);
                setUrl("");
                setPos(0);
                setDisplay(false);
                setSearch(p[0].FullName);
                props.select(p[0]);


            }
        }
    }

    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove("keySelected");
        }
      }



    return(
        <AutoCompleteWrapper>
            <div className="search-form">
               <div className="left-inner-addon">
                    <input autoFocus type="search" name="whatever"  id="ato" className="search-form__input" placeholder={props.placeholder} autoComplete="new-password" onKeyDown={(e) => handleKeyDown(e)} onChange={(event) => handleSearch(event)} value={search}  />
                    {/* <img onClick={clearInput} role="img"  src={clearIcon} /> */}
                </div>
            {display && (
                <div id="autoList" className="autoContainer">
                    {options
                        .filter(({FullName}) => FullName.indexOf(search.toUpperCase()) > -1)
                        .map((value, i) => {
                            return (
                                <div onClick={() => updatePokemon(value)} data-patient={value.IDX} className="option" key={i} tabIndex="0">
                                    <div className="PName">{value.FullName}</div>
                                    <div className="PDOB">{value.DOB}</div>
                                </div>
                            );
                        })}
                   
                    
                </div>
                
            )}

                {/* <Toggle lbl="Active Patients"></Toggle> */}
            </div>
        </AutoCompleteWrapper>
    );
            
}

export default AutoComplete;
