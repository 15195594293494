import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import Toast from './toaster';
import SideList from './side-list';
import AutoCompleteDropdown from '../theme/autocomplete-dropdown';
import LoadingButton from '../theme/loading-button';
import FormularyEdit from './formulary-edit';
import MNDCEdit from './mndc-add';
import { getSession, getAPI } from '../utility';

import { URL, reason_codes, inv } from '../constants';



const EditInventoryWrapper = styled.div`
    
    color: #fff;
    width: 100%;
    max-height: 90%;
    border: .25rem solid rgba(#fff, .5);
    border-radius: 5px;
    border-top: var(--line);
    

    .edit_inventory__container {
        display: flex;
        justify-content: space-around;
         margin-top: 1.5rem;
    }

    .edit_inventory_content {
        
        width: 65%;
        height: 32rem;
        box-shadow: 0 .5rem 2rem rgba(#fff, .2);
        border-radius: 5px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, 1fr);
        overflow: hidden;
        position: relative;
        opacity: 1;
        border: .5rem solid rgba(#fff, .5);
        transition: all 1s;
        background-color: rgba(var(--color-primary-rgb), .75);
        background-size: cover;
        background-position: top;

      

        .edit_inventory--side {
            overflow: scroll;
            position: relative;
            width: 25%;
            height: 32rem;
            border-radius: 5px;
            border: .5rem solid rgba(#fff, .5);
        }

        

    }

    
    .rcv_dsp {
        max-width: 25rem;
        padding: 1rem 2rem;
        margin-left: 7rem;
        
    }

    .edit_inventory__search {
           
        width: 90%;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:2rem;

        .edit_inventory__search__input {
            font-family: 'BenchNine', sans-serif;
            font-size: initial;
            background-color: var(--color-grey-light-2);
            border: none;
            color: var(--color-primary);
            padding: .7rem 2rem;
            border-radius: 1rem;
            width: 50%;
            transform: all .3s;
            transition: all .5s;
            z-index: 10;
            margin-top: 1rem;
            margin-left: 2.5rem;

            &:focus {
                outline: none;
                width: 70%;
                background-color: var(--color-grey-light-3);
            }

            &::input-placeholder {
                font-weight: 100;
                color: var(--color-grey-light-4);
            }
        }
        .edit_inventory__search__input:focus  {
                background-color: var(--color-grey-light-3);
            }

    
    }

    .edit_inventory__top-hdr {
        font-family: 'BenchNine', sans-serif;
        font-size: 2.5rem;
    }

    .edit_inventory__btn-bar {
           
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: -1rem;
    }

    .edit_inventory__metadata {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1rem;
    }

    .edit_inventory__adj {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1rem;
    }

   

 

    input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    .toggle {
        transform: scale(.6);
    }

.switch {
  cursor: pointer;
  text-indent: -9999px;
  width: 15rem;
  height: 6rem;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 5rem;
  height: 5rem;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

#switch:checked + label {
  background: var(--color-tertiary);

}

#switch:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 10rem;
}

.ld_thin {
    display: flex;
    align-items: center;
    justify-content: center;
    height:4rem;
    width: 30rem;
    border-radius: 15px;
    border: .25rem solid #fff;
    padding: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    cursor: pointer;

    &:hover {
        background-color: var(--color-grey-light-3);
        color: navy;
    }
}

#ln_container {
        grid-column: 1/-1;
        font-size: 2.75rem;
        color: #fff;
        border-bottom: 2px solid var(--color-primary);
        text-align: center;
    
    }

.hideReason {
    display: none;
}

.showReason {
    display: block;
}


   
`;



const EditInventory = (props) => {

   
    const [clinic, setClinic] = useState(getSession("cln"));
    const [valid_err, setValErr] = useState([]);
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");
    
    
    const [idx, setIDX] = useState('');
    const [minLevel, setMinLevel] = useState('');

  
   
    const [updateReason, setUpdateReason] = useState('');
    const [invAmt, setInvAmt] = useState('');
    const [reasonCode, setReasonCode] = useState('');
    const [active_lots, setActiveLots] = useState([]);
    const [ln, setLN] = useState('');
    const [ndc, setNDC] = useState('');
    const [mndc, setMNDC] = useState('');
    const [lot, setLot] = useState('');
    const [product, setProduct] = useState('');
    const [exp_date, setExpDate] = useState('');
    const [reasonClass, setReasonClass] = useState("hideReason");
    const [formulary, setFormulary] = useState(false);
    const [mndc_edit, setMNDC_EDIT] = useState(false);
    const [active, setActive] = useState('true');
    const [disabled, setDisabled] = useState(false);
    const [btn_txt, setBtnTxt] = useState("DEACTIVATE");


    useEffect(() => {
        if (active === 'false') {
            postData();
        } 
    }, [active]);

   
    const clearForm = () => {
        setUpdateReason("");
        setReasonCode("0");
        setInvAmt("");
        setNDC("");
        setLot("");
        setMNDC("");
        setProduct("");
        setExpDate("");
       
        setReasonClass("hideReason");

    }

    const handleActivate = () => {
        
        setActive('false');
       
    }


    const submitForm = () => {
        setDisabled(true);
        postData();
     }


    const postData = () => {

        const errors = [];
        setValErr([]);


        let obj = {};
        obj.idx = idx;
        obj.ndc = ndc;
        obj.mndc = mndc;
        obj.lot = lot;
        obj.exp = exp_date;
        obj.inv = invAmt;
        obj.active = active;
        obj.prd = product;
        

        console.log(obj);
        let json = JSON.stringify(obj);
      
 
        if (errors.length > 0) {
            setDisabled(false);
            setValErr(errors);

            
           
        } else {

         //POST DATA
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
          body: json
          };
          fetch(`${URL}admUpdateInventory`, requestOptions)
                .then(()=> {
                  setValErr([]);
                  setToastIcon("success");
                  setToastMsg("Inventory Updated Successfully");
                  setToastState(true);
                  setToastTitle("Success");
                  setToastBackground("#5cb85c");
                  setDisabled(false);
                  setActive('true');

                  clearForm();
                  setActiveLots([]);
                  setLN('');


              })            
              .catch(err => {
                console.log(err);
                  setToastIcon("error");
                  setToastTitle("Error");
                  setToastBackground("#d9534f");
                  setToastMsg("There was a Problem Updating the Inventory, Please Try Again.");
                  setToastState(true);
                  setDisabled(false);
                  setActive('true');

              });

            }
    }
    
    
    const selectRow = (id) => {
        console.log(id);
      
        let obj = active_lots.find(obj => obj.idx == id);
         console.log(obj);

       setIDX(obj.idx);
       setInvAmt(obj.INV);
       setNDC(obj.NDC);
       setLot(obj.LOT);
       setMNDC(obj.MNDC);
       setProduct(obj.ProductCode);
       setExpDate(obj.ExpDate);
     
       
    }

    const updateActiveTab = (e) => {
        console.log(e.target.id);
        let index = e.target.id;
        switch(index) {
            case "1": setActiveLots(active_lots); setBtnTxt("DEACTIVATE");
             break;
            case "2": reactivateWarning();
             break;
            default:  setActiveLots(active_lots); setBtnTxt("DEACTIVATE");
        }
 
    };

    const reactivateWarning = () => {
        alert("Making changes to DeActivated Inventory can cause Inventory Issues to Clinics.  Please consult a database administrator to make changes to DeActivated Inventory.");
    }

   
  const table_meta = [
    {'col_width': 'col-100', 'data_label': 'LOT','key': 'LOT', 'input': 'string'},
   
 ];
 const err = () => {
    try {
        if (Array.isArray(valid_err)) {
          return valid_err.map((item, index)=>{
              return (
                    <ul>
                          <li className="err_list">{item}</li>
                         
                    </ul>
               );
          });
      } else {
        return (
          <tr><td colSpan='8'><div></div></td></tr>
        );
      }
      } catch (err) {
        return;
      }
}       

   

    let _reason_codes = reason_codes.map((item, index) => {
        return (<option key={index} value={item}>{item}</option>);
    });
  

   const selectCLN = (val) => {

      console.log(val);
      setClinic(val.idx);
    }

    const selectLN = (val) => {
        console.log(val.LOTS);
        setActiveLots(val.LOTS);
        setLN(val.LN);
    }

    const openFrm = () => {
        console.log("open formulary");
        setFormulary(true);

    }


    return(
       <EditInventoryWrapper>
          <div  className="edit_inventory__search">
                 <AutoCompleteDropdown Label="CLINIC"  url={`${URL}admReturnClinicAutoFill?idx=1`}  data=""  select={(val) => selectCLN(val)} />
                 <AutoCompleteDropdown Label="LN" url={`${URL}inventoryAutofill?cln=${clinic}&type=${'LN'}`}  data=""  select={(val) => selectLN(val)}/>
                
                 <div className="ld_thin"  onClick={openFrm}>MANAGE FORMULARY</div>
                 <div className="ld_thin" onClick={() => setMNDC_EDIT(true)}>MANAGE MNDC</div>
            </div>
        <div className="form__container">
         
                  
                    <div  className="form_content--side ">
                        <SideList data={active_lots} meta={table_meta} title="" select={(id) => selectRow(id)} tab_change={updateActiveTab}></SideList>
                    </div>
                  
                    <div  className="form_content ">
                    <span className="edit_inventory__top-hdr"></span>
                    <form>

                    <div className="form_row1-col1">

                    <div id="ln_container">
                                    <span>{ln}</span>
                    </div>
                        
                    </div>
                    <div className="form_row2-col3">
                            <div className="form_col3_fld1">
                                <input onChange={e => setNDC(e.target.value)} value={ndc} type="text" className="form_input--light req" placeholder="NDC"  id="ndc" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="ndc" className="form_label--light">NDC</label>
                           </div>
                           <div className="form_col3_fld2">
                                <input onChange={e => setMNDC(e.target.value)} value={mndc} type="text" className="form_input--light req" placeholder="MNDC"  id="mndc" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="mndc" className="form_label--light">MNDC</label>
                           </div>
                           <div className="form_col3_fld3">
                                <input onChange={e => setLot(e.target.value)} value={lot} type="text" className="form_input--light req" placeholder="LOT"  id="lot" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="lot" className="form_label--light">LOT</label>
                           </div>
                    </div>
                    
                    <div className="form_row3-col3">
                          <div className="form_col3_fld1">
                                <input onChange={e => setExpDate(e.target.value)} value={exp_date} type="text" className="form_input--light req" placeholder="Current Inventory"  id="active_inv" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="active_inv" className="form_label--light">Exp. Date</label>
                           </div>
                           <div className="form_col3_fld2">
                                <input onChange={e => setInvAmt(e.target.value)} value={invAmt} type="text" className="form_input--light req" placeholder="New Inventory"  id="new_inv" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="new_inv" className="form_label--light">Inventory</label>
                           </div>
                           <div className="form_col3_fld3">
                                <input onChange={e => setProduct(e.target.value)} value={product} type="text" className="form_input--light req" placeholder="Product Code"  id="unit_price" maxLength="35" autoComplete="off" required />  
                                <label htmlFor="unit_price" className="form_label--light">Product Code</label>
                           </div>
                        
                    </div>

                    {/* <div className="form_row4-col2">

                        <div className="form_col2_fld1">
                            <select id="reason-code"onChange={e => updateReasonCode(e.target.value)} value={reasonCode}   className="form_input--light">
                                    {_reason_codes}
                            </select>
                                <label htmlFor="reason-code" className="form_label--light">Reason Code</label>
                        </div>

                    </div>
                    <div className={`form_row5-col1 ${reasonClass}`}>

                        <div className="form_col1_fld1">
                            <textarea onChange={e => setUpdateReason(e.target.value)} className="form_input--light" id="reason" placeholder="Update Reason">{updateReason}</textarea>  
                            <label htmlFor="reason" className="form_label--light">Update Reason</label>
                        </div>

                    </div> */}
                      
                        
                    </form>
                  
                     <div id="error_list" >{err()}</div>


                    <div className="edit_inventory__btn-bar">
                        <LoadingButton title="CLEAR FORM" select={clearForm}  ></LoadingButton>
                        <LoadingButton title={btn_txt} select={handleActivate}  ></LoadingButton>
                        { disabled ? <div></div> : <LoadingButton title="SUBMIT" select={submitForm}  ></LoadingButton>}
                    </div>
                    
                    </div>

                    {formulary ? <FormularyEdit close={() => setFormulary(false)} /> : null} 
                    {mndc_edit ? <MNDCEdit close={() => setMNDC_EDIT(false)} /> : null} 
        </div>

            <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>
        
       
       </EditInventoryWrapper>
    );
};

export default EditInventory;