import React, {useEffect, useState} from 'react';
import { returnTodayDate, getSession, getAPI, isTablet } from '../utility';
import ButtonSlide  from '../theme/btn-slide';
import styled from 'styled-components';
import AddNote from './add-note';
import AddCustomDosage from './add-custom-dosage';
import Toast from './toaster';
import { URL } from '../constants';
import WarningModal from './warning-model';
import BasicDocument from '../pdf/basic_document';
import { Tooltip } from 'react-tooltip';
import Overlay from '../theme/overlay';
import arrow_down_right from '../assets/arrow_down_right.png';
import arrow_up from '../assets/arrow_up.png';
import arrow_down from '../assets/arrow_down.png';
import LabelPosition from './label-position';




const DrugDetailWrapper = styled.div`



    background-color: var(--color-grey-light-4);
    color:var(--color-grey-dark-2);
    font-size: 1.4rem;
    min-height: 25rem;
    grid-column: 1 / -1;
    grid-row: 4 / 5;
    display:grid;
    border: 1px solid var(--color-grey-dark-2);
    grid-template-columns: repeat( auto-fit, minmax(20rem, 1fr));
    background-color: #80cbc4;
    padding: 10px;

    @media only screen and (max-width: 1029px) {
      font-size: 1.2rem;
    }
    

    .drug__btn-actions {
        grid-row: 5/ 6;
        grid-column: 1 / -1;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: var(--color-grey-dark-2);
        
       
    }

         

    .drug__ln {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        background-color: #fff;
        color: var(--color-grey-dark-1);
        font-size: 2.5rem;
        font-family:'Raleway', sans-serif;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-weight: 400;
        border-bottom: var(--line);
        transition: all 1s;
    }

    .drug--text {
        margin-right: auto;
        margin-left: auto;
    } 

    .drug--close {
        margin-right: 2rem;
        cursor: pointer;
        fill: var(--color-primary);
        transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

        &:hover {
            fill: red;
        }
    }

    .drug__details {
        display: flex;
        //grid-template-columns: repeat(8, 1fr);
        align-items: center;
        justify-content: space-around;
        grid-column: 1/-1;
        grid-row: 2/3;
        border: 1px solid #fff;
        padding: 20px;
        font-size: 2rem;
        text-align: center;
    }

    .drug__details-2 {
        display: flex;
        //grid-template-columns: repeat(8, 1fr);
        align-items: center;
        justify-content: space-around;
        grid-column: 1/-1;
        grid-row: 3/4;
        border: 1px solid #fff;
        padding: 20px;
        font-size: 2rem;
        text-align: center;
     }

    .drug__dosage {
        display: flex;
        align-items: center;
        justify-content: space-around;
        grid-column: 1 / -1;
        grid-row: 4 / 5;

        .drug__dosage--txt {
            width: 35rem;
            min-height: 6rem;
            background-color: rgba(248, 248, 80, .70);
            color: #000;
            padding: .3rem;
            border-radius: 5px;
        }
    }

   



.drg {
  
.drg__wrn {
    position: absolute;
    top:-100%;
    left: 0;
    width: 100%;
    height: 15rem;
    background-color: var(--color-primary);
    transition: all .7s cubic-bezier(0.36, 0, 0.66, -0.56);

    .drg-content {
    // grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--line);
    padding: 2rem;
    }
    

    .drg-close {
        text-decoration: none;
        transition: all .3s;
        line-height: 1;
        margin-right: 2rem;
        cursor: pointer;
        fill: #fff;
        transition: fill .9s cubic-bezier(0.33, 1, 0.68, 1);

        &:hover {
            fill: red;
        }

    
    }

    .drg-message {
        color: #fff;
        font-size: 2rem;
    }

}

.drg__open-add {
    top: 0;
}
}

.svg-wrapper {
    height: 1.5rem;
    margin: 0 auto;
    position: relative;
    transform: translateY(-50%);
    width: 32rem;
    cursor: pointer;
    transform: scale(.75);
    margin-bottom: 4rem;
  }
  
  .shape {
    fill: transparent;
    stroke-dasharray: 280 540;
    stroke-dashoffset: -400;
    stroke-width: 8px;
    stroke: var(--color-primary);
  }
  
  .text {
    color: #fff;
    font-family:'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 8px;
    line-height: 3rem;
    position: relative;
    top: -5rem;
    text-align: center;
  }
  
  @keyframes draw {
    0% {
      stroke-dasharray: 140 540;
      stroke-dashoffset: -474;
      stroke-width: 8px;
    }
    100% {
      stroke-dasharray: 760;
      stroke-dashoffset: 0;
      stroke-width: 2px;
    }
  }
  
  .svg-wrapper:hover .shape {
    -webkit-animation: 0.5s draw linear forwards;
    animation: 0.5s draw linear forwards;
  }


.form_lbl {
     margin-right: 1rem;
     color: #fff;
     background-color: var(--color-primary);
     padding: .3rem;
     border-radius: 3px;
     box-shadow: 0 1rem 1.5rem rgba(#000, .22);
     text-transform: uppercase;
     
 }

 .ingreds {
   cursor: pointer;
 }

.form_txt {
    color: var(--color-primary);
    font-weight: 600;
    font-size:2rem;
    background-color: #fff;
    padding: .4rem;
    border-radius: 5px;
}

.form_date_input {
     background-color: rgba(248, 248, 80, .70);
     color: var(--color-primary);
     padding: .3rem;
     border-radius: 5px;
     width:40%;
}

input[type="checkbox"] {
    display: none;
  }
  
  input[type="checkbox"]:checked ~ .drug__aside{
    transform: scale(50);
  }
  
  input[type="checkbox"]:checked ~ label .bar {
    background-color: var(--color-primary);
  }
  
  input[type="checkbox"]:checked ~ label .top {
    -webkit-transform: translateY(0px) rotateZ(45deg);
    -moz-transform: translateY(0px) rotateZ(45deg);
    -ms-transform: translateY(0px) rotateZ(45deg);
    -o-transform: translateY(0px) rotateZ(45deg);
    transform: translateY(0px) rotateZ(45deg);
  }
  
  input[type="checkbox"]:checked ~ label .bottom {
    -webkit-transform: translateY(-15px) rotateZ(-45deg);
    -moz-transform: translateY(-15px) rotateZ(-45deg);
    -ms-transform: translateY(-15px) rotateZ(-45deg);
    -o-transform: translateY(-15px) rotateZ(-45deg);
    transform: translateY(-15px) rotateZ(-45deg);
  }
  
  input[type="checkbox"]:checked ~ label .middle {
    width: 0;
  }

  section {
    position: relative;
    width: 10%;
    height: auto;
    background-color: #fff;
    overflow: hidden;
  }
  
  label {
    display: inline-block;
    // padding: 7px 10px;
    background-color: transparent;
    cursor: pointer;
    margin: 1rem 0;
    z-index: 3;
    position:relative;
  }
  
  .bar {
    display: block;
    background-color: #0799d3;
    width: 30px;
    height: 3px;
    border-radius: 5px;
    margin: 5px auto;
    transition: background-color .5s ease-in, transform .5s ease-in, width .5s ease-in;
    z-index:3;
  }

  .middle {
    margin: 0 auto;
  }

  .drug__aside {
    background-color: #0799d3;
    position: fixed;
    top: 51%;
    bottom: 0;
    left: 28.75%;
    opacity: 1;
    transform: scale(1);
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    z-index: 1000;

    transition: transform .8s cubic-bezier(0.83, 0, 0.17, 1);
  }

  .asideList {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 100px;
    text-align: center;
    border-top: 2px solid rgba(255, 255, 255, .7);
  }
  
  .asideAnchor {
    border-bottom: 2px solid rgba(255, 255, 255, .7);
    padding: 20px 0;
    display: block;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    transition: color .3s .15s ease-in;
  }
  
  .asideAnchor::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    background-color: #fff;
    width: 0;
    transition: width .3s ease-in;
    z-index: -1;
  }
  
  .asideAnchor:hover {
    color: #0799d3;
  }
  
  .asideAnchor:hover::after {
    width: 100%;
  }

  .svg_circle {
    fill:#43B05C;
  }

  .svg_line {
    fill:none;
    stroke:#FFFFFF;
    stroke-width:2;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
  }

  .svg_init {
    transform: scale(.75);
    cursor: pointer;

  }

 .error {
    color: red;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    position: absolute;
    top: 94%;  
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
 }
`;



function DrugDetail(props) {

    const [wrn_msg, setWrnMsg] = useState(true);
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");

    const [allergen_result, setAllergenResult] = useState([]);
    const [interaction_result, setInteractionResult] = useState([]);

    const [note, setNote] = useState(false);
    const [drug, setDrug] = useState({});
    const [writtenDate, setWrittenDate] = useState(returnTodayDate());
    const [directions, setDirections] = useState(props.drg.DefaultDosage);
    const [custom, setCustom] = useState(props.drg.CustomDirections);
    const [comment, setComment] = useState("");
    const [add_dosage, setAddDosage] = useState(false);
    const [warn, setWarn] = useState(false);
    const [valid_err, setValErr] = useState([]);
    const [warnTitle, setWarnTitle] = useState("");
    const [warnBody, setWarnBody] = useState("");

    const [disabled, setDisabled] = useState(false);
    const [disabled_que, setDisabledQue] = useState(false);

    const [lb, setPDF] = useState(false);
    const [ds, setLBL] = useState(false);
    const [mono, setMono] = useState(false);
    const [dsp_idx, setIDX] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [dob, setDOB] = useState('');
    const [tablet, setTablet] = useState(getSession("tablet"));
    const [type, setType] = useState('');

    const [ingredients, setIngredients] = useState('');

    const [que_dispense, setQueDispense] = useState(false);

    const [grp, setGrp] = useState(getSession("grp"));

    const [lbl_type, setLabelType] = useState(getSession("lbl"));
    const [showMono, setShowMono] = useState(true);
    const [overlay, setOverlay] = useState(false);

    const [lblPosition, setLblPos] = useState(0);
    const [pos, setPos] = useState(false);

    useEffect(() => {

     
        setDrug(props.drg);
        
        
        setFirstname(props.pat.FirstName);
        setLastname(props.pat.LastName);
        setDOB(props.pat.DOB);

        let labelSaved = getSession("lbl");

        if(labelSaved === "ZR"){
          //zebra
          setLabelType("ZR");
          setShowMono(true);
        } else {
          //sheet
          setLabelType("LB");
          setShowMono(false);
        }

        if(tablet ) {
          setShowMono(false);
          setDisabled(true);

        }

        setValErr([]);
       

    }, []);

    useEffect(() => {

      
        
      

        if(Object.keys(drug).length > 0) {
          checkAllergen();
          checkDrugInteraction();
        }

    }, [drug]);
    
    useEffect(() => {
     
      if(que_dispense) {
       
        dispenseToQueue();
        
      }
    }, [que_dispense]);

    useEffect(() => {
       
      if(dsp_idx !== '') {
        if(type === "print") {
          setValErr([]);
          setToastIcon("success");
          setToastMsg("Medication Dispensed Successfully");
          setToastState(true);
          setToastBackground("#5cb85c");
          setToastTitle("Success");
          setDisabled(false);

          //if lbl_type is sheet get label position
          if(lbl_type === "LB") {
            setPos(true);
          } else {
            setLBL(true);
          }

          
        
         
        }
      }
    }, [dsp_idx]);

    useEffect(() => {

      if (lblPosition === 0) {
        setPos(false);
      } else {
        setPos(true);

      }

     
    }, [lblPosition]);


    useEffect(() => {

      if (lb === true) {
        setPos(false);
      } 
     
    }, [lb]);

    const getLabelPosition = (position) => {
		
      console.log('getLabelPosition',position);
      console.log(firstname);
      console.log(lastname);
      console.log(dob);
      console.log(dsp_idx);
      console.log(lbl_type);
      
       setLblPos(position);
      
       setPDF(true);
   
      
     }

  
   
    const handleDispense = () => {
         setValErr([]);
         
         setDisabled(true);
         setType("print");

        if(dispenseDrug("print")) {
          //lauch print dialog
        
          setDisabled(false);

         
           

        } else {
          //display error
          setToastIcon("error");
          setToastTitle("Error");
          setToastBackground("#d9534f");
          setToastMsg("There was a Problem Dispensing the Medication, Please Try Again");
          setToastState(true);
          setDisabled(false);

        }
    }

    const handleDispenseQueue = () => {
      setValErr([]);
      setDisabledQue(true);
      setType("queue");

        if(dispenseDrug("queue")) {
          
          
          setValErr([]);
          setDisabledQue(false);
          

          //write to queue    
          
            
           

        } else {
          //display error
          
          setDisabledQue(false);

        }

    }

    const  dispenseDrug =  async (which) => {

      
     let errors = [];
     let obj = {};

      obj.pat = props.pat.IDX;
      obj.dsr = props.dsr;
      obj.drg = drug.DRG_IDX;
      obj.pvd = props.pvd;
      obj.written_date = writtenDate;
      obj.dispense_qty = drug.QTY;
      obj.refill_count = "0";
      obj.days_supply = drug.DaysSupply === undefined ? "0" : drug.DaysSupply;
      obj.directions = directions === undefined ? errors.push("Default Directions are Missing. Please Provide a Custom Direction") : directions;
      obj.prn = "false";
      obj.comment = comment;
      obj.encounter_guid = props.encounterGUID;
      obj.serial = "";
      obj.primary_diagnosis_code = "";

     
      
      let json = JSON.stringify(obj);
      

      //check for drug interactions, allergies 

      if (errors.length > 0) {
        setValErr(errors);
       
    } else {

          
          //POST DATA
          const requestOptions = {
             method: 'POST',
             headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
             body: json
             };
             await fetch(`${URL}dispensePostInsert`, requestOptions)
              .then(response => response.ok ? response :  (function(){throw new Error('Network response was not ok.')}()))
              .then( response => response.json())
              .then(data => {
                   
                      setIDX(data);

                      console.log('postDsp',data);
                      
                      if(which === "queue") {
                        setQueDispense(true);
                        
                      }
                

                   //if control report cspmp

                   if(drug.IsControl) {
                   
                     checkControl(data);
                  }
                  })            
                  .catch(err => {
                  
                    setToastIcon("error");
                    setToastTitle("Error");
                    setToastBackground("#d9534f");
                    setToastMsg("There was a Problem Dispensing the Medication, Please Try Again");
                    setToastState(true);
                    setDisabled(false);
                    setDisabledQue(false);

                   

                     return false;
                  });
         }

         
         return true;

        
    }

    const dispenseToQueue = async() => {

      let obj = {};
      obj.usr = getSession("usr");
      obj.dsp = dsp_idx;
      obj.pat = props.pat.IDX;

   console.log('que',obj);

      let ul = (`${URL}dispenseInsertQue?usr=${obj.usr}&dsp=${obj.dsp}&pat=${obj.pat}`);
      try {
        const response = await fetch(ul, {
          method: 'GET',
              headers: {
                  'x-functions-key': getAPI(),	
              }
          });
        const rslt = await response.json();
       
        setToastIcon("success");
        setToastMsg("Medication Dispensed to Queue Successfully");
        setToastBackground("#5cb85c");
        setToastTitle("Success");
        setToastState(true);
        setDisabledQue(false);

        props.close();


        if(drug.IsControl) {
           checkControl();
        }
              
    } catch (error) {
         return false;
    }

         return true;
    }
    
    function open_add() {
       

        let element = document.getElementById("drug_wrn");

        if(wrn_msg) {
          
            element.classList.add("drg__open-add");

        } else {
            element.classList.remove("drg__open-add");
        }
        
        setWrnMsg(!wrn_msg);
       

    }

    const customs = () => {
      try {
        let arr = custom.split(",");
        if (Array.isArray(arr)) {
          return arr.map((item, index)=>{
           
              return (
                <option key={index} value={item}>{item.substring(0,10) + '...'}</option>
              );
          });
      } 
      } catch (err) {
        console.log(err);
        return;
      }
    }

    const handleCustomSelect = (e) => {

      if(e.target.value === "0") {
        setDirections(props.drg.DefaultDosage);
      } else {
        setDirections(e.target.value);
      }
    }

    const handleAddCustom = (val) => { 

      let txt_arr = custom;
      txt_arr = txt_arr + "," + val;
      setCustom(txt_arr);
     
    }

    const  checkAllergen = async() => {   

    
      let url = `${URL}dispenseAllergenCheck?ptt=${props.pat.IDX}&medid=${props.drg.MEDID}`;
      const response = await fetch(url, {
        method: 'GET',
            headers: {
                'x-functions-key': getAPI(),	
            }
        });
      const rslt = await response.json();
      let data = "";
      try {
          data = await(JSON.parse(rslt));
         
          setAllergenResult(data);
          setAllergenResult(data);
          setWarnTitle("ALLERGY WARNING");
          setWarnBody("CRx has detected a possible allergy to this medication.");
          setWarn(true);
         
      } catch {
          data = rslt;
      }
      


    }

    const checkDrugInteraction = async() => {

  
    
      let url = `${URL}dispenseInteractionCheck?ptt=${props.pat.IDX}&frm=${props.drg.FRM_IDX}`;
      const response = await fetch(url, {
        method: 'GET',
            headers: {
                'x-functions-key': getAPI(),	
            }
        });
      const rslt = await response.json();
      let data = "";
      try {
          data = await(JSON.parse(rslt));
          
          setInteractionResult(data);
          setWarnTitle("DRUG INTERACTION WARNING");
          setWarnBody("CRx has detected a possible drug interaction with this medication.");
          setWarn(true);
         
      } catch {
        console.log(rslt);
          data = rslt;
      }
      

    }

  

    const checkControl = async(which) => {

          //if clinic setup for auto report
          console.log(getSession("opt_in"));

          if(getSession("opt_in")) {

            console.log('dsp', which);

           

           let ul = (`${URL}cspmpAutoReportDSP?dsp=${which}`);
           try {
             const response = await fetch(ul, {
              method: 'GET',
                  headers: {
                      'x-functions-key': getAPI(),	
                  }
              });
              
             const rslt = await response.json();
            
             try {
                 
                 setToastIcon("success");
                 setToastTitle("Success");
                 setToastBackground("#5cb85c");
                 setToastMsg("Control Reported Successfully.");
                 setToastState(true);
             } catch {
                 data = rslt;
             }
             
             
            
         } catch (error) {
               setToastIcon("error");
               setToastTitle("Error");
               setToastBackground("#d9534f");
               setToastMsg("There was a Problem Reporting this Control, Please Try Again.");
               setToastState(true);
               
         }

        }
       
        
      
    }

    const overrideDispense = () => {
     
      //insert ovveride record into database
      //remove drug and close drug_detail
            setWarn(false);
            let ul ="";

            let obj = {};

            obj.pat = props.pat.IDX;
            obj.usr = getSession("usr");
            obj.pvd = props.pvd;
            obj.frm = props.drg.FRM_IDX;
                  
            if(warnTitle === "ALLERGY WARNING") {
              ul = (`${URL}allergenOverride`);
              obj._override = "ALLERGY Overridden by Provider";
              obj.alg = allergen_result[0].ALG_ROOT;

            }else {
              ul = (`${URL}interactionOverride`);
              obj._override = "Potential DRUG INTERACTION Overridden by Provider";
              obj.medid = props.drg.MEDID;
              obj.rx_bn = interaction_result[0].RxBN;  
            }

            let json = JSON.stringify(obj);
          
           //POST DATA
           const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
            body: json
            };

            fetch(`${ul}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setValErr([]);
                    setToastIcon("success");
                    setToastMsg("Override Logged Successfully");
                    setToastState(true);
                    setToastBackground("#5cb85c");
                    setToastTitle("Success");
                })            
                .catch(err => {
                    setToastIcon("error");
                    setToastTitle("Error");
                    setToastBackground("#d9534f");
                    setToastMsg("There was a Problem Logging this Overrride. Please Try Again.");
                    setToastState(true);
                   
                });

                
      
         }

         const handlePrintMonograph = () => {
            setMono(true);
         }



    const cancelDispense = () => {
    
      //remove drug and close drug_detail
      setWarn(false);
      props.close();
    }

    const err = () => {
      try {
          if (Array.isArray(valid_err)) {
            return valid_err.map((item, index)=>{
                return (
                      <ul>
                            <li className="err_list">{item}</li>
                           
                      </ul>
                 );
            });
        } else {
          return (
            <tr><td colSpan='8'><div>The Patient Allergen Profile is Empty.</div></td></tr>
          );
        }
        } catch (err) {
          return;
        }
  }

  const loadIngredients = () => {
   

    let ul = `${URL}inventoryIngredientList?mndc=${drug.MNDC}`;

    fetch(ul, {
        method: 'GET',
            headers: {
                'x-functions-key': getAPI(),	
            }
        })  //
        .then(response => response.json())
        .then(rslt => {
        
     

        setIngredients(rslt);
       

     
     })
     .catch(err => {
        setToastIcon("error");
        setToastTitle("Error");
        setToastBackground("#d9534f");
        setToastMsg("There was a Problem Retreiving the Ingredient List. Please Try Again Later.");
        setToastState(true);
        setDisabled(false);
     });
  }
   

  const closePDF = () => {

    //<TODO> setup for Print Label Sheet as well
    setPDF(false);
    setLBL(false);
    props.close();
  }

  const closeOverlay = () => {
    setOverlay(false);
}

    return (
      <DrugDetailWrapper>
         
         <Tooltip id="rx-tooltip" place="top" variant="info"  style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }}/>
         <Tooltip id="rx-tooltip1" place="top" variant="info"  style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }}/>
         <Tooltip id="rx-tooltip2" place="top" variant="info"  style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }}/>
         <Tooltip id="rx-tooltip3" place="top" variant="info"  style={{ backgroundColor: "navy", color: "#FFF", fontSize:"14px" }}/>

          <div className="drug__ln">
         
         
              <span className="drug--text">{drug.LN}</span>
             { getSession("help") ? <svg style={{marginRight: "3rem", cursor:"pointer"}} onClick={() => setOverlay(true)} width="40px" height="40px" viewBox="0 0 12 12" enable-background="new 0 0 12 12"  version="1.1"  ><path d="M6,0C2.6862793,0,0,2.6862793,0,6s2.6862793,6,6,6s6-2.6862793,6-6S9.3137207,0,6,0z M6.5,9.5h-1v-1h1V9.5z   M7.2651367,6.1738281C6.7329102,6.5068359,6.5,6.6845703,6.5,7v0.5h-1V7c0-0.9023438,0.7138672-1.3486328,1.2348633-1.6738281  C7.2670898,4.9931641,7.5,4.8154297,7.5,4.5c0-0.5517578-0.4487305-1-1-1h-1c-0.5512695,0-1,0.4482422-1,1V5h-1V4.5  c0-1.1025391,0.8969727-2,2-2h1c1.1030273,0,2,0.8974609,2,2C8.5,5.4023438,7.7861328,5.8486328,7.2651367,6.1738281z" fill="#000000"></path></svg> : null }
              <svg className="drug--close" onClick={props.close} width="32" height="32"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g><path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path></g></svg>
          </div>
          <div className="drug__details">
                <div className="form_group--1">
                    <span className="form_lbl" >Dispense Date</span>
                    <span className="form_txt">{writtenDate}</span>
                </div>
                <div className="form_group--2">
                    <span className="form_lbl" >Unit Qty</span>
                    <span className="form_txt">{drug.QTY}</span>
                </div>
                <div className="form_group--3">
                    <span className="form_lbl">Days Supply</span>
                    <span className="form_txt">{drug.DaysSupply}</span>
                </div>
                <div className="form_group--4">
                    <span className="form_lbl">Exp Date</span>
                    <span className="form_txt">{drug.ExpDate}</span>
                </div>
       
          </div>
          <div className="drug__details-2">
                    <div className="form_group--1">
                        <span className="form_lbl" >NDC</span>
                        <span className="form_txt">{drug.NDC}</span>
                    </div>
                    <div className="form_group--2">
                        <span className="form_lbl" >LOT</span>
                        <span className="form_txt">{drug.LOT}</span>
                    </div>
                    <div className="form_group--3">
                        <span className="form_lbl" >INV</span>
                        <span className="form_txt">{drug.INV}</span>
                    </div>
                    <a data-tooltip-id="rx-tooltip2"  data-tooltip-content="Click to Load Ingredient List">
                    <div className="form_group--4  ingreds" onClick={loadIngredients}>
                        <span className="form_lbl" >LOAD INGREDIENTS</span>
                        <span className="form_txt">{ingredients}</span>
                    </div>
                    </a>
            </div>
            
          <div className="drug__dosage">
          <a data-tooltip-id="rx-tooltip1"  data-tooltip-content="Load Added Custom Dosage">
              <div id="custom_container">
                    <select onChange={handleCustomSelect} id="gender"  className="form_input req">
                        <option value="0">CUSTOM DOSAGE</option>
                        {customs()}
                       
                    </select>
                    
                </div>
            </a>
                <a data-tooltip-id="rx-tooltip"  data-tooltip-content="Add Custom Dosage">
                  <svg onClick={() => setAddDosage(true)} className="svg_init" version="1.1" id="Capa_1"  viewBox="0 0 50 50"  width="64px" height="64px" fill="#000000">
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"/>
                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>
                      <g id="SVGRepo_iconCarrier"> <circle className="svg_circle"  cx="25" cy="25" r="25"/> <line className="svg_line" x1="25" y1="13" x2="25" y2="38"/> <line className="svg_line" x1="37.5" y1="25" x2="12.5" y2="25"/> </g>
                  </svg>
              </a>
            <textarea readOnly className="drug__dosage--txt" placeholder="Dosage" value={directions}></textarea> 
            <ButtonSlide width={'150%'} click={() => setNote(true)} title="ADD NOTE" />     
          </div>
          <div className="drug__btn-actions">


          { disabled_que ? <div></div> : <div className="svg-wrapper" onClick={handleDispenseQueue}>
            <svg height="60" width="3200" xmlns="http://www.w3.org/2000/svg">
                <rect className="shape" height="60" width="320" />
            </svg>
            <div className="text">QUEUE DISPENSE</div>
          </div>}

       
          {/* {showMono ? <div className="svg-wrapper" onClick={handlePrintMonograph}>
            <svg height="60" width="3200" xmlns="http://www.w3.org/2000/svg">
                <rect className="shape" height="60" width="320" />
            </svg>
            <div className="text">PRINT MONOGRAPH</div>
          </div> : null} */}

           {disabled ? <div></div> :  <div className="svg-wrapper" onClick={handleDispense}>
            <svg height="60" width="320" xmlns="http://www.w3.org/2000/svg">
                <rect className="shape" height="60" width="320" />
            </svg>
            <div className="text">PRINT DISPENSE</div>
          </div>}
        </div>

          <div className="error" >{err()}</div>

          {note ? <AddNote close={() => setNote(false)}  submit={(val) => setComment(val)}/> : null}
          {add_dosage ? <AddCustomDosage close={() => setAddDosage(false)} grp={grp} ndc={drug.NDC}  lot={drug.LOT}  drg={drug.DRG_IDX} submit={(val) => handleAddCustom(val)}/> : null}
          
            <div className="toasty">
                 {toast ?  <Toast 
                     id={74}
                     position={'bottom-center'}
                     autoDelete={true}
                     autoDeleteTime={3000}
                     title={toast_title}
                     description={toast_msg}
                     backgroundColor={toast_background}
                     icon={toast_icon}
                     close={() => setToastState(false)}

                /> : null }
            </div>

            {warn ? <WarningModal show_btn={true} cancel={cancelDispense} accept={overrideDispense} title={warnTitle} body={warnBody} /> : null }
            { lb ?  <BasicDocument close={() => setPDF(false)} active_report={`LB`} title={`PRINT DISPENSE`} idx={dsp_idx} firstname={firstname} lastname={lastname} dob={dob} lblPosition={lblPosition}  /> : null}

            { ds ?    <BasicDocument close={closePDF} active_report={lbl_type} title={`DISPENSE LABEL`} idx={dsp_idx} firstname={firstname} lastname={lastname} dob={dob}  /> : null}
            { mono ?  <BasicDocument close={() => setMono(false)} active_report={`MO`} title={`MONOGRAPH`} idx={dsp_idx}  /> : null}

            {overlay ? <Overlay close={() => setOverlay(false)} >
            <>
                        
                        <div  className="item-end" style={{gridRow: "7/8",gridColumn: "1/2", marginLeft: "-40px", alignSelf: "start", justifySelf:"end"}}>
                            <span>Saved Custom Dosages Here.</span>
                            <img src={arrow_down_right} alt="arrow" className="arrow" />
                        </div>
                        
                        <div style={{gridColumn: "3/5",gridRow: "7/8",alignSelf:"start", justifySelf: "start", marginLeft:"20px"}}>
                            <img src={arrow_down} alt="arrow" className="arrow" />
                            <span >To Add Custom Dosages click here.</span>
                        </div>

                        <div style={{gridColumn: "6/7",gridRow: "4/5",alignSelf:"end", justifySelf: "start", marginLeft:"20px"}}>
                            <img src={arrow_down} alt="arrow" className="arrow" />
                            <span >Unload the Drug Here.</span>
                        </div>
        
        
                                               
                            <div style={{gridRow:"6/7", gridColumn:"5/-1", alignSelf:"end", marginLeft: "30px"}}>
                              
                                <span >You can select Load Ingredients to view Drug Ingredients</span>
                            </div>

                            <div style={{gridRow:"8/-1", gridColumn:"2/6", alignSelf:"start", }}>
                              
                                <span >Use the Button Bar to Queue or Print the Dispense & Monograph</span>
                            </div>
                            
                            
                       
                        
                        <span className="close_overlay" onClick={closeOverlay} style={{gridColumn: "3/5", gridRow:"2/5",  fontFamily: "BenchNine", fontSize:"3rem",cursor:"pointer"}}>Click Here to Close</span>
        </> 
           </Overlay> : null}
       
           {pos ? <LabelPosition select={(pos) => getLabelPosition(pos)} close={() => setPos(false)} /> : null}
          
          </DrugDetailWrapper>
    );
  }
  
  export default DrugDetail;