import React, {useEffect, useState} from 'react';
import Toast from './toaster';
import LoadingButton from '../theme/loading-button';
import styled from 'styled-components';
import useFetch from '../hooks/useFetch';
import {URL} from '../constants';
import FormHeader from './form-header';
import ReactLoading from 'react-loading';
import OrderConfirm from './inventory-order-confirm';
import { getSession, getAPI } from '../utility';
import Overlay from '../theme/overlay';
import arrow_down_right from '../assets/arrow_down_right.png';
import arrow_up from '../assets/arrow_up.png';
import arrow_down from '../assets/arrow_down.png';



const OrderProcessWrapper = styled.div`
    height: 100vh;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    opacity: 1;
    z-index: 998;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))  {
        -webkit-backdrop-filter: grayscale(80%) blur(5px);
        backdrop-filter: grayscale(70%) blur(5px);
        background-color: rgba(#000, .4);

    }
       
      .order_container { 
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 80vh;
        width: 70%;
        
       
        /* background-color: rgba(0,0,0,.6); */
        background-color: var(--color-grey-dark-2);
        z-index: 999;
        opacity: 1;
        transition: all .3s;
   
     min-height: 43.3rem;
     border: 2rem solid  var(--color-primary);
     border-radius: 5px;

     font-size: 1.8rem;
     color: navy;
     /* color: var(--color-primary); */

      }

    .receive_inventory__form_header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;
        margin-top: 2rem;
        padding-top: 1.7rem;
        border-top: var(--line);
        border-bottom: var(--line);
        padding-bottom: 1.7rem;
        color: var(--color-primary); 
    }

    .receive_inventory__btn-bar {
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: var(--line);
        padding: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 2rem;
    }

    .rcv_dsp {
        max-width: 25rem;
        padding: 1rem 2rem;
        margin-left: 7rem;
    }

    .active_dsp {
        text-transform: uppercase;
        font-family: 'BenchNine', sans-serif;
        font-size: 2.2rem;
        margin: 0 auto;
    }


    .order_responsive-table {
      height: 55rem;
      overflow:scroll;

      @media screen and (max-height: 950px) {
          height: 40rem;
      }

      @media screen and (max-height: 780px) {
          height: 30rem;
      }

    }
  
    li {
      border-radius: 3px;
      padding: .5rem .6rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: .75rem;
    }
    .order_table-header {
      background-color: var(--color-primary);
      font-size: 1.1rem;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      color: #fff;
    }
    .order_table-row {
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
      color: var(--color-primary);
      transition: all .9s;
      cursor: pointer;
      text-transform: uppercase;
      font-size:1.75rem;
      font-weight: 700;

      &:hover {
          background-color: rgb(243, 184, 162);
          color: var(--color-primary);
      }

      &__selected {
        background-color: rgb(247, 104, 9);
        color:#fff;
      }
    }

    .order-row--light__selected {
        background-color: rgb(247, 104, 9);
        color:#fff;
   }
    .col-5 {
        flex-basis: 5%;
        pointer-events: none;
      }
      .col-10 {
        flex-basis: 10%;
        pointer-events: none;
      }
      .col-15 {
        flex-basis: 15%;
        pointer-events: none;
      }
      .col-input {
        flex-basis: 10%;
        // pointer-events: none;
      }
      .col-20 {
        flex-basis: 20%;
        pointer-events: none;
      }
    .col-25 {
      flex-basis: 25%;
      pointer-events: none;
    }
    .col-30 {
      flex-basis: 30%;
      pointer-events: none;
    }
    .col-35 {
      flex-basis: 35%;
      pointer-events: none;
    }
    .col-40 {
      flex-basis: 40%;
      pointer-events: none;
    }
    .col-50 {
        flex-basis: 50%;
        pointer-events: none;
      }
      .col-60 {
        flex-basis: 60%;
        pointer-events: none;
      }
      .col-75 {
        flex-basis: 75%;
        pointer-events: none;
      }
      .col-100 {
        flex-basis: 100%;
        pointer-events: none;
        text-align: center;
      }
    
    @media all and (max-width: 767px) {
      .table-header {
        display: none;
      }
 
      li {
        display: block;
      }
      .col {
        
        flex-basis: 100%;
        
      }
      .col {
        display: flex;
        padding: .8rem 0;
        &:before {
          color: #6C7A89;
          padding-right: 10px;
          content: attr(data-label);
          flex-basis: 50%;
          text-align: right;
        }
      }
    }
 

  .tbl-lng {
    height: 80rem;
    overflow-y:scroll;
   }

   .ord_instruct {
        font-size: 1.5rem;
        color: #fff;
        border: 1px solid #fff;
        padding: 1rem;
        border-radius: 5px;
   }
 
   



`;

const OrderProcess = (props) => {

  
  const [valid_err, setValErr] = useState([]);
   
    const [toast, setToastState] = useState(false);
    const [toast_msg, setToastMsg] = useState("");
    const [toast_icon, setToastIcon] = useState("");
    const [toast_title, setToastTitle] = useState("Success");
    const [toast_background, setToastBackground] = useState("#5cb85c");

    const [overlay, setOverlay] = useState(false);

    const [disable, setDisable] = useState(false);
    const [cln , setCln] = useState(getSession("cln"));


    const [orders, setOrders] = useState([]);

    let order_val = [];
  
    useEffect(() => {
        
       
        const query = 1;

        if (query) {
			setQuery(query);
	    }
      order_val = [];


    }, []);

   


  const [query, setQuery] = useState('');

	const url = query && `${URL}inventoryOrderList?idx=${getSession("cln")}`;
  const [confirm, setConfirm] = useState(false);
  const { status, data, error } = useFetch(url);
   
    
    const selectRow = (e) => {
       console.log(e.target.id);
       try {
       const sel = document.getElementById(e.target.id);
      
      
       let unit_qty = sel.childNodes[4].innerText;
       let ln = sel.childNodes[3].innerText;
       let frm_idx = sel.getElementsByClassName("col-order-value")[0].classList[1];

       let order_amt = order_val.find(i => i.idx === e.target.id);
       console.log(order_amt);
       console.log("order_val", order_val);

        let ord_obj = {};
        ord_obj.frm = frm_idx;
        ord_obj.cln = cln;
        ord_obj.qty = unit_qty;
        ord_obj.ln = ln;
        ord_obj.amt = order_amt.order_amt;
        
      
     
       setOrders([...orders, ord_obj]);
    
        
        if(sel) {
            sel.classList.add("order-row--light__selected");

            console.log(sel.getElementsByClassName("col-order-value")[0]);
          
        }
      } catch (err) {
        console.log(err);
        return;
      }
        
    }


    const clearReceive = () => {

        let els = document.getElementsByClassName('order-row--light__selected');
        setOrders([]);
        order_val = [];

        setDisable(false);
        setConfirm(false);

        while (els[0]) {
            els[0].classList.remove('order-row--light__selected');
        }

        var inputs, index;

        inputs = document.getElementsByTagName('input');
        for (index = 0; index < inputs.length; ++index) {
            // deal with inputs[index] element.
            inputs[index].value = 0;
        }
    }


    const submitReceive = () => {
      setDisable(true);
      setConfirm(true);
    }

    const processOrders = () => {

      setConfirm(false);
      setDisable(false);
     

      console.log("processOrders", orders);
      let uuid = crypto.randomUUID();

      let json = JSON.stringify(orders);

      console.log('processOrder',json);

      if (orders.length > 0) {
                 //POST DATA
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'x-functions-key' : getAPI() },
                body: json
                };
                fetch(`${URL}inventoryOrderInsert?guid=${uuid}`, requestOptions)
               .then((response) => {

                if (response.ok === false) {  throw new Error('Network response was not ok.') };

                    setValErr([]);
                    setToastIcon("success");
                    setToastMsg("Import Processed Successfully");
                    setToastState(true);
                    setToastBackground("#5cb85c");
                    setToastTitle("Success");
                    setOrders([]);
                    order_val = [];
                    setDisable(false);
                    clearReceive();
                    
                    
                })            
                    .catch(err => {
                      console.log(err);
                        setToastIcon("error");
                        setToastTitle("Error");
                        setToastBackground("#d9534f");
                        setToastMsg("There was a Problem Processing the Import. Please Try Again.");
                        setToastState(true);
                        setOrders([]);
                        order_val = [];
                        
                    });
      
          } 
      
         else {
                console.log("Nothing to Import");
         }


    }

    const updateOrderAmt = (e) => {
       
      
       let val = e.target.value; 

        console.log(val);
        console.log(e.target.parentNode.parentNode.id);

        let idx = e.target.parentNode.parentNode.id;

        let obj = {idx: idx, order_amt: val};

        const i = order_val.findIndex(_element => _element.idx === idx);
        if (i > -1) {
          order_val[i] = obj; 
        } else {
          order_val.push(obj);
        }

        
        let v = e.target;
        v.value = val;

    }

    const data_table = () => {
        try {
          
            if (Array.isArray(data)) {
              return data.map((item, index)=>{
               
                  return (
                   
                    <li key={index} className="order_table-row" onClick={selectRow} id={index}>
                      <div className="col col-input" >
                             <input className={`col-order-value ${item.frm}`} type="text" onChange={(e) => updateOrderAmt(e)}  defaultValue={'0'} size="3"/>   
                        </div>
                        <div className="col col-10"  data-label="Last Order">{item.LastOrderAmount}</div>
                                 
                        <div className="col col-15" data-label="Current Inv.">{item.current_inv_qty}</div>
                        <div className="col col-50" data-label="Drug">{item.LN}</div>
                        <div className="col col-10" data-label="Unit Qty">{item.UnitQty}</div>
             
                    </li>
                   );
              });
          } else {
            return (
               <li className="table-row">
                    <div>The Patient Rx History is Empty.</div>
               </li>
            );
          }
          } catch (err) {
            return;
          }
        
    }

    
    const closeOverlay = () => {
      setOverlay(false);
  }


    return(
       <OrderProcessWrapper>
        <div className="order_container">
         <FormHeader onClose={props.onClose} title="ORDER PROCESSING" addClose={true} class_style="form_header--light"></FormHeader>
         { getSession("help") ? <svg style={{marginRight: "3rem", cursor:"pointer", position:"absolute", top:"20", left: "500"}} onClick={() => setOverlay(true)} width="40px" height="40px" viewBox="0 0 12 12" enable-background="new 0 0 12 12"  version="1.1"  ><path d="M6,0C2.6862793,0,0,2.6862793,0,6s2.6862793,6,6,6s6-2.6862793,6-6S9.3137207,0,6,0z M6.5,9.5h-1v-1h1V9.5z   M7.2651367,6.1738281C6.7329102,6.5068359,6.5,6.6845703,6.5,7v0.5h-1V7c0-0.9023438,0.7138672-1.3486328,1.2348633-1.6738281  C7.2670898,4.9931641,7.5,4.8154297,7.5,4.5c0-0.5517578-0.4487305-1-1-1h-1c-0.5512695,0-1,0.4482422-1,1V5h-1V4.5  c0-1.1025391,0.8969727-2,2-2h1c1.1030273,0,2,0.8974609,2,2C8.5,5.4023438,7.7861328,5.8486328,7.2651367,6.1738281z" fill="#ffffff"></path></svg> : null}
                                 

           <div className="ord__table">

            
        {status === 'error' && <div>{error}</div>}
				{status === 'fetching' && <div className="loading"><ReactLoading type={"bars"} color={'#ffffff'} height={67} width={37} /></div>}
				{status === 'fetched' && (
                    <div className="recent__table ">
                        
                        <ul className="order_responsive-table ">
                            <li className="order_table-header">
                                <div className="col col-15">New Order Amt.</div>
                                <div className="col col-15">Last Order Amt.</div>
                                <div className="col col-15">Current Inv.</div>
                                <div className="col col-50">Drug</div>
                                <div className="col col-10">Unit Qty</div>
                               
                            </li>
                            {data_table()}
                        </ul>
            </div>
				)}

               
            </div>


           
           <div className="receive_inventory__btn-bar">

                <LoadingButton id="check" title="CLEAR SELECTED" select={clearReceive} />
                <div className="ord_instruct">Enter Amount into "New Order Amt." Input. Then select the item by clicking on the Row. It will remain orange while selected</div>
               { disable ?  null : <LoadingButton id="check2" title="SUBMIT ORDER" select={submitReceive} /> }
                    
            </div>

             
            </div>

            <div className="toasty">
                 {toast ?  <Toast 
                    id={74}
                    position={'bottom-center'}
                    autoDelete={true}
                    autoDeleteTime={3000}
                    title={toast_title}
                    description={toast_msg}
                    backgroundColor={toast_background}
                    icon={toast_icon}
                    close={() => setToastState(false)}

                /> : null }
            </div>

              {confirm ? <OrderConfirm close={clearReceive} cancel={clearReceive} accept={processOrders} title={`Order to be Submitted`} data={orders} /> : null}


              {overlay ? <Overlay close={() => setOverlay(false)} >
              <>
                        
                        <div  className="item-end" style={{gridRow: "7/8",gridColumn: "2/3", marginLeft: "20px", alignSelf: "end", justifySelf:"end"}}>
                            <span>Clear Selected Items Here.</span>
                            <img src={arrow_down_right} alt="arrow" className="arrow" />
                        </div>
                        
                        <div style={{gridColumn: "1/5",gridRow: "3/4",alignSelf:"end", justifySelf: "start", marginLeft:"90px"}}>
                            
                            <span >First Enter an Order Amount in the Input.</span>
                        </div>

                                                                     
                            <div style={{gridRow:"7/8", gridColumn:"3/-1", alignSelf:"end", marginLeft: "30px"}}>
                              
                                <span >Submit all Manually Selected Items by Clicking "Submit Order". You will be presented with a Confirmation Screen Next</span>
                            </div>

                            <div style={{gridRow:"3/4", gridColumn:"2/6", alignSelf:"start", }}>
                              
                                <span >With an Order amount entered, Click the Corresponding Row to Select it. </span>
                            </div>
                            
                            
                       
                        
                        <span className="close_overlay" onClick={closeOverlay} style={{gridColumn: "3/5", gridRow:"2/3",  fontFamily: "BenchNine", fontSize:"3rem",cursor:"pointer"}}>Click Here to Close</span>
        </> 
           </Overlay> : null}

        </OrderProcessWrapper>
    );

}
export default OrderProcess;